import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import StorageAddressTable from './storageAddressTable';

export default class AddressLinkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fromStreet: '',
      toStreet: 'Z'.repeat(15),
      fromSector: '',
      toSector: 'Z'.repeat(15),
      fromLevel: '',
      toLevel: 'Z'.repeat(15),
      fromDrawer: '',
      toDrawer: 'Z'.repeat(15),
      fromColumn: '',
      toColumn: 'Z'.repeat(15),
    }
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  }

  resetFilters = () => {
    this.setState({
      fromStreet: '',
      toStreet: 'Z'.repeat(15),
      fromSector: '',
      toSector: 'Z'.repeat(15),
      fromLevel: '',
      toLevel: 'Z'.repeat(15),
      fromDrawer: '',
      toDrawer: 'Z'.repeat(15),
      fromColumn: '',
      toColumn: 'Z'.repeat(15),
    })
  }

  previewAddressLink = async () => {
    const { fromSector, fromStreet, fromColumn, fromLevel, fromDrawer,
      toSector, toStreet, toColumn, toLevel, toDrawer } = this.state;

    await this.props.previewAddressLink({
      fromSector: fromSector.toLocaleUpperCase(), 
      toSector: toSector.toLocaleUpperCase(),
      fromStreet: fromStreet.toLocaleUpperCase(),
      toStreet: toStreet.toLocaleUpperCase(),
      fromColumn: fromColumn.toLocaleUpperCase(),
      toColumn: toColumn.toLocaleUpperCase(),
      fromLevel: fromLevel.toLocaleUpperCase(),
      toLevel: toLevel.toLocaleUpperCase(),
      fromDrawer: fromDrawer.toLocaleUpperCase(),
      toDrawer: toDrawer.toLocaleUpperCase(),
    })
  }

  persistAddressLink = async () => {
    const { fromSector, fromStreet, fromColumn, fromLevel, fromDrawer,
      toSector, toStreet, toColumn, toLevel, toDrawer } = this.state;

    await this.props.persistAddressLink({
      fromSector: fromSector.toLocaleUpperCase(),
      toSector: toSector.toLocaleUpperCase(),
      fromStreet: fromStreet.toLocaleUpperCase(),
      toStreet: toStreet.toLocaleUpperCase(),
      fromColumn: fromColumn.toLocaleUpperCase(),
      toColumn: toColumn.toLocaleUpperCase(),
      fromLevel: fromLevel.toLocaleUpperCase(),
      toLevel: toLevel.toLocaleUpperCase(),
      fromDrawer: fromDrawer.toLocaleUpperCase(),
      toDrawer: toDrawer.toLocaleUpperCase(),
    })
  }

  render() {
    let { fromSector, fromStreet, fromColumn, fromLevel, fromDrawer,
      toSector, toStreet, toColumn, toLevel, toDrawer } = this.state;

    const { showAddressLinkModal, previewAddressList, isUnlink } = this.props;

    const title = isUnlink
      ? I18n.t('BEE607' /* Desnvincular Endereços */)
      : I18n.t('BEE595' /* Gerar vínculo com endereços */)

    return (
      <Modal size='lg' isOpen={showAddressLinkModal} toggle={() => this.props.closeAddressLinkModal()}>
        <ModalHeader toggle={() => this.props.closeAddressLinkModal()}>{title}</ModalHeader>
        {!previewAddressList && <ModalBody>
          <div className="d-flex align-items-center mb-1 mt-1">
            <p>
              {I18n.t('BEE598' /* Critérios de vinculação */)}
            </p>
          </div>
          <div style={{ marginTop: 30 }}>
            <Form
              noPanel
              leftType
              setValue={this.setValue}
              inputs={(formContext) => ([
                formContext.createInputRange(
                  { from: fromSector.toLocaleUpperCase(), to: toSector.toLocaleUpperCase() },
                  { from: 'fromSector', to: 'toSector' },
                  `${I18n.t('BEE279' /* Setor */)}:`,
                  { from: '', to: 'Z'.repeat(15) }
                ),
                formContext.createInputRange(
                  { from: fromStreet.toLocaleUpperCase(), to: toStreet.toLocaleUpperCase()},
                  { from: 'fromStreet', to: 'toStreet' },
                  `${I18n.t('BEE121' /* Rua */)}:`,
                  { from: '', to: 'Z'.repeat(15) }
                ),
                formContext.createInputRange(
                  { from: fromColumn.toLocaleUpperCase(), to: toColumn.toLocaleUpperCase()},
                  { from: 'fromColumn', to: 'toColumn' },
                  `${I18n.t('BEE281' /* Coluna */)}:`,
                  { from: '', to: 'Z'.repeat(15) }
                ),
                formContext.createInputRange(
                  { from: fromLevel.toLocaleUpperCase(), to: toLevel.toLocaleUpperCase() },
                  { from: 'fromLevel', to: 'toLevel' },
                  `${I18n.t('BEE283' /* Nível */)}:`,
                  { from: '', to: 'Z'.repeat(15) }
                ),
                formContext.createInputRange(
                  { from: fromDrawer.toLocaleUpperCase(), to: toDrawer.toLocaleUpperCase() },
                  { from: 'fromDrawer', to: 'toDrawer' },
                  `${I18n.t('BEE285' /* Gaveta */)}:`,
                  { from: '', to: 'Z'.repeat(15) }
                ),
              ])}
            />
          </div>
        </ModalBody>}
        {previewAddressList && <ModalBody>
          <div className="d-flex align-items-center mb-1 mt-1">
            <StorageAddressTable
              title={I18n.t('BEE20' /* Endereços */)}
              storageAddresses={this.props.previewAddressList}
              lines={5}
            />
          </div>
        </ModalBody>}
        <ModalFooter>
          <div className="row">
            <div className="col text-right">
              {!previewAddressList && <OneClickButton
                className="btn btn-white btn-120 p-5 m-5"
                onClick={() => this.props.closeAddressLinkModal()}
              >
                {I18n.t('BEE99' /* Cancelar */)}
              </OneClickButton>}
              {previewAddressList && <OneClickButton
                className="btn btn-white btn-120 p-5 m-5"
                onClick={() => this.props.clearPreviewList()}
              >
                {I18n.t('BEE137' /* Voltar */)}
              </OneClickButton>}
              {!previewAddressList && <OneClickButton
                className="btn btn-white btn-120 p-5 m-5"
                onClick={() => this.resetFilters()}
              >
                {I18n.t('BEE605' /* Limpar Filtros */)}
              </OneClickButton>}
              {!previewAddressList && <OneClickButton
                className="btn btn-primary btn-120 p-5 m-5"
                onClick={() => this.previewAddressLink()}
              >
                {I18n.t('BEE600' /* Simular Vínculos */)}
              </OneClickButton>}
              {previewAddressList && <OneClickButton
                className={isUnlink ? "btn btn-danger btn-120 p-5 m-5" : "btn btn-primary btn-120 p-5 m-5"}
                onClick={() => this.persistAddressLink()}
              >
                {isUnlink ? I18n.t('BEE608' /* Desvincular */) : I18n.t('BEE601' /* Vincular */)}
              </OneClickButton>}
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}