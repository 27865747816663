import RestService from '../services/rest';

// GET
async function getCarriers(tableParams) {
  return RestService.postAuthenticated('carriers/list/all', tableParams);
}

async function getCarrier(carrierId) {
  return RestService.getAuthenticated(`carriers/detail?carrierId=${carrierId}`);
}

async function getCarriersOptions() {
  return RestService.getAuthenticated('carriers/list/options');
}

// POST
async function createCarrier(carrier) {
  return RestService.postAuthenticated('carriers/create', carrier);
}

async function deleteCarrier(carrierId) {
  return RestService.postAuthenticated('carriers/delete', { carrierId });
}

// PUT
async function updateCarrier(carrier) {
  return RestService.putAuthenticated('carriers/update', carrier);
}

export default {
  getCarriers,
  createCarrier,
  updateCarrier,
  getCarrier,
  deleteCarrier,
  getCarriersOptions,
};
