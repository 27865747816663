import CompaniesRequests from '../../server/companies';
import { addLoading, removeLoading } from './loading';

export const setCompanies = companies => ({
  type: 'SET_COMPANIES',
  companies,
});

export const getCompaniesList = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const companies = await CompaniesRequests.getCompanies();
    dispatch(setCompanies(companies));
  } finally {
    dispatch(removeLoading());
  }
};

export const createCompany = company => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newCompany = await CompaniesRequests.createCompany(company);
    return newCompany;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCompany = (company) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CompaniesRequests.updateCompany(company);
  } finally {
    dispatch(removeLoading());
  }
}

export const getCompany = (companyId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CompaniesRequests.getCompany(companyId);
  } finally {
    dispatch(removeLoading());
  }
}

export const getCompaniesOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CompaniesRequests.getCompaniesOptions();
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteCompany = (companyId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CompaniesRequests.deleteCompany(companyId);
  } finally {
    dispatch(removeLoading());
  }
}