import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import { getJsonContent } from '../../../../app/store/actions/bulkImport';
import {
  bulkImportUsersRange, deleteRange, getRangesList, openBulkImportRange,
} from '../../../../app/store/actions/ranges';
import DragAndDrop from '../../../../components/bulkImport/DragAndDrop';
import ImportCaption from '../../../../components/bulkImport/ImportCaption';
import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import Table from '../../../../components/table/Table';

class RangeList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      rangeUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">
              {I18n.t('BEE55' /* Ações */)}
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.showRangeDetail(rows.row)}
                >
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showEditRange(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyRange(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem
                  onClick={() => this.showDeleteRange(rows.row)}
                >
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];

    this.csvPreviewTable = [
      {
        Header: I18n.t('BEE1754' /* Código Range */),
        accessor: 'rangeCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        sortable: false,
      }, {
        Header: I18n.t('BEE1' /* Usuário */),
        accessor: 'login',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
      }, {
        Header: I18n.t('BEE2499' /* Ação */),
        accessor: 'action',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        sortable: false,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'error.error',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
        Cell: (row) => (
          <span>
            {this.renderStatusValue(row.original)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE2521' /* Linha do arquivo */),
        accessor: 'error.line',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        sortable: false,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'error.message',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        sortable: false,
      },
    ];

    this.renderStatusValue = (row) => {
      if (row?.error?.error === '') {
        return (
          <span style={{ color: 'green', fontWeight: '700' }}>
            {I18n.t('BEE2523' /* Processado com sucesso */)}
          </span>
        );
      }
      if (row?.error?.error) {
        return <span style={{ color: 'red', fontWeight: '700' }}>{I18n.t('BEE2520' /* Processado com erro */)}</span>;
      }
      if (!row.error) {
        return (
          <span style={{ color: '#F17B1E', fontWeight: '700' }}>
            {I18n.t('BEE2524' /* Aguardando processamento */)}
          </span>
        );
      }
    };
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getRanges();
    } else {
      await this.getRanges();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      rangeUpdate: null,
    });
  };

  getRanges = async () => {
    await this.props.getRangesList();
  };

  deleteRange = async (range) => {
    try {
      const result = await this.props.deleteRange(range._original.id, range._original.code);
      this.setInitState();
      if (result) {
        addNotification(
          'danger',
          I18n.t('BEE582' /* Eliminar Range */),
          I18n.t('BEE583', { 0: range.code } /* Range ${0} eliminada com sucesso! */),
          'top-right',
        );
      }
      await this.getRanges();
    } catch (error) {
      if (error.response && error.response.data && error.response.data.error && error.response.data.error.message) {
        addNotification(
          'danger',
          I18n.t('BEE582' /* Eliminar Range */),
          error.response.data.error.message,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE582' /* Eliminar Range */),
          I18n.t('BEE1627' /* Erro */),
          'top-right',
        );
      }
      this.setInitState();
    }
  };

  showRangeDetail = (range) => {
    this.props.history.push({
      pathname: ROUTES.RANGE_DETAIL,
      state: {
        range: range._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditRange = (range) => {
    this.props.history.push({
      pathname: ROUTES.RANGE_EDIT,
      state: {
        range: range._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyRange = (range) => {
    this.props.history.push({
      pathname: ROUTES.RANGE_COPY,
      state: {
        range: range._original,
      },
    });
  };

  showDeleteRange = (range) => {
    this.setState({ showModalDelete: true, rangeUpdate: range });
  };

  validProcessFile = async () => {
    if (this.props.jsonCsv === null) {
      addNotification(
        'danger',
        I18n.t('BEE2509' /* Importação em massa */),
        I18n.t('BEE2511' /* Nenhum arquivo selecionado */),
        'top-right',
      );
    } else await this.processFile();
  };

  processFile = async () => {
    const { jsonCsv } = this.props;

    const usersRangeErrors = await this.props.generateBulkImport(jsonCsv);
    await this.props.getJsonContent(usersRangeErrors);
  };

  cancelBulkImport = () => {
    this.props.setStatusBulkImport(!this.props.showBulkImport);
    this.props.getJsonContent(null);
  };

  render() {
    const {
      rangeUpdate, showModalDelete, page,
    } = this.state;
    const { rangesList = [], showBulkImport, jsonCsv = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE581' /* Ranges */)}</li>
          </ol>
        </div>
        {
          this.props.showBulkImport ? (
            <>
              <h1 className="page-header mb-0">{I18n.t('BEE581' /* Ranges */)}</h1>
              <div className="slideUpTransition">
                <div className="d-flex align-items-center mb-md-3 mb-2" />
                <div className="row">
                  <div className="col-xl-12">
                    <Panel>
                      <PanelHeader noButton />
                      <PanelBody>
                        <div className="row">
                          <div
                            style={{
                              justifyContent: 'space-evenly',
                              display: 'flex',
                              flex: 1,
                            }}
                          >
                            <ImportCaption
                              headers={[
                                { label: 'rangeCode', key: 'range' },
                                { label: 'login', key: 'userName' },
                                { label: 'action', key: 'action' },
                              ]}
                              data={[
                                {
                                  range: 'S90-99', userName: 'user.name', action: 'C',
                                },
                                {
                                  range: 'S80-85', userName: 'user.name', action: 'D',
                                },
                              ]}
                              filename="VincularUsuariosxRangeEmLote.csv"
                              legend={[
                                {
                                  nameField: 'rangeCode',
                                  observationField: I18n.t('BEE2501' /* Código do range de atuação do usuário */),
                                }, {
                                  nameField: 'userName',
                                  observationField: I18n.t(
                                    I18n.t('BEE2505'),
                                    /* Informe neste campo o login do usuário que receberá a ação */
                                  ),
                                }, {
                                  nameField: 'action',
                                  observationField: I18n.t(
                                    I18n.t('BEE2502', { 0: 'C', 1: 'D' }),
                                    /*
                                      Ação a ser executada '%{0}” ou “%{1}”! O código '%{0}” representa
                                      a ação de Criare o código '%{1}” representa a ação de Deletar
                                    */
                                  ),
                                },
                              ]}
                            />
                            <DragAndDrop />
                          </div>
                        </div>
                      </PanelBody>
                      <PanelFooter className="text-right">
                        <OneClickButton
                          type="submit"
                          className="btn btn-white m-5"
                          style={{ paddingRight: '25px', paddingLeft: '25px' }}
                          onClick={this.cancelBulkImport}
                        >
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                        <OneClickButton
                          type="submit"
                          style={{ paddingRight: '25px', paddingLeft: '25px' }}
                          className="btn btn-primary m-5"
                          onClick={() => this.validProcessFile()}
                        >
                          {I18n.t('BEE2496' /* Processar Arquivo */)}
                        </OneClickButton>
                      </PanelFooter>
                    </Panel>
                  </div>
                </div>
              </div>
              <div>
                <Table
                  downloadCSV
                  filterable
                  data={jsonCsv}
                  columns={this.csvPreviewTable}
                  expander
                  defaultPageSize={10}
                  defaultSorted={this.defaultSorted}
                  page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
                  onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
                  defaultFilterMethod={(filter, row) => {
                    const input = _.lowerCase(filter.value);
                    const value = _.lowerCase(row[filter.id]);
                    if (_.includes(value, input)) {
                      return true;
                    }
                  }}
                />
              </div>
            </>
          ) : (
            <Table
              downloadCSV
              wikiHelp={ROUTES.RANGE_HELP}
              headerTitle={I18n.t('BEE581' /* Ranges */)}
              actionButtons={(
                <div className="ml-auto d-flex">
                  <div>
                    <button
                      type="button"
                      className="btn btn-success btn-sm btn-rounded pl-2"
                      onClick={() => this.props.setStatusBulkImport(!showBulkImport)}
                    >
                      <i className="fa fa-link mr-1" />
                      {I18n.t('BEE2498' /* Vincular Usuários / Remover em Lote */)}
                    </button>
                  </div>
                  <div>
                    <Link to={ROUTES.RANGE_CREATE} className="btn btn-success btn-sm btn-rounded pl-3 pr-3 ml-1">
                      <i className="fa fa-plus mr-1" />
                      {I18n.t('BEE584' /* Incluir Range */)}
                    </Link>
                  </div>
                </div>
              )}
              panelHeaderProps={{
                onClickReload: this.getRanges,
              }}
              filterable
              data={rangesList}
              columns={this.tableColumns}
              expander
              defaultPageSize={10}
              defaultSorted={this.defaultSorted}
              page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
              onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          )
        }
        {
          showModalDelete && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.deleteRange(rangeUpdate)}
              onCancel={() => this.setInitState()}
            >
              {I18n.t('BEE585', { 0: rangeUpdate.code } /* A Range ${0} será eliminada! */)}
            </SweetAlert>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  rangesList: state.ranges && state.ranges.rangesList,
  showBulkImport: state.ranges.showBulkImport,
  jsonCsv: state.bulkImport.jsonCsv || [],
  fileCsv: state.bulkImport.fileCsv,
});

const mapDispatchToProps = (dispatch) => ({
  getRangesList: () => dispatch(getRangesList()),
  deleteRange: (rangeId, rangeCode) => dispatch(deleteRange(rangeId, rangeCode)),
  setStatusBulkImport: (showBulkImport) => dispatch(openBulkImportRange(showBulkImport)),
  generateBulkImport: (jsonCsv) => dispatch(bulkImportUsersRange(jsonCsv)),
  getJsonContent: (jsonCsv) => dispatch(getJsonContent(jsonCsv)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(RangeList));
