import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { listingByDateInboundOrderProducts } from '../../../app/store/actions/inboundOrders';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import FormatHelpers from '../../../helpers/format';
import InboundOrderHelpers from '../../../helpers/inboundOrders';
import ROUTES from '../../../config/routes';

class InboundProductMonitor extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE36' /* Recebimento */) },
      { value: I18n.t('BEE1968' /* Monitor de Produtos do Recebimento */), active: true },
    ];

    this.tableColumns = [{
      Header: I18n.t('BEE425' /* Batismo */),
      accessor: 'baptismLabel',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
    }, {
      Header: I18n.t('BEE225' /* Produto */),
      accessor: 'productCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
    }, {
      Header: I18n.t('BEE277' /* Descrição */),
      accessor: 'productShortName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 400,
    }, {
      Header: I18n.t('BEE224' /* Status */),
      accessor: 'status',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{InboundOrderHelpers.inboundOrderProductStatus(row.value)}</span>
        </div>
      ),
      Filter: ({ filter, onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => onChange(event.target.value)}
        >
          <option value="">{I18n.t('BEE793' /* Todos */)}</option>
          {InboundOrderHelpers.inboundOrderProductStatus().map((element) => {
            if (element.value !== 5 /* Bloqueado */) {
              return (
                <option
                  key={element.value}
                  value={element.value}
                >
                  {element.label}
                </option>
              );
            }
            return '';
          })}
        </select>
      ),
    }, {
      Header: I18n.t('BEE1027' /* Número do Documento */),
      accessor: 'orderNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
    }, {
      Header: I18n.t('BEE342' /* Fornecedor */),
      accessor: 'supplierName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
    }, {
      Header: I18n.t('BEE145' /* Filial */),
      accessor: 'branchCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
    }, {
      Header: I18n.t('BEE1970' /* Data de Impressão */),
      accessor: 'preConfLabelPrintAt',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      filterMethod: (filter, row) => {
        const input = _.lowerCase(filter.value);
        const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
        const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
        if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
          return true;
        }
      },
      Cell: (row) => (
        <span>
          {' '}
          {row.value ? moment(row.value).format('L LTS') : ''}
        </span>
      ),
    }, {
      Header: I18n.t('BEE578' /* Data de Criação */),
      accessor: 'createdAt',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      filterMethod: (filter, row) => {
        const input = _.lowerCase(filter.value);
        const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
        const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
        if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
          return true;
        }
      },
      Cell: (row) => (
        <span>
          {' '}
          {row.value ? moment(row.value).format('L LTS') : ''}
        </span>
      ),
    }, {
      Header: I18n.t('BEE441' /* Quantidade */),
      accessor: 'quantity',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        </div>
      ),
    }, {
      Header: I18n.t('BEE464' /* Conferida */),
      accessor: 'quantityCheck',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        </div>
      ),
    }, {
      Header: I18n.t('BEE470' /* Pendente */),
      accessor: 'pending',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        </div>
      ),
    }, {
      Header: I18n.t('BEE427' /* Armazenado */),
      accessor: 'quantityStorage',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        </div>
      ),
    }, {
      Header: I18n.t('BEE465' /* Danificada */),
      accessor: 'quantityDamage',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        </div>
      ),
    }, {
      Header: I18n.t('BEE176' /* Divergência */),
      accessor: 'quantityDivergence',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        </div>
      ),
    }, {
      Header: I18n.t('BEE384' /* Unidade */),
      accessor: 'unitMeasure',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
    }, {
      Header: I18n.t('BEE466' /* Usuário Conferência */),
      accessor: 'checkUserName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
    }, {
      Header: I18n.t('BEE467' /* Data Conferência */),
      accessor: 'checkProductAt',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      filterMethod: (filter, row) => {
        const input = _.lowerCase(filter.value);
        const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
        const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
        if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
          return true;
        }
      },
      Cell: (row) => (
        <span>
          {' '}
          {row.value ? moment(row.value).format('L LTS') : ''}
        </span>
      ),
    }, {
      Header: I18n.t('BEE468' /* Usuário Armazenagem */),
      accessor: 'storageUserName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
    }, {
      Header: I18n.t('BEE469' /* Data Armazenagem */),
      accessor: 'storageProductAt',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      filterMethod: (filter, row) => {
        const input = _.lowerCase(filter.value);
        const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
        const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
        if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
          return true;
        }
      },
      Cell: (row) => (
        <span>
          {' '}
          {row.value ? moment(row.value).format('L LTS') : ''}
        </span>
      ),
    }];

    this.state = {
      inboundOrderProductList: [],
      defaultPageSize: 5,
      defaultSorted: [{ id: 'baptismLabel', desc: false }],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    };
  }

  async componentDidMount() {
    await this.listingByDateInboundOrderProducts();
  }

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });
    await this.listingByDateInboundOrderProducts();
  };

  listingByDateInboundOrderProducts = async () => {
    const { startDate, endDate } = this.state;

    try {
      const inboundOrderProduct = await this.props.listingByDateInboundOrderProducts(moment(
        startDate,
      ).format(), moment(endDate).format());

      if (inboundOrderProduct && inboundOrderProduct.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1968' /* Monitor de Produtos do Recebimento */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({
          inboundOrderProductList: inboundOrderProduct,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification(
          'danger',
          I18n.t('BEE1968' /* Monitor de Produtos do Recebimento */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1968' /* Monitor de Produtos do Recebimento */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      defaultPageSize, defaultSorted, inboundOrderProductList,
    } = this.state;

    return (
      <Table
        breadcrumb={this.breadcrumb}
        headerTitle={I18n.t('BEE1968' /* Monitor de Produtos do Recebimento */)}
        wikiHelp={ROUTES.INBOUND_PRODUCT_MONITOR_HELP}
        downloadCSV
        panelHeaderProps={{
          noButton: false,
          // onClickReload: this.outboundOrdersConsolidation
          // children: I18n.t('BEE44' /* Consolidação */).toUpperCase(),
        }}
        datePicker={{
          handleDateApplyEvent: this.handleDateApplyEvent,
        }}
        // filtered={[{ id: "status", value: 2 }]}
        filterable
        expander
        data={inboundOrderProductList}
        columns={this.tableColumns}
        defaultPageSize={defaultPageSize}
        defaultSorted={defaultSorted}
        defaultFilterMethod={(filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row[filter.id]);
          if (_.includes(value, input)) {
            return true;
          }
        }}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  listingByDateInboundOrderProducts: (filterStartDate, filterEndDate) => dispatch(
    listingByDateInboundOrderProducts(filterStartDate, filterEndDate),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InboundProductMonitor));
