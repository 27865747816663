import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

import OneClickButton from '../../../components/form/button';
import Form from '../../../components/form/form';
import addNotification from '../../../components/notification';

class ConfirmModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inventoryType: '1',
      branchInInventory: false,
      complementAddress: true,
      transactionDate: new Date(),
      grouperCode: '',
      mandatoryComplementAddress: true,
    };
  }

  async componentDidUpdate(prevProps) {
    const params = this.props.tableFilter;

    if (params && params !== prevProps.tableFilter) {
      const branchCode = params.branchCode.value;
      const type = this.state.inventoryType === '1' ? 'R' : 'G';

      const { transactionDate } = this.state;

      const grouperAux = await this.props.getNewGrouperCode(branchCode, type, transactionDate);

      this.setState({
        grouperCode: grouperAux.grouperCode,
        mandatoryComplementAddress: grouperAux.mandatoryComplementAddress,
      });
    }
  }

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });

    if (attr === 'inventoryType') {
      if (value === '1') {
        this.setState({
          branchInInventory: false,
        });
      } else if (value === '2') {
        this.setState({
          branchInInventory: true,
        });
      }
    }

    if (attr === 'inventoryType' || attr === 'transactionDate') {
      const params = this.props.tableFilter;

      if (params) {
        const branchCode = params.branchCode.value;
        const type = attr === 'inventoryType'
          ? value === '1' ? 'R' : 'G'
          : this.state.inventoryType === '1'
            ? 'R' : 'G';
        const dateAux = attr === 'transactionDate'
          ? value
          : this.state.transactionDate;

        const grouperAux = await this.props.getNewGrouperCode(branchCode, type, dateAux);

        this.setState({
          grouperCode: grouperAux.grouperCode,
          mandatoryComplementAddress: grouperAux.mandatoryComplementAddress,
        });
      }
    }
  };

  submitConfirm = () => {
    const {
      grouperCode, inventoryType, branchInInventory, transactionDate, complementAddress,
    } = this.state;

    if (!grouperCode || grouperCode === '') {
      addNotification(
        'danger',
        'Agrupador',
        'Agrupador deve ser informado',
        'top-right',
      );
    } else {
      this.props.submitConfirm({
        grouperCode, inventoryType, branchInInventory, transactionDate, complementAddress,
      });
    }
  };

  render() {
    const {
      inventoryType, branchInInventory, transactionDate, grouperCode, complementAddress, mandatoryComplementAddress,
    } = this.state;
    const { showConfirmModal = false } = this.props;

    return (
      <Modal size="md" isOpen={showConfirmModal} toggle={this.props.closeConfirm} className="adjustmentPadding">
        <ModalHeader toggle={this.props.closeConfirm}>Parâmetros Inventário</ModalHeader>
        <ModalBody>
          <div style={{ marginTop: 20 }}>
            <Form
              noPanel
              isModal
              headerProps={{
                className: 'm-b-0',
              }}
              setValue={this.setValue}
              setValueDrop={this.setValueDrop}
              inputs={(formContext) => ([
                formContext.createInput(
                  grouperCode,
                  'grouperCode',
                  'Agrupador:',
                  'Informe o Agrupador',
                  'text',
                  false,
                  false,
                  undefined,
                  undefined,
                  undefined,
                  null,
                  20,
                ),
                formContext.createRadioButtons([
                  {
                    label: I18n.t('BEE2085' /* Rotativo */),
                    value: 1,
                  }, {
                    label: I18n.t('BEE191' /* Geral */),
                    value: 2,
                  },
                ], inventoryType, 'inventoryType', `${I18n.t('BEE2046' /* Tipo Inventário */)}:`, undefined, undefined, 'm-l-10', ['col-md-3']),
                formContext.createCheckBoxes([
                  {
                    value: branchInInventory,
                    attr: 'branchInInventory',
                    disabled: true,
                    label: I18n.t('BEE779' /* Filial em Inventário */),
                  },
                ], ' ', undefined, ['col-md-3', 'col-md-6']),
                formContext.createCheckBoxes([
                  {
                    value: complementAddress,
                    attr: 'complementAddress',
                    label: 'Gerar Ficha para Endereços Complementares',
                    disabled: mandatoryComplementAddress,
                  },
                ], ' ', undefined, ['col-md-3', 'col-md-8']),
                formContext.createDateInput(
                  transactionDate,
                  'transactionDate',
                  `${I18n.t('BEE2088' /* Data Transação */)}:`,
                  false,
                  undefined,
                  ['col-md-3', 'col-md-4'],
                ),
              ])}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-120 btn-white p-5 m-5" onClick={this.props.closeConfirm}>
            {I18n.t('BEE99' /* Cancelar */)}
          </button>
          <OneClickButton type="button" className="btn btn-120 btn-primary p-5 m-5" onClick={this.submitConfirm}>
            Confirmar
          </OneClickButton>
        </ModalFooter>
      </Modal>

    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ConfirmModal));
