import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import DragAndDrop from '../../../../components/bulkImport/DragAndDrop';
import ImportCaption from '../../../../components/bulkImport/ImportCaption';
import OneClickButton from '../../../../components/form/button';

import { getJsonContent } from '../../../../app/store/actions/bulkImport';

import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import {
  getCapacitiesList,
  deleteCapacity,
  openBulkImportCapacity,
  bulkImportCapacity,
} from '../../../../app/store/actions/capacities';

class CapacityList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      capacityUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.showCapacityDetail(rows.row)}
                >
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.showCapacity(rows.row)}
                >
                  {I18n.t('BEE57' /* Editar */)}
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.showCopyCapacity(rows.row)}
                >
                  {I18n.t('BEE80' /* Copiar */)}
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.showDeleteCapacity(rows.row)}
                >
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      },
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'description',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE1491' /* Classificação de Endereço */),
        accessor: 'sizeName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE2662' /* Capacidade Máxima (UN) */),
        accessor: 'maximumCapacity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 70,
      }, {
        Header: I18n.t('BEE2663' /* Ponto de Ressuprimento (UN) */),
        accessor: 'referencePointUnity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 70,
      }, {
        Header: I18n.t('BEE2664' /* Ponto de Ressuprimento (%) */),
        accessor: 'referencePointPercentage',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 70,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'statusName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row._original.statusCode;

          if (_.includes(_.lowerCase(I18n.t('BEE207' /* Bloqueado */)), input)
            && _.includes(_.lowerCase(I18n.t('BEE499' /* Ativo */)), input)) {
            return true;
          }

          if (_.includes(_.lowerCase(I18n.t('BEE207' /* Bloqueado */)), input)) {
            return id === 0;
          }
          if (_.includes(_.lowerCase(I18n.t('BEE499' /* Ativo */)), input)) {
            return id === 1;
          }
        },
        Cell: (row) => (
          <span>
            <span>
              <i
                className="fa fa-circle f-s-7 fa-fw m-r-10 m-t-5"
                style={{
                  transition: 'all .3s ease',
                  color: row.value === I18n.t('BEE499' /* Ativo */) ? 'green' : 'red',
                }}
              />
              {row.value}
            </span>
          </span>
        ),
      },
    ];

    this.csvPreviewTable = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        sortable: false,
      },
      {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        sortable: false,
      }, {
        Header: I18n.t('BEE1491' /* Classificação de Endereço */),
        accessor: 'storageAddressSize',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        sortable: false,
      }, {
        Header: I18n.t('BEE2662' /* Capacidade Máxima (UN) */),
        accessor: 'maximumCapacity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
      }, {
        Header: I18n.t('BEE2663' /* Ponto de Ressuprimento (UN) */),
        accessor: 'referencePointUnity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
      }, {
        Header: I18n.t('BEE2664' /* Ponto de Ressuprimento (%) */),
        accessor: 'referencePointPercentage',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
      }, {
        Header: I18n.t('BEE2499' /* Ação */),
        accessor: 'action',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        sortable: false,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'error.error',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
        Cell: (row) => (
          <span>
            {this.renderStatusValue(row.original)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE2521' /* Linha do arquivo */),
        accessor: 'error.line',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 60,
        sortable: false,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'error.message',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        sortable: false,
      },
    ];

    this.renderStatusValue = (row) => {
      if (row?.error?.error === '') {
        return (
          <span style={{ color: 'green', fontWeight: '700' }}>
            {I18n.t('BEE2523' /* Processado com sucesso */)}
          </span>
        );
      }
      if (row?.error?.error) {
        return <span style={{ color: 'red', fontWeight: '700' }}>{I18n.t('BEE2520' /* Processado com erro */)}</span>;
      }
      if (!row.error) {
        return (
          <span style={{ color: '#F17B1E', fontWeight: '700' }}>
            {I18n.t('BEE2524' /* Aguardando processamento */)}
          </span>
        );
      }
    };
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getCapacities();
    } else {
      await this.getCapacities();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      capacityUpdate: null,
    });
  };

  getCapacities = async () => {
    await this.props.getCapacitiesList();
  };

  deleteCapacity = async (capacity) => {
    const result = await this.props.deleteCapacity(capacity._original.id);
    this.setInitState();
    if (result) {
      addNotification(
        'success',
        I18n.t('BEE2711' /* Eliminar capacidade */),
        I18n.t('BEE2712', { 0: capacity.code } /* Capacidade eliminado com sucesso! */),
        'top-right',
      );
    } else {
      addNotification(
        'danger',
        I18n.t('BEE2711' /* Eliminar capacidade */),
        result.message,
        'top-right',
      );
    }
    await this.getCapacities();
  };

  showCapacityDetail = (capacity) => {
    this.props.history.push({
      pathname: ROUTES.CAPACITY_DETAIL,
      state: {
        capacity: capacity._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCapacity = (capacity) => {
    this.props.history.push({
      pathname: ROUTES.CAPACITY_EDIT,
      state: {
        capacity: capacity._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyCapacity = (capacity) => {
    this.props.history.push({
      pathname: ROUTES.CAPACITY_COPY,
      state: {
        capacity: capacity._original,
      },
    });
  };

  showDeleteCapacity = (capacity) => {
    this.setState({ showModalDelete: true, capacityUpdate: capacity });
  };

  validProcessFile = async () => {
    if (this.props.jsonCsv === null) {
      addNotification(
        'danger',
        I18n.t('BEE2509' /* Importação em massa */),
        I18n.t('BEE2511' /* Nenhum arquivo selecionado */),
        'top-right',
      );
    } else {
      await this.processFile();
      await this.getCapacities();
    }
  };

  processFile = async () => {
    const { jsonCsv } = this.props;
    const capacityErrors = await this.props.generateBulkImport(jsonCsv);
    await this.props.getJsonContent(capacityErrors);
  };

  cancelBulkImport = () => {
    this.props.setStatusBulkImport(!this.props.showBulkImport);
    this.props.getJsonContent(null);
  };

  render() {
    const { capacityUpdate, showModalDelete, page } = this.state;
    const { showBulkImport, capacitiesList = [], jsonCsv = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">
              {I18n.t('BEE389' /* Capacidade */)}
            </li>
          </ol>
        </div>
        {
          this.props.showBulkImport ? (
            <>
              <h1 className="page-header mb-0">
                {I18n.t('BEE2658' /* Capacidade Produto x Classificação x Filial */)}
              </h1>
              <div className="slideUpTransition">
                <div className="d-flex align-items-center mb-md-3 mb-2" />
                <div className="row">
                  <div className="col-xl-12">
                    <Panel>
                      <PanelHeader noButton />
                      <PanelBody>
                        <div className="row">
                          <div
                            style={{
                              justifyContent: 'space-evenly',
                              display: 'flex',
                              flex: 1,
                            }}
                          >
                            <ImportCaption
                              headers={[
                                { label: 'branchCode', key: 'branchCode' },
                                { label: 'productCode', key: 'productCode' },
                                { label: 'storageAddressSize', key: 'storageAddressSize' },
                                { label: 'maximumCapacity', key: 'maximumCapacity' },
                                { label: 'referencePointUnity', key: 'referencePointUnity' },
                                { label: 'referencePointPercentage', key: 'referencePointPercentage' },
                                { label: 'status', key: 'status' },
                                { label: 'action', key: 'action' },
                              ]}
                              data={[
                                {
                                  branchCode: '04041',
                                  productCode: '10',
                                  storageAddressSize: 'padrao',
                                  maximumCapacity: 1000,
                                  referencePointUnity: 100,
                                  referencePointPercentage: 10,
                                  status: 1,
                                  action: 'C',
                                },
                                {
                                  branchCode: '04041',
                                  productCode: '100',
                                  storageAddressSize: 'Grande',
                                  maximumCapacity: 1000,
                                  referencePointUnity: 100,
                                  referencePointPercentage: 10,
                                  status: 0,
                                  action: 'D',
                                }, {
                                  branchCode: '04041',
                                  productCode: '10',
                                  storageAddressSize: 'padrao',
                                  maximumCapacity: 2000,
                                  referencePointUnity: 200,
                                  referencePointPercentage: 10,
                                  status: 1,
                                  action: 'A',
                                },
                              ]}
                              filename="CapacidadeEmLote.csv"
                              legend={[
                                {
                                  nameField: 'branchCode',
                                  observationField: I18n.t('BEE1994' /* Código Filial */),
                                }, {
                                  nameField: 'productCode',
                                  observationField: I18n.t('BEE1999' /* Código Produto */),
                                }, {
                                  nameField: 'storageAddressSize',
                                  observationField: I18n.t('BEE1491' /* Classificação de Endereço */),
                                }, {
                                  nameField: 'maximumCapacity',
                                  observationField: I18n.t('BEE2662' /* Capacidade Máxima (UN) */),
                                }, {
                                  nameField: 'referencePointUnity',
                                  observationField: I18n.t('BEE2663' /* Ponto de Ressuprimento (UN) */),
                                }, {
                                  nameField: 'referencePointPercentage',
                                  observationField: I18n.t('BEE2664' /* Ponto de Ressuprimento (%) */),
                                }, {
                                  nameField: 'status',
                                  observationField: I18n.t('BEE2706' /* Status - 0 para bloqueiado, 1 - para ativo */),
                                }, {
                                  nameField: 'action',
                                  observationField: I18n.t(
                                    I18n.t('BEE2695', { 0: 'C', 1: 'D', 2: 'A' }),
                                    /*
                                      Ação a ser executada '%{0}” , “%{1}” %{2}!
                                      O código '%{0}” representa a ação de Criar
                                      O código '%{1}” representa a ação de Deletar
                                      O código '%{2}” representa a ação de Alterar
                                    */
                                  ),
                                },
                              ]}
                            />
                            <DragAndDrop />
                          </div>
                        </div>
                      </PanelBody>
                      <PanelFooter className="text-right">
                        <OneClickButton
                          type="submit"
                          className="btn btn-white m-5"
                          style={{ paddingRight: '25px', paddingLeft: '25px' }}
                          onClick={this.cancelBulkImport}
                        >
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                        <OneClickButton
                          type="submit"
                          style={{ paddingRight: '25px', paddingLeft: '25px' }}
                          className="btn btn-primary m-5"
                          onClick={() => this.validProcessFile()}
                        >
                          {I18n.t('BEE2496' /* Processar Arquivo */)}
                        </OneClickButton>
                      </PanelFooter>
                    </Panel>
                  </div>
                </div>
              </div>
              <div>
                <Table
                  downloadCSV
                  filterable
                  data={jsonCsv}
                  columns={this.csvPreviewTable}
                  expander
                  defaultPageSize={10}
                  defaultSorted={this.defaultSorted}
                  page={(page) || ((!this.state.firstRun
                    && this.props.location.state && this.props.location.state.dice
                    && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
                  onPageChange={(Page) => ((Page >= 0) ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
                  defaultFilterMethod={(filter, row) => {
                    const input = _.lowerCase(filter.value);
                    const value = _.lowerCase(row[filter.id]);
                    if (_.includes(value, input)) {
                      return true;
                    }
                  }}
                />
              </div>
            </>
          ) : (
            <Table
              downloadCSV
              wikiHelp={ROUTES.CAPACITY_HELP}
              headerTitle={I18n.t('BEE2658' /* Capacidade Produto x Classificação x Filial */)}
              actionButtons={(
                <div className="ml-auto d-flex">
                  <div>
                    <button
                      type="button"
                      className="btn btn-success btn-sm btn-rounded pl-2"
                      onClick={() => this.props.setStatusBulkImport(!showBulkImport)}
                    >
                      <i className="fa fa-link mr-1" />
                      {I18n.t('BEE2694' /* Vincular / Remover Capacidades em Lote */)}
                    </button>
                  </div>

                  <Link to={ROUTES.CAPACITY_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3 ml-1">
                    <i className="fa fa-plus mr-1" />
                    {' '}
                    {I18n.t('BEE2659' /* + Cadastrar Capacidade */)}
                  </Link>
                </div>
              )}
              panelHeaderProps={{
                onClickReload: this.getCapacities,
              }}
              filterable
              data={capacitiesList}
              columns={this.tableColumns}
              expander
              defaultPageSize={10}
              defaultSorted={this.defaultSorted}
              page={(page) || (
                (!this.state.firstRun && this.props.location.state
                  && this.props.location.state.dice && this.props.location.state.dice.page)
                  ? this.props.location.state.dice.page : page)}
              onPageChange={(Page) => ((Page >= 0)
                ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          )
        }
        {(showModalDelete
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.deleteCapacity(capacityUpdate)}
              onCancel={() => this.setInitState()}
            >
              {I18n.t('BEE2713', { 0: capacityUpdate.description } /* Capacidade será eliminado! */)}
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  capacitiesList: state.capacities.capacitiesList || [],
  showBulkImport: state.capacities.showBulkImport,
  jsonCsv: state.bulkImport.jsonCsv || [],
  fileCsv: state.bulkImport.fileCsv,
});

const mapDispatchToProps = (dispatch) => ({
  getCapacitiesList: () => dispatch(getCapacitiesList()),
  setStatusBulkImport: (showBulkImport) => dispatch(openBulkImportCapacity(showBulkImport)),
  deleteCapacity: (capacityId) => dispatch(deleteCapacity(capacityId)),
  generateBulkImport: (jsonCsv) => dispatch(bulkImportCapacity(jsonCsv)),
  getJsonContent: (jsonCsv) => dispatch(getJsonContent(jsonCsv)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CapacityList));
