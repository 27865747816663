/* eslint-disable react/sort-comp */
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';

import {
  Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import {
  Panel,
  PanelBody, PanelFooter,
  PanelHeader,
} from '../../../../components/panel/panel';

import { getBranchesOptions } from '../../../../app/store/actions/branches';
import { getDepositsOptions } from '../../../../app/store/actions/deposits';
import { createTypeOrder, updateTypeOrders, getAllocateBalanceDeposits } from '../../../../app/store/actions/typeOrder';
import ROUTES from '../../../../config/routes';
import DualList from '../../../../components/pages/DualList';

class TypeOrdersForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      typeOrderId: '',
      activeTab: '1',
      mode: 'detail',
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      type: '',
      name: '',
      prefix: '',
      disablePrefix: true,
      disableName: true,
      disableType: true,
      disableBranchCode: true,
      disableDepositCode: true,
      branchName: '',
      branchCode: '',
      depositName: '',
      depositCode: '',
      listBranch: [],
      listDeposit: [],
      requestWeightDimensions: false,
      mandatoryWeightDimensions: false,
      preConference: false,
      receiveLeftover: false,
      partialInboundCheck: false,
      inboundCheckPlate: false,
      disableRequestProduct: true,
      disableMandatoryProduct: true,
      disablePreConference: true,
      disableReceiveLeftover: true,
      disablePartialInboundCheck: true,
      disableInboundCheckPlate: true,
      usesCableCuttingPlan: false,
      consolidation: false,
      dockControl: false,
      automaticPickingConfirmation: false,
      blindConference: false,
      automaticSeparationAfterCuttingCables: false,
      restrictionPicking: false,
      automaticCheckAfterPicking: false,
      disableConsolidation: true,
      disableDockControl: true,
      disableBlindConference: true,
      disableAutomaticSeparationAfterCuttingCables: true,
      disabledRestrictionPicking: true,
      disableAutomaticCheckAfterPicking: true,
      disableUsesCableCuttingPlan: true,
      usesIndustryProcess: false,
      disabledUsesIndustryProcess: true,
      productionSeparation: false,
      disabledProductionSeparation: true,
      awaitBilling: false,
      disabledAwaitBilling: true,
      automaticAllocation: false,
      disabledAutomaticAllocation: true,
      usesRequestManual: false,
      disableAutomaticPickingConfirmation: true,
      disabledUsesRequestManual: true,
      optionsAllocateBalanceDeposits: [],
      selectedAllocateBalanceDeposits: [],
      urgentAutoAssignSeparator: false,
      disableUrgentAutoAssignSeparator: true,
      fullAllocation: false,
      disableFullAllocation: true,
      documentSeparationUzSuggestion: false,
      disableDocumentSeparationUzSuggestion: true,
      separateProductIntegration: false,
      disableSeparateProductIntegration: true,
    };
  }

  async componentDidMount() {
    await this.getOptions();
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getOrderTypeSelected(false);
    }
  }

  onChangeDualList = (selected) => {
    this.setState({ selectedAllocateBalanceDeposits: selected });
  };

  getOptions = async () => {
    let orderSelectedId = '';

    if (this.props.location.state && this.props.location.state.orderType) {
      const { orderType } = this.props.location.state;
      const orderSelected = await orderType;

      if (orderSelected) orderSelectedId = orderSelected.id;
    }

    const listBranch = await this.props.getBranchesOptions();
    const listDeposit = await this.props.getDepositsOptions();
    const listAllocateBalanceDeposit = await this.props.getAllocateBalanceDeposits(orderSelectedId);
    const branchCodeUser = await this.props.userLogged.mainBranch;
    const selectBranch = listBranch.find((element) => element.value === branchCodeUser);

    if (selectBranch) {
      this.setState({
        branchCode: selectBranch.value,
        branchName: selectBranch.label,
      });
    }

    this.setState({
      listBranch,
      listDeposit,
      optionsAllocateBalanceDeposits: listAllocateBalanceDeposit.options || [],
      selectedAllocateBalanceDeposits: listAllocateBalanceDeposit.selected || [],
    });
  };

  setBranch = async (branchCode) => {
    const { listBranch } = this.state;
    const selectBranch = listBranch.find((element) => element.value === branchCode);

    if (selectBranch) {
      this.setState({
        branchCode: selectBranch.value,
        branchName: selectBranch.label,
      });
    }
  };

  setDeposit = async (depositCode) => {
    const { listDeposit } = this.state;
    const selectDeposit = listDeposit.find((element) => element.value === depositCode);

    if (selectDeposit) {
      this.setState({
        depositCode: selectDeposit.value,
        depositName: selectDeposit.label,
      });
    }
  };

  getOrderTypeSelected = async () => {
    if (this.props.location.state.orderType) {
      const { orderType } = this.props.location.state;
      const orderSelected = await orderType;

      if (orderSelected) {
        await this.setBranch(orderSelected.branchCode);
        await this.setDeposit(orderSelected.depositCode);

        this.setState({
          typeOrderId: orderSelected.id,
          type: orderSelected.type,
          name: orderSelected.name,
          branchCode: orderSelected.branchCode,
          depositCode: orderSelected.depositCode,
          requestWeightDimensions: orderSelected.requestWeightDimensions,
          mandatoryWeightDimensions: orderSelected.mandatoryWeightDimensions,
          preConference: orderSelected.preConference,
          receiveLeftover: orderSelected.receiveLeftover,
          partialInboundCheck: orderSelected.partialInboundCheck,
          inboundCheckPlate: orderSelected.inboundCheckPlate,
          usesCableCuttingPlan: orderSelected.usesCableCuttingPlan,
          automaticSeparationAfterCuttingCables: orderSelected.automaticSeparationAfterCuttingCables,
          blindConference: orderSelected.blindConference,
          consolidation: orderSelected.consolidation,
          dockControl: orderSelected.dockControl,
          restrictionPicking: orderSelected.restrictionPicking,
          automaticCheckAfterPicking: orderSelected.automaticCheckAfterPicking,
          usesIndustryProcess: orderSelected.usesIndustryProcess,
          productionSeparation: orderSelected.productionSeparation,
          awaitBilling: orderSelected.awaitBilling,
          automaticAllocation: orderSelected.automaticAllocation,
          usesRequestManual: orderSelected.usesRequestManual,
          urgentAutoAssignSeparator: orderSelected.urgentAutoAssignSeparator,
          automaticPickingConfirmation: orderSelected.automaticPickingConfirmation,
          prefix: orderSelected.prefix,
          documentSeparationUzSuggestion: orderSelected.documentSeparationUzSuggestion,
          fullAllocation: orderSelected.fullAllocation,
          separateProductIntegration: orderSelected.separateProductIntegration,
        });
      }
    }
  };

  setMode = () => {
    let mode;
    if (this.props.isCreate) mode = 'create';
    else if (this.props.isCopy) mode = 'copy';
    else if (this.props.isEdit) mode = 'edit';
    else if (this.props.isDetail) mode = 'detail';
    else mode = '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableName: false,
        disableType: false,
        disableDepositCode: false,
        disableBranchCode: false,
        disableRequestProduct: false,
        disableMandatoryProduct: false,
        disablePreConference: false,
        disableReceiveLeftover: false,
        disablePartialInboundCheck: false,
        disableInboundCheckPlate: false,
        disableUsesCableCuttingPlan: false,
        disableConsolidation: false,
        disableDockControl: false,
        disablePrefix: false,
        disableDocumentSeparationUzSuggestion: false,
        disableBlindConference: false,
        disableAutomaticSeparationAfterCuttingCables: false,
        disabledRestrictionPicking: false,
        disableAutomaticCheckAfterPicking: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        disabledUsesIndustryProcess: false,
        disableAutomaticPickingConfirmation: false,
        disabledProductionSeparation: false,
        disabledAwaitBilling: false,
        disabledAutomaticAllocation: false,
        disabledUsesRequestManual: false,
        disableUrgentAutoAssignSeparator: false,
        disableFullAllocation: false,
        disableSeparateProductIntegration: false,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableName: false,
        disableType: true,
        disableBranchCode: true,
        disableDepositCode: false,
        disableRequestProduct: false,
        disableMandatoryProduct: false,
        disablePrefix: false,
        disableDocumentSeparationUzSuggestion: false,
        disablePreConference: false,
        disableReceiveLeftover: false,
        disablePartialInboundCheck: false,
        disableInboundCheckPlate: false,
        disableUsesCableCuttingPlan: false,
        disableConsolidation: false,
        disableDockControl: false,
        disableBlindConference: false,
        disableAutomaticSeparationAfterCuttingCables: false,
        disabledRestrictionPicking: false,
        disableAutomaticCheckAfterPicking: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
        disabledUsesIndustryProcess: false,
        disabledProductionSeparation: false,
        disabledAwaitBilling: false,
        disabledAutomaticAllocation: false,
        disabledUsesRequestManual: false,
        disableUrgentAutoAssignSeparator: false,
        disableAutomaticPickingConfirmation: false,
        disableFullAllocation: false,
        disableSeparateProductIntegration: false,
      });
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : '',
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  createTypeOrder = async () => {
    const {
      name, branchCode, depositCode, usesIndustryProcess, type, productionSeparation, restrictionPicking,
      automaticCheckAfterPicking, automaticSeparationAfterCuttingCables, blindConference, awaitBilling,
      consolidation, dockControl, inboundCheckPlate, mandatoryWeightDimensions, partialInboundCheck, fullAllocation,
      preConference, receiveLeftover, requestWeightDimensions, usesCableCuttingPlan, usesRequestManual, prefix,
      selectedAllocateBalanceDeposits, automaticAllocation, urgentAutoAssignSeparator, automaticPickingConfirmation,
      documentSeparationUzSuggestion, separateProductIntegration,
    } = this.state;

    try {
      const newOrder = await this.props.createTypeOrderSubmit({
        branchCode,
        depositCode,
        name,
        type,
        prefix,
        documentSeparationUzSuggestion,
        requestWeightDimensions,
        mandatoryWeightDimensions,
        preConference,
        receiveLeftover,
        partialInboundCheck,
        inboundCheckPlate,
        usesIndustryProcess,
        productionSeparation,
        awaitBilling,
        automaticAllocation,
        usesCableCuttingPlan,
        consolidation,
        dockControl,
        blindConference,
        automaticSeparationAfterCuttingCables,
        automaticCheckAfterPicking,
        restrictionPicking,
        usesRequestManual,
        urgentAutoAssignSeparator,
        automaticPickingConfirmation,
        allocateBalanceDeposits: selectedAllocateBalanceDeposits.join('|'),
        fullAllocation,
        separateProductIntegration: productionSeparation && separateProductIntegration,
      });

      if (newOrder) {
        if (newOrder.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE2801' /* Novo Tipo de Documento */),
            I18n.t('BEE2805', { 0: type } /* Erro ao incluir o Tipo de Documento %{0} */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE2801' /* Novo Tipo de Documento */),
            I18n.t('BEE2804', { 0: type } /* Tipo de documento %{0} incluído com sucesso ! */),
            'top-right',
          );
          this.props.history.push(ROUTES.ORDERS_TYPE_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;
        await addNotification(
          'danger',
          I18n.t('BEE2801' /* Novo Tipo de Documento */),
          `${error.code} - ${error.message || error.details}`,
          'top-right',
        );
      }
    }
  };

  updateTypeOrderSubmit = async () => {
    const {
      name, type, branchCode, depositCode, typeOrderId, productionSeparation, restrictionPicking, awaitBilling,
      usesIndustryProcess, automaticCheckAfterPicking, automaticSeparationAfterCuttingCables, usesRequestManual,
      blindConference, consolidation, dockControl, inboundCheckPlate, mandatoryWeightDimensions, automaticAllocation,
      partialInboundCheck, preConference, receiveLeftover, requestWeightDimensions, usesCableCuttingPlan, prefix,
      selectedAllocateBalanceDeposits, urgentAutoAssignSeparator, automaticPickingConfirmation, fullAllocation,
      documentSeparationUzSuggestion, separateProductIntegration,
    } = this.state;

    try {
      await this.props.updateTypeOrder({
        id: typeOrderId,
        branchCode,
        depositCode,
        name,
        type,
        prefix,
        requestWeightDimensions,
        documentSeparationUzSuggestion,
        mandatoryWeightDimensions,
        preConference,
        receiveLeftover,
        partialInboundCheck,
        inboundCheckPlate,
        usesIndustryProcess,
        productionSeparation,
        awaitBilling,
        automaticAllocation,
        usesCableCuttingPlan,
        consolidation,
        dockControl,
        blindConference,
        automaticSeparationAfterCuttingCables,
        automaticCheckAfterPicking,
        restrictionPicking,
        automaticPickingConfirmation,
        usesRequestManual,
        urgentAutoAssignSeparator,
        allocateBalanceDeposits: selectedAllocateBalanceDeposits.join('|'),
        fullAllocation,
        separateProductIntegration: productionSeparation && separateProductIntegration,
      });
      await addNotification(
        'success',
        I18n.t('BEE2802' /* Editar Tipo de Documento */),
        I18n.t('BEE2806', { 0: type } /* Tipo de documento %{0} atualizado com sucesso ! */),
        'top-right',
      );
      this.props.history.push(ROUTES.ORDERS_TYPE_LIST);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;
        await addNotification(
          'danger',
          I18n.t('BEE2802' /* Editar Tipo de Documento */),
          `${error.code} - ${error.message || error.details}`,
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      type, name, mode, showCancel, showCreate, showUpdate, listBranch, depositName, awaitBilling, automaticAllocation,
      branchCode, depositCode, branchName, usesIndustryProcess, listDeposit, productionSeparation, usesRequestManual,
      urgentAutoAssignSeparator, automaticPickingConfirmation, fullAllocation, separateProductIntegration,
    } = this.state;

    const {
      requestWeightDimensions, mandatoryWeightDimensions, partialInboundCheck,
      inboundCheckPlate, preConference,
      disableRequestProduct, disableMandatoryProduct, disablePartialInboundCheck,
      disableInboundCheckPlate, disablePreConference,
      receiveLeftover, disableReceiveLeftover,
    } = this.state;

    const {
      usesCableCuttingPlan, consolidation, dockControl, blindConference, restrictionPicking, disabledAwaitBilling,
      automaticSeparationAfterCuttingCables, automaticCheckAfterPicking, disableUsesCableCuttingPlan, prefix,
      disabledAutomaticAllocation, documentSeparationUzSuggestion,
    } = this.state;

    const {
      disableConsolidation, disableDockControl, disableBlindConference, disableBranchCode, disabledRestrictionPicking,
      disableAutomaticSeparationAfterCuttingCables, disableAutomaticCheckAfterPicking, disableName,
      disabledUsesIndustryProcess, disableType, disableDepositCode, disabledProductionSeparation, disableFullAllocation,
      disabledUsesRequestManual, disablePrefix, disableUrgentAutoAssignSeparator, disableAutomaticPickingConfirmation,
      disableDocumentSeparationUzSuggestion, disableSeparateProductIntegration,
    } = this.state;

    let title = '';
    let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE2801' /* Novo Tipo de Documento */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE2802' /* Editar Tipo de Documento */);
      breadActive = `${type} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE2803' /* Detalhes do Tipo de Documento */);
      breadActive = `${type} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE2796' /* Tipos de Documentos */)}</li>
            <li className="breadcrumb-item active">{breadActive || title}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <Panel className="panel-with-tabs">
              <PanelHeader noButton />
              <PanelBody>
                <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '1' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('1');
                      }}
                    >
                      <span className="d-sm-none">Tab 1</span>
                      <span className="d-sm-block d-none">{I18n.t('BEE370' /* Dados */)}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '2' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('2');
                      }}
                    >
                      <span className="d-sm-none">Tab 2</span>
                      <span className="d-sm-block d-none">{I18n.t('BEE36' /* Recebimento */)}</span>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={this.state.activeTab === '3' ? 'active' : ''}
                      onClick={() => {
                        this.toggleTab('3');
                      }}
                    >
                      <span className="d-sm-none">Tab 3</span>
                      <span className="d-sm-block d-none">{I18n.t('BEE43' /* Separação */)}</span>
                    </NavLink>
                  </NavItem>

                </Nav>
                <TabContent activeTab={this.state.activeTab}>
                  <TabPane tabId="1">
                    <div className="ml-3">
                      <Form
                        noPanel
                        setValue={this.setValue}
                        setValueDrop={this.setValueDrop}
                        inputs={(formContext) => ([
                          formContext.createSelectDropDown(
                            { value: branchCode, label: branchName },
                            'branch',
                            `${I18n.t('BEE145' /* Filial */)}:`,
                            listBranch,
                            disableBranchCode,
                          ),
                          formContext.createInput(
                            type,
                            'type',
                            `${I18n.t('BEE82' /* Código */)}:`,
                            I18n.t('BEE2807' /* Informe o tipo do documento */),
                            'text',
                            undefined,
                            disableType,
                          ),
                          formContext.createInput(
                            name,
                            'name',
                            `${I18n.t('BEE1897' /* Descrição */)}:`,
                            I18n.t('BEE2808' /* Informe a descrição do tipo do documento */),
                            'text',
                            undefined,
                            disableName,
                          ),
                          formContext.createSelectDropDown(
                            { value: depositCode, label: depositName },
                            'deposit',
                            `${I18n.t('BEE1796' /* Depósito Entrada */)}:`,
                            listDeposit,
                            disableDepositCode,
                          ),
                          formContext.createCheckBoxes(
                            [
                              {
                                value: usesIndustryProcess,
                                attr: 'usesIndustryProcess',
                                disabled: disabledUsesIndustryProcess,
                              },
                            ],
                            `${I18n.t('BEE2809' /* Utiliza processo de Indústria */)}:`,
                            undefined,
                            ['col-md-2', 'col-md-5 text-left'],
                          ),
                          formContext.createCheckBoxes(
                            [
                              {
                                value: usesRequestManual,
                                attr: 'usesRequestManual',
                                disabled: disabledUsesRequestManual,
                              },
                            ],
                            `${I18n.t('BEE2974' /* Utiliza processo de Requisição Manual */)}:`,
                            undefined,
                            ['col-md-2', 'col-md-5 text-left'],
                          ),
                        ])}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="2">
                    <div className="ml-3">
                      <Form
                        noPanel
                        setValue={this.setValue}
                        setValueDrop={this.setValueDrop}
                        inputs={(formContext) => ([
                          formContext.createInputPrefix(
                            prefix,
                            'prefix',
                            I18n.t('BEE2990' /* Prefixo do código: */),
                            I18n.t('BEE2991' /* Informe o prefixo */),
                            'text',
                            undefined,
                            disablePrefix,
                            undefined,
                            1.1,
                            undefined,
                            null,
                            524288,
                          ),
                          formContext.createCheckBoxes(
                            [
                              {
                                value: inboundCheckPlate,
                                attr: 'inboundCheckPlate',
                                disabled: disableInboundCheckPlate,
                              },
                            ],
                            `${I18n.t('BEE399' /* Conferência de Placa */)}:`,
                            undefined,
                            ['col-md-2', 'col-md-5 text-left'],
                          ),
                          formContext.createCheckBoxes(
                            [
                              {
                                value: partialInboundCheck,
                                attr: 'partialInboundCheck',
                                disabled: disablePartialInboundCheck,
                              },
                            ],
                            `${I18n.t('BEE783' /* Conferência física parcial */)}:`,
                            undefined,
                            ['col-md-2', 'col-md-5 text-left'],
                          ),
                          formContext.createCheckBoxes(
                            [
                              {
                                value: requestWeightDimensions,
                                attr: 'requestWeightDimensions',
                                disabled: disableRequestProduct,
                              },
                            ],
                            `${I18n.t('BEE784' /* Solicitar pesos e dimensões do produto na conferência Física */)}:`,
                            undefined,
                            ['col-md-2', 'col-md-5 text-left'],
                          ),
                          formContext.createCheckBoxes(
                            [
                              {
                                value: mandatoryWeightDimensions,
                                attr: 'mandatoryWeightDimensions',
                                disabled: disableMandatoryProduct,
                              },
                            ],
                            `${I18n.t('BEE786' /* Pesagem e medição obrigatórios */)}:`,
                            undefined,
                            ['col-md-2', 'col-md-5 text-left'],
                          ),
                          formContext.createCheckBoxes(
                            [
                              {
                                value: preConference,
                                attr: 'preConference',
                                disabled: disablePreConference,
                              },
                            ],
                            `${I18n.t('BEE1792' /* Pré-conferência */)}:`,
                            undefined,
                            ['col-md-2', 'col-md-5 text-left'],
                          ),
                          formContext.createCheckBoxes(
                            [
                              {
                                value: receiveLeftover,
                                attr: 'receiveLeftover',
                                disabled: disableReceiveLeftover,
                              },
                            ],
                            `${I18n.t('BEE1967' /* Permite receber Sobra de Produto */)}:`,
                            undefined,
                            ['col-md-2', 'col-md-5 text-left'],
                          ),
                        ])}
                      />
                    </div>
                  </TabPane>
                  <TabPane tabId="3">
                    <div className="ml-3">
                      <Form
                        noPanel
                        setValue={this.setValue}
                        setValueDrop={this.setValueDrop}
                        inputs={(formContext) => ([
                          formContext.createCheckBoxLabel(
                            usesCableCuttingPlan,
                            'usesCableCuttingPlan',
                            I18n.t('BEE780' /* Utiliza plano de corte de cabo */),
                            disableUsesCableCuttingPlan,
                          ),
                          formContext.createCheckBoxLabel(
                            documentSeparationUzSuggestion,
                            'documentSeparationUzSuggestion',
                            I18n.t('BEE3320' /* Última UZ utilizada na Separação. */),
                            disableDocumentSeparationUzSuggestion,
                          ),
                          formContext.createCheckBoxLabel(
                            automaticSeparationAfterCuttingCables,
                            'automaticSeparationAfterCuttingCables',
                            I18n.t('BEE1054' /* Separação Automática Após Corte de Cabos */),
                            disableAutomaticSeparationAfterCuttingCables,
                          ),
                          formContext.createCheckBoxLabel(
                            urgentAutoAssignSeparator,
                            'urgentAutoAssignSeparator',
                            I18n.t('BEE3273' /* Urgência automática ao atribuir separador */),
                            disableUrgentAutoAssignSeparator,
                          ),
                          formContext.createCheckBoxLabel(
                            blindConference,
                            'blindConference',
                            I18n.t('BEE1053' /* Conferência a Cegas */),
                            disableBlindConference,
                          ),
                          formContext.createCheckBoxLabel(
                            consolidation,
                            'consolidation',
                            I18n.t('BEE1046' /* Consolidação */),
                            disableConsolidation,
                          ),
                          formContext.createCheckBoxLabel(
                            dockControl,
                            'dockControl',
                            I18n.t('BEE1049' /* Controla Doca */),
                            disableDockControl,
                          ),
                          formContext.createCheckBoxLabel(
                            restrictionPicking,
                            'restrictionPicking',
                            I18n.t('BEE2778' /* Utilizar Monitor de Restrição de Separação */),
                            disabledRestrictionPicking,
                          ),
                          formContext.createCheckBoxLabel(
                            automaticCheckAfterPicking,
                            'automaticCheckAfterPicking',
                            I18n.t('BEE2354' /* Conferência Automática Após Separação Por Documento */),
                            disableAutomaticCheckAfterPicking,
                          ),
                          formContext.createCheckBoxLabel(
                            automaticPickingConfirmation,
                            'automaticPickingConfirmation',
                            I18n.t('BEE3277' /* Confirmação automática da separação */),
                            disableAutomaticPickingConfirmation,
                          ),
                          formContext.createCheckBoxLabel(
                            productionSeparation,
                            'productionSeparation',
                            I18n.t('BEE2890' /* Separação de Ordem de Produção */),
                            disabledProductionSeparation,
                          ),
                          formContext.createCheckBoxLabel(
                            awaitBilling,
                            'awaitBilling',
                            I18n.t('BEE2962' /* Aguardar Faturamento */),
                            disabledAwaitBilling,
                          ),
                          formContext.createCheckBoxLabel(
                            automaticAllocation,
                            'automaticAllocation',
                            I18n.t('BEE3003' /* Aloca Saldo Automaticamente */),
                            disabledAutomaticAllocation,
                          ),
                          formContext.createCheckBoxLabel(
                            fullAllocation,
                            'fullAllocation',
                            I18n.t('BEE3315' /* Alocação Total do Pedido */),
                            disableFullAllocation,
                          ),
                          formContext.createCheckBoxLabel(
                            separateProductIntegration && productionSeparation,
                            'separateProductIntegration',
                            I18n.t('BEE3329' /* Envie integração para cada produto separado na ordem de produção */),
                            disableSeparateProductIntegration || !productionSeparation,
                          ),
                        ])}
                      />
                    </div>
                    <DualList
                      id="dualselectdeposit"
                      title={I18n.t('BEE2992' /* Depósitos para alocação de saldo */)}
                      availableHeader={I18n.t('BEE2993' /* Depósitos Disponíveis */)}
                      selectedHeader={I18n.t('BEE2994' /* Depósitos Selecionados */)}
                      filterPlaceholder={I18n.t('BEE541' /* Filtrar... */)}
                      options={this.state.optionsAllocateBalanceDeposits}
                      selected={this.state.selectedAllocateBalanceDeposits}
                      onChange={this.onChangeDualList}
                      preserveSelectOrder
                      showOrderButtons
                      showHeaderLabels
                    />
                  </TabPane>
                </TabContent>
              </PanelBody>
              <PanelFooter>
                <Link to={{ pathname: ROUTES.ORDERS_TYPE_LIST, state: { dice: this.props.location.dice } }}>
                  {(showCancel
                    ? (
                      <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                        {I18n.t('BEE99' /* Cancelar */)}
                      </OneClickButton>
                    )
                    : (
                      <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                        {I18n.t('BEE137' /* Voltar */)}
                      </OneClickButton>
                    ))}
                </Link>
                {(showCreate
                  && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createTypeOrder()}
                    >
                      {I18n.t('BEE138' /* Criar */)}

                    </OneClickButton>
                  )
                )}
                {(showUpdate
                  && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateTypeOrderSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}

                    </OneClickButton>
                  )
                )}
              </PanelFooter>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getDepositsOptions: () => dispatch(getDepositsOptions()),
  getAllocateBalanceDeposits: (typeOrder = '') => dispatch(getAllocateBalanceDeposits(typeOrder)),
  createTypeOrderSubmit: (newTypeOrder) => dispatch(createTypeOrder(newTypeOrder)),
  updateTypeOrder: (updateTypeOrder) => dispatch(updateTypeOrders(updateTypeOrder)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TypeOrdersForm));
