import { I18n } from 'react-redux-i18n';

function outboundOrderStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE1946' /* Pendente Alocação */);
      case 2:
        return I18n.t('BEE1947' /* Pendente Corte de Cabos */);
      case 3:
        return I18n.t('BEE1365' /* Pendente Separação */);
      case 4:
        return I18n.t('BEE1367' /* Em Separação */);
      case 5:
        return I18n.t('BEE1948' /* Pendente Conferência */);
      case 6:
        return I18n.t('BEE1949' /* Pendente Docas */);
      case 7:
        return I18n.t('BEE1773' /* Pendente Romaneio */);
      case 8:
        return I18n.t('BEE1769' /* Pendente Consolidação */);
      case 9:
        return I18n.t('BEE2963' /* Pendente de Faturamento */);
      case 10:
        return I18n.t('BEE1833' /* Finalizado */);
      case 11:
        return I18n.t('BEE176' /* Divergência */);
      case 12:
        return I18n.t('BEE64' /* Cancelado */);
      case 13:
        return I18n.t('BEE1899' /* Devolvido */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE1946' /* Pendente Alocação */) },
      { value: 2, label: I18n.t('BEE1947' /* Pendente Corte de Cabos */) },
      { value: 3, label: I18n.t('BEE1365' /* Pendente Separação */) },
      { value: 4, label: I18n.t('BEE1367' /* Em Separação */) },
      { value: 5, label: I18n.t('BEE1948' /* Pendente Conferência */) },
      { value: 6, label: I18n.t('BEE1949' /* Pendente Docas */) },
      { value: 7, label: I18n.t('BEE1773' /* Pendente Romaneio */) },
      { value: 8, label: I18n.t('BEE1769' /* Pendente Consolidação */) },
      { value: 9, label: I18n.t('BEE2963' /* Pendente de Faturamento */) },
      { value: 10, label: I18n.t('BEE1833' /* Finalizado */) },
      { value: 11, label: I18n.t('BEE176' /* Divergência */) },
      { value: 12, label: I18n.t('BEE64' /* Cancelado */) },
      { value: 13, label: I18n.t('BEE1899' /* Devolvido */) },
    ];
  }
}

function outboundOrderProductStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE1946' /* Pendente Alocação */);
      case 2:
        return I18n.t('BEE1947' /* Pendente Corte de Cabos */);
      case 3:
        return I18n.t('BEE1803' /* Corte Finalizado */);
      case 4:
        return I18n.t('BEE1365' /* Pendente Separação */);
      case 5:
        return I18n.t('BEE1368' /* Separado */);
      case 6:
        return I18n.t('BEE1948' /* Pendente Conferência */);
      case 7:
        return I18n.t('BEE1833' /* Finalizado */);
      case 8:
        return I18n.t('BEE176' /* Divergência */);
      case 9:
        return I18n.t('BEE64' /* Cancelado */);
      case 10:
        return I18n.t('BEE1899' /* Devolvido */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE1946' /* Pendente Alocação */) },
      { value: 2, label: I18n.t('BEE1947' /* Pendente Corte de Cabos */) },
      { value: 3, label: I18n.t('BEE1803' /* Corte Finalizado */) },
      { value: 4, label: I18n.t('BEE1365' /* Pendente Separação */) },
      { value: 5, label: I18n.t('BEE1368' /* Separado */) },
      { value: 6, label: I18n.t('BEE1948' /* Pendente Conferência */) },
      { value: 7, label: I18n.t('BEE1833' /* Finalizado */) },
      { value: 8, label: I18n.t('BEE176' /* Divergência */) },
      { value: 9, label: I18n.t('BEE64' /* Cancelado */) },
      { value: 10, label: I18n.t('BEE1899' /* Devolvido */) },
    ];
  }
}

function outboundOrderProductAllocationStatus(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE1947' /* Pendente Corte de Cabos */);
      case 2:
        return I18n.t('BEE1803' /* Corte Finalizado */);
      case 3:
        return I18n.t('BEE1365' /* Pendente Separação */);
      case 4:
        return I18n.t('BEE1368' /* Separado */);
      case 5:
        return I18n.t('BEE1948' /* Pendente Conferência */);
      case 6:
        return I18n.t('BEE1833' /* Finalizado */);
      case 7:
        return I18n.t('BEE176' /* Divergência */);
      case 8:
        return I18n.t('BEE64' /* Cancelado */);
      case 9:
        return I18n.t('BEE1899' /* Devolvido */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE1947' /* Pendente Corte de Cabos */) },
      { value: 2, label: I18n.t('BEE1803' /* Corte Finalizado */) },
      { value: 3, label: I18n.t('BEE1365' /* Pendente Separação */) },
      { value: 4, label: I18n.t('BEE1368' /* Separado */) },
      { value: 5, label: I18n.t('BEE1948' /* Pendente Conferência */) },
      { value: 6, label: I18n.t('BEE1833' /* Finalizado */) },
      { value: 7, label: I18n.t('BEE176' /* Divergência */) },
      { value: 8, label: I18n.t('BEE64' /* Cancelado */) },
      { value: 9, label: I18n.t('BEE1899' /* Devolvido */) },
    ];
  }
}

function outboundOrderPickingType(type) {
  if (type || type === 0) {
    switch (type) {
      case 1:
        return I18n.t('BEE1454' /* Onda */);
      case 2:
        return I18n.t('BEE1378' /* Documento */);
      default:
        return '';
    }
  } else if (!type) {
    return [
      { value: 1, label: I18n.t('BEE1454' /* Onda */) },
      { value: 2, label: I18n.t('BEE1378' /* Documento */) },
    ];
  }
}

export default {
  outboundOrderStatus,
  outboundOrderProductStatus,
  outboundOrderProductAllocationStatus,
  outboundOrderPickingType,
};
