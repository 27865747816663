export default {
  // INICIO
  HOME: '/',
  // LOGIN
  LOGIN: '/login',

  WIZARD_SETUP: '/wizard',

  // ADMINISTRACAO
  ADMIN: '/admin',

  // GERAIS
  ADMIN_GENERAL: '/admin/general',
  // EMPRESAS
  COMPANY_HELP: '/admin/general/company',
  COMPANY_LIST: '/admin/general/company/list',
  COMPANY_COPY: '/admin/general/company/copy',
  COMPANY_DETAIL: '/admin/general/company/detail',
  COMPANY_EDIT: '/admin/general/company/edit',
  COMPANY_CREATE: '/admin/general/company/new',
  // FILIAIS
  BRANCH_HELP: '/admin/general/branch',
  BRANCH_LIST: '/admin/general/branch/list',
  BRANCH_COPY: '/admin/general/branch/copy',
  BRANCH_DETAIL: '/admin/general/branch/detail',
  BRANCH_EDIT: '/admin/general/branch/edit',
  BRANCH_CREATE: '/admin/general/branch/new',
  BRANCH_SETTINGS: '/admin/general/branch/settings',
  // JOBS
  JOBS_HELP: '/admin/general/jobs',
  JOBS_LIST: '/admin/general/jobs/list',
  JOBS_EDIT: '/admin/general/jobs/edit',

  // USUARIOS
  ADMIN_USER: '/admin/user',
  // USUARIOS
  USER_HELP: '/admin/users/user',
  USER_LIST: '/admin/user/list',
  USER_COPY: '/admin/user/copy',
  USER_DETAIL: '/admin/user/detail',
  USER_EDIT: '/admin/user/edit',
  USER_CREATE: '/admin/user/new',
  // PERFIL DE ACESSO
  DEVELOP_ACCESS_PROFILE_HELP: '/admin/users/accessProfile',
  DEVELOP_ACCESS_PROFILE_LIST: '/admin/user/accessProfile/list',
  DEVELOP_ACCESS_PROFILE_CREATE: '/admin/user/accessProfile/new',
  DEVELOP_ACCESS_PROFILE_EDIT: '/admin/user/accessProfile/edit',
  DEVELOP_ACCESS_PROFILE_MANAGER: '/admin/user/accessProfile/manager',
  // DEPARTAMENTOS
  DEPARTMENT_LIST: '/admin/user/departments/list',
  DEPARTMENT_COPY: '/admin/user/departments/copy',
  DEPARTMENT_DETAIL: '/admin/user/departments/detail',
  DEPARTMENT_EDIT: '/admin/user/departments/edit',
  DEPARTMENT_CREATE: '/admin/user/departments/new',
  // FUNCOES DO USUARIO
  USER_FUNCTION_LIST: '/admin/user/userFunctions/list',
  USER_FUNCTION_COPY: '/admin/user/userFunctions/copy',
  USER_FUNCTION_DETAIL: '/admin/user/userFunctions/detail',
  USER_FUNCTION_EDIT: '/admin/user/userFunctions/edit',
  USER_FUNCTION_CREATE: '/admin/user/userFunctions/new',
  // TURNOS DE TRABALHO
  WORK_SHIFT_LIST: '/admin/user/workShifts/list',
  WORK_SHIFT_COPY: '/admin/user/workShifts/copy',
  WORK_SHIFT_DETAIL: '/admin/user/workShifts/detail',
  WORK_SHIFT_EDIT: '/admin/user/workShifts/edit',
  WORK_SHIFT_CREATE: '/admin/user/workShifts/new',
  // TAREFAS INDIRETAS
  INDIRECT_TASKS_LIST: '/admin/user/indirectTasks/list',
  INDIRECT_TASKS_COPY: '/admin/user/indirectTasks/copy',
  INDIRECT_TASKS_DETAIL: '/admin/user/indirectTasks/detail',
  INDIRECT_TASKS_EDIT: '/admin/user/indirectTasks/edit',
  INDIRECT_TASKS_CREATE: '/admin/user/indirectTasks/new',

  // CADASTROS
  REGISTRATION: '/registration',
  // GERAIS
  REG_GENERAL: '/registration/general',
  // ARMAZENS
  WAREHOUSE_HELP: '/registration/general/warehouse',
  WAREHOUSE_COPY: '/registration/general/warehouse/copy',
  WAREHOUSE_DETAIL: '/registration/general/warehouse/detail',
  WAREHOUSE_EDIT: '/registration/general/warehouse/edit',
  WAREHOUSE_CREATE: '/registration/general/warehouse/new',
  WAREHOUSE_LIST: '/registration/general/warehouse/list',
  // DEPOSITOS
  DEPOSIT_HELP: '/registration/general/deposit',
  DEPOSIT_COPY: '/registration/general/deposit/copy',
  DEPOSIT_DETAIL: '/registration/general/deposit/detail',
  DEPOSIT_EDIT: '/registration/general/deposit/edit',
  DEPOSIT_CREATE: '/registration/general/deposit/new',
  DEPOSIT_LIST: '/registration/general/deposit/list',
  // DOCAS
  DOCKS_HELP: '/registration/general/dock',
  DOCKS_COPY: '/registration/general/dock/copy',
  DOCKS_DETAIL: '/registration/general/dock/detail',
  DOCKS_EDIT: '/registration/general/dock/edit',
  DOCKS_CREATE: '/registration/general/dock/create',
  DOCKS_LIST: '/registration/general/dock/list',
  DOCKS_LINK_CARRIER: '/registration/general/dock/linkCarrier',
  // RANGE
  RANGE_HELP: '/registration/general/range',
  RANGE_COPY: '/registration/general/range/copy',
  RANGE_DETAIL: '/registration/general/range/detail',
  RANGE_EDIT: '/registration/general/range/edit',
  RANGE_CREATE: '/registration/general/range/new',
  RANGE_LIST: '/registration/general/range/list',
  // ENDERECOS
  ADDRESS_HELP: '/registration/general/address',
  ADDRESS_COPY: '/registration/general/address/copy',
  ADDRESS_DETAIL: '/registration/general/address/detail',
  ADDRESS_EDIT: '/registration/general/address/edit',
  ADDRESS_CREATE: '/registration/general/address/new',
  ADDRESS_BULK_BLOCK: '/registration/general/address/massBlock',
  GENERATE_ADDRESSES: '/registration/general/address/new-generation',
  ADDRESS_LIST: '/registration/general/address/list',
  // CURVAS
  CURVES_HELP: '/registration/general/curve',
  CURVES_LIST: '/registration/general/curve/list',
  CURVE_CREATE: '/registration/general/curve/new',
  CURVE_EDIT: '/registration/general/curve/edit',
  // TAMANHO DE ENDEREÇO
  ADDRESS_SIZE_LIST: '/registration/general/addressSize/list',
  ADDRESS_SISE_DETAIL: '/registration/general/addressSize/detail',
  ADDRESS_SIZE_EDIT: '/registration/general/addressSize/edit',
  ADDRESS_SIZE_COPY: '/registration/general/addressSize/copy',
  ADDRESS_SIZE_CREATE: '/registration/general/addressSize/new',
  // TIPO DE ENDERECO
  ADDRESS_TYPE_COPY: '/registration/general/addressType/copy',
  ADDRESS_TYPE_DETAIL: '/registration/general/addressType/detail',
  ADDRESS_TYPE_EDIT: '/registration/general/addressType/edit',
  ADDRESS_TYPE_CREATE: '/registration/general/addressType/new',
  ADDRESS_TYPE_LIST: '/registration/general/addressType/list',
  // TIPO DE DOCUMENTOS
  ORDERS_TYPE_COPY: '/registration/general/ordersType/copy',
  ORDERS_TYPE_DETAIL: '/registration/general/ordersType/detail',
  ORDERS_TYPE_EDIT: '/registration/general/ordersType/edit',
  ORDERS_TYPE_CREATE: '/registration/general/ordersType/new',
  ORDERS_TYPE_LIST: '/registration/general/ordersType/list',
  // CLIENTES
  CUSTOMER_HELP: '/registration/general/customer',
  CUSTOMER_COPY: '/registration/general/customer/copy',
  CUSTOMER_DETAIL: '/registration/general/customer/detail',
  CUSTOMER_EDIT: '/registration/general/customer/edit',
  CUSTOMER_CREATE: '/registration/general/customer/new',
  CUSTOMER_LIST: '/registration/general/customer/list',
  // CLIENTES POR FILIAIS
  CUSTOMER_BRANCH_HELP: '/registration/general/customerBranch',
  CUSTOMER_BRANCH_LIST: '/registration/general/customerBranch/list',
  CUSTOMER_BRANCH_COPY: '/registration/general/customerBranch/copy',
  CUSTOMER_BRANCH_DETAIL: '/registration/general/customerBranch/detail',
  CUSTOMER_BRANCH_EDIT: '/registration/general/customerBranch/edit',
  CUSTOMER_BRANCH_CREATE: '/registration/general/customerBranch/new',
  // FORNECEDORES
  SUPPLIER_HELP: '/registration/general/supplier',
  SUPPLIER_COPY: '/registration/general/supplier/copy',
  SUPPLIER_DETAIL: '/registration/general/supplier/detail',
  SUPPLIER_EDIT: '/registration/general/supplier/edit',
  SUPPLIER_CREATE: '/registration/general/supplier/new',
  SUPPLIER_LIST: '/registration/general/supplier/list',
  // TRANSPORTADORAS
  CARRIER_HELP: '/registration/general/carrier',
  CARRIER_COPY: '/registration/general/carrier/copy',
  CARRIER_DETAIL: '/registration/general/carrier/detail',
  CARRIER_EDIT: '/registration/general/carrier/edit',
  CARRIER_CREATE: '/registration/general/carrier/new',
  CARRIER_LIST: '/registration/general/carrier/list',
  CARRIER_LINK_DOCKS: '/registration/general/carrier/linkDocks',
  // PRIORIDADES
  PRIORITY_COPY: '/registration/general/priority/copy',
  PRIORITY_DETAIL: '/registration/general/priority/detail',
  PRIORITY_EDIT: '/registration/general/priority/edit',
  PRIORITY_CREATE: '/registration/general/priority/create',
  PRIORITY_LIST: '/registration/general/priority/list',
  // PRODUTOS
  ADMIN_PRODUCT: '/registration/product',
  // PRODUTOS
  PRODUCT_HELP: '/registration/product/product',
  PRODUCT_COPY: '/registration/product/product/copy',
  PRODUCT_DETAIL: '/registration/product/product/detail',
  PRODUCT_EDIT: '/registration/product/product/edit',
  PRODUCT_CREATE: '/registration/product/product/new',
  PRODUCT_LIST: '/registration/product/product/list',
  // EAN
  EAN_HELP: '/registration/product/ean',
  EAN_COPY: '/registration/product/ean/copy',
  EAN_DETAIL: '/registration/product/ean/detail',
  EAN_CREATE: '/registration/product/ean/create',
  EAN_LIST: '/registration/product/ean/list',
  EAN_EDIT: '/registration/product/ean/edit',
  // EMBALAGENS
  PACKAGE_COPY: '/registration/product/package/copy',
  PACKAGE_DETAIL: '/registration/product/package/detail',
  PACKAGE_EDIT: '/registration/product/package/edit',
  PACKAGE_CREATE: '/registration/product/package/new',
  PACKAGE_LIST: '/registration/product/package/list',
  // FAMILIA DE MATERIAS
  MATERIAL_FAMILY_HELP: '/registration/product/materialFamily',
  MATERIAL_FAMILY_COPY: '/registration/product/materialFamily/copy',
  MATERIAL_FAMILY_DETAIL: '/registration/product/materialFamily/detail',
  MATERIAL_FAMILY_EDIT: '/registration/product/materialFamily/edit',
  MATERIAL_FAMILY_CREATE: '/registration/product/materialFamily/new',
  MATERIAL_FAMILY_LIST: '/registration/product/materialFamily/list',
  // PRODUTOS X CLIENTE
  CUSTOMER_PRODUCT_HELP: '/registration/product/customerProduct',
  CUSTOMER_PRODUCT_COPY: '/registration/product/customerProduct/copy',
  CUSTOMER_PRODUCT_DETAIL: '/registration/product/customerProduct/detail',
  CUSTOMER_PRODUCT_CREATE: '/registration/product/customerProduct/create',
  CUSTOMER_PRODUCT_LIST: '/registration/product/customerProduct/list',
  CUSTOMER_PRODUCT_EDIT: '/registration/product/customerProduct/edit',
  // PRODUTOS X FILIAL
  PRODUCT_BRANCH_HELP: '/registration/product/productBranch',
  PRODUCT_BRANCH_COPY: '/registration/product/productBranch/copy',
  PRODUCT_BRANCH_DETAIL: '/registration/product/productBranch/detail',
  PRODUCT_BRANCH_CREATE: '/registration/product/productBranch/create',
  PRODUCT_BRANCH_LIST: '/registration/product/productBranch/list',
  PRODUCT_BRANCH_EDIT: '/registration/product/productBranch/edit',
  // UNIDADE DE MEDIDA
  UNIT_COPY: '/registration/product/unit/copy',
  UNIT_DETAIL: '/registration/product/unit/detail',
  UNIT_EDIT: '/registration/product/unit/edit',
  UNIT_CREATE: '/registration/product/unit/new',
  UNIT_LIST: '/registration/product/unit/list',

  // EQUIPAMENTOS
  REG_EQUIPMENTS: '/registration/equipments',
  // IMPRESSORAS
  PRINTER_HELP: '/registration/equipments/printer',
  PRINTER_COPY: '/registration/equipments/printer/copy',
  PRINTER_DETAIL: '/registration/equipments/printer/detail',
  PRINTER_EDIT: '/registration/equipments/printer/edit',
  PRINTER_CREATE: '/registration/equipments/printer/new',
  PRINTER_LIST: '/registration/equipments/printer/list',
  // COLETORES
  COLLECTOR_COPY: '/registration/equipments/collector/copy',
  COLLECTOR_DETAIL: '/registration/equipments/collector/detail',
  COLLECTOR_EDIT: '/registration/equipments/collector/edit',
  COLLECTOR_CREATE: '/registration/equipments/collector/new',
  COLLECTOR_LIST: '/registration/equipments/collector/list',
  // EQUIPATAMENTOS DE TRANSPORTE
  TRANSPORT_EQUIP_LIST: '/registration/equipments/transportEquipment/list',
  TRANSPORT_EQUIP_DETAIL: '/registration/equipments/transportEquipment/detail',
  TRANSPORT_EQUIP_EDIT: '/registration/equipments/transportEquipment/edit',
  TRANSPORT_EQUIP_COPY: '/registration/equipments/transportEquipment/copy',
  TRANSPORT_EQUIP_CREATE: '/registration/equipments/transportEquipment/new',
  // EQUIPAMENTOS X USUARIOS
  EQUIPMENTS_USER_LIST: '/registration/equipments/userEquip/list',
  // EQUIPAMENTOS X ENDERECOS
  EQUIPMENTS_ADDRESS_LIST: '/registration/equipments/addressEquip/list',

  // PORTARIA
  ENTRANCE: '/entrance',
  // AGENDAMENTOS
  SCHEDULE_COPY: '/entrance/schedule/copy',
  SCHEDULE_DETAIL: '/entrance/schedule/detail',
  SCHEDULE_EDIT: '/entrance/schedule/edit',
  SCHEDULE_CREATE: '/entrance/schedule/create',
  SCHEDULE_LIST: '/entrance/schedule/list',
  // RECEPCAO NF-E PLACA E VOLUME
  ENTRANCE_VOLUME_PLATE: '/entrance/volumePlate',
  ENTRANCE_VOLUME_PLATE_HELP: '/entrance/volumePlate',
  // CONFERENCIA DE PLACA
  ENTRANCE_PLATE_CHECK: '/entrance/plateCheck',
  ENTRANCE_PLATE_CHECK_HELP: '/entrance/plateCheck',

  // MOVIMENTACAO
  MOVEMENT: '/movement',
  // RECEBIMENTO
  INBOUND: '/movement/inbound',
  // DOCUMENTOS DE ENTRADA
  INBOUND_ORDER_LIST_HELP: '/movement/inbound/panel',
  INBOUND_ORDER_LIST: '/movement/inbound/orders/list',
  INBOUND_ORDER_DETAIL: '/movement/inbound/orders/detail',
  INBOUND_ORDER_ADD: '/movement/inbound/orders/add',
  INBOUND_ORDER_EDIT: '/movement/inbound/orders/edit',
  // MONITOR DE RECEBIMENTO
  INBOUND_ORDER_MONITOR_HELP: '/movement/inbound/monitor',
  INBOUND_ORDER_PANEL: '/movement/inbound/panel',
  // CONFERENCIA
  INBOUND_CHECK: '/movement/inbound/check',
  INBOUND_CHECK_HELP: '/movement/inbound/check',
  // ARMAZENAMENTO
  INBOUND_STORAGE: '/movement/inbound/storage',
  INBOUND_STORAGE_HELP: '/movement/inbound/storage',
  // CROSS DOCKING
  INBOUND_CROSS_DOCKING: '/movement/inbound/crossDocking',
  // Monitor de Produtos do Recebimento
  INBOUND_PRODUCT_MONITOR: '/movement/inbound/inboundProductMonitor',
  INBOUND_PRODUCT_MONITOR_HELP: '/movement/inbound/inboundProductMonitor',
  // EXPEDICAO
  OUTBOUND: '/movement/outbound',
  // DOCUMENTOS DE SAIDA
  OUTBOUND_ORDER_LIST_HELP: '/movement/outbound/panel',
  OUTBOUND_ORDER_LIST: '/movement/outbound/orders/list',
  OUTBOUND_ORDER_DETAIL: '/movement/outbound/orders/detail',
  // DOCUMENTOS DE SAIDA INDUSTRIA
  OUTBOUND_INDUSTRY_ORDER_LIST: '/movement/outbound/monitorIndustry',
  OUTBOUND_INDUSTRY_ORDER_LIST_HELP: '/movement/outbound/monitorIndustry',
  P_CANCEL_OUTBOUND_INDUSTRY_ORDER: 'cancelOutboundIndustryOrder',
  // MONITOR DE EXPEDICAO
  OUTBOUND_MONITOR: '/movement/outbound/monitor',
  OUTBOUND_MONITOR_HELP: '/movement/outbound/monitor',
  // MONITOR DE PENDENCIAS DE ALOCACAO
  PENDING_ALLOCATION_MONITOR: '/movement/outbound/pendingAllocationMonitor',
  PENDING_ALLOCATION_MONITOR_HELP: '/movement/outbound/pendingAllocationMonitor',
  // MONITOR DE CORTES
  OUTBOUND_CABLE_CUT_MONIT: '/movement/outbound/cableCutMonit',
  OUTBOUND_CABLE_CUT_MONIT_HELP: '/movement/outbound/cableCutMonit',
  // PLANOS DE CORTE DE CABOS
  OUTBOUND_CABLE_CUT_PLAN: '/movement/outbound/cableCutPlan',
  OUTBOUND_CABLE_CUT_PLAN_HELP: '/movement/outbound/cableCutPlan',
  // CORTE DE CABOS
  OUTBOUND_CABLE_CUT: '/movement/outbound/cableCut',
  // CRIACAO DE ONDA
  OUTBOUND_WAVE_CREATE: '/movement/outbound/wave/new',
  // SEPARACAO POR ONDA
  OUTBOUND_WAVE_PICKING_HELP: '/movement/outbound/wave/picking',
  OUTBOUND_WAVE_PICKING: '/movement/outbound/wave/picking',
  // SEPARACAO POR DOCUMENTO
  OUTBOUND_PICKING_HELP: '/movement/outbound/doc/picking',
  OUTBOUND_PICKING: '/movement/outbound/picking',
  // CONFERENCIA
  OUTBOUND_CHECK: '/movement/outbound/check',
  OUTBOUND_CHECK_HELP: '/movement/outbound/check',
  // DOCAS
  OUTBOUND_DOCKS: '/movement/outbound/docks',
  OUTBOUND_DOCKS_HELP: '/movement/outbound/docks',
  // MONITOR DE CONTROLE DE DOCAS
  OUTBOUND_DOCK_CONTROL_MONITOR: '/movement/outbound/DockControlMonitor',
  OUTBOUND_DOCK_CONTROL_MONITOR_HELP: '/movement/outbound/DockControlMonitor',
  // CONSOLIDACAO
  OUTBOUND_CONSOLIDATION: '/movement/outbound/consolidation',
  // DESPACHO
  OUTBOUND_DISPATCH: '/movement/outbound/dispatch',
  // DEVOLUCAO DE PICKING
  OUTBOUND_PICKING_RETURN: '/movement/outbound/pickingReturn',
  OUTBOUND_PICKING_RETURN_HELP: '/movement/outbound/pickingReturn',
  // MONITOR DE DEVOLUCAO
  OUTBOUND_DEVOLUTION_MONITOR_LIST: '/movement/outbound/devolutionMonitor',
  OUTBOUND_DEVOLUTION_MONITOR_HELP: '/movement/outbound/devolutionMonitor',
  // MONITOR DE ROMANEIO
  WAYBILL_MONITOR: '/movement/outbound/waybill/list',
  WAYBILL_MONITOR_HELP: '/movement/outbound/waybill',
  // MONITOR DE RESTRIÇÃO
  OUTBOUND_RESTRICTION_MONITOR: '/movement/outbound/restrictionMonitor/list',
  OUTBOUND_RESTRICTION_MONITOR_HELP: '/movement/outbound/restrictionMonitor/list',
  // MONITOR DE RESTRIÇÃO DE SEPARAÇÃO
  OUTBOUND_PICKING_RESTRICTION_MONITOR: '/movement/outbound/pickingRestrictionMonitor/list',
  OUTBOUND_PICKING_RESTRICTION_MONITOR_HELP: '/movement/outbound/pickingRestrictionMonitor/list',
  // RESERVA DE PRODUTO
  OUTBOUND_RESERVE: '/movement/outbound/reserve',
  // MONITOR DE ONDA
  OUTBOUND_WAVE_MONITOR: '/movement/outbound/waveMonitor',
  OUTBOUND_WAVE_MONITOR_HELP: '/movement/outbound/waveMonitor',
  // Produtos Alocados
  OUTBOUND_ALLOCATED_PRODUCT: '/movement/outbound/allocatedProduct',
  OUTBOUND_ALLOCATED_PRODUCT_HELP: '/movement/outbound/allocatedProduct',
  // ALTERAR ALOCAÇÃO DE PRODUTO
  OUTBOUND_CHANGE_ALLOCATION: '/movement/outbound/changeAllocation',
  OUTBOUND_CHANGE_ALLOCATION_HELP: '/movement/outbound/changeAllocation',
  // ESTOQUE
  MOVEMENT_STOCK: '/movement/stock',
  // TRANSFERENCIA ENDERECO
  STOCK_TRANSFER_ADDRESS_FORM: '/movement/stock/transfer/address',
  STOCK_TRANSFER_ADDRESS_HELP: '/movement/stock/transfer/address',
  // DOCUMENTO DE TRANSFERENCIA
  STOCK_WAVE_TRANSFER: '/movement/stock/waveTransfer',
  STOCK_WAVE_TRANSFER_HELP: '/movement/stock/waveTransfer',

  STOCK_WAVE_TRANSFER_MONITOR: '/movement/stock/WaveTransferMonitor/list',
  STOCK_WAVE_TRANSFER_MONITOR_HELP: '/movement/stock/waveTransfer/monitor',

  STOCK_WAVE_TRANSFER_MONITOR_FORM: '/movement/stock/WaveTransferMonitor/transfer',

  STOCK_WAVE_TRANSFER_MONITOR_FORM_DETAIL: '/movement/stock/WaveTransferMonitor/detail',

  STOCK_WAVE_TRANSFER_MONITOR_FORM_EDIT: '/movement/stock/WaveTransferMonitor/edit',

  // INVENTARIO
  MOVEMENT_INVENTORY: '/movement/inventory',
  // ALÇADA DE APROVAÇÃO
  APPROVAL_LEVEL_HELP: '/movement/inventory/approvalLevel',
  APPROVAL_LEVEL_LIST: '/movement/inventory/approvalLevel/list',
  APPROVAL_LEVEL_COPY: '/movement/inventory/approvalLevel/copy',
  APPROVAL_LEVEL_DETAIL: '/movement/inventory/approvalLevel/detail',
  APPROVAL_LEVEL_EDIT: '/movement/inventory/approvalLevel/edit',
  APPROVAL_LEVEL_CREATE: '/movement/inventory/approvalLevel/new',
  // GERAR INVENTARIO
  MOVEMENT_INVENTORY_GENERATE: '/movement/inventory/generateInventory/list',
  MOVEMENT_INVENTORY_GENERATE_HELP: '/movement/inventory/generateInventory',
  // MANUTENÇÃO DE INVENTARIO
  MOVEMENT_INVENTORY_MAINTENANCE: '/movement/inventory/inventoryMaintenance/list',
  MOVEMENT_INVENTORY_MAINTENANCE_HELP: '/movement/inventory/inventoryMaintenance',
  // MONITOR INVENTARIO
  MOVEMENT_INVENTORY_MONITOR: '/movement/inventory/monitor',
  MOVEMENT_INVENTORY_MONITOR_HELP: '/movement/inventory/monitor',
  // ATUALIZAÇÃO FICHA DE INVENTARIO
  UPDATE_INVENTORY_SHEET: '/movement/inventory/updateInventorySheet',
  UPDATE_INVENTORY_SHEET_HELP: '/movement/inventory/updateInventorySheet',
  // USUÁRIO DE CONTAGEM
  MOVEMENT_INVENTORY_COUNT_USER: '/movement/inventory/CountUser/list',
  MOVEMENT_INVENTORY_COUNT_USER_HELP: '/movement/inventory/CountUser',
  // INVENTARIO FÍSICO
  PHYSICAL_INVENTORY: '/movement/inventory/physicalInventory',
  PHYSICAL_INVENTORY_HELP: '/movement/inventory/physicalInventory',
  // ETIQUETAS
  MOVEMENT_LABEL: '/movement/label',
  // LOTE
  MOVEMENT_LABEL_LOT: '/movement/label/lot',
  MOVEMENT_LABEL_LOT_HELP: '/movement/label/lot',
  // EAN
  MOVEMENT_LABEL_EAN: '/movement/label/ean',
  MOVEMENT_LABEL_EAN_HELP: '/movement/label/ean',
  // BATISMO REC
  INBOUND_BAPTISM: '/movement/label/baptismIn',
  INBOUND_BAPTISM_HELP: '/movement/label/baptismIn',
  // BATISMO EXP
  OUTBOUND_BAPTISM: '/movement/label/baptism',
  OUTBOUND_BAPTISM_HELP: '/movement/label/baptismOut',
  // BATISMO TRA
  TRANSFER_BAPTISM: '/movement/label/baptismTra',
  TRANSFER_BAPTISM_HELP: '/movement/label/baptismTra',
  // ENDERECO
  MOVEMENT_LABEL_ADDRESS: '/movement/label/address',
  MOVEMENT_LABEL_ADDRESS_HELP: '/movement/label/address',
  // CONFERENCIA
  MOVEMENT_LABEL_CONFERENCE: '/movement/label/conference',
  // VOLUME
  MOVEMENT_LABEL_VOLUME: '/movement/label/volume',
  MOVEMENT_LABEL_VOLUME_HELP: '/movement/label/volume',
  // CORTE DE CABOS
  MOVEMENT_LABEL_KARDEXCABLECUT: '/movement/label/kardexCableCut',
  MOVEMENT_LABEL_KARDEXCABLECUT_HELP: '/movement/label/kardexCableCut',
  // LOTE COM SALDO
  MOVEMENT_LABEL_LOT_WITH_STOCK_BALANCE: '/movement/label/lotWithStockBalance',
  // INTERAÇÃO
  INTEGRATION: '/movement/integration',
  // MONITOR DE INTEGRAÇÃO
  INTEGRATION_MONITOR: '/movement/integration/integrationMonitor',
  INTEGRATION_MONITOR_BEESTOCK: '/movement/integration/integrationMonitor/beestock',
  INTEGRATION_MONITOR_ERP: '/movement/integration/integrationMonitor/erp',
  // INDUSTRIA
  INDUSTRY: '/movement/industry',
  // REQUISIÇÃO MANUAL
  INDUSTRY_MANUAL_REQUEST: '/movement/industry/manualRequest/list',
  INDUSTRY_MANUAL_REQUEST_HELP: '/movement/industry/manualRequest',
  // Etiquetas Seriadas
  INDUSTRY_LABEL_SERIAL: '/movement/industry/serialLabel',
  // Gerar Etiquetas Seriadas
  INDUSTRY_GENERATE_LABEL_SERIAL: '/movement/industry/generateSerialLabel',
  // Gerar Etiquetas Seriadas Help
  INDUSTRY_GENERATE_LABEL_SERIAL_HELP: '/movement/industry/generateSerialLabel',
  // Imprimir Etiquetas Agrupadora
  INDUSTRY_PRINT_SERIAL_GROUP: '/movement/industry/printSerialGroup',
  // Help Imprimir Etiquetas Agrupadora
  INDUSTRY_PRINT_SERIAL_GROUP_HELP: '/movement/industry/printSerialGroup',
  // Ordem Produção
  INDUSTRY_PRODUCTION_ORDER: '/movement/industry/productionOrder',
  // HELP MONITOR DE RESSUPRIMENTO
  RESUPPLY_MONITOR_HELP: '/movement/resupply/resupplyMonitor',
  // MONITOR DE RESSUPRIMENTO
  RESUPPLY_MONITOR: '/movement/resupply/resupplyMonitor',
  // RESSUPRIMENTO
  MOVEMENT_RESUPPLY: '/movement/resupply',
  MOVEMENT_RESUPPLY_HELP: '/movement/resupply',
  // GERADOR RESSUPRIMENTO
  MOVEMENT_RESUPPLY_GENERATE: '/movement/resupply/generateResupply/list',
  MOVEMENT_RESUPPLY_GENERATE_HELP: '/movement/resupply/generateResupply',
  // MONITOR RESSUPRIMENTO
  MOVEMENT_RESUPPLY_MONITOR: '/movement/resupply/monitor',
  // REGRAS DE RESSUPRIMENTO
  MOVEMENT_RESUPPLY_RULES: '/movement/resupply/resupplyRules/list',
  MOVEMENT_RESUPPLY_RULES_HELP: '/movement/resupply/resupplyRules',
  MOVEMENT_RESUPPLY_RULES_CREATE: '/movement/resupply/resupplyRules/resupply/new',
  MOVEMENT_RESUPPLY_RULES_DETAIL: '/movement/resupply/resupplyRules/resupply/detail',
  MOVEMENT_RESUPPLY_RULES_EDIT: '/movement/resupply/resupplyRules/resupply/edit',
  MOVEMENT_RESUPPLY_RULES_COPY: '/movement/resupply/resupplyRules/resupply/copy',
  // CONSULTAS
  CONSULT: '/consult',
  // ESTOQUE
  CONSULT_STOCK: '/consult/stock',
  // SALDO ESTOQUE
  STOCK_BALANCE_LIST: '/consult/stock/balances',
  STOCK_BALANCE_HELP: '/consult/stock/balances',
  // MOVIMENTO ESTOQUE
  STOCK_MOVEMENT_LIST: '/consult/stock/movements',
  STOCK_MOVEMENT_HELP: '/consult/stock/movements',
  // ESTOQUE ERP
  STOCK_INVENTORY_ERP: '/consult/stock/inventoryErp',
  STOCK_INVENTORY_ERP_HELP: '/consult/stock/inventoryErp',
  // SALDO DE ESTOQUE DETALHADO
  DETAILED_STOCK_BALANCE: '/consult/stock/StockBalanceDetails',
  DETAILED_STOCK_BALANCE_HELP: '/consult/stock/StockBalanceDetails',
  // CUSTO MÉDIO
  AVERAGE_COST: '/consult/stock/averageCost',
  AVERAGE_COST_HELP: '/consult/stock/averageCost',
  // Comparativo Saldo Stock
  CONSULT_STOCK_BALANCE_COMPARISON: '/consult/stock/StockBalanceComparison',
  CONSULT_STOCK_BALANCE_COMPARISON_HELP: '/consult/stock/StockBalanceComparison',
  // PORTARIA
  CONSULT_ENTRANCE: '/consult/entrance',
  // AGENDAMENTOS
  CONSULT_ENTRANCE_SCHEDULE: '/consult/entrance/schedule',
  // CONFERENCIA DE PLACA
  CONSULT_ENTRANCE_CHECK: '/consult/entrance/checkPlate',
  // RECEBIMENTO
  CONSULT_INBOUND: '/consult/inbound',
  // DOCUMENTO DE ENTRADA
  CONSULT_INBOUND_HELP: '/consult/inbound/panel',
  CONSULT_INBOUND_LIST: '/consult/inbound/list',
  CONSULT_INBOUND_DETAILS: '/consult/inbound/details',
  // CONFERENCIA
  CONSULT_INBOUND_CHECK: '/consult/inbound/check',
  // CROSS DOCKING
  CONSULT_INBOUND_CROSS: '/consult/inbound/crossDocking',
  // EXPEDICAO
  CONSULT_OUTBOUND: '/consult/outbound',
  // CONFERENCIA
  CONSULT_OUTBOUND_CHECK: '/consult/outbound/check',
  CONSULT_OUTBOUND_CHECK_HELP: '/consult/outbound/check',

  // ETIQUETA BATISMO - RECEBIMENTO e EXPEDICAO
  CONSULT_BAPTISM_LABEL_INBOUND: '/consult/inbound/baptismLabel',
  CONSULT_BAPTISM_LABEL_OUTBOUND: '/consult/outbound/baptismLabel',
  CONSULT_BAPTISM_LABEL_INBOUND_HELP: '/consult/inbound/baptismLabel',
  CONSULT_BAPTISM_LABEL_OUTBOUND_HELP: '/consult/outbound/baptismLabel',

  CONSULT_OUTBOUND_ORDER_HELP: '/consult/outbound/panel',
  CONSULT_OUTBOUND_ORDER_LIST: '/consult/outbound/orders/list',
  CONSULT_OUTBOUND_ORDER_DETAIL: '/consult/outbound/orders/detail',

  // RELATORIOS
  REPORT: '/report',
  REPORT_HELP: '/reports',
  // ADMINISTRACAO
  REPORT_ADMIN: '/report/admin',
  // GERAIS
  REPORT_ADMIN_GENERAL: '/report/admin/geral',
  // EMPRESA
  REPORT_COMPANY_LIST: '/report/admin/geral/company',
  // FILIAL
  REPORT_BRANCH_LIST: '/report/admin/geral/branch',
  // USUARIOS
  REPORT_ADMIN_USER: '/report/admin/user',
  // USUARIOS
  REPORT_USER_LIST: '/report/admin/user/users',
  // PERFIL DE ACESSO
  REPORT_ACCESS_PROFILE_LIST: '/report/admin/user/accessProfile',
  // DEPARTAMENTOS
  REPORT_USERS_DEPARTMENTS: '/report/admin/user/departments',
  // FUNÇÕES DO USUÁRIO
  REPORT_USERS_USERFUNCTIONS: '/report/admin/user/userFunctions',
  // TURNOS DE TRABALHO
  REPORT_USERS_WORKTURNS: '/report/admin/user/workTurns',
  // TAREFAS INDIRETAS
  REPORT_USERS_INDIRECTTASKS_LIST: '/report/admin/user/indirectTasks',
  // CADASTROS
  REPORT_REGISTRATION: '/report/registration',
  // GERAIS
  REPORT_REG_GENERAL: '/report/registration/general',
  // ARMAZENS
  REPORT_WAREHOUSE_LIST: '/report/registration/general/warehouse',
  // DEPOSITOS
  REPORT_DEPOSIT_LIST: '/report/registration/general/deposit',
  // DOCAS
  REPORT_DOCKS_LIST: '/report/registration/general/docks',
  // RANGES X USUARIOS
  REPORT_RANGES_BY_USER_LIST: '/report/registration/general/rangesbyuser',
  // RANGE X ENDERECOS X USUARIOS
  REPORT_RANGE_LIST: '/report/registration/general/range',
  // RANGES
  REPORT_RANGES_LIST: '/report/registration/general/ranges',
  // ENDERECO
  REPORT_ADDRESS_LIST: '/report/registration/general/address',
  // CURVAS
  REPORT_CURVES_LIST: '/report/registration/general/curves',
  // CLIENTES
  REPORT_CUSTOMER_LIST: '/report/registration/general/customer',
  // FORNECEDORES
  REPORT_SUPPLIER_LIST: '/report/registration/general/supplier',
  // TRANSPORTADORAS
  REPORT_CARRIER_LIST: '/report/registration/general/carrier',
  // TIPOS DE ENDEREÇO
  REPORT_TYPEADDRESS_LIST: '/report/registration/general/typeaddress',
  // PRIORIDADES
  REPORT_PRIORITIES_LIST: '/report/registration/general/priorities',
  // PRODUTOS
  REPORT_ADMIN_PRODUCT: '/report/registration/product',
  // PRODUTOS
  REPORT_PRODUCT_LIST: '/report/registration/product/products',
  // EAN
  REPORT_EAN_LIST: '/report/registration/product/ean',
  // EMBALAGENS
  REPORT_PACKING_LIST: '/report/registration/product/packing',
  // PRODUTOS X CLIENTE
  REPORT_PRODUCTS_CUSTOMER_LIST: '/report/registration/product/productsCustomer',
  // PRODUTOS X FILIAL
  REPORT_PRODUCTS_BRANCH_LIST: '/report/registration/product/productsBranch',
  // UNIDADE DE MEDIDA
  REPORT_UNITS_MEASURE_LIST: '/report/registration/product/unitsMeasure',
  // EQUIPAMENTOS
  REPORT_REG_EQUIPS: '/report/equips',
  // IMPRESSORAS
  REPORT_PRINTER_LIST: '/report/equips/printer',
  // COLETORES
  REPORT_COLLECTOR: '/report/equips/collector',
  // EQUIPAMENTOS DE TRANSPORTE
  REPORT_TRANSPORT_EQUIP: '/report/equips/transportEquipment',
  // EQUIPAMENTOS X USUARIOS
  REPORT_EQUIPMENTS_USER: '/report/equips/userEquip',
  // EQUIPAMENTOS X ENDERECOS
  REPORT_EQUIPMENTS_ADDRESS: '/report/equips/addressEquip',
  // ESTOQUE
  REPORT_STOCK: '/report/stock',
  // MOVIMENTO ESTOQUE X ENDERECO
  REPORT_STOCK_ADRESS_MOVEMENT_LIST: '/report/stock/stockAdressMovement',
  // SALDO ESTOQUE
  REPORT_STOCK_BALANCE_LIST: '/report/stock/stockBalance',
  // ESTOQUE ERP
  REPORT_STOCK_ERP_LIST: '/report/stock/stockErp',
  // ENDEREÇOS VAZIOS
  REPORT_STOCK_EMPTY_ADDRESSES: '/report/stock/stockEmptyAddresses',
  // ALOCACAO DE ENDERECO
  REPORT_STOCK_ALLOCATION_ADDRESS: '/report/stock/stockAllocationAddress',
  // PRODUTOS VENCIDOS
  REPORT_STOCK_EXPIRED_PRODUCTS: '/report/stock/stockExpiredProducts',
  // MOVIMENTACAO
  REPORT_MOVEMENT: '/report/movement',
  // RECEBIMENTO
  REPORT_INBOUND: '/report/movement/inbound',
  // DOCUMENTOS DE ENTRADA
  REPORT_INBOUND_ORDER_LIST: '/report/movement/inbound/orders/list',
  // ITENS DE DOCUMENTOS DE ENTRADA
  REPORT_INBOUND_ORDERS_ITEMS_LIST: '/report/movement/inbound/orders/items',
  // NOTA FISCAL PRÉ ENTRADA
  REPORT_INBOUND_ORDER_PRE_ENTRY: '/report/movement/inbound/ordersPreEntry/',
  // KPIS
  REPORT_REG_KPIS: '/report/kpis',
  // CONFERÊNCIA QTD PRODUTOS POR CONFERENTE - RECEBIMENTO
  REPORT_KPIS_CHECKQTYPRODUCTSPERCHECKER_INBOUND: '/report/kpis/checkQtyProductsPerCheckerInbound',
  // CONFERÊNCIA QTDE PRODUTOS POR CONFERENTE - EXPEDIÇÃO
  REPORT_KPIS_CHECKQTYPRODUCTSPERCHECKER_OUTBOUND: '/report/kpis/checkQtyProductsPerCheckerOutbound',
  // Divergências Curva dos Produtos
  REPORT_PRODUCT_CURVE_DIVERGENCES: '/report/kpis/productCurveDivergences',
  // QTD DE PRODUTOS ARMAZENADOS POR USUÁRIO
  REPORT_QTY_PRODUCTS_STORED_PER_USER: '/report/kpis/qtyProductsStoredPerUser',
  // STATUS DOS PEDIDOS DE VENDA
  REPORT_SALES_ORDER_STATUS: '/report/kpis/salesOrderStatus',
  // QTD PRODUTOS SEPARADOS POR DATA E USUARIO
  REPORT_QTY_PRODUCTS_SEPARATED_PER_DATE_AND_USER: '/report/kpis/qtyProductsSeparatedPerDateAndUser',
  // RETALHOS
  REPORT_RETAIL: '/report/kpis/retail',
  // SEPARAÇÃO DE PRODUTOS POR DATA
  REPORT_PRODUCTS_SEPARATION_DATE: '/report/kpis/productsSeparationDate',

  // MOVIMENTAÇÃO
  REPORT_REG_MOVEMENT: '/report/movement',
  // EXPEDIÇÃO
  REPORT_REG_EXPEDITION: '/report/movement/expedition',
  // CONFERÊNCIA QTD PRODUTOS E RANGE
  REPORT_MOVEMENT_CHECKQTYPRODUCTSRANGE_LIST: '/report/movement/expedition/checkQtyProductsRange',
  // DOCUMENTOS DE SAÍDA
  REPORT_MOVEMENT_EXPEDITION_OUTBOUND_ORDER_LIST: '/report/movement/expedition/outbound',
  // ITENS DE DOCUMENTOS DE SAÍDA
  REPORT_MOVEMENT_EXPEDITION_OUTBOUND_ORDERS_ITEMS_LIST: '/report/movement/expedition/outbounditems',
  // VOLUMES POR DOCUMENTO
  REPORT_VOLUME_DOCUMENT_LIST: '/report/movement/expedition/volumeDocument',
  // PRODUTOS PENDENTES PARA ALOCAÇÃO
  REPORT_MOVEMENT_EXPEDITION_ITEMS_PENDING_ALLOCATION: '/report/movement/expedition/itemsPendingAllocation',
  // INVENTARIO
  REPORT_MOVEMENT_INVENTORY: '/report/inventory',
  // CONFERÊNCIA DE INVENTARIO
  // REPORT_MOVEMENT_INVENTORY_CHECK:  '/report/inventory/inventoryCheck',
  // CONTAGEM DE INVENTARIO
  REPORT_MOVEMENT_INVENTORY_COUNT: '/report/inventory/inventoryCount',
  // Ressuprimento
  REPORT_RESUPPLY: '/report/resupply',
  REPORT_RESUPPLY_PICKING: '/report/resupply/picking',
  // DASHBOARD
  DASHBOARD: '/dashboard',
  // RECEBIMENTO
  DASHBOARD_INBOUND: '/dashboard/inbound',
  // DASHBOARD_INBOUND
  INBOUND_ORDERS_DASHBOARD: '/dashboard/inbound/inboundOrder',
  // DASHBOARD VISÃO GERAL RECEBIMENTO
  INBOUND_ORDERS_DASHBOARD_OVERVIEW: '/dashboard/inbound/overview',
  // DASHBOARD ANÁLISE DE TEMPOS MÉDIOS
  INBOUND_ORDERS_DASHBOARD_AVERAGE_TIME: '/dashboard/inbound/averageTime',
  // DASHBOARD
  INBOUND_ORDER_DASHBOARD: '/dashboard/inbound/dashboard',
  // EXPEDICAO
  DASHBOARD_OUTBOUND: '/dashboard/outbound',
  // DASHBOARD
  OUTBOUND_ORDERS_DASHBOARD: '/dashboard/outbound/outboundOrder',
  // DASHBOARD VISÃO GERAL EXPEDIÇÃO
  OUTBOUND_ORDERS_DASHBOARD_OVERVIEW: '/dashboard/outbound/overview',
  DASHBOARD_OUTBOUND_AVERAGE_TIME: '/dashboard/outbound/averageTime',
  // INVENTARIO
  DASHBOARD_INVENTORY: '/dashboard/inventory',
  // DASHBOARD
  DASHBOARD_INVENTORY_PER_BRANCH: '/dashboard/inventory/general',
  // ESTOQUE
  DASHBOARD_STOCK: '/dashboard/stock',
  // DASHBOARD
  DASHBOARD_ADDRESS_OCCUPATION: '/dashboard/stock/addressOccupation',
  // DESENVOLVEDOR
  DEVELOP: '/develop',
  // IDIOMAS
  DEVELOP_I18N_HELP: '/develop/language',
  DEVELOP_I18N_LIST: '/develop/i18n/list',
  DEVELOP_I18N_CREATE: '/develop/i18n/new',
  DEVELOP_I18N_EDIT: '/develop/i18n/edit',
  // AGENTES
  DEVELOP_AGENT_HELP: '/develop/agent',
  DEVELOP_AGENT_LIST: '/develop/agent/list',
  DEVELOP_AGENT_DETAIL: '/develop/agent/details',
  DEVELOP_AGENT_CREATE: '/develop/agent/new',
  DEVELOP_AGENT_EDIT: '/develop/agent/edit',
  // Versão APP
  CONSULT_VERSION_APP: '/settings/detailVersion',

  // NAO UTILIZADAS
  STOCK: '/stock',
  ADMIN_COLLECTOR: '/admin/collector',
  COLLECTOR_USER: '/admin/collector/user',
  COLLECTOR_DEVICE: '/admin/collector/device',
  OUTBOUND_VOLUME_CHECK: '/outbound/VolumeCheck',
  INBOUND_RETURN: '/inbound/return',
  OUTBOUND_LIST: '/outbound/list',
  OUTBOUND_TRANSFER: '/outbound/transfer',

  // PERMISSOES
  P_UNDO_INBOUND_CONFERENCE: 'undoInboundConference',
  P_CANCEL_INBOUND_ORDER: 'cancelInboundOder',
  P_ACCESS_BRANCH_PARAMETERS: 'accessBranchParameters',
  P_INDUSTRY_REPRINT_SERIAL_GROUP: 'reprintSerialGroup',
  P_EXPEDITION_CANCEL_ORDER: 'cancelOutboundOrder',
  P_EXPEDITION_CANCEL_ITEM_ORDER: 'cancelItemOutboundOrder',
  P_EXPEDITION_ALLOWS_REMOVE_DOCK_CANCELED: 'allowsRemoveDockCanceled',

  // NOTIFICACOES
  NOTIFICATION: '/notification',
  NOTIFICATION_DETAIL: '/notification/detail',

  // CAPACIDADE
  CAPACITY_HELP: '/registration/general/capacity',
  CAPACITY_COPY: '/registration/general/capacity/copy',
  CAPACITY_DETAIL: '/registration/general/capacity/detail',
  CAPACITY_EDIT: '/registration/general/capacity/edit',
  CAPACITY_CREATE: '/registration/general/capacity/new',
  CAPACITY_LIST: '/registration/general/capacity/list',

  // AREAS DE PICKING
  PICKING_AREA_HELP: '/registration/general/pickingArea',
  PICKING_AREA_LIST: '/registration/general/pickingArea/list',
  PICKING_AREA_CREATE: '/registration/general/pickingArea/new',
  PICKING_AREA_DETAIL: '/registration/general/pickingArea/detail',
  PICKING_AREA_EDIT: '/registration/general/pickingArea/edit',
  PICKING_AREA_COPY: '/registration/general/pickingArea/copy',
  PICKING_AREA_LINK_ADDRESS: '/registration/general/pickingArea/linkAddress',

  // RESSUPRIMENTO DEPOSITOS
  RESUPPLY_DEPOSITS_HELP: '/registration/general/resupplyDeposits',
  RESUPPLY_DEPOSITS_LIST: '/registration/general/resupplyDeposits/list',
  RESUPPLY_DEPOSITS_CREATE: '/registration/general/resupplyDeposits/new',
  RESUPPLY_DEPOSITS_DETAIL: '/registration/general/resupplyDeposits/detail',
  RESUPPLY_DEPOSITS_EDIT: '/registration/general/resupplyDeposits/edit',
  RESUPPLY_DEPOSITS_COPY: '/registration/general/resupplyDeposits/copy',
};
