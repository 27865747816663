import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from '../../../../components/form/button';
import { PanelPage } from '../../../../components/pages/pages';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import ROUTES from '../../../../config/routes';
import {
  createStorageAddressSize, getStorageAddressSize, updateStorageAddressSize,
} from '../../../../app/store/actions/storageAdressSizes';
import storageAddresses from '../../../../helpers/storageAddresses';

import { getAllCurves } from '../../../../app/store/actions/curves';

class StorageAddressSizeForm extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          { I18n.t('BEE12' /* Início */)}
                </>),
      },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE14' /* Gerais */) },
      { value: I18n.t('BEE1491' /* Tamanho de Endereço */) },
      { value: '', active: true },
    ];
    this.state = {
      mode: 'detail',
      name: '',
      code: '',
      height: '',
      width: '',
      length: '',
      capacityVolume: '',
      capacityWeight: '',
      note: '',
      disableCode: true,
      disableName: true,
      disableHeight: true,
      disableWidth: true,
      disableLength: true,
      disableCapacityVolume: true,
      disableCapacityWeight: true,
      disableCurve: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      curve: '',
      curves: [],
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getStorageAddressSizeSelected();
    }

    await this.getCurves();
  }

  getStorageAddressSizeSelected = async () => {
    if (this.props.location.state.addressSize) {
      const { addressSize } = this.props.location.state;
      const addrressSizeSelected = await this.props.getStorageAddressSize(addressSize.id);

      if (addrressSizeSelected) {
        this.setState({
          addrressSizeSelected,
          addressSizeId: addrressSizeSelected.id,
          name: addrressSizeSelected.name,
          code: addrressSizeSelected.code,
          height: addrressSizeSelected.height,
          width: addrressSizeSelected.width,
          length: addrressSizeSelected.length,
          capacityVolume: addrressSizeSelected.capacityVolume,
          capacityWeight: addrressSizeSelected.capacityWeight,
          curve: addrressSizeSelected.curve,
          note: addrressSizeSelected.note,
        });
      }
    }
  };

  getCurves = async () => {
    await this.props.getAllCurves();
    const curves = [];
    this.props.curves.map((element) => (curves.push({
      value: element.code,
      label: element.code,
    })));
    this.setState({
      curves: [...curves],
    });
  };

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableHeight: false,
        disableWidth: false,
        disableLength: false,
        disableCapacityVolume: false,
        disableCapacityWeight: false,
        disableCurve: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableHeight: false,
        disableWidth: false,
        disableLength: false,
        disableCapacityVolume: false,
        disableCapacityWeight: false,
        disableCurve: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  createStorageAddressSizeSubmit = async () => {
    const {
      name, code, height, width, length, capacityVolume, capacityWeight, curve, curves, note,
    } = this.state;
    const tempCurve = (curve === '') ? curves[0].value : curve;
    try {
      const newAddressSize = await this.props.createStorageAddressSize({
        name,
        code,
        height,
        width,
        length,
        capacityVolume,
        capacityWeight,
        curve: tempCurve,
        note,
      });

      if (newAddressSize) {
        await addNotification('success', I18n.t('BEE1605' /* Novo tamanho de endereço */), I18n.t('BEE1610', { 0: name } /* Novo tamanho de endereço %{0} criado com sucesso! */), 'top-right');

        this.props.history.push(ROUTES.ADDRESS_SIZE_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
        await addNotification(
          'danger',
          I18n.t('BEE1605' /* Novo tamanho de endereço */),
          err.response.data.error.message,
          'top-right',
        );
      } else {
        await addNotification('danger', I18n.t('BEE1605' /* Novo tamanho de endereço */), I18n.t('BEE1609', { 0: name } /* Erro ao incluir o tamanho de endereço %{0} */), 'top-right');
      }
    }
  };

  updateStorageAddressSizeSubmit = async () => {
    const {
      addressSizeId, name, code, height, width, length, capacityVolume, capacityWeight, curve, curves, note,
    } = this.state;

    try {
      const updAddressSize = await this.props.updateStorageAddressSize({
        addressSizeId,
        name,
        code,
        height,
        width,
        length,
        capacityVolume,
        capacityWeight,
        curve,
        note,
        curves,
      });

      if (updAddressSize) {
        await addNotification('success', I18n.t('BEE1583' /* Atualizar tamanho do Endereço */), I18n.t('BEE1584', { 0: name } /* Tamanho de endereço %{0} atualizado com sucesso! */), 'top-right');

        this.props.history.push(ROUTES.ADDRESS_SIZE_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error && err.response.data.error.message) {
        await addNotification(
          'danger',
          I18n.t('BEE1583' /* Atualizar tamanho do Endereço */),
          err.response.data.error.message,
          'top-right',
        );
      } else {
        await addNotification('danger', I18n.t('BEE1583' /* Atualizar tamanho do Endereço */), I18n.t('BEE1590', { 0: name } /* Erro ao atualizar o tamanho do endereço %{0} */), 'top-right');
      }
    }
  };

  render() {
    const {
      name, code, height, width, length, capacityVolume, capacityWeight, curve, curves, note,
    } = this.state;

    const {
      disableCode, disableName, disableHeight, disableWidth, disableCapacityVolume, disableCapacityWeight, disableCurve, disableNote,
      mode, showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';
    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE1605' /* Novo Tamanho de Endereço */);
      this.breadcrumb[4].value = title;
    } else if (mode === 'edit') {
      title = I18n.t('BEE1611' /* Editar Tamanho de Endereço */);
      this.breadcrumb[4].value = title;
    } else if (mode === 'detail') {
      title = I18n.t('BEE1560' /* Detalhes do Tamanho de Endereço */);
      this.breadcrumb[4].value = title;
    }

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={title}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInput(code, 'code', `${I18n.t('BEE82' /* Código */)}:`, '', 'text', true, disableCode),
                  formContext.createInput(name, 'name', `${I18n.t('BEE83' /* Nome */)}:`, '', 'text', true, disableName),
                  formContext.createInput(height, 'height', `${I18n.t('BEE386' /* Altura */)}:`, '', 'number', true, disableHeight),
                  formContext.createInput(width, 'width', `${I18n.t('BEE387' /* Largura */)}:`, '', 'number', true, disableWidth),
                  formContext.createInput(length, 'length', `${I18n.t('BEE388' /* Comprimento */)}:`, '', 'number', true, disableWidth),
                  formContext.createInput(capacityVolume, 'capacityVolume', `${I18n.t('BEE727' /* Capacidade (Volume) */)}:`, '', 'number', true, disableCapacityVolume),
                  formContext.createInput(capacityWeight, 'capacityWeight', `${I18n.t('BEE726' /* Capacidade (Peso) */)}:`, '', 'number', true, disableCapacityWeight),
                  ((disableCurve)
                    ? formContext.createInput(curve, 'curve', `${I18n.t('BEE355' /* Curva */)}:`, '', 'text', true, disableCurve)
                    : formContext.createSelect(curve, 'curve', `${I18n.t('BEE355' /* Curva */)}:`, curves)),
                  formContext.createInput(note, 'note', `${I18n.t('BEE135' /* Observação */)}:`, '', 'text', true, disableNote),
                ])}
              />
            </div>
          </div>
        )}
        footerContent={(
          <>
            <Link to={{ pathname: ROUTES.ADDRESS_SIZE_LIST, state: { dice: this.props.location.dice } }}>
              {(showCancel
                ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
            </Link>
            {(showCreate
              && (
              <OneClickButton
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.createStorageAddressSizeSubmit()}
              >
                {I18n.t('BEE138' /* Criar */)}
              </OneClickButton>
              )
            )}
            {(showUpdate
              && (
              <OneClickButton
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.updateStorageAddressSizeSubmit()}
              >
                {I18n.t('BEE139' /* Atualizar */)}
              </OneClickButton>
              )
            )}
          </>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  curves: state.curves?.curvesList,
});

const mapDispatchToProps = (dispatch) => ({
  getStorageAddressSize: (addressSizeId) => dispatch(getStorageAddressSize(addressSizeId)),
  createStorageAddressSize: (addressSize) => dispatch(createStorageAddressSize(addressSize)),
  updateStorageAddressSize: (addressSize) => dispatch(updateStorageAddressSize(addressSize)),
  getAllCurves: () => dispatch(getAllCurves()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorageAddressSizeForm));
