import { store } from '../config/configureStore';

function formatNumber(number, decimals, isCurrency = false, isFractional = false, currencyCode = 'BRL') {
  number = parseFloat(number);

  const style = isCurrency ? 'currency' : 'decimal';
  const fractionalQuantity = store.getState().app.permissionsCompany
  && store.getState().app.permissionsCompany.fractionalQuantity;

  return new Intl.NumberFormat('pt-BR', {
    style,
    currency: currencyCode,
    minimumFractionDigits: (!fractionalQuantity && !isCurrency && !isFractional) ? 0 : decimals,
    maximumFractionDigits: (!fractionalQuantity && !isCurrency && !isFractional) ? 0 : decimals,
  }).format(number);
}

export default {
  formatNumber,
};
