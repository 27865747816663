import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { SimpleForm } from '../../../components/panel/panel';
import addNotification from '../../../components/notification';

import ROUTES from '../../../config/routes';

import {
  createAccessProfile, updateAccessProfile, getAccessProfile,
} from '../../../app/store/actions/accessProfile';

class DevelopAccessProfileForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      disableCode: true,
      disableName: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode === 'create') {
      // do something
    } else {
      await this.getAccessProfileSelected();
    }
  }

  getAccessProfileSelected = async () => {
    if (this.props.location.state.accessProfile) {
      const { accessProfile } = this.props.location.state;

      const accessProfileSelected = await this.props.getAccessProfile(accessProfile.code);

      if (accessProfileSelected) {
        this.setState({
          accessProfileSelected,
          code: accessProfileSelected.code,
          name: accessProfileSelected.name,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div key={attr} className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  createAccessProfileSubmit = async () => {
    const {
      code, name,
    } = this.state;

    try {
      const newAccessProfile = await this.props.createAccessProfile({
        code, name,
      });

      if (newAccessProfile && typeof newAccessProfile !== 'object') {
        await addNotification('success', I18n.t('BEE249' /* Novo Perfil de Acesso */), I18n.t('BEE250', { 0: code } /* Novo Perfil %{0} incluído com sucesso! */), 'top-right');
        this.props.history.push(ROUTES.DEVELOP_ACCESS_PROFILE_LIST);
      } else {
        await addNotification(
          'danger', I18n.t('BEE249' /* Novo Perfil de Acesso */), I18n.t('BEE251', { 0: code } /* Erro ao incluir o Perfil de Acesso %{0}! */), 'top-right'
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE249' /* Novo Perfil de Acesso */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE249' /* Novo Perfil de Acesso */), I18n.t('BEE251', { 0: code } /* Erro ao incluir o Perfil de Acesso %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE249' /* Novo Perfil de Acesso */), I18n.t('BEE251', { 0: code } /* Erro ao incluir o Perfil de Acesso %{0}! */), 'top-right'
        );
      }
    }
  };

  updateAccessProfile = async () => {
    const {
      code, name,
    } = this.state;

    try {
      const updAccessProfile = await this.props.updateAccessProfile({
        code, name,
      });

      if (updAccessProfile && updAccessProfile instanceof Array && updAccessProfile.length) {
        await addNotification('success', I18n.t('BEE252' /* Atualizar Perfil de Acesso */), I18n.t('BEE253', { 0: code } /* Atualização do Perfil de Acesso %{0} efetuado com sucesso! */), 'top-right');
        this.props.history.push(ROUTES.DEVELOP_ACCESS_PROFILE_LIST);
      } else {
        await addNotification(
          'danger', I18n.t('BEE252' /* Atualizar Perfil de Acesso */), I18n.t('BEE254', { 0: code } /* Erro ao atualizar o Perfil de Acesso %{0}! */), 'top-right'
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE252' /* Atualizar Perfil de Acesso */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE252' /* Atualizar Perfil de Acesso */), I18n.t('BEE254', { 0: code } /* Erro ao atualizar o Perfil de Acesso %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE252' /* Atualizar Perfil de Acesso */), I18n.t('BEE254', { 0: code } /* Erro ao atualizar o Perfil de Acesso %{0}! */), 'top-right'
        );
      }
      await addNotification('danger', I18n.t('BEE252' /* Atualizar Perfil de Acesso */), I18n.t('BEE254', { 0: code } /* Erro ao atualizar o Perfil de Acesso %{0}! */), 'top-right');
    }
  };

  render() {
    const {
      mode, code, name, disableCode, disableName,
      showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE249' /* Novo Perfil de Acesso */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE255' /* Editar Perfil de Acesso */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE256' /* Detalhes Perfil de Acesso */);
    }

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE25' /* Usuários */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE247' /* Perfil de Acesso */)}</li>
          </ol>
        </div>
        <SimpleForm
          title={title}
          noButton={true}
          linkBack={{pathname: ROUTES.DEVELOP_ACCESS_PROFILE_LIST, state: {dice: this.props.location.dice}}}
          showCancel={showCancel}
          showCreate={showCreate}
          onSubmitCreate={() => this.createAccessProfileSubmit()}
          showUpdate={showUpdate}
          onSubmitUpdate={() => this.updateAccessProfile()}
          inputs={[
            this.createInput(
              code, 'code', `${I18n.t('BEE82' /* Código */)}:`, I18n.t('BEE257' /* Informe o perfil de acesso (obrigatório) */), 'text', true, disableCode,
            ),
            this.createInput(
              name, 'name', `${I18n.t('BEE83' /* Nome */)}:`, I18n.t('BEE258' /* Informe o nome do perfil de acesso (obrigatório) */), 'text', true, disableName,
            ),
          ]}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getAccessProfile: code => dispatch(getAccessProfile(code)),
  createAccessProfile: accessProfile => dispatch(createAccessProfile(accessProfile)),
  updateAccessProfile: accessProfile => dispatch(updateAccessProfile(accessProfile)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DevelopAccessProfileForm));
