import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';

export default class ProductsList extends React.Component { 
  render() {
    const { products } = this.props;

    return (
      <div className="table-responsive">
        <table className={'table table-bordered widget-table widget-table-rounded'}>
          <thead>
            <tr className="text-center f-s-12">
              <th width="5%">{I18n.t('BEE463' /* Linha */)}</th>
              <th width="35%">{I18n.t('BEE225' /* Produto */)}</th>
              <th width="15%">{I18n.t('BEE384' /* Unidade */)}</th>
              <th width="15%">{I18n.t('BEE428' /* Lote */)}</th>
              <th width="20%">{I18n.t('BEE135' /* Observação */)}</th>
              <th width="10%">{`${I18n.t('BEE1368' /* Separado */)} ?`}</th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => {
              const textClass = product.status >= 4 ? ' text-teal' : ''
              return (
                <tr className="text-center" key={index}>
                  <td>
                    <h5 className={textClass}>{product.lineNumber}</h5>
                  </td>
                  <td className="text-left">
                    <h4 className={"widget-table-title" + textClass}>{product.productCode}</h4>
                    <p className={"widget-table-desc m-b-15" + textClass}>{product.productName}</p>
                    <div className={"clearfix f-s-10" + textClass}>
                      {`${I18n.t('BEE224' /* Status */)}:`} 
                      <b className={textClass}> { product.status < 4 ? I18n.t('BEE470' /* Pendente */) : I18n.t('BEE1368' /* Separado */)}</b>
                    </div>
                  </td>
                  <td className={textClass}>
                    {product.unit}
                  </td>
                  <td className={textClass}>
                    {product.lotNumber}
                  </td>
                  <td className={textClass}>
                    {product.note}
                  </td>
                  <td>
                    { product.status >= 4 ? 
                      <i className="fas fa-check-circle fa-2x text-teal"></i> :
                      <i className="fas fa-times-circle fa-2x text-danger"></i> }
                  </td>
                </tr>
                ) 
              })}
          </tbody>
        </table>
      </div>
    );
  }
};