import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getWorkShiftsList, deleteWorkShift } from '../../../../app/store/actions/workShifts';

class WorkShiftsList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      workShiftUpdate: null
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false
      }
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 100,
        Cell: rows => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs"></DropdownToggle>
              <DropdownMenu>
              <DropdownItem onClick={() => this.showWorkShiftDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditWorkShift(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyWorkShift(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteWorkShift(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        )
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        id: 'name',
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200
      },{
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200
      },
    ];
  }
  
  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true
      });
      await this.getWorkShifts();
    } else {
      await this.getWorkShifts();
    }
  }

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      workShiftUpdate: null,
    });
  }

  getWorkShifts = async () => {
    await this.props.getWorkShiftsList();
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  deleteWorkShift = async (workShift) => {
    const result = await this.props.deleteWorkShift(workShift._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'danger', I18n.t('BEE932' /* Eliminar Turno */), I18n.t('BEE933', { 0: workShift.code } /* Turno %{0} eliminado com sucesso! */), 'top-right'
      );
    }
    await this.getWorkShifts();
  }

  showWorkShiftDetail = (workShift) => {
    this.props.history.push({
      pathname: ROUTES.WORK_SHIFT_DETAIL,
      state: {
        workShift: workShift._original,
      },
      dice: {
        page: this.state.page,
      }
    });
  }

  showEditWorkShift = (workShift) => {
    this.props.history.push({
      pathname: ROUTES.WORK_SHIFT_EDIT,
      state: {
        workShift: workShift._original,
      },
      dice: {
        page: this.state.page,
      }
    });
  }

  showCopyWorkShift = (workShift) => {
    this.props.history.push({
      pathname: ROUTES.WORK_SHIFT_COPY,
      state: {
        workShift: workShift._original,
      }
    });
  }

  showDeleteWorkShift = (workShift) => {
    this.setState({ showModalDelete: true, workShiftUpdate: workShift });
  }

  
  render() {
    const { workShiftUpdate, showModalDelete, page,} = this.state;
    const { workShiftsList = []  } = this.props;

    return ( 
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE25' /* Usuários */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE869' /* Turnos de Trabalho */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE869' /* Turnos de Trabalho */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.WORK_SHIFT_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1"></i> {I18n.t('BEE882' /* Incluir Turno de Trabalho */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getWorkShifts
          }}
          filterable
          data={workShiftsList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) ? page : (!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page}
          onPageChange={(page) =>  ((page >= 0) ? this.setState({page}) : this.setState({page: 0}))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete &&
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteWorkShift(workShiftUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE931', { 0: workShiftUpdate.code } /* O Turno %{0} será eliminado! */)}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  workShiftsList: state.workShifts && state.workShifts.workShiftsList,
});

const mapDispatchToProps = dispatch => ({
  getWorkShiftsList: () => dispatch(getWorkShiftsList()),
  deleteWorkShift: (workShiftId) => dispatch(deleteWorkShift(workShiftId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WorkShiftsList));