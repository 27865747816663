import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createUnitMeasure, getUnitMeasure, updateUnitMeasure,
} from '../../../../app/store/actions/unitsMeasure';

class UnitMeasureForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      note: '',
      disableCode: true,
      disableName: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getUnitMeasureSelected();
    }
  }

  getUnitMeasureSelected = async () => {
    if (this.props.location.state.unit) {
      const { unit } = this.props.location.state;

      const unitSelected = await this.props.getUnitMeasure(unit.id);

      if (unitSelected) {
        this.setState({
          unitSelected,
          unitMeasureId: unitSelected.id,
          code: unitSelected.code,
          name: unitSelected.name,
          note: unitSelected.note,
        });
      }
    }
  };

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  createUnitMeasureSubmit = async () => {
    const {
      code, name, note,
    } = this.state;

    try {
      const newUnitMeasure = await this.props.createUnitMeasure({
        code,
        name,
        note,
      });

      if (newUnitMeasure) {
        await addNotification('success', I18n.t('BEE867' /* Nova unidade de medida */), I18n.t('BEE868', { 0: code } /* Nova unidade de medida %{0} incluída com sucesso ! */), 'top-right');

        this.props.history.push(ROUTES.UNIT_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE867' /* Nova unidade de medida */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE867' /* Nova unidade de medida */), I18n.t('BEE870', { 0: code } /* Erro ao incluir a unidade de medida %{0} ! */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE867' /* Nova unidade de medida */), I18n.t('BEE870', { 0: code } /* Erro ao incluir a unidade de medida %{0} ! */), 'top-right');
      }
    }
  };

  updateUnitMeasureSubmit = async () => {
    const {
      unitMeasureId, code, name, note,
    } = this.state;

    try {
      const updUnitMeasure = await this.props.updateUnitMeasure({
        unitMeasureId,
        name,
        note,
      });

      if (updUnitMeasure) {
        await addNotification('success', I18n.t('BEE871' /* Atualizar unidade de medida */), I18n.t('BEE872', { 0: code } /* Atualização da unidade de medida %{0} efetuada com sucesso ! */), 'top-right');

        this.props.history.push(ROUTES.UNIT_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE871' /* Atualizar unidade de medida */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE871' /* Atualizar unidade de medida */), I18n.t('BEE873', { 0: code } /* Erro ao atualizar a unidade de medida %{0} ! */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE871' /* Atualizar unidade de medida */), I18n.t('BEE873', { 0: code } /* Erro ao atualizar a unidade de medida %{0} ! */), 'top-right');
      }
    }
  };

  render() {
    const {
      code, name, note,
    } = this.state;

    const {
      mode, disableCode, disableName, disableNote, showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';
    // let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE867' /* Nova unidade de medida */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE874' /* Editar unidade de medida */);
      //   breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE875' /* Detalhes unidade de medida */);
      //    breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE29' /* Unidades de Medida */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE867' /* Nova unidade de medida */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  {this.createInput(code, 'code', `${I18n.t('BEE29' /* Unidades de Medida */)}:`, I18n.t('BEE876' /* Informe a unidade de medida (obrigatório) */), 'text', true, disableCode)}
                  {this.createInput(name, 'name', `${I18n.t('BEE277' /* Descrição */)}:`, I18n.t('BEE778' /* Informe a descrição (obrigatório) */), 'text', true, disableName)}
                  {this.createInput(note, 'note', `${I18n.t('BEE135' /* Observação */)}:`, I18n.t('BEE136' /* Informe a observação */), 'text', false, disableNote)}
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.UNIT_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createUnitMeasureSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}

                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateUnitMeasureSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}

                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getUnitMeasure: (unitMeasureId) => dispatch(getUnitMeasure(unitMeasureId)),
  createUnitMeasure: (unitMeasure) => dispatch(createUnitMeasure(unitMeasure)),
  updateUnitMeasure: (unitMeasure) => dispatch(updateUnitMeasure(unitMeasure)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UnitMeasureForm));
