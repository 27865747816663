import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SweetAlert from 'react-bootstrap-sweetalert';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import _ from 'lodash';

import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';

import ROUTES from '../../../config/routes';

import { getAllAccessProfiles, deleteAccessProfile } from '../../../app/store/actions/accessProfile';

class DevelopPaList extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      rowToUpdate: null,
      showModalDelete: false,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false
      },
      {
        id: 'name',
        desc: false
      }
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 100,
        Cell: rows => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs"></DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.showManager(rows.row)}>{I18n.t('BEE26' /* Permissões */)}</DropdownItem>
                <DropdownItem divider></DropdownItem>
                <DropdownItem onClick={() => this.showEdit(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDelete(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        )
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true
      });
      await this.getGrid();
    } else {
      await this.getGrid();
    }
  }

  setInitState = () => {
    this.setState({
      rowToUpdate: null,
      showModalDelete: false,
    });
  }

  getGrid = async () => {
    await this.props.getGrid();
  }

  deleteAccessProfile = async (row) => {
    const result = await this.props.deleteAccessProfile(row._original.code);

    this.setInitState();
    if (result) {
      addNotification(
        'danger', I18n.t('BEE260' /* Eliminar Perfil de Acesso */), I18n.t('BEE259', { 0: row.code } /* Perfil de Acesso %{0} eliminado com sucesso! */), 'top-right'
      );
    }
    await this.getGrid();
  }

  showDelete = (row) => {
    this.setState({ showModalDelete: true, rowToUpdate: row });
  }

  showEdit = (row) => {
    this.props.history.push({
      pathname: ROUTES.DEVELOP_ACCESS_PROFILE_EDIT,
      state: {
        accessProfile: row._original,
      },
      dice: {
        page: this.state.page,
      }      
    });
  }

  showManager = (row) => {
    this.props.history.push({
      pathname: ROUTES.DEVELOP_ACCESS_PROFILE_MANAGER,
      state: {
        accessProfile: row._original,
      }
    });
  }

  render() {
    const { rowToUpdate, showModalDelete, page,} = this.state;
    const { accessProfile = [], } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE25' /* Usuários */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE247' /* Perfil de Acesso */)}</li>
          </ol>
        </div>
        <Table
          wikiHelp={ROUTES.DEVELOP_ACCESS_PROFILE_HELP}
          headerTitle={I18n.t('BEE247' /* Perfil de Acesso */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.DEVELOP_ACCESS_PROFILE_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1"></i> {I18n.t('BEE248' /* Incluir Perfil de Acesso */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getGrid
          }}
          filterable
          data={accessProfile}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) ? page : (!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page}
          onPageChange={(page) =>  ((page >= 0) ? this.setState({page}) : this.setState({page: 0}))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete &&
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteAccessProfile(rowToUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE261', { 0: rowToUpdate.code } /* O perfil de acesso %{0} será eliminado! */)}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  accessProfile: state.accessProfile && state.accessProfile.accessProfile,
});

const mapDispatchToProps = dispatch => ({
  getGrid: () => dispatch(getAllAccessProfiles()),
  deleteAccessProfile: (key) => dispatch(deleteAccessProfile(key)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DevelopPaList));