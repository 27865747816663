import StocksRequests from '../../server/stocks';
import { addLoading, removeLoading } from './loading';

export const setStockBalances = (stockBalances) => ({
  type: 'SET_STOCK_BALANCES',
  stockBalances,
});

export const setStockMovements = (stockMovements) => ({
  type: 'SET_STOCK_MOVEMENTS',
  stockMovements,
});

export const setAllStockBalances = (allStockBalances) => ({
  type: 'SET_ALL_STOCK_BALANCES',
  allStockBalances,
});

export const setProductsStockBalance = (productsStockBalance) => ({
  type: 'SET_PRODUCTS_STOCK_BALANCE',
  productsStockBalance,
});

export const getStockBalances = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const stockBalances = await StocksRequests.getStockBalances(tableParams);
    dispatch(setStockBalances(stockBalances ? stockBalances.rows : []));
    dispatch(removeLoading());
    return stockBalances || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const getAllStockBalances = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const allStockBalances = await StocksRequests.getAllStockBalances(tableParams);
    dispatch(setAllStockBalances(allStockBalances ? allStockBalances.allStockBalancesOrganized : []));
    dispatch(removeLoading());
    return allStockBalances || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const getProductsStockBalance = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const productsStockBalance = await StocksRequests.getProductsStockBalance(tableParams);
    dispatch(setProductsStockBalance(productsStockBalance ? productsStockBalance.organizedStockBalanceProducts : []));
  } finally {
    dispatch(removeLoading());
  }
};

export const getStockMovements = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const stockMovements = await StocksRequests.getStockMovements(tableParams);
    dispatch(setStockMovements(stockMovements ? stockMovements.rows : []));
    dispatch(removeLoading());
    return stockMovements || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const getProductByEan = (ean, orderType = '') => async (dispatch) => {
  dispatch(addLoading());
  try {
    const product = await StocksRequests.getProductByEan(ean, orderType);
    return product;
  } finally {
    dispatch(removeLoading());
  }
};

export const getProductStockBalanceByAddresAndEan = (ean = '', addressId = '') => async (dispatch) => {
  dispatch(addLoading());
  try {
    const product = await StocksRequests.getProductStockBalanceByAddresAndEan(ean, addressId);
    return product;
  } finally {
    dispatch(removeLoading());
  }
};

export const doStockTransfer = (transfer) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StocksRequests.doStockTransfer(transfer);
  } finally {
    dispatch(removeLoading());
  }
};

export const getInformation = (addressCode, productCode, lotNumber = '') => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StocksRequests.getInformation(addressCode, productCode, lotNumber);
  } finally {
    dispatch(removeLoading());
  }
};

export const setNewQuantityToStock = (branchCode, addressCode, ean, lotNumber, expirationDate, quantity, depositCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StocksRequests.setNewQuantityToStock(branchCode, addressCode, ean, lotNumber, expirationDate, quantity, depositCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const getStockDashboardInfo = (selectedBranch) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await StocksRequests.getStockDashboardInfo(selectedBranch);
  } finally {
    dispatch(removeLoading());
  }
};
