import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import moment from 'moment';

import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';
import Select from '../../../../components/form/select';

import ROUTES from '../../../../config/routes';

import {
  createSchedule, updateSchedule, getSchedule,
} from '../../../../app/store/actions/schedules';
import { getCarrierSchedulesList } from '../../../../app/store/actions/carrierSchedules';
import { getBranchesOptions } from '../../../../app/store/actions/branches';
import CarrierScheduleTable from './carrierScheduleTable';

class ScheduleForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 'main',
      mode: 'detail',
      listBranch: [],
      carrierSchedules: [],
      carrierOptions: [],
      customerOptions: [],
      branchCode: '',
      code: '',
      name: '',
      monday: false,
      tuesday: false,
      wednesday: false,
      thursday: false,
      friday: false,
      saturday: false,
      sunday: false,
      startTime: '',
      endTime: '',
      cif: false,
      fob: false,
      sale: false,
      transfer: false,
      note: '',
      disableBranchCode: true,
      disableCode: true,
      disableName: true,
      disableMonday: true,
      disableTuesday: true,
      disableWednesday: true,
      disableThursday: true,
      disableFriday: true,
      disableSaturday: true,
      disableSunday: true,
      disableStartTime: true,
      disableEndTime: true,
      disableCif: true,
      disableFob: true,
      disableSale: true,
      disableTransfer: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getScheduleSelected(false);
    }

    const listBranch = await this.props.getBranchesOptions();

    this.setState({
      listBranch,
    });
  }

  getScheduleSelected = async () => {
    if (this.props.location.state.schedule) {
      const { schedule } = this.props.location.state;
      const scheduleSelected = await schedule;

      if (scheduleSelected) {
        this.setState(
          {
            scheduleSelected,
            scheduleId: scheduleSelected.id,
            branchCode: scheduleSelected.branchCode,
            branchCodeName: `${scheduleSelected.branchCode} - ${scheduleSelected.branch.name}`,
            code: scheduleSelected.code,
            name: scheduleSelected.name,
            monday: scheduleSelected.monday,
            tuesday: scheduleSelected.tuesday,
            wednesday: scheduleSelected.wednesday,
            thursday: scheduleSelected.thursday,
            friday: scheduleSelected.friday,
            saturday: scheduleSelected.saturday,
            sunday: scheduleSelected.sunday,
            startTime: moment(scheduleSelected.startTime, 'HH:mm:ss', true),
            endTime: moment(scheduleSelected.endTime, 'HH:mm:ss', true),
            cif: scheduleSelected.cif,
            fob: scheduleSelected.fob,
            sale: scheduleSelected.sale,
            transfer: scheduleSelected.transfer,
            note: scheduleSelected.note,
          },
          async () => {
            await this.updateCarrierScheduleList();
          },
        );
      }
    }
  };

  updateCarrierScheduleList = async () => {
    const carrierSchedules = await this.props.getCarrierSchedulesList({
      scheduleCode: this.state.scheduleSelected.code,
    });

    this.setState({
      carrierSchedules,
    });
  };

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableBranchCode: false,
        disableCode: false,
        disableName: false,
        disableMonday: false,
        disableTuesday: false,
        disableWednesday: false,
        disableThursday: false,
        disableFriday: false,
        disableSaturday: false,
        disableSunday: false,
        disableStartTime: false,
        disableEndTime: false,
        disableCif: false,
        disableFob: false,
        disableSale: false,
        disableTransfer: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableBranchCode: true,
        disableCode: true,
        disableName: false,
        disableMonday: false,
        disableTuesday: false,
        disableWednesday: false,
        disableThursday: false,
        disableFriday: false,
        disableSaturday: false,
        disableSunday: false,
        disableStartTime: false,
        disableEndTime: false,
        disableCif: false,
        disableFob: false,
        disableSale: false,
        disableTransfer: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value || ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <div className="row" style={{ marginTop: '8px', marginLeft: '0px' }}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={(e) => this.handleInputChange(e)}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`} />
          </div>
        </div>
      </div>
    </div>
  );

  createTimePicker = (value, attr, label) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <DateTime
          value={value}
          dateFormat={false}
          timeFormat="HH:mm:ss"
          onChange={(val) => this.setValue(attr, val)}
          inputProps={{
            placeholder: 'hh:mm:ss',
          }}
        />
      </div>
    </div>
  );

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.type === 'radio' ? parseInt(target.value) : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  createScheduleSubmit = async () => {
    const {
      branchCode, code, name, monday, tuesday, wednesday, thursday, friday,
      saturday, sunday, startTime, endTime, cif, fob, sale, transfer, note,
    } = this.state;

    const formatedStartTime = moment(startTime, 'HH:mm:ss').isValid() ? moment(startTime).format('HH:mm:ss') : '';
    const formatedEndTime = moment(endTime, 'HH:mm:ss').isValid() ? moment(endTime).format('HH:mm:ss') : '';

    try {
      const newSchedule = await this.props.createSchedule({
        branchCode,
        code,
        name,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        startTime: formatedStartTime,
        endTime: formatedEndTime,
        cif,
        fob,
        sale,
        transfer,
        note,
      });

      if (newSchedule) {
        await addNotification('success', I18n.t('BEE658' /* Agendamentos */), I18n.t('BEE674', { 0: code } /* Agendamento %{0} criado com sucesso! */), 'top-right');

        this.props.history.push(ROUTES.SCHEDULE_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE658' /* Agendamentos */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE658' /* Agendamentos */), I18n.t('BEE675', { 0: code } /* Erro ao incluir a agendamento %{0}! */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE658' /* Agendamentos */), I18n.t('BEE675', { 0: code } /* Erro ao incluir a agendamento %{0}! */), 'top-right');
      }
    }
  };

  updateScheduleSubmit = async () => {
    const {
      branchCode, code, name, monday, tuesday, wednesday, thursday, friday,
      saturday, sunday, startTime, endTime, cif, fob, sale, transfer, note,
      scheduleId,
    } = this.state;

    const formatedStartTime = moment(startTime, 'HH:mm:ss').isValid() ? moment(startTime).format('HH:mm:ss', true) : startTime;
    const formatedEndTime = moment(endTime, 'HH:mm:ss').isValid() ? moment(endTime).format('HH:mm:ss', true) : endTime;

    try {
      const updSchedule = await this.props.updateSchedule({
        scheduleId,
        branchCode,
        code,
        name,
        monday,
        tuesday,
        wednesday,
        thursday,
        friday,
        saturday,
        sunday,
        startTime: formatedStartTime,
        endTime: formatedEndTime,
        cif,
        fob,
        sale,
        transfer,
        note,
      });

      if (updSchedule) {
        await addNotification('success', I18n.t('BEE676' /* Atualizar Agendamento */), I18n.t('BEE684', { 0: code } /* Agendamento %{0} atualizado com sucesso! */), 'top-right');

        this.props.history.push(ROUTES.SCHEDULE_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE685' /* Atualizar Agendamento */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE685' /* Atualizar Agendamento */), I18n.t('BEE686', { 0: code } /* Erro ao atualizar o agendamento %{0} */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE685' /* Atualizar Agendamento */), I18n.t('BEE686', { 0: code } /* Erro ao atualizar o agendamento %{0} */), 'top-right');
      }
    }
  };

  toggleTab = (tab) => {
    this.setState({
      activeTab: tab,
    });
  };

  render() {
    const {
      branchCode, code, name, monday, tuesday, wednesday, thursday, friday,
      saturday, sunday, startTime, endTime, cif, fob, sale, transfer, note,
    } = this.state;

    const {
      mode, disableBranchCode, disableCode, disableName, disableMonday, disableTuesday,
      disableWednesday, disableThursday, disableFriday, disableSaturday, disableSunday,
      disableStartTime, disableEndTime, disableCif, disableFob, disableSale, disableTransfer,
      disableNote, showCreate, showUpdate, showCancel, listBranch, branchCodeName,
      scheduleSelected, carrierSchedules, carrierOptions, customerOptions,
    } = this.state;

    const formatedStartTime = scheduleSelected ? moment(startTime).format('HH:mm:ss') : '';
    const formatedEndTime = scheduleSelected ? moment(endTime).format('HH:mm:ss') : '';

    let title = '';
    let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE659' /* Novo Agendamento */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE660' /* Editar Agendamento */);
      breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE661' /* Detalhes Agendamento */);
      breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE658' /* Agendamentos */)}</li>
            <li className="breadcrumb-item active">{breadActive}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <div className="row">
                    <div className="col mb-3">
                      <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === 'main' ? 'active' : ''}
                            onClick={() => {
                              this.toggleTab('main');
                            }}
                          >
                            {I18n.t('BEE191' /* Geral */)}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === 'address' ? 'active' : ''}
                            onClick={() => {
                              this.toggleTab('link');
                            }}
                          >
                            {I18n.t('BEE20' /* Endereços */)}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="main">
                          {this.createInput(code, 'code', `${I18n.t('BEE82' /* Código */)}:`, I18n.t('BEE647' /* Informe a prioridade (obrigatório) */), 'text', true, disableCode)}
                          {this.createInput(name, 'name', `${I18n.t('BEE83' /* Nome */)}:`, I18n.t('BEE648' /* Informe o nome da prioridade (obrigatório) */), 'text', true, disableName)}
                          {(disableBranchCode
                            ? this.createInput(branchCodeName, 'branchCodeName', I18n.t('BEE145' /* Filial */), '', 'text', false, disableBranchCode)
                            : this.createSelectDropDown({ value: branchCode, label: branchCodeName }, 'branchCode', I18n.t('BEE145' /* Filial */), listBranch)
                          )}
                          {this.createToggleBox(monday, 'monday', `${I18n.t('BEE662' /* Segunda-feira */)}:`, disableMonday)}
                          {this.createToggleBox(tuesday, 'tuesday', `${I18n.t('BEE663' /* Terça-feira */)}:`, disableTuesday)}
                          {this.createToggleBox(wednesday, 'wednesday', `${I18n.t('BEE664' /* Quarta-feira */)}:`, disableWednesday)}
                          {this.createToggleBox(thursday, 'thursday', `${I18n.t('BEE665' /* Quinta-feira */)}:`, disableThursday)}
                          {this.createToggleBox(friday, 'friday', `${I18n.t('BEE666' /* Sexta-feira */)}:`, disableFriday)}
                          {this.createToggleBox(saturday, 'saturday', `${I18n.t('BEE667' /* Sábado */)}:`, disableSaturday)}
                          {this.createToggleBox(sunday, 'sunday', `${I18n.t('BEE668' /* Domingo */)}:`, disableSunday)}
                          {(disableStartTime
                            ? this.createInput(formatedStartTime, 'startTime', I18n.t('BEE672' /* Hora de início */), I18n.t('BEE672' /* Hora de início */), 'text', true, disableStartTime)
                            : this.createTimePicker(startTime, 'startTime', I18n.t('BEE672' /* Hora de início */), I18n.t('BEE672' /* Hora de início */), '99:99', 'text', true)
                          )}
                          {(disableEndTime
                            ? this.createInput(formatedEndTime, 'endTime', I18n.t('BEE673' /* Hora de Término */), I18n.t('BEE673' /* Hora de Término */), 'text', true, disableEndTime)
                            : this.createTimePicker(endTime, 'endTime', I18n.t('BEE673' /* Hora de Término */), I18n.t('BEE673' /* Hora de Término */), '99:99', 'text', true)
                          )}
                          {this.createToggleBox(cif, 'cif', `${I18n.t('BEE669' /* CIF */)}:`, disableCif)}
                          {this.createToggleBox(fob, 'fob', `${I18n.t('BEE670' /* FOB */)}:`, disableFob)}
                          {this.createToggleBox(sale, 'sale', `${I18n.t('BEE671' /* Venda */)}:`, disableSale)}
                          {this.createToggleBox(transfer, 'transfer', `${I18n.t('BEE34' /* Transferência */)}:`, disableTransfer)}
                          {this.createInput(note, 'note', `${I18n.t('BEE135' /* Observação */)}:`, I18n.t('BEE136' /* Informe a observação */), 'text', false, disableNote)}
                        </TabPane>
                        <TabPane tabId="link">
                          <CarrierScheduleTable
                            scheduleSelected={scheduleSelected}
                            canEdit={!!scheduleSelected}
                            updateCarrierSchedules={this.updateCarrierScheduleList}
                            carrierSchedules={carrierSchedules}
                            carrierOptions={carrierOptions}
                            customerOptions={customerOptions}
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </PanelBody>
                <PanelFooter>
                  <Link to={ROUTES.SCHEDULE_LIST}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createScheduleSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}

                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateScheduleSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}

                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white', zIndex: 99999 }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getSchedule: (depositId) => dispatch(getSchedule(depositId)),
  createSchedule: (deposit) => dispatch(createSchedule(deposit)),
  updateSchedule: (deposit) => dispatch(updateSchedule(deposit)),
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getCarrierSchedulesList: (query) => dispatch(getCarrierSchedulesList(query)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ScheduleForm));
