import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';

import addNotification from '../../../../components/notification';

import { getAllCurves } from '../../../../app/store/actions/curves';
import { getBranchOptions } from '../../../../app/store/actions/customerBranch';
import { getDepositsOptionsList } from '../../../../app/store/actions/deposits';
import { getMaterialsFamilyOptions } from '../../../../app/store/actions/materialsFamily';
import { getStorageAddressSizesList } from '../../../../app/store/actions/storageAdressSizes';
import { createRulesResupply, updateRule } from '../../../../app/store/actions/resupplyRules';
import { getUserPermissions } from '../../../../app/store/actions/users';
import Form from '../../../../components/form/form';

class ResupplyRulesForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      storageAddressCodeFrom: '',
      storageAddressCodeTo: '',
      sectorFrom: '',
      sectorTo: '',
      streetFrom: '',
      streetTo: '',
      levelFrom: '',
      levelTo: '',
      columnFrom: '',
      columnTo: '',
      drawerFrom: '',
      drawerTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      resupplyBy: 1,
      criteriaResupply: 1,
      maxOccupationPicking: 0,
      storageRangeCodeFrom: '',
      storageRangeCodeTo: '',
      selectedCurves: [],
      curvesList: [],
      addressSizesList: [],
      selectedAddressSizesList: [],
      branchCode: '',
      branchName: '',
      listBranch: [],
      depositName: '',
      depositCode: '',
      listDeposit: [],
      listMaterialFamily: [],
      selectedMaterialFamilyList: [],
    };
  }

  componentDidMount() {
    this.getCurves();
    this.branchOptions();
    this.depositsOptionsList();
    this.getFamily();
    this.getStorageAddressSizesList();
  }

  componentDidUpdate(prevProps) {
    if (this.props.showFilterModal && this.props.showFilterModal !== prevProps.showFilterModal) {
      this.branchOptions();
      this.depositsOptionsList();
    }
  }

  branchOptions = async () => {
    try {
      const listBranch = await await this.props.getBranchOptions();
      const userPermission = await this.props.getUserPermissions();

      if (!this.state.branchCode && userPermission && userPermission.mainBranch) {
        const mainBranch = listBranch.find((branch) => branch.value === userPermission.mainBranch);

        if (mainBranch) {
          this.setState({
            branchCode: mainBranch.value,
            branchName: mainBranch.label,
          });
        }
      }

      this.setState({
        listBranch: [...listBranch || []],
      });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2666' /* Gerar Ressuprimento */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2666' /* Gerar Ressuprimento */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getCurves = async () => {
    try {
      await this.props.getAllCurves();

      const curvesList = this.props.curvesList.map((element) => ({
        value: element.code,
        label: `${element.code} - ${element.name}`,
      }));

      this.setState({
        curvesList: [...curvesList || []],
      });
    } catch (e) {
      addNotification(
        'danger',
        I18n.t('BEE1627' /* Erro */),
        I18n.t('BEE1969' /* Erro ao buscar dados */),
        'top-right',
      );
    }
  };

  getStorageAddressSizesList = async () => {
    try {
      await this.props.getStorageAddressSizesList();

      const addressSizesList = this.props.addressSizesList.map((element) => ({
        value: element.code,
        label: `${element.code} - ${element.name}`,
      }));

      this.setState({
        addressSizesList: [...addressSizesList || []],
      });
    } catch (e) {
      addNotification(
        'danger',
        I18n.t('BEE1627' /* Erro */),
        I18n.t('BEE1969' /* Erro ao buscar dados */),
        'top-right',
      );
    }
  };

  getFamily = async () => {
    try {
      const listMaterialFamily = await this.props.getMaterialsFamilyOptions();

      this.setState({
        listMaterialFamily,
      });
    } catch (e) {
      addNotification(
        'danger',
        I18n.t('BEE1627' /* Erro */),
        I18n.t('BEE1969' /* Erro ao buscar dados */),
        'top-right',
      );
    }
  };

  depositsOptionsList = async () => {
    try {
      const listDeposit = await this.props.getDepositsOptionsList();
      const defaultDeposit = listDeposit.find((element) => element.value === 'RV1');
      await this.setState({
        listDeposit: [...listDeposit || []],
        ...(defaultDeposit && { depositCode: defaultDeposit.value }),
        ...(defaultDeposit && { depositName: defaultDeposit.label }),
      });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2666' /* Gerar Ressuprimento */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2666' /* Gerar Ressuprimento */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: (attr === 'resupplyBy' || attr === 'criteriaResupply') ? parseInt(value, 10) : value,
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  listToGenerateResupply = async () => {
    const {
      storageAddressCodeFrom, storageAddressCodeTo, sectorFrom, sectorTo, streetFrom, streetTo, levelFrom, levelTo,
      columnFrom, columnTo, drawerFrom, drawerTo, productCodeFrom, productCodeTo, criteriaResupply, resupplyBy,
      branchCode, depositCode, storageRangeCodeFrom, storageRangeCodeTo, selectedMaterialFamilyList,
      selectedCurves, selectedAddressSizesList, maxOccupationPicking,
    } = this.state;

    const listCodesMaterialFamily = [];
    if (selectedMaterialFamilyList) {
      selectedMaterialFamilyList.forEach((i) => listCodesMaterialFamily.push(i.value));
    }

    const listCodesAddressSize = [];
    if (selectedMaterialFamilyList) {
      selectedMaterialFamilyList.forEach((i) => selectedAddressSizesList.push(i.value));
    }

    const listCodesCurves = [];
    if (selectedMaterialFamilyList) {
      selectedMaterialFamilyList.forEach((i) => selectedCurves.push(i.value));
    }

    const filters = {
      storageAddressCodeFrom,
      storageAddressCodeTo,
      sectorFrom,
      sectorTo,
      storageRangeCodeFrom,
      storageRangeCodeTo,
      streetFrom,
      streetTo,
      columnFrom,
      columnTo,
      levelFrom,
      levelTo,
      drawerFrom,
      drawerTo,
      materialFamily: listCodesMaterialFamily,
      resupplyCriteria: criteriaResupply,
      maxOccupationPicking,
      branchCode,
      depositCode,
      productCodeFrom,
      productCodeTo,
      size: listCodesAddressSize,
      curve: listCodesCurves,
      resupplyBy,
    };

    this.props.getListToGenerateResupply(filters);
  };

  render() {
    const {
      storageAddressCodeFrom, storageAddressCodeTo, sectorFrom, sectorTo, streetFrom, streetTo, levelFrom, levelTo,
      columnFrom, columnTo, drawerFrom, drawerTo, productCodeFrom, productCodeTo, resupplyBy, criteriaResupply,
      curvesList, addressSizesList, branchCode, branchName, listBranch, depositName, depositCode, listDeposit,
      listMaterialFamily, storageRangeCodeFrom, storageRangeCodeTo, selectedMaterialFamilyList, maxOccupationPicking,
      selectedCurves, selectedAddressSizesList,
    } = this.state;

    const { showFilterModal = false, closeFilters = () => {} } = this.props;

    return (
      <Modal size="xl" isOpen={showFilterModal} toggle={closeFilters} className="adjustmentPadding">
        <ModalHeader toggle={this.props.closeFilters}>{I18n.t('BEE546' /* Filtros */).toUpperCase()}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col-md-6 mt-3" style={{ borderRight: 'solid 1px #d5dbe0' }}>
              <Form
                noPanel
                setValue={this.setValue}
                setValueDrop={this.setValueDrop}
                inputs={(formContext) => ([
                  formContext.createSelectDropDown(
                    { value: branchCode, label: branchName },
                    'branch',
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    listBranch,
                    false,
                    undefined,
                    ['col-md-6', 'col-md-6'],
                  ),
                  formContext.createSelectDropDown(
                    { value: depositCode, label: depositName },
                    'deposit',
                    `${I18n.t('BEE182' /* Depósito */)}:`,
                    listDeposit,
                    false,
                    undefined,
                    ['col-md-6', 'col-md-6'],
                  ),
                  formContext.createSelectDropDownMulti(
                    selectedMaterialFamilyList,
                    `${I18n.t('BEE2668' /* Família Material */)}:`,
                    'selectedMaterialFamilyList',
                    listMaterialFamily,
                    `${I18n.t('BEE2718' /* Todas estão selecionadas */)}`,
                    ['col-md-4 mt-auto mb-auto', 'col-md-6'],
                    false,
                    3,
                  ),
                  formContext.createSelectDropDownMulti(
                    selectedAddressSizesList,
                    `${I18n.t('BEE1491' /* Classificação do Endereço */)}:`,
                    'selectedAddressSizesList',
                    addressSizesList,
                    `${I18n.t('BEE2718' /* Todas estão selecionadas */)}`,
                    ['col-md-4 mt-auto mb-auto', 'col-md-6'],
                    false,
                    2,
                  ),
                  formContext.createSelectDropDownMulti(
                    selectedCurves,
                    `${I18n.t('BEE355' /* Curva */)}:`,
                    'selectedCurves',
                    curvesList,
                    `${I18n.t('BEE2478' /* Todas as curvas estão selecionadas */)}`,
                    ['col-md-4 mt-auto mb-auto', 'col-md-6'],
                    false,
                    1,
                  ),
                ])}
              />
              <hr />
              <Form
                noPanel
                setValue={this.setValue}
                setValueDrop={this.setValueDrop}
                inputs={(formContext) => ([
                  formContext.createRadioButtons(
                    [{
                      label: I18n.t('BEE2660' /* Utilizar como base a % Ponto de Ressuprimento de Cada Produto */),
                      value: 1,
                    }, {
                      label: I18n.t(
                        'BEE2661', /* Completar o Picking em sua Capacidade Máxima
                          (Desconsiderar Ponto de Ressuprimento) */
                      ),
                      value: 2,
                    },
                    {
                      label: I18n.t(
                        'BEE2729', /* % Máximo de Ocupação do Picking */
                      ),
                      value: 3,
                    },
                    ],
                    criteriaResupply,
                    'criteriaResupply',
                    `${I18n.t('BEE2669' /* Critério do Ressuprimento */)}:`,
                    ['ms-0'],
                    undefined,
                    undefined,
                  ),
                  criteriaResupply === 3 && formContext.createInput(
                    maxOccupationPicking,
                    'maxOccupationPicking',
                    `${I18n.t('BEE2730' /* Informe a % máxima de ocupação: */)}:`,
                    '',
                    'number',
                    true,
                    criteriaResupply !== 3,
                  ),
                ])}
              />
            </div>
            <div className="col-md-6">
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createRadioButtons(
                    [{
                      label: I18n.t('BEE267' /* Endereço */),
                      value: 1,
                    }, {
                      label: I18n.t('BEE225' /* Produto */),
                      value: 2,
                    },
                    ],
                    resupplyBy,
                    'resupplyBy',
                    `${I18n.t('BEE2683' /* Ressuprir por */)}:`,
                    undefined,
                    undefined,
                    'col-md-8',
                    ['col-md-2'],
                  ),
                  formContext.createInputRange(
                    { from: storageAddressCodeFrom, to: storageAddressCodeTo },
                    { from: 'storageAddressCodeFrom', to: 'storageAddressCodeTo' },
                    `${I18n.t('BEE267' /* Endereço */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                    undefined,
                    undefined,
                  ),
                  formContext.createInputRange(
                    { from: sectorFrom, to: sectorTo },
                    { from: 'sectorFrom', to: 'sectorTo' },
                    `${I18n.t('BEE279' /* Setor */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                    undefined,
                    undefined,
                  ),
                  formContext.createInputRange(
                    { from: storageRangeCodeFrom, to: storageRangeCodeTo },
                    { from: 'storageRangeCodeFrom', to: 'storageRangeCodeTo' },
                    `${I18n.t('BEE581' /* Range */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                    undefined,
                    undefined,
                  ),
                  formContext.createInputRange(
                    { from: streetFrom, to: streetTo },
                    { from: 'streetFrom', to: 'streetTo' },
                    `${I18n.t('BEE121' /* Rua */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                    undefined,
                    undefined,
                  ),
                  formContext.createInputRange(
                    { from: columnFrom, to: columnTo },
                    { from: 'columnFrom', to: 'columnTo' },
                    `${I18n.t('BEE281' /* Coluna */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                    undefined,
                    undefined,
                  ),
                  formContext.createInputRange(
                    { from: levelFrom, to: levelTo },
                    { from: 'levelFrom', to: 'levelTo' },
                    `${I18n.t('BEE283' /* Nível */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                    undefined,
                    undefined,
                  ),
                  formContext.createInputRange(
                    { from: drawerFrom, to: drawerTo },
                    { from: 'drawerFrom', to: 'drawerTo' },
                    `${I18n.t('BEE285' /* Gaveta */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                    undefined,
                    undefined,
                  ),
                  (resupplyBy === '2' || resupplyBy === 2) && (
                    formContext.createInputRange(
                      { from: productCodeFrom, to: productCodeTo },
                      { from: 'productCodeFrom', to: 'productCodeTo' },
                      `${I18n.t('BEE225' /* Produto */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                      undefined,
                      undefined,
                    )
                  ),
                ])}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-120 btn-white p-5 m-5" onClick={closeFilters}>
            {I18n.t('BEE99' /* Cancelar */)}
          </button>
          <button type="button" className="btn btn-120 btn-primary p-5 m-5" onClick={this.listToGenerateResupply}>
            {I18n.t('BEE407' /* Buscar */)}
          </button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  curvesList: state.curves && state.curves.curvesList,
  addressSizesList: state.storageAddressSizes.storageAddressSizesList,
});

const mapDispatchToProps = (dispatch) => ({
  getAllCurves: () => dispatch(getAllCurves()),
  getBranchOptions: () => dispatch(getBranchOptions()),
  getDepositsOptionsList: () => dispatch(getDepositsOptionsList()),
  getMaterialsFamilyOptions: () => dispatch(getMaterialsFamilyOptions()),
  getStorageAddressSizesList: () => dispatch(getStorageAddressSizesList()),
  getUserPermissions: () => dispatch(getUserPermissions()),
  createRulesResupply: (rules) => dispatch(createRulesResupply(rules)),
  updateRule: (rulesId) => dispatch(updateRule(rulesId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ResupplyRulesForm));
