import 'react-table/react-table.css';
import React from 'react';
import { I18n } from 'react-redux-i18n';

import OutboundOrderHelpers from '../../../helpers/outboundOrder';
import FormatHelpers from '../../../helpers/format';

export default class ProductsList extends React.Component {  
  render() {
    const { products } = this.props;
    return (
      <div className="table table-bordered">
        <table className={'table widget-table widget-table-rounded'}>
          <thead>
            <tr className="text-center f-s-14">
              <th width="5%">{I18n.t('BEE463' /* Linha */)}</th>
              <th width="25%">{I18n.t('BEE225' /* Produto */)}</th>
              <th width="10%">{I18n.t('BEE441' /* Quantidade */)}</th>
              <th width="10%">{I18n.t('BEE1112' /* Minimo */)}</th>
              <th width="10%">{I18n.t('BEE1114' /* Obrigatório */)}</th>
              <th width="10%">{I18n.t('BEE1115' /* Valor */)}</th>
              <th width="30%">{I18n.t('BEE135' /* Observação */)}</th>
            </tr>
          </thead>
          <tbody>
            {products.map(product => (
              <tr className="text-center" key={product.lineNumber}>
                <td>
                  <h5>{product.lineNumber}</h5>
                </td>
                <td className="text-left">
                  <h4 className="widget-table-title">{product.productCode}</h4>
                  <p className="widget-table-desc m-b-15">{product.product.name}</p>
                  <p className="widget-table-desc m-b-15">{product.product.fullName}</p>
                  <div className="clearfix f-s-10">
                    {`${I18n.t('BEE224' /* Status */)}:`}
                    <b className="text-inverse"> {OutboundOrderHelpers.outboundOrderProductStatus(product.status)}</b>
                  </div>
                </td>
                <td>
                  {FormatHelpers.formatNumber(product.quantity, 3, false)}
                </td>
                <td>
                  {FormatHelpers.formatNumber(product.minimumQuantity, 3, false)}
                </td>
                <td>
                  {FormatHelpers.formatNumber(product.mandatoryQuantity, 3, false)}
                </td>
                <td>
                  {`R$ ${parseFloat(product.value).toFixed(2)}`}
                </td>
                <td>
                  {product.note}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}