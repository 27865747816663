import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import {
  changeAllocationProduct, allocationListToChange, getStockBalancesAvailable,
} from '../../../app/store/actions/outboundOrderAllocations';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import FormatHelpers from '../../../helpers/format';
import ProductsHelpers from '../../../helpers/products';
import ModalChangeAllocation from './modalChangeAllocation';

class allocatedProductList extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE3296' /* Alterar Alocação */), active: true },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showChangeAllocation(
                  rows.original,
                )}
                >
                  {I18n.t('BEE3296' /* Alterar Alocação */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE2997' /* Tipo de Documento */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE434' /* Data de Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE267' /* Endereço */),
        accessor: 'addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE482' /* Alocada */),
        accessor: 'allocatedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value || 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE2040' /* Separada */),
        accessor: 'pickedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE530' /* Disponível */),
        accessor: 'balance',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'productFullName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 350,
      }, {
        Header: I18n.t('BEE1808' /* Pedido Cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE444' /* Data de Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE773' /* Controle Estoque */),
        accessor: 'stockControlType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>
            {' '}
            {ProductsHelpers.stockControlType(row.value || 0)}
          </span>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option key="all" value="">{I18n.t('BEE793' /* Todos */)}</option>
            {ProductsHelpers.stockControlType().map((element) => (
              <option key={`${element.value}`} value={element.value}>{element.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE578' /* Data de Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('L')}
          </span>
        ),
      },
    ];

    this.state = {
      productsList: [],
      defaultPageSize: 10,
      defaultSorted: [{ id: 'productCode', desc: false }],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      openChangeModal: false,
      allocationSelected: null,
      availableBalances: [],
    };
  }

  componentDidMount() {
    this.productAllocationsList();
  }

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });
    this.productAllocationsList();
  };

  productAllocationsList = async () => {
    try {
      const productsList = await this.props.allocationListToChange(
        moment(this.state.startDate).format(),
        moment(this.state.endDate).format(),
      );

      if (productsList && productsList.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2007' /* Produtos Alocados */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({
          productsList,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2007' /* Produtos Alocados */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2007' /* Produtos Alocados */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  showChangeAllocation = async (allocation) => {
    const { productAllocationId } = allocation;
    try {
      const balances = await this.props.getStockBalancesAvailable(productAllocationId);

      if (balances && balances.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2007' /* Produtos Alocados */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({
          availableBalances: balances,
          allocationSelected: allocation,
        });
        this.showModalChangeAllocation();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2007' /* Produtos Alocados */), messageError, 'top-right');
      }
    }
  };

  showModalChangeAllocation = () => {
    this.setState({
      openChangeModal: true,
    });
  };

  render() {
    const {
      defaultPageSize, defaultSorted, productsList, allocationSelected, availableBalances,
    } = this.state;

    return (
      <>
        <Table
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE3296' /* Alterar Alocação */)}
          wikiHelp={ROUTES.OUTBOUND_CHANGE_ALLOCATION_HELP}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          filterable
          expander
          downloadCSV
          data={productsList}
          columns={this.tableColumns}
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        <ModalChangeAllocation
          openChangeModal={this.state.openChangeModal}
          closeModalChangeAllocation={(update = false) => {
            this.setState({ openChangeModal: false });
            if (update) this.productAllocationsList();
          }}
          balances={availableBalances}
          allocationSelected={allocationSelected}
          changeAllocationProduct={(
            outboundProductAllocationId,
            balances,
          ) => this.props.changeAllocationProduct(outboundProductAllocationId, balances)}
        />
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  allocationListToChange: (filterStartDate, filterEndDate) => dispatch(allocationListToChange(
    filterStartDate,
    filterEndDate,
  )),
  changeAllocationProduct: (
    outboundProductAllocationId,
    balances,
  ) => dispatch(changeAllocationProduct(outboundProductAllocationId, balances)),
  getStockBalancesAvailable: (outboundProductAllocationId) => dispatch(
    getStockBalancesAvailable(outboundProductAllocationId),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(allocatedProductList));
