import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import Select from 'react-select';

import Form from '../../../components/form/form';
import addNotification from '../../../components/notification';
import { getBranchOptions } from '../../../app/store/actions/customerBranch';
import { getDepositsOptionsList } from '../../../app/store/actions/deposits';
import { getUserPermissions } from '../../../app/store/actions/users';
import { getAllCurves } from '../../../app/store/actions/curves';

import { returnFiltersByRules } from '../../reports/utils';

class FilteringModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      codeFrom: '',
      codeTo: '',
      sectorFrom: '',
      sectorTo: '',
      streetFrom: '',
      streetTo: '',
      levelFrom: '',
      levelTo: '',
      columnFrom: '',
      columnTo: '',
      drawerFrom: '',
      drawerTo: '',
      productFrom: '',
      productTo: '',
      countsFrom: 0,
      countsTo: 0,
      branch: '',
      branchName: '',
      listBranch: [],
      depositName: '',
      deposit: '',
      listDeposit: [],
      inventoryBy: 1,
      considerEmptyAddress: false,
      selectedCurves: [],
      curvesCodes: [],
    };
    this.initialState = this.state;
  }

  componentDidMount() {
    this.getCurves();
  }

  componentDidUpdate(prevProps) {
    if (this.props.showFilterModal && this.props.showFilterModal !== prevProps.showFilterModal) {
      this.branchOptions();
      this.depositsOptionsList();
    }
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });

    if (attr === 'inventoryBy' && value === '2') {
      await this.setState({
        considerEmptyAddress: false,
      });
    }
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  getCurves = async () => {
    await this.props.getAllCurves();
    const curvesCodes = [];
    this.props.curves.map((element) => (curvesCodes.push({
      value: element.code,
      label: `${element.code} - ${element.name}`,
    })));
    this.setState({
      curvesCodes,
    });
  };

  branchOptions = async () => {
    try {
      const listBranch = await this.props.getBranchOptions();
      const userPermission = await this.props.getUserPermissions();

      if (!this.state.branch && userPermission && userPermission.mainBranch) {
        const mainBranch = listBranch.find((branch) => branch.value === userPermission.mainBranch);

        if (mainBranch) {
          this.setState({
            branch: mainBranch.value,
            branchName: mainBranch.label,
          });
        }
      }

      this.setState({
        listBranch: [...listBranch || []],
      });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE1408' /* Gerar Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1408' /* Gerar Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  depositsOptionsList = async () => {
    try {
      const listDeposit = await this.props.getDepositsOptionsList();
      const defaultDeposit = listDeposit.find((element) => element.value === 'RV1');
      await this.setState({
        listDeposit: [...listDeposit || []],
        ...(defaultDeposit && { deposit: defaultDeposit.value }),
        ...(defaultDeposit && { depositName: defaultDeposit.label }),
      });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE1408' /* Gerar Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1408' /* Gerar Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  listToGenerateInventory = () => {
    const {
      codeFrom, codeTo, sectorFrom, sectorTo, streetFrom, streetTo, levelFrom, levelTo,
      columnFrom, columnTo, drawerFrom, drawerTo, productFrom, productTo, countsFrom, countsTo,
      branch, deposit, considerEmptyAddress, selectedCurves,
    } = this.state;

    const filterCurves = [];

    selectedCurves.map((element) => (
      filterCurves.push(element.value)
    ));

    const filters = returnFiltersByRules([
      ['addressCode', { type: 'between', value: [codeFrom.toLocaleUpperCase(), codeTo.toLocaleUpperCase()] }],
      ['productCode', { type: 'between', value: [productFrom, productTo] }],
      ['sector', { type: 'between', value: [sectorFrom.toLocaleUpperCase(), sectorTo.toLocaleUpperCase()] }],
      ['street', { type: 'between', value: [streetFrom.toLocaleUpperCase(), streetTo.toLocaleUpperCase()] }],
      ['column', { type: 'between', value: [columnFrom.toLocaleUpperCase(), columnTo.toLocaleUpperCase()] }],
      ['level', { type: 'between', value: [levelFrom.toLocaleUpperCase(), levelTo.toLocaleUpperCase()] }],
      ['drawer', { type: 'between', value: [drawerFrom.toLocaleUpperCase(), drawerTo.toLocaleUpperCase()] }],
      ['counts', { type: 'between', value: [parseInt(countsFrom || 0, 10), parseInt(countsTo || 999, 10)] }],
      ['curve', { type: 'in', value: filterCurves }],
      ['branchCode', { type: 'and', value: branch }],
      ['depositCode', { type: 'and', value: deposit }],
      ['considerEmptyAddress', { type: 'and', value: considerEmptyAddress }],
    ]);

    if (!deposit) {
      addNotification(
        'danger',
        I18n.t('BEE2386' /* Verifique os Filtros */),
        I18n.t('BEE1330' /* Depósito deve ser informado ! */),
        'top-right',
      );
      return;
    }

    this.props.getListToGenerateInventory(filters);
  };

  handleCurvesSelected = async (event) => {
    const selectedCurves = [];
    event.map((element) => (
      selectedCurves.push({ value: element.value, label: element.label })
    ));

    this.setState({
      selectedCurves,
    });
  };

  render() {
    const {
      codeFrom, codeTo, sectorFrom, sectorTo, streetFrom, streetTo, levelFrom, levelTo,
      columnFrom, columnTo, drawerFrom, drawerTo, productFrom, productTo, countsFrom, countsTo,
      branch, branchName, listBranch, depositName, deposit, curvesCodes,
      listDeposit, inventoryBy, considerEmptyAddress,
    } = this.state;
    const { showFilterModal = false } = this.props;

    return (
      <Modal size="xl" isOpen={showFilterModal} toggle={this.props.closeFilters} className="adjustmentPadding">
        <ModalHeader toggle={this.props.closeFilters}>{I18n.t('BEE546' /* Filtros */).toUpperCase()}</ModalHeader>
        <ModalBody>
          <div className="row">
            <div className="col" style={{ borderRight: 'solid 1px #d5dbe0' }}>
              <div className="pt-4 add_display_flex">
                <Form
                  noPanel
                  leftType
                  setValue={this.setValue}
                  setValueDrop={this.setValueDrop}
                  inputs={(formContext) => ([
                    formContext.createSelectDropDown(
                      { value: branch, label: branchName },
                      'branch',
                      `${I18n.t('BEE145' /* Filial */)}:`,
                      listBranch,
                      false,
                      undefined,
                      ['col-md-3', 'col-md-8'],
                    ),
                    formContext.createSelectDropDown(
                      { value: deposit, label: depositName },
                      'deposit',
                      `${I18n.t('BEE182' /* Depósito */)}:`,
                      listDeposit,
                      false,
                      undefined,
                      ['col-md-3', 'col-md-8'],
                      undefined,
                      undefined,
                      false,
                    ),
                    formContext.createRadioButtons(
                      [{
                        label: I18n.t('BEE267' /* Endereço */),
                        value: 1,
                      }, {
                        label: I18n.t('BEE225' /* Produto */),
                        value: 2,
                      },
                      ],
                      inventoryBy,
                      'inventoryBy',
                      `${I18n.t('BEE2084' /* Inventariar Por */)}:`,
                      undefined,
                      undefined,
                      'col-md-6',
                      ['col-md-3'],
                    ),
                    formContext.createCheckBoxes([
                      {
                        value: considerEmptyAddress,
                        attr: 'considerEmptyAddress',
                        disabled: inventoryBy === '2',
                        label: I18n.t('BEE2087' /* Considerar Endereço Vazio */),
                      },
                    ], ' ', undefined, ['col-md-3', 'col-md-8']),
                  ])}
                />
                <div className="row" style={{ justifyContent: 'space-between' }}>
                  <label htmlFor="label" style={{ marginTop: 'auto', marginBottom: 'auto', paddingLeft: '10px' }}>
                    {`${I18n.t('BEE2463' /* Curvas */)}:`}
                  </label>
                  <div className="col-8" style={{ marginLeft: 'auto', marginRight: '2.5vw' }}>
                    <Select
                      value={this.state.selectedCurves}
                      isMulti
                      onChange={(event) => this.handleCurvesSelected(event || [])}
                      closeMenuOnSelect={false}
                      blurInputOnSelect={false}
                      isSearchable
                      styles={{
                        control: (defaultStyles) => ({
                          ...defaultStyles,
                          minHeight: '38px',
                          border: '1px solid #ccc',
                          boxShadow: 'none',
                          color: '#ccc',
                          backgroundColor: '#fff',
                          borderRadius: '5px',
                          fontFamily: 'Open Sans", sans-serif',
                          fontWeigth: '400',
                        }),
                        placeholder: (defaultStyles) => ({
                          ...defaultStyles,
                          color: '#333',
                          fontSize: '12px',
                        }),
                        dropdownIndicator: (defaultStyles) => ({
                          ...defaultStyles,
                          paddingTop: 0,
                          paddingBottom: 0,
                          color: '#ccc',
                        }),
                        clearIndicator: (defaultStyles) => ({
                          ...defaultStyles,
                          paddingTop: 0,
                          paddingBottom: 0,
                        }),
                      }}
                      maxMenuHeight={300}
                      placeholder={I18n.t('BEE2478' /* Todas as curvas estão selecionadas */)}
                      options={curvesCodes}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col" style={{ borderLeft: 'solid 1px #d5dbe0' }}>
              <div className="pt-4">
                <Form
                  noPanel
                  leftType
                  setValue={this.setValue}
                  inputs={(formContext) => ([
                    formContext.createInputRange(
                      { from: codeFrom.toLocaleUpperCase(), to: codeTo.toLocaleUpperCase() },
                      { from: 'codeFrom', to: 'codeTo' },
                      `${I18n.t('BEE267' /* Endereço */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ),
                    formContext.createInputRange(
                      { from: sectorFrom.toLocaleUpperCase(), to: sectorTo.toLocaleUpperCase() },
                      { from: 'sectorFrom', to: 'sectorTo' },
                      `${I18n.t('BEE279' /* Setor */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ),
                    formContext.createInputRange(
                      { from: streetFrom.toLocaleUpperCase(), to: streetTo.toLocaleUpperCase() },
                      { from: 'streetFrom', to: 'streetTo' },
                      `${I18n.t('BEE121' /* Rua */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ),
                    formContext.createInputRange(
                      { from: columnFrom.toLocaleUpperCase(), to: columnTo.toLocaleUpperCase() },
                      { from: 'columnFrom', to: 'columnTo' },
                      `${I18n.t('BEE281' /* Coluna */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ),
                    formContext.createInputRange(
                      { from: levelFrom.toLocaleUpperCase(), to: levelTo.toLocaleUpperCase() },
                      { from: 'levelFrom', to: 'levelTo' },
                      `${I18n.t('BEE283' /* Nível */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ),
                    formContext.createInputRange(
                      { from: drawerFrom.toLocaleUpperCase(), to: drawerTo.toLocaleUpperCase() },
                      { from: 'drawerFrom', to: 'drawerTo' },
                      `${I18n.t('BEE285' /* Gaveta */)}:`,
                      { from: '', to: 'Z'.repeat(20) },
                    ),
                    formContext.createInputRange(
                      { from: countsFrom, to: countsTo },
                      { from: 'countsFrom', to: 'countsTo' },
                      'Contagens:',
                      { from: '0', to: '9'.repeat(3) },
                    ),
                    inventoryBy === '2' && (
                      formContext.createInputRange(
                        { from: productFrom, to: productTo },
                        { from: 'productFrom', to: 'productTo' },
                        `${I18n.t('BEE225' /* Produto */)}:`,
                        { from: '', to: 'Z'.repeat(20) },
                      )
                    ),

                  ])}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-120 btn-white p-5 m-5" onClick={this.props.closeFilters}>
            {I18n.t('BEE99' /* Cancelar */)}
          </button>
          <button type="button" className="btn btn-120 btn-primary p-5 m-5" onClick={this.listToGenerateInventory}>
            {I18n.t('BEE407' /* Buscar */)}
          </button>
        </ModalFooter>
      </Modal>

    );
  }
}

const mapStateToProps = (state) => ({
  curves: state.curves && state.curves.curvesList,
});

const mapDispatchToProps = (dispatch) => ({
  getBranchOptions: () => dispatch(getBranchOptions()),
  getDepositsOptionsList: () => dispatch(getDepositsOptionsList()),
  getUserPermissions: () => dispatch(getUserPermissions()),
  getAllCurves: () => dispatch(getAllCurves()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(FilteringModal));
