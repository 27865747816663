import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';
import FormatHelpers from '../../../../helpers/format';
import { getAllCurves } from '../../../../app/store/actions/curves';

import { getProductBranchesList, deleteProductBranch } from '../../../../app/store/actions/productBranches';

class ProductBranchList extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      showModalDelete: false,
      productBranchUpdate: null,
      pages: 0,
      curves: [],
      totalDataLength: 0,
    };

    this.defaultSorted = [
      {
        id: 'productCode',
        desc: false,
      },
    ];

    this.tableColumns = [];
  }

  async componentDidMount() {
    this.maintainsState();
    await this.getCurves();
    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showProductBranchDetail(rows.row)}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showEditProductBranch(rows.row)}>
                  {I18n.t('BEE57' /* Editar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showCopyProductBranch(rows.row)}>
                  {I18n.t('BEE80' /* Copiar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showDeleteProductBranch(rows.row)}>
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 400,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE410' /* Custo Médio */),
        accessor: 'averageCost',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => FormatHelpers.formatNumber(row.value, 3, false),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE423' /* Endereço Padrão */),
        accessor: 'defaultAddress',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE355' /* Curva */),
        accessor: 'curve',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumnSelect,
      }, {
        Header: I18n.t('BEE713' /* Lote Múltiplo Venda */),
        accessor: 'product.multipleSale',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => FormatHelpers.formatNumber(row.value, 3, false),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE412' /* Lead Time */),
        accessor: 'leadTime',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      },
    ];
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({ page: this.props.location.state.dice.page });
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      productBranchUpdate: null,
    });
  };

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getCurves = async () => {
    await this.props.getAllCurves();
    const curves = [];
    this.props.curves.map((element) => (curves.push({
      value: element.code,
      label: element.code,
    })));
    this.setState({
      curves: [...curves],
    });
  };

  filterColumnSelect = ({ onChange }) => {
    const { curves } = this.state;
    return (
      <select
        type="text"
        style={{ width: '100%' }}
        onChange={(event) => onChange(event.target.value)}
      >
        <option value="">{I18n.t('BEE793' /* Todos */)}</option>
        {curves.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    );
  };

  getProductBranches = async () => {
    const data = await this.props.getProductBranchesList({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
    });
    this.setState({ pages: data.pages, firstRun: true, totalDataLength: data.totalDataLength });
  };

  deleteProductBranch = async (productBranch) => {
    const result = await this.props.deleteProductBranch(productBranch._original.id);

    this.setInitState();

    if (result) {
      addNotification(
        'danger',
        I18n.t('BEE864' /* Eliminar produto por filial */),
        I18n.t('BEE865' /* Produto por filial eliminado com sucesso ! */),
        'top-right',
      );
    }
    await this.getProductBranches();
  };

  showProductBranchDetail = (productBranch) => {
    this.props.history.push({
      pathname: ROUTES.PRODUCT_BRANCH_DETAIL,
      state: {
        productBranch: productBranch._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditProductBranch = (productBranch) => {
    this.props.history.push({
      pathname: ROUTES.PRODUCT_BRANCH_EDIT,
      state: {
        productBranch: productBranch._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyProductBranch = (productBranch) => {
    this.props.history.push({
      pathname: ROUTES.PRODUCT_BRANCH_COPY,
      state: {
        productBranch: productBranch._original,
      },
    });
  };

  showDeleteProductBranch = (productBranch) => {
    this.setState({ showModalDelete: true, productBranchUpdate: productBranch });
  };

  render() {
    const { productBranchesList = [] } = this.props;
    const {
      productBranchUpdate, showModalDelete, pages, page, totalDataLength,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE408' /* Produtos por Filial */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          wikiHelp={ROUTES.PRODUCT_BRANCH_HELP}
          headerTitle={I18n.t('BEE408' /* Produtos por Filial */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.PRODUCT_BRANCH_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE416' /* Incluir Produto por Filial */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getProductBranches,
          }}
          filterable
          data={productBranchesList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual
          pages={pages}
          page={(page)
            || ((!this.state.firstRun
              && this.props.location.state
              && this.props.location.state.dice
              && this.props.location.state.dice.page)
              ? this.props.location.state.dice.page : page)}
          onPageChange={(p) => ((p >= 0) ? this.setState({ page: p }) : this.setState({ page: 0 }))}
          onFetchData={(state) => {
            this.page = (state.page) ? state.page
              : ((!this.state.firstRun
              && this.props.location.state
              && this.props.location.state.dice
              && this.props.location.state.dice.page)
              || state.page);
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.getProductBranches();
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteProductBranch(productBranchUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t(
              'BEE866',
              { 0: productBranchUpdate.productCode }, /*  O produto por cliente %{0} será eliminado ! */
            )}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productBranchesList: state.productBranches && state.productBranches.productBranchesList,
  curves: state.curves?.curvesList,
});

const mapDispatchToProps = (dispatch) => ({
  getProductBranchesList: (tableParams) => dispatch(getProductBranchesList(tableParams)),
  deleteProductBranch: (productBranchId) => dispatch(deleteProductBranch(productBranchId)),
  getAllCurves: () => dispatch(getAllCurves()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductBranchList));
