import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { PanelPage } from '../../../../components/pages/pages';
import addNotification from '../../../../components/notification';
import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';
import ROUTES from '../../../../config/routes';
import {
  createStorageAddress,
  generateStorageAddresses,
  updateStorageAddress,
  getStorageAddress,
  saveListGeneratedAddresses,
} from '../../../../app/store/actions/storageAddresses';
import { getStorageAddressTypesOptions } from '../../../../app/store/actions/storageAdressTypes';
import { getBranchesOptions } from '../../../../app/store/actions/branches';
import StorageAddressTable from './storageAddressTable';
import { getStorageAddressSizesList } from '../../../../app/store/actions/storageAdressSizes';
import { getRangesOptions } from '../../../../app/store/actions/ranges';
import { getAllCurves } from '../../../../app/store/actions/curves';

class StorageAddressForm extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE14' /* Gerais */) },
      { value: I18n.t('BEE20' /* Endereços */) },
      { value: I18n.t('BEE273' /* Novo Endereço */), active: true },
    ];

    this.state = {
      code: '',
      branch: null,
      branchName: '',
      range: null,
      rangeName: '',
      type: '',
      typeName: '',
      sector: '',
      street: '',
      level: '',
      column: '',
      drawer: '',
      sectorTo: '',
      streetTo: '',
      levelTo: '',
      columnTo: '',
      drawerTo: '',
      size: 0,
      sizeName: '',
      height: 0,
      width: 0,
      length: 0,
      capacityVolume: 0,
      capacityWeight: 0,
      shareAddress: false,
      shareAddressOfProductsWithDifferentLots: false,
      note: '',
      generateAddressList: [],
      generateModal: false,
      codeSector: '',
      codeStreet: '',
      codeLevel: '',
      codeColumn: '',
      codeDrawer: '',
      addressSizesOptions: [],
      curves: [],
      curve: '',
    };
  }

  async componentDidMount() {
    const listBranch = await this.props.getBranchesOptions();
    await this.props.getStorageAddressSizesList();
    const listType = await this.props.getStorageAddressTypesOptions();
    await this.listOptions();
    this.setState({
      listBranch,
      listType,
    });

    this.getCurves();
  }

  listOptions = async () => {
    if (this.props.addressSizesList) {
      this.props.addressSizesList.forEach((element) => {
        this.state.addressSizesOptions.push({ value: element.code, label: `${element.code} - ${element.name}` });
      });
    }
  };

  generateAddressesSubmit = async () => {
    const {
      code, branch, type, sector, street, level, column, drawer,
      sectorTo, streetTo, levelTo, columnTo, drawerTo,
      size, height, width, length, capacityVolume, capacityWeight,
      note, generateModal, shareAddress, shareAddressOfProductsWithDifferentLots,
      codeSector, codeStreet, codeLevel, codeColumn, addressSizesOptions,
      codeDrawer, range, curves, curve,
    } = this.state;

    const selectedCurve = curve || curves[0].value;

    try {
      const addressesList = await this.props.generateStorageAddresses({
        branchCode: branch,
        type,
        sectorCode: codeSector,
        sectorFrom: sector,
        sectorTo,
        streetCode: codeStreet,
        streetFrom: street,
        streetTo,
        columnCode: codeColumn,
        columnFrom: column,
        columnTo,
        levelCode: codeLevel,
        levelFrom: level,
        levelTo,
        drawerCode: codeDrawer,
        drawerFrom: drawer,
        drawerTo,
        blocked: false,
        size,
        height,
        length,
        width,
        capacityVolume,
        capacityWeight,
        shareAddress,
        shareAddressOfProductsWithDifferentLots,
        note,
        addressSizesOptions,
        curve: selectedCurve,
        rangeCode: range,
      });
      if (addressesList.length) {
        this.setState({
          generateAddressList: addressesList,
          generateModal: !generateModal,
        });
      } else {
        addNotification(
          'danger',
          I18n.t('BEE20' /* Endereços */),
          I18n.t('BEE604' /* Nenhum endereço localizado com os filtros informados! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE267' /* Endereço */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE267' /* Endereço */),
            I18n.t(
              'BEE269',
              {
                0: code,
              }, /* Erro ao incluir o Endereço %{code}! */
            ),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE267' /* Endereço */),
          I18n.t(
            'BEE269',
            {
              0: code,
            }, /* Erro ao incluir o Endereço %{code}! */
          ),
          'top-right',
        );
      }
    }
  };

  selectAddressTable = async (address, storageAddresses) => {
    try {
      const newAddress = await this.props.createStorageAddress(address);
      if (newAddress) {
        storageAddresses.forEach((item, key) => {
          if (item.code === address.code) {
            storageAddresses.splice(key, 1);
          }
        });
        await addNotification(
          'success',
          I18n.t('BEE267' /* Endereço */),
          I18n.t(
            'BEE268',
            { 0: address.code }, /* Endereço %{0} incluído com sucesso! */
          ),
          'top-right',
        );
        return await storageAddresses;
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE267' /* Endereço */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
          return storageAddresses;
        } await addNotification(
          'danger',
          I18n.t(
            'BEE267', /* Endereço */
          ),
          I18n.t(
            'BEE269',
            {
              0: address.code,
            }, /* Erro ao incluir o Endereço %{code}! */
          ),
          'top-right',
        );
        return storageAddresses;
      } await addNotification(
        'danger',
        I18n.t('BEE267' /* Endereço */),
        I18n.t(
          'BEE269',
          {
            0: address.code,
          }, /* Erro ao incluir o Endereço %{code}! */
        ),
        'top-right',
      );
      return storageAddresses;
    }
  };

  saveListAddresses = async () => {
    const { generateAddressList } = this.state;
    try {
      const totalStoredAddresses = await this.props.saveListGeneratedAddresses(generateAddressList);
      if (totalStoredAddresses) {
        await addNotification(
          'success',
          I18n.t('BEE20' /* Endereços */),
          I18n.t(
            'BEE657',
            {
              0: totalStoredAddresses,
            },
          /* %{0} endereço(s) incluído(s) com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.ADDRESS_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE20' /* Endereços */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE20' /* Endereços */),
            I18n.t(
              'BEE269',
              {
                0: generateAddressList.length,
              },
            /* Erro ao incluir o Endereço %{code}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE20' /* Endereços */),
          I18n.t(
            'BEE269',
            {
              0: generateAddressList.length,
            }, /* Erro ao incluir o Endereço %{code}! */
          ),
          'top-right',
        );
      }
    }
  };

  getCurves = async () => {
    await this.props.getAllCurves();
    const curves = [];
    this.props.curves.map((element) => (curves.push({
      value: element.code,
      label: element.code,
    })));
    this.setState({
      curves: [...curves],
    });
  };

  validColumnModalTable = (columns) => {
    const arColumns = columns.filter((column) => column.isVisible);
    return arColumns;
  };

  validString = (str) => {
    const er = /[A-Za-z]/;
    return (er.test(str));
  };

  setValue = async (attr, value) => {
    if (
      attr === 'sector'
      || attr === 'sector'
      || attr === 'street'
      || attr === 'level'
      || attr === 'column'
      || attr === 'drawer'
      || attr === 'sectorTo'
      || attr === 'streetTo'
      || attr === 'levelTo'
      || attr === 'columnTo'
      || attr === 'drawerTo'
    ) {
      if (parseInt(value) >= 0 || value === '') {
        await this.setState({
          [`${attr}`]: value,
        });
      }
    } else if (
      attr === 'codeSector'
      || attr === 'codeStreet'
      || attr === 'codeLevel'
      || attr === 'codeColumn'
      || attr === 'codeDrawer'
    ) {
      if (this.validString(value)) {
        if (value.slice().length <= 1) {
          await this.setState({
            [`${attr}`]: value.toUpperCase(),
          });
        } else {
          await this.setState({
            [`${attr}`]: value.slice(0, 1),
          });
        }
      } else if (value === '') {
        await this.setState({
          [`${attr}`]: value,
        });
      }
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
    if (attr === 'size' && value) {
      this.props.addressSizesList.forEach((element) => {
        if (element.code === value.value) {
          this.setState({
            size: element.id,
            height: element.height,
            width: element.width,
            length: element.length,
            capacityVolume: element.capacityVolume,
            capacityWeight: element.capacityWeight,
            curve: element.curve,
          });
        }
      });
    } else if (attr === 'size') {
      this.setState({
        size: 0,
        height: '',
        width: '',
        length: '',
        capacityVolume: '',
        capacityWeight: '',
        curve: '',
      });
    } else if (attr === 'branch') {
      const { branch } = this.state;
      const listRange = await this.props.getRangesOptions(branch);
      await this.setState({
        listRange,
        range: null,
        rangeName: '',
      });
    } else if (attr === 'shareAddress') {
      const { branch } = this.state;
      const listRange = await this.props.getRangesOptions(branch);
      await this.setState({
        listRange,
        shareAddress: true,
      });
    } else if (attr === 'shareAddressOfProductsWithDifferentLots') {
      const { branch } = this.state;
      const listRange = await this.props.getRangesOptions(branch);
      await this.setState({
        listRange,
        shareAddressOfProductsWithDifferentLots: true,
      });
    }
  };

  closeAddressModal = () => {
    this.setState({
      generateAddressList: [],
      generateModal: false,
    });
  };

  exportAddressList = (columns, rows) => {
    this.props.exportAddressList(
      columns,
      rows,
      `${I18n.t('BEE691' /* Exportar */)} ${I18n.t('BEE683' /* Lista de Endereços */)}`,
      'xlsx',
    );
  };

  render() {
    const {
      branch, branchName, type, range, rangeName, typeName,
      sector, street, level, column, drawer,
      sectorTo, streetTo, levelTo, columnTo, drawerTo,
      height, length, width, size, sizeName, note,
      capacityVolume, capacityWeight, listType,
      listBranch, curve, generateModal, generateAddressList,
      codeSector, codeStreet, codeLevel, codeColumn,
      codeDrawer, addressSizesOptions, listRange, shareAddress,
      shareAddressOfProductsWithDifferentLots, curves,
    } = this.state;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={I18n.t('BEE597' /* Gerar Endereços */)}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                setValueDrop={this.setValueDrop}
                inputs={(formContext) => ([
                  formContext.createSelectDropDown(
                    { value: branch, label: branchName },
                    'branch',
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    listBranch,
                    false,
                  ),
                  formContext.createSelectDropDown(
                    { value: type, label: typeName },
                    'type',
                    `${I18n.t('BEE200' /* Tipo */)}:`,
                    listType,
                    false,
                  ),
                  formContext.createSelectDropDown(
                    { value: range, label: rangeName },
                    'range',
                    `${I18n.t('BEE581' /* Range */)}:`,
                    listRange,
                    false,
                  ),
                  formContext.createInputRange(
                    { from: sector, to: sectorTo },
                    { from: 'sector', to: 'sectorTo' },
                    `${I18n.t('BEE279' /* Setor */)}:`,
                    { from: '01', to: '9'.repeat(15) },
                    { from: 'text', to: 'text' },
                    '',
                    '',
                    '',
                    {
                      checks: true,
                      value: codeSector,
                      attr: 'codeSector',
                      placeholder: I18n.t('BEE1462' /* S */),
                      type: 'text',
                    },
                  ),
                  formContext.createInputRange(
                    { from: street, to: streetTo },
                    { from: 'street', to: 'streetTo' },
                    `${I18n.t('BEE121' /* Rua */)}:`,
                    { from: '01', to: '9'.repeat(15) },
                    { from: 'text', to: 'text' },
                    '',
                    '',
                    '',
                    {
                      checks: true,
                      value: codeStreet,
                      attr: 'codeStreet',
                      placeholder: I18n.t('BEE1463' /* R */),
                      type: 'text',
                    },
                  ),
                  formContext.createInputRange(
                    { from: column, to: columnTo },
                    { from: 'column', to: 'columnTo' },
                    `${I18n.t('BEE281' /* Coluna */)}:`,
                    { from: '01', to: '9'.repeat(15) },
                    { from: 'text', to: 'text' },
                    '',
                    '',
                    '',
                    {
                      checks: true,
                      value: codeColumn,
                      attr: 'codeColumn',
                      placeholder: I18n.t('BEE1464' /* C */),
                      type: 'text',
                    },
                  ),
                  formContext.createInputRange(
                    { from: level, to: levelTo },
                    { from: 'level', to: 'levelTo' },
                    `${I18n.t('BEE283' /* Nível */)}:`,
                    { from: '01', to: '9'.repeat(15) },
                    { from: 'text', to: 'text' },
                    '',
                    '',
                    '',
                    {
                      checks: true,
                      value: codeLevel,
                      attr: 'codeLevel',
                      placeholder: I18n.t('BEE1465' /* N */),
                      type: 'text',
                    },
                  ),
                  formContext.createInputRange(
                    { from: drawer, to: drawerTo },
                    { from: 'drawer', to: 'drawerTo' },
                    `${I18n.t('BEE285' /* Gaveta */)}:`,
                    { from: '01', to: '9'.repeat(15) },
                    { from: 'text', to: 'text' },
                    '',
                    '',
                    '',
                    {
                      checks: true,
                      value: codeDrawer,
                      attr: 'codeDrawer',
                      placeholder: I18n.t('BEE1088' /* G */),
                      type: 'text',
                    },
                  ),
                  formContext.createCheckBoxes([
                    {
                      value: shareAddress,
                      attr: 'shareAddress',
                    },
                  ], `${I18n.t('BEE697' /* Compartilha Endereço */)}:`),
                  formContext.createCheckBoxes([
                    {
                      value: shareAddressOfProductsWithDifferentLots,
                      attr: 'shareAddressOfProductsWithDifferentLots',
                    },
                  ], `${I18n.t('BEE2371' /* Compartilha Lote */)}:`),
                  formContext.createSelectDropDown(
                    { value: size, label: sizeName },
                    'size',
                    `${I18n.t('BEE385' /* Classificação */)}:`,
                    addressSizesOptions,
                    false,
                  ),
                  formContext.createInput(
                    height,
                    'height',
                    `${I18n.t('BEE386' /* Altura */)}:`,
                    I18n.t('BEE395' /* Informe a largura */),
                    'number',
                  ),
                  formContext.createInput(
                    length,
                    'length',
                    `${I18n.t('BEE388' /* Comprimento */)}:`,
                    I18n.t('BEE396' /* Informe a largura */),
                    'number',
                  ),
                  formContext.createInput(
                    width,
                    'width',
                    `${I18n.t('BEE387' /* Largura */)}:`,
                    I18n.t('BEE397' /* Informe a largura */),
                    'number',
                  ),
                  formContext.createSelect(
                    curve,
                    'curve',
                    `${I18n.t('BEE355' /* Curva */)}:`,
                    curves,
                  ),
                  formContext.createInput(
                    capacityVolume,
                    'capacityVolume',
                    `${I18n.t('BEE727' /* Capacidade (Volume) */)}:`,
                    I18n.t('BEE398' /* Informe a capacidade */),
                    'text',
                  ),
                  formContext.createInput(
                    capacityWeight,
                    'capacityWeight',
                    `${I18n.t('BEE726' /* Capacidade (Peso) */)}:`,
                    I18n.t('BEE398' /* Informe a capacidade */),
                    'text',
                  ),
                  formContext.createInput(
                    note,
                    'note',
                    `${I18n.t('BEE135' /* Observação */)}:`,
                    I18n.t('BEE136' /* Informe a observação */),
                    'text',
                  ),
                ])}
              />
            </div>
            <Modal size="lg" isOpen={generateModal} toggle={() => this.closeAddressModal()}>
              <ModalHeader
                toggle={() => this.closeAddressModal()}
              >
                {I18n.t('BEE683' /* Lista de Endereços */)}
              </ModalHeader>
              <ModalBody>
                <div className="d-flex align-items-center mb-1 mt-1">
                  <StorageAddressTable
                    storageAddresses={generateAddressList}
                    closeAddressModal={this.closeAddressModal}
                    selectAddressTable={this.selectAddressTable}
                    validColumnModalTable={this.validColumnModalTable}
                    exportAddressList={this.exportAddressList}
                    lines={5}
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="row">
                  <div className="col text-right">
                    <OneClickButton
                      className="btn btn-white btn-120 p-5 m-5"
                      onClick={() => this.closeAddressModal()}
                    >
                      {I18n.t('BEE99' /* Cancelar */)}
                    </OneClickButton>
                    <OneClickButton
                      className="btn btn-danger btn-120 p-5 m-5"
                      onClick={() => this.saveListAddresses()}
                    >
                      {I18n.t('BEE493' /* Salvar */)}
                    </OneClickButton>
                  </div>
                </div>
              </ModalFooter>
            </Modal>
          </div>
        )}
        footerContent={(
          <>
            <Link to={ROUTES.ADDRESS_LIST}>
              <OneClickButton
                type="submit"
                className="btn btn-120 btn-white p-5 m-5"
              >
                {I18n.t('BEE99' /* Cancelar */)}
              </OneClickButton>
            </Link>

            <OneClickButton
              type="button"
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={() => this.generateAddressesSubmit()}
            >
              {I18n.t('BEE602' /* Gerar */)}
            </OneClickButton>
          </>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  addressSizesList: state.storageAddressSizes.storageAddressSizesList,
  curves: state.curves?.curvesList,
});

const mapDispatchToProps = (dispatch) => ({
  getStorageAddress: (storageAddressId) => dispatch(getStorageAddress(storageAddressId)),
  createStorageAddress: (address) => dispatch(createStorageAddress(address)),
  generateStorageAddresses: (addresses) => dispatch(generateStorageAddresses(addresses)),
  saveListGeneratedAddresses: (addressesList) => dispatch(saveListGeneratedAddresses(addressesList)),
  updateStorageAddress: (address) => dispatch(updateStorageAddress(address)),
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getStorageAddressSizesList: () => dispatch(getStorageAddressSizesList()),
  getStorageAddressTypesOptions: () => dispatch(getStorageAddressTypesOptions()),
  getRangesOptions: (branchCode) => dispatch(getRangesOptions(branchCode)),
  getAllCurves: () => dispatch(getAllCurves()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorageAddressForm));
