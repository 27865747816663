import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import CarrierHelpers from '../../../../helpers/carriers';

import ROUTES from '../../../../config/routes';

import { getCarriersList, deleteCarrier } from '../../../../app/store/actions/carriers';

class CarrierList extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      showModalDelete: false,
      carrierUpdate: null,
      pages: 0,
      totalDataLength: 0,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];
    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showCarrierDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditCarrier(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyCarrier(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteCarrier(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showLinkDocks(rows.row)}>{I18n.t('BEE1081' /* Vincular Docas */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE352' /* Nome Fantasia */),
        accessor: 'businessName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE291' /* Via de Transporte */),
        accessor: 'mode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          const mode = CarrierHelpers.modeTransport(id);

          if (_.includes(_.lowerCase(mode), input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>{CarrierHelpers.modeTransport(row.value)}</span>
        ),
        Filter: this.filterColumnSelect,
      }, {
        Header: I18n.t('BEE85' /* CNPJ */),
        accessor: 'cnpj',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE86' /* E-mail */),
        accessor: 'email',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE53' /* Cidade */),
        accessor: 'city',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE87' /* Estado */),
        accessor: 'state',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
    }
    // else {
    //   await this.getCarriers();
    // }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      carrierUpdate: null,
    });
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  filterColumnSelect = ({ filter, onChange }) => (
    <select
      type="text"
      style={{ width: '100%' }}
      onChange={(event) => {
        onChange(event.target.value);
      }}
    >
      <option value={0}>{I18n.t('BEE793' /* Todos */)}</option>
      <option value={1}>{I18n.t('BEE292' /* Rodoviário */)}</option>
      <option value={2}>{I18n.t('BEE293' /* Aeroviário */)}</option>
      <option value={3}>{I18n.t('BEE295' /* Ferroviário */)}</option>
      <option value={4}>{I18n.t('BEE294' /* Marítimo */)}</option>
      <option value={5}>{I18n.t('BEE296' /* Outros */)}</option>
    </select>
  );

  getCarriers = async () => {
    const data = await this.props.getCarriersList({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
    });
    this.setState({ pages: data.pages, totalDataLength: data.totalDataLength });
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteCarrier = async (carrier) => {
    const result = await this.props.deleteCarrier(carrier._original.id);

    this.setInitState();
    if (result) {
      addNotification('danger', I18n.t('BEE297' /* Eliminar Transportadora */), I18n.t('BEE298', { 0: carrier.code } /* Transportadora %{0} eliminada com sucesso! */), 'top-right');
    }
    await this.getCarriers();
  };

  showCarrierDetail = (carrier) => {
    this.props.history.push({
      pathname: ROUTES.CARRIER_DETAIL,
      state: {
        carrier: carrier._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditCarrier = (carrier) => {
    this.props.history.push({
      pathname: ROUTES.CARRIER_EDIT,
      state: {
        carrier: carrier._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyCarrier = (carrier) => {
    this.props.history.push({
      pathname: ROUTES.CARRIER_COPY,
      state: {
        carrier: carrier._original,
      },
    });
  };

  showDeleteCarrier = (carrier) => {
    this.setState({ showModalDelete: true, carrierUpdate: carrier });
  };

  showLinkDocks = (carrier) => {
    this.props.history.push({
      pathname: ROUTES.CARRIER_LINK_DOCKS,
      state: {
        carrier: carrier._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  render() {
    const {
      carrierUpdate, showModalDelete, pages, page, totalDataLength,
    } = this.state;
    const { carriersList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE24' /* Transportadoras */)}</li>
          </ol>
        </div>
        <Table
          wikiHelp={ROUTES.CARRIER_HELP}
          downloadCSV
          headerTitle={I18n.t('BEE24' /* Transportadoras */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.CARRIER_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE299' /* Incluir Transportadora */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getCarriers,
          }}
          filterable
          data={carriersList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual
          pages={pages}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          onFetchData={(state, instance) => {
            this.page = (state.page) ? state.page : (!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.getCarriers();
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteCarrier(carrierUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE300', { 0: carrierUpdate.code } /* A transportadora %{0} será eliminada! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  carriersList: state.carriers && state.carriers.carriersList,
});

const mapDispatchToProps = (dispatch) => ({
  getCarriersList: (tableParams) => dispatch(getCarriersList(tableParams)),
  deleteCarrier: (carrierId) => dispatch(deleteCarrier(carrierId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CarrierList));
