import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getUserFunctionsList, deleteUserFunction } from '../../../../app/store/actions/userFunctions';

class UserFunctionList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      userFunctionUpdate: null
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false
      }
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 100,
        Cell: rows => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs"></DropdownToggle>
              <DropdownMenu>
              <DropdownItem onClick={() => this.showUserFunctionDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditUserFunction(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyUserFunction(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteUserFunction(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        )
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        id: 'name',
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200
      },{
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200
      },
    ];
  }
  
  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true
      });
      await this.getUserFunctions();
    } else {
      await this.getUserFunctions();
    }
  }

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      userFunctionUpdate: null,
    });
  }

  getUserFunctions = async () => {
    await this.props.getUserFunctionsList();
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  deleteUserFunction = async (userFunction) => {
    const result = await this.props.deleteUserFunction(userFunction._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'danger', I18n.t('BEE928' /* Eliminar Função do Usuário */), I18n.t('BEE929', { 0: userFunction.code } /* Função %{0} eliminada com sucesso! */), 'top-right'
      );
    }
    await this.getUserFunctions();
  }

  showUserFunctionDetail = (userFunction) => {
    this.props.history.push({
      pathname: ROUTES.USER_FUNCTION_DETAIL,
      state: {
        userFunction: userFunction._original,
      },
      dice: {
        page: this.state.page,
      }
    });
  }

  showEditUserFunction = (userFunction) => {
    this.props.history.push({
      pathname: ROUTES.USER_FUNCTION_EDIT,
      state: {
        userFunction: userFunction._original,
      },
      dice: {
        page: this.state.page,
      }
    });
  }

  showCopyUserFunction = (userFunction) => {
    this.props.history.push({
      pathname: ROUTES.USER_FUNCTION_COPY,
      state: {
        userFunction: userFunction._original,
      }
    });
  }

  showDeleteUserFunction = (userFunction) => {
    this.setState({ showModalDelete: true, userFunctionUpdate: userFunction });
  }

  
  render() {
    const { userFunctionUpdate, showModalDelete, page,} = this.state;
    const { userFunctionsList = []  } = this.props;

    return ( 
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE25' /* Usuários */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE881' /* Funções do Usuário */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE881' /* Funções do Usuário */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.USER_FUNCTION_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1"></i> {I18n.t('BEE919' /* Incluir Função do Usuário */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getUserFunctions
          }}
          filterable
          data={userFunctionsList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) ? page : (!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page}
          onPageChange={(page) =>  ((page >= 0) ? this.setState({page}) : this.setState({page: 0}))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete &&
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteUserFunction(userFunctionUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE930', { 0: userFunctionUpdate.code } /* A função %{0} será eliminada! */)}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  userFunctionsList: state.userFunctions && state.userFunctions.userFunctionsList,
});

const mapDispatchToProps = dispatch => ({
  getUserFunctionsList: () => dispatch(getUserFunctionsList()),
  deleteUserFunction: (userFunctionId) => dispatch(deleteUserFunction(userFunctionId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserFunctionList));