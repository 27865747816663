import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { printKardexCableCutLabel } from '../../../../app/store/actions/labels';
import { getUserPrinterOptions } from '../../../../app/store/actions/printers';
import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import { PanelPage } from '../../../../components/pages/pages';
import ROUTES from '../../../../config/routes';

class LabelKardexCableCutForm extends React.PureComponent {
  constructor() {
    super();
    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE1371' /* Etiquetas */) },
      { value: I18n.t('BEE1407' /* Etiqueta Kardex - Corte de Cabos */), active: true },
    ];

    this.state = {
      productCode: '',
      addressCode: '',
      lotCode: '',
      printerOptions: [],
      printerSelected: null,
      showPrinterDialog: false,
    };
  }

  async componentDidMount() {
    this.getUserPrinterOptions();
  }

  getUserPrinterOptions = async () => {
    const printerInformations = await this.props.getUserPrinterOptions();
    const { userPrinter, printerOptions } = printerInformations;
    const { printerSelected } = this.state;

    this.setState({
      printerOptions,
      printerSelected: (printerSelected === null)
        ? userPrinter
        : (printerSelected !== userPrinter)
          ? printerSelected
          : userPrinter,
    });
  };

  setValue = async (attr, value) => {
    if (attr === 'amount') {
      if (parseInt(value, 10) >= 1) {
        this.setState({
          [`${attr}`]: value,
        });
      }
    } else {
      this.setState({
        [`${attr}`]: value,
      });
    }
  };

  hidePrinterDialog = () => {
    this.setState({
      showPrinterDialog: false,
      printerSelected: null,
    });
  };

  checkPrinters = () => {
    const { printerOptions, printerSelected } = this.state;

    if (printerOptions.length === 0 && printerSelected === null) {
      addNotification(
        'danger',
        I18n.t('BEE1407' /* Etiqueta Kardex - Corte de Cabos */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrinterDialog: true,
      });
    } else {
      this.printOut();
    }
  };

  printOut = async () => {
    this.setState({
      showPrinterDialog: false,
    });

    try {
      const {
        productCode, addressCode, lotCode,
        printerSelected, printerOptions,
      } = this.state;
      const selected = (printerSelected === null) ? printerOptions[0].value : printerSelected;

      if (productCode.length === 0 || addressCode.length === 0 || lotCode.length === 0) {
        addNotification(
          'danger',
          I18n.t('BEE2304' /* Impressão Corte de Cabos */),
          I18n.t('BEE1483' /* Todos os campos são obrigatórios! */),
          'top-right',
        );
      } else {
        const printLabelReturn = await this.props.printKardexCableCutLabel(
          selected,
          productCode,
          addressCode.toLocaleUpperCase(),
          lotCode,
        );

        if (printLabelReturn && printLabelReturn.success === false && printLabelReturn.printerNotFound === true) {
          addNotification(
            'danger',
            I18n.t('BEE2304' /* Impressão Corte de Cabos */),
            I18n.t('BEE1919' /* Impressora não localizada */),
            'top-right',
          );
        } if (printLabelReturn && printLabelReturn.success === false && printLabelReturn.printerNotFound === false) {
          addNotification(
            'danger',
            I18n.t('BEE2304' /* Impressão Corte de Cabos */),
            I18n.t('BEE2307' /* Não foi possível localizar esse produto */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE2304' /* Impressão Corte de Cabos */),
            I18n.t('BEE1427' /* Etiquetas enviadas para impressora ! */),
            'top-right',
          );
        }
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE1407' /* Etiqueta Kardex - Corte de Cabos */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1407' /* Etiqueta Kardex - Corte de Cabos */),
          I18n.t('BEE1441' /* Erro ao tentar imprimir */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      productCode, addressCode, lotCode,
      showPrinterDialog, printerSelected, printerOptions,
    } = this.state;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        noButton
        title={I18n.t('BEE1407' /* Etiqueta Kardex - Corte de Cabos */)}
        wikiHelp={ROUTES.MOVEMENT_LABEL_KARDEXCABLECUT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <div style={{ marginTop: 30 }} onSubmit={(e) => e.preventDefault()}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInput(
                    productCode,
                    'productCode',
                    `${I18n.t('BEE378' /* Código do Produto */)}:`,
                    '',
                    'text',
                  ),
                  formContext.createInput(
                    addressCode.toLocaleUpperCase(),
                    'addressCode',
                    `${I18n.t('BEE755' /* Código do Endereço */)}:`,
                    '',
                    'text',
                  ),
                  formContext.createInput(lotCode, 'lotCode', `${I18n.t('BEE1091' /* Número do lote */)}:`, '', 'text'),
                ])}
              />
            </div>
            {(showPrinterDialog && (
            <SweetAlert
              confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1324' /* Selecionar Impressora */)}
              onConfirm={() => this.printOut()}
              onCancel={() => this.hidePrinterDialog()}
            >
              <div className="mt-4">
                <Form
                  noPanel
                  setValue={this.setValue}
                  inputs={(formContext) => ([
                    formContext.createSelect(
                      printerSelected,
                      'printerSelected',
                      I18n.t('BEE328' /* Impressora */),
                      printerOptions,
                      '',
                      '',
                      12,
                      true,
                    ),
                  ])}
                />
              </div>
            </SweetAlert>
            ))}
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            className="btn btn-120 btn-info p-5 m-5"
            onClick={() => this.checkPrinters()}
          >
            {I18n.t('BEE1269' /* Reimprimir */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  printKardexCableCutLabel: (
    printerCode,
    productCode,
    addressCode,
    lotCode,
  ) => dispatch(printKardexCableCutLabel(printerCode, productCode, addressCode, lotCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LabelKardexCableCutForm);
