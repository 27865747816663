import { addLoading, removeLoading } from './loading';
import OutboundOrdersPickingRequests from '../../server/outboundOrdersPicking';

export const getOutboundOrdersList = (startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrdersList = await OutboundOrdersPickingRequests.getOutboundOrdersList(startDate, endDate);
    return outboundOrdersList;
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrderRangeListPicking = (
  startDate,
  endDate,
  orderNumber,
  openPicking = false,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrderRangeListPicking = await OutboundOrdersPickingRequests.getOutboundOrderRangeListPicking(
      startDate,
      endDate,
      orderNumber,
      openPicking,
    );
    return outboundOrderRangeListPicking;
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundRangePicking = (outboundRangeId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundProductsPicking = await OutboundOrdersPickingRequests.getOutboundRangePicking(outboundRangeId);
    return outboundProductsPicking;
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundWavesList = (startDate, endDate) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const wavesList = await OutboundOrdersPickingRequests.getOutboundWavesList(startDate, endDate);
    return wavesList;
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrder = (id) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrder = await OutboundOrdersPickingRequests.getOutboundOrder(id);
    return outboundOrder;
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundOrderByNumber = (orderNumber) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrder = await OutboundOrdersPickingRequests.getOutboundOrderByNumber(orderNumber);
    return outboundOrder;
  } finally {
    dispatch(removeLoading());
  }
};

export const getOutboundWaveByNumber = (orderNumber) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const outboundOrder = await OutboundOrdersPickingRequests.getOutboundWaveByNumber(orderNumber);
    return outboundOrder;
  } finally {
    dispatch(removeLoading());
  }
};

export const getStorageAddressesOptions = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const storageAddressesOptions = await OutboundOrdersPickingRequests.getStorageAddressesOptions();
    return storageAddressesOptions;
  } finally {
    dispatch(removeLoading());
  }
};

export const confirmPick = (
  outboundRangeProductId,
  pickedQuantity,
  baptismLabel,
  storageAddressPicked,
  serialGroupId,
  serialLabelId,
  serialLabelControl = false,
) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const pickResult = await OutboundOrdersPickingRequests.postConfirmPick(
      outboundRangeProductId,
      pickedQuantity,
      baptismLabel,
      storageAddressPicked,
      serialGroupId,
      serialLabelId,
      serialLabelControl,
    );
    return pickResult;
  } finally {
    dispatch(removeLoading());
  }
};

export const outboundSerialLabel = (serialCode, outboundRangeId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await OutboundOrdersPickingRequests.outboundSerialLabel(serialCode, outboundRangeId);
  } finally {
    dispatch(removeLoading());
  }
};
