import LabelsRequests from '../../server/labels';
import { addLoading, removeLoading } from './loading';

export const printEanLabel = (eanCode, printer, filters, amount) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printEanLabel(eanCode, printer, filters, amount);
  } finally {
    dispatch(removeLoading());
  }
};

export const printLotLabels = (lots, printer) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printLotLabels(lots, printer);
  } finally {
    dispatch(removeLoading());
  }
};

export const printPreConferenceLabel = (preConferenceLabelId, printerCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printPreConferenceLabel(preConferenceLabelId, printerCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const printConsolidationLabel = (printerCode, outboundOrderId, customerCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printConsolidationLabel(printerCode, outboundOrderId, customerCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const
  printBaptismLabel = (printerCode, baptismStart, baptismEnd, branchCode, typeBaptism) => async (dispatch) => {
    dispatch(addLoading());
    try {
      return await LabelsRequests.printBaptismLabel(printerCode, baptismStart, baptismEnd, branchCode, typeBaptism);
    } finally {
      dispatch(removeLoading());
    }
  };

export const printConferenceVolumeLabel = (printerCode, checkedVolumeIds) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printConferenceVolumeLabel(printerCode, checkedVolumeIds);
  } finally {
    dispatch(removeLoading());
  }
};

export const printKardexCableCutLabel = (printerCode, productCode, addressCode, lotCode) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printKardexCableCutLabel(printerCode, productCode, addressCode, lotCode);
  } finally {
    dispatch(removeLoading());
  }
};

export const printAddressLabel = (branchCode, printerCode, filters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printAddressLabel(branchCode, printerCode, filters);
  } finally {
    dispatch(removeLoading());
  }
};

export const printLotWithStockBalance = (printerCode, filters) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printLotWithStockBalance(printerCode, filters);
  } finally {
    dispatch(removeLoading());
  }
};

export const printSerialGroup = (printerCode, serialGroupId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await LabelsRequests.printSerialGroup(printerCode, serialGroupId);
  } finally {
    dispatch(removeLoading());
  }
};

export const printConsolidationsLabel = (printerCode, outboundOrderId, showLoading = true) => async (dispatch) => {
  if (showLoading) dispatch(addLoading());
  try {
    return await LabelsRequests.printConsolidationsLabel(printerCode, outboundOrderId);
  } finally {
    if (showLoading) dispatch(removeLoading());
  }
};
