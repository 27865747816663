/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unused-state */
import SyntaxHighlighter from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';

import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { PopoverHeader, UncontrolledPopover } from 'reactstrap';

import { Panel, SimpleForm } from '../../../components/panel/panel';
import addNotification from '../../../components/notification';

import ROUTES from '../../../config/routes';

import {
  createAgent, updateAgent, getAgent,
} from '../../../app/store/actions/agent';

class DevelopAgentForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.focusedValue = '';
    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      description: '',
      token: '',
      installerRoute: '',
      installerFileName: '',
      installerCommand: '',
      disableCode: true,
      disableName: true,
      disableDescription: true,
      disableToken: true,
      disable: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      showAlertTranslate: false,
      showAlertAttr: '',
      showAlertValue: '',
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getAgentSelected();
    }
  }

  static handleDownload = (href, fileName) => {
    const link = document.createElement('a');
    link.href = href;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  getAgentSelected = async () => {
    if (this.props.location.state.agent) {
      const { agent } = this.props.location.state;

      const agentSelected = await this.props.getAgent(agent.code);

      if (agentSelected) {
        this.setState({
          agentSelected,
          code: agentSelected.code,
          name: agentSelected.name,
          description: agentSelected.description,
          token: agentSelected.token,
          installerRoute: agentSelected.installerRoute,
          installerFileName: agentSelected.installerFileName,
          installerCommand: agentSelected.installerCommand,
        });
      }
    }
  };

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableDescription: false,
        disableToken: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        disable: false,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableDescription: false,
        disableToken: true,
        disable: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  copyFunction = (value, stateName = '', propsName = '') => {
    const valueToCopy = value || this.state[stateName] || this.props[propsName];

    // Navigator clipboard api precisa estar em um ambiente seguro (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(valueToCopy);

      addNotification(
        'success',
        '',
        I18n.t('BEE231' /* Copiado para o clipboard */),
        'top-right',
      );
    } else {
      // Se for http, será necessário realizar uma adaptação tecnica
      const textArea = document.createElement('textarea');
      textArea.value = valueToCopy;

      // Move a textarea para um lugar não visivel
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
        addNotification(
          'success',
          '',
          I18n.t('BEE231' /* Copiado para o clipboard */),
          'top-right',
        );
      } finally {
        textArea.remove();
      }
    }
  };

  renderCopy = (value, withIcon = true) => (
    <button
      id="tooltipCopy"
      type="button"
      className={withIcon ? '' : 'btn btn-white'}
      style={{ backgroundColor: withIcon ? '#fff' : 'transparent' }}
      onClick={() => this.copyFunction(value)}
    >
      {withIcon ? (
        <i
          className="fas fa-copy fa-2x"
          style={{ lineHeight: '0.59em' }}
        />
      ) : (
        I18n.t('BEE80' /* Copiar */)
      )}
      <UncontrolledPopover trigger="hover" placement="top" target="tooltipCopy">
        <PopoverHeader>
          {I18n.t('BEE80' /* Copiar */)}
        </PopoverHeader>
      </UncontrolledPopover>
    </button>
  );

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled, showCopy) => (
    <div code={attr} key={attr} className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          onFocus={(e) => { this.focusedValue = e.target.value; }}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
      {showCopy && this.renderCopy(value)}
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  onSubmitCreate = async () => {
    const {
      code, name, description,
    } = this.state;

    try {
      const createdAgent = await this.props.createAgent({
        code, name, description,
      });

      if (createdAgent) {
        await addNotification(
          'success',
          I18n.t('BEE3468' /* Incluir Agente */),
          I18n.t('BEE3480', { 0: code } /* Agente %{0} incluído com sucesso! */),
          'top-right',
        );

        this.props.history.push({
          pathname: ROUTES.DEVELOP_AGENT_DETAIL,
          state: {
            agent: { code },
          },
          dice: this.props.location.dice,
        });
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3468' /* Incluir Agente */),
          I18n.t('BEE3481', { 0: code } /* Erro ao incluir o agente %{0}! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3468' /* Incluir Agente */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3468' /* Incluir Agente */),
            I18n.t('BEE3481', { 0: code } /* Erro ao incluir o agente %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3468' /* Incluir Agente */),
          I18n.t('BEE3481', { 0: code } /* Erro ao incluir o agente %{0}! */),
          'top-right',
        );
      }
      await addNotification(
        'danger',
        I18n.t('BEE3468' /* Incluir Agente */),
        I18n.t('BEE3481', { 0: code } /* Erro ao incluir o agente %{0}! */),
        'top-right',
      );
    }
  };

  onSubmitUpdate = async () => {
    const {
      code, name, description, token,
    } = this.state;

    try {
      const updAgent = await this.props.updateAgent({
        code, name, description, token,
      });

      if (updAgent && updAgent instanceof Array && updAgent.length) {
        await addNotification(
          'success',
          I18n.t('BEE3469' /* Atualizar Agente */),
          I18n.t('BEE3470', { 0: code } /* Atualização do agente %{0} efetuada com sucesso! */),
          'top-right',
        );
        this.props.history.push(ROUTES.DEVELOP_AGENT_LIST);
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3469' /* Atualizar Agente */),
          I18n.t('BEE3471', { 0: code } /* Erro ao atualizar o agente %{0}! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE3469' /* Atualizar Agente */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE3469' /* Atualizar Agente */),
            I18n.t('BEE3471', { 0: code } /* Erro ao atualizar o agente %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE3469' /* Atualizar Agente */),
          I18n.t('BEE3471', { 0: code } /* Erro ao atualizar o agente %{0}! */),
          'top-right',
        );
      }
      await addNotification(
        'danger',
        I18n.t('BEE3469' /* Atualizar Agente */),
        I18n.t('BEE3471', { 0: code } /* Erro ao atualizar o agente %{0}! */),
        'top-right',
      );
    }
  };

  render() {
    const {
      mode, code, name, description, token, disableCode, disableName,
      disableDescription, disableToken, showCreate, showUpdate, showCancel,
      installerRoute, installerFileName, installerCommand,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE3468' /* Incluir Agente */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE3472' /* Editar Agente */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE3473' /* Detalhes do Agente */);
    }

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE48' /* Desenvolvedor */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE3465' /* Agentes */)}</li>
            <li className="breadcrumb-item active">{title}</li>
          </ol>
        </div>
        <SimpleForm
          title={title}
          noButton
          linkBack={{ pathname: ROUTES.DEVELOP_AGENT_LIST, state: { dice: this.props.location.dice } }}
          showCancel={showCancel}
          showCreate={showCreate}
          showUpdate={showUpdate}
          onSubmitCreate={() => this.onSubmitCreate()}
          onSubmitUpdate={() => this.onSubmitUpdate()}
          inputs={[
            this.createInput(
              code,
              'code',
              `${I18n.t('BEE82' /* Código */)}:`,
              I18n.t('BEE3476' /* Informe o código (obrigatório) */),
              'text',
              true,
              disableCode,
            ),
            this.createInput(
              name,
              'name',
              `${I18n.t('BEE83' /* Nome */)}:`,
              I18n.t('BEE3475' /* Informe o nome (obrigatório) */),
              'text',
              false,
              disableName,
            ),
            this.createInput(
              description,
              'description',
              `${I18n.t('BEE277' /* Descrição */)}:`,
              I18n.t('BEE3477' /* Informe a descrição */),
              'text',
              false,
              disableDescription,
            ),
            mode === 'detail' && this.createInput(
              token,
              'token',
              `${I18n.t('BEE3478' /* Token */)}:`,
              I18n.t('BEE3479' /* Token gerado automaticamente */),
              'text',
              false,
              disableToken,
              true,
            ),
          ]}
        />
        {
          mode === 'detail'
          && (
            <div>
              <div className="d-flex align-items-center mb-md-3 mb-2">
                <h1 className="page-header mb-0">
                  {I18n.t('BEE3499' /* Instalação */)}
                </h1>
              </div>
              <Panel>
                <div className="p-4">
                  <div style={{ padding: 10 }}>
                    <h6>
                      1 - {I18n.t('BEE3504' /* O arquivo de instalação do agente Beestock para Linux está disponível no link abaixo. */)}
                    </h6>
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                      paddingTop: 15,
                      paddingLeft: 10,
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                    onClick={() => DevelopAgentForm.handleDownload(installerRoute, installerFileName)}
                  >

                    <div><i className="fa fa-download" /></div>
                    <div>
                      <h5 style={{
                        paddingLeft: 10,
                        marginBottom: 0,
                        color: 'blue',
                        textDecoration: 'underline',
                      }}
                      >
                        {installerFileName}
                      </h5>
                    </div>
                  </div>
                  <br />
                  <br />
                  <div style={{ padding: 10 }}>
                    <h6>
                      2 - {I18n.t('BEE3505' /* Com o arquivo de instalação, execute o comando abaixo. Logo em seguida, o serviço (beestock-agent) será criado e apresentará o status (CONNECTED). */)}
                    </h6>
                  </div>
                  <br />
                  {this.renderCopy(installerCommand, false)}
                  <SyntaxHighlighter language="bash" style={prism}>
                    {installerCommand}
                  </SyntaxHighlighter>
                </div>
              </Panel>
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getAgent: (code) => dispatch(getAgent(code)),
  createAgent: (i18n) => dispatch(createAgent(i18n)),
  updateAgent: (i18n) => dispatch(updateAgent(i18n)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DevelopAgentForm));
