import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from './../../../../components/form/button';
import { Panel, PanelHeader, PanelBody, PanelFooter } from './../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import { 
  createCustomerProduct, getCustomerProduct, updateCustomerProduct,
} from '../../../../app/store/actions/customerProducts';
import { getUnitsMeasureOptions } from '../../../../app/store/actions/unitsMeasure';
import Select from '../../../../components/form/select';

class CustomerProductForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      productCode: '',
      customerCode: '',
      productCustomerCode: '',
      productCustomerName: '',
      unitMeasure: '',
      unitMeasureName: '',
      note: '',
      disableProductCode: true,
      disableCustomerCode: true,
      disableProductCustomerCode: true,
      disableProductCustomerName: true,
      disableUnitMeasure: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      listUnitMeasure: '',
    };

  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getCustomerProductSelected();
    }

    const listUnitMeasure = await this.props.getUnitsMeasureOptions();

    this.setState({
      listUnitMeasure,
    });
  }

  getCustomerProductSelected = async () => {
    if (this.props.location.state.customerProduct) {
      const customerProduct = this.props.location.state.customerProduct;

      const customerProductSelected = await this.props.getCustomerProduct(customerProduct.id);

      if (customerProductSelected) {
        this.setState({
          customerProductSelected,
          customerProductId: customerProductSelected.id,
          productCode: customerProductSelected.productCode,
          customerCode: customerProductSelected.customerCode,
          productCustomerCode: customerProductSelected.productCustomerCode,
          productCustomerName: customerProductSelected.productCustomerName,
          unitMeasure: customerProductSelected.unitMeasure,
          unitMeasureName: customerProductSelected.unitMeasureName,
          note: customerProductSelected.note,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableProductCode: false,
        disableCustomerCode: false,
        disableProductCustomerCode: false,
        disableProductCustomerName: false,
        disableUnitMeasure: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableProductCode: true,
        disableCustomerCode: false,
        disableProductCustomerCode: false,
        disableProductCustomerName: false,
        disableUnitMeasure: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value || ''}
          onChange={e => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  createCustomerProductSubmit = async () => {
    const {
      productCode, customerCode, productCustomerCode, productCustomerName,unitMeasure, note,
    } = this.state;

    try {
      const newCustomerProduct = await this.props.createCustomerProduct({
        productCode,
        customerCode,
        productCustomerCode,
        productCustomerName,
        unitMeasure,
        note,
      });

      if (newCustomerProduct) {
        if (newCustomerProduct.success && newCustomerProduct.success === false) {
          await addNotification(
            'danger', I18n.t('BEE810' /* Novo produto por cliente */), I18n.t('BEE811', {0:productCode} /* Erro ao incluir o produto por cliente %{0} */), 'top-right'
          );
        } else {
          await addNotification(
            'success', I18n.t('BEE810' /* Novo produto por cliente */), I18n.t('BEE815', {0:productCode} /* Novo produto por cliente %{0} incluída com sucesso! */), 'top-right'
          );

          this.props.history.push(ROUTES.CUSTOMER_PRODUCT_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE810' /* Novo produto por cliente */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE810' /* Novo produto por cliente */), I18n.t('BEE811', {0:productCode} /* Erro ao incluir o produto por cliente %{0} */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE810' /* Novo produto por cliente */), I18n.t('BEE811', {0:productCode} /* Erro ao incluir o produto por cliente %{0} */), 'top-right'
        );
      }
    }
  };

  updateCustomerProductSubmit = async () => {
    const {
      customerProductId, productCode, customerCode, productCustomerCode,
      productCustomerName, unitMeasure, note,
    } = this.state;

    try {
      const updCustomerProduct = await this.props.updateCustomerProduct({
        customerProductId,
        productCode,
        customerCode,
        productCustomerCode,
        productCustomerName,
        unitMeasure,
        note,
      });

      if (updCustomerProduct) {
        if (updCustomerProduct.success && updCustomerProduct.success === false) {
          await addNotification(
            'danger', I18n.t('BEE817' /* Atualizar produto por cliente */), I18n.t('BEE816', {0:productCode} /* Erro ao atualizar o produto por cliente %{0} ! */), 'top-right'
          );
        } else {
          await addNotification('success', I18n.t('BEE817' /* Atualizar produto por cliente */), I18n.t('BEE818', {0:productCode} /* Atualização do produto por cliente %{0} efetuada com sucesso! */), 'top-right');

          this.props.history.push(ROUTES.CUSTOMER_PRODUCT_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE817' /* Atualizar produto por cliente */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE817' /* Atualizar produto por cliente */), I18n.t('BEE816', {0:productCode} /* Erro ao atualizar o produto por cliente %{0} ! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE817' /* Atualizar produto por cliente */), I18n.t('BEE816', {0:productCode} /* Erro ao atualizar o produto por cliente %{0} ! */), 'top-right'
        );
      }
    }
  };

  render() {
    let {
      productCode, customerCode, productCustomerCode, productCustomerName, unitMeasure,
      unitMeasureName, note
    } = this.state;

    const {
      mode, disableProductCode, disableCustomerCode, disableProductCustomerCode, 
      disableProductCustomerName, disableUnitMeasure, disableNote, showCancel, 
      showUpdate, showCreate, listUnitMeasure
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE810' /* Novo produto por cliente */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE820' /* Editar produto por cliente */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE819' /* Detalhes produto por cliente */);
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE380' /* Produtos por Cliente */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE810' /* Novo produto por cliente */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton={true} />
                <PanelBody>
                  {this.createInput(
                    productCode, 'productCode', `${I18n.t('BEE378' /* Código do Produto */)}:`, I18n.t('BEE372' /* Informe o código do produto (obrigatório) */), 'text', true, disableProductCode,
                  )}
                  {this.createInput(
                    customerCode, 'customerCode', I18n.t('BEE381' /* Código do Cliente */), I18n.t('BEE821' /* Informe o código do cliente (obrigatório) */), 'text', true, disableCustomerCode,
                  )}
                  {this.createInput(
                    productCustomerCode, 'productCustomerCode', I18n.t('BEE382' /* Código do Produto do Cliente */), I18n.t('BEE822' /* Informe o código do produto do cliente (obrigatório) */), 'text', true, disableProductCustomerCode,
                  )}
                  {this.createInput(
                    productCustomerName, 'productCustomerName', I18n.t('BEE383' /* Nome do Produto do Cliente */), I18n.t('BEE823' /* Informe o nome do produto do cliente (obrigatório) */), 'text', true, disableProductCustomerName,
                  )}
                  {(disableUnitMeasure
                    ? this.createInput(unitMeasure, 'unitMeasure', I18n.t('BEE384' /* Unidade */), '', 'text', false, disableUnitMeasure)
                    : this.createSelectDropDown(
                      { value: unitMeasure, label: unitMeasureName }, 'unitMeasure', I18n.t('BEE384' /* Unidade */), listUnitMeasure)  
                  )}
                  {this.createInput(
                    note, 'note', `${I18n.t('BEE135' /* Observação */)}:`, I18n.t('BEE136' /* Informe a observação */), 'text', false, disableNote,
                  )}
                </PanelBody>
                <PanelFooter>
                  <Link to={{pathname: ROUTES.CUSTOMER_PRODUCT_LIST, state: {dice: this.props.location.dice}}}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createCustomerProductSubmit()}
                    >{I18n.t('BEE138' /* Criar */)}</OneClickButton>
                  )}
                  {(showUpdate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateCustomerProductSubmit()}
                    >{I18n.t('BEE139' /* Atualizar */)}</OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    }
  }),
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getCustomerProduct: productEanId => dispatch(getCustomerProduct(productEanId)),
  createCustomerProduct: ean => dispatch(createCustomerProduct(ean)),
  updateCustomerProduct: ean => dispatch(updateCustomerProduct(ean)),
  getUnitsMeasureOptions: () => dispatch(getUnitsMeasureOptions())
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerProductForm));