/* eslint-disable react/no-unused-state */
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import Select from '../../../components/form/select';
import addNotification from '../../../components/notification';
import { PanelPage } from '../../../components/pages/pages';

import InventorySheet from './inventorySheet';

import { getBranchesOptions } from '../../../app/store/actions/branches';
import { getInventoryOptions, getInventorySheetsList } from '../../../app/store/actions/inventory';

import InventoryHelpers from '../../../helpers/inventory';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class UpdateInventorySheet extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      inventoryId: null,
      searchPage: true,
      grouperDisabled: true,
      grouperCode: null,
      grouperName: '',
      listInventory: [],
      branchName: '',
      branchCode: null,
      depositName: '',
      status: null,
      dateInventory: null,
      typeInventory: null,
      pendingSheet: null,
      sheetMissingCount: null,
      differenceValue: null,
      sheets: [],
      listBranch: [],
    };
    this.initialState = this.state;

    this.colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, {
        data, isDisabled, isFocused, isSelected,
      }) => ({
        ...styles,
        backgroundColor: isDisabled ? 'grey' : 'white',
        color: isDisabled ? 'grey' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        textAlign: 'left',

        ':hover': {
          ...styles[':hover'],
          backgroundColor: isSelected ? 'grey' : '#eb900a',
          color: 'white',
        },
      }),
    };
  }

  componentDidMount() {
    document.getElementById(1).focus();
    this.getBranchesOptions();
  }

  setValueDrop = async (attr, value) => {
    this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
    if (attr === 'branch') {
      await this.setState({
        grouperDisabled: !((value && value.value)),
        grouperCode: null,
        grouperName: '',
      });
      if (value && value.value) this.getInventoryOptions();
    }
  };

  goToElement = (e, id, attr) => {
    if (e.keyCode === 13) {
      if (attr === 'grouper') {
        this.checkFields();
      } else {
        document.getElementById(id + 1).focus();
      }
    }
  };

  createSelectDropDown = (value, attr, label, items, disabled, keypressFunction = undefined, id) => (
    <div className="form-group m-b-15 text-left">
      <label>{label}</label>
      <div>
        <Select
          id={id && id}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          value={value.label ? value : ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={this.colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          isDisabled={disabled}
        />
      </div>
    </div>
  );

  dataOrganization = (inventory) => {
    if (Object.keys(inventory).length) {
      this.setState({
        inventoryId: inventory.inventoryId,
        branchName: inventory.branchName,
        depositName: inventory.depositName,
        grouperCode: inventory.grouperCode,
        status: InventoryHelpers.inventoryStatus(inventory.status),
        dateInventory: (inventory.dateInventory) ? moment(inventory.dateInventory).format('L') : null,
        typeInventory: InventoryHelpers.inventoryType(inventory.typeInventory),
        pendingSheet: inventory.pendingSheet,
        sheetMissingCount: inventory.sheetMissingCount,
        differenceValue: inventory.differenceValue,
        sheets: [...inventory.sheets],
      });
    }
  };

  getInventorySheetsList = async (valid = true) => {
    const { grouperCode, branchCode } = this.state;
    try {
      const inventory = await this.props.getInventorySheetsList(grouperCode, branchCode);

      if (inventory && inventory.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else if (inventory) {
        this.setState({ searchPage: false });
        this.dataOrganization(inventory);
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        if (error.code === 914 && valid) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;
          addNotification('danger', I18n.t('BEE2151' /* Atualizar Ficha de Inventário */), messageError, 'top-right');
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
            `${error.code} - ${error.message || error.details}`,
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  checkFields = () => {
    const { grouperCode, branchCode } = this.state;
    if (!branchCode) {
      addNotification(
        'danger',
        I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
        I18n.t('BEE1781' /* Informe a filial */),
        'top-right',
      );
    } else if (!grouperCode) {
      addNotification(
        'danger',
        I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
        I18n.t('BEE2111' /* Informe o agrupador */),
        'top-right',
      );
    } else {
      this.getInventorySheetsList();
    }
  };

  getBranchesOptions = async () => {
    this.setState({ listBranch: [] });
    try {
      const listBranch = await this.props.getBranchesOptions();
      if (listBranch.length) this.setState({ listBranch });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2151' /* Atualizar Ficha de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getInventoryOptions = async () => {
    const { branchCode } = this.state;
    this.setState({ listInventory: [] });
    try {
      const listInventory = await this.props.getInventoryOptions(branchCode);
      if (listInventory.length) this.setState({ listInventory });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2151' /* Atualizar Ficha de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2151' /* Atualizar Ficha de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      searchPage, grouperCode, grouperDisabled, listBranch = [],
      branchCode, branchName, grouperName, listInventory,
    } = this.state;

    return (
      <>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE572' /* Inventário */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2151' /* Atualizar Ficha de Inventário */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2151' /* Atualizar Ficha de Inventário */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.UPDATE_INVENTORY_SHEET_HELP} />
        </div>
        {searchPage && (
          <PanelPage
            noButton
            content={(
              <div className="col-md-5">
                {this.createSelectDropDown(
                  { value: branchCode, label: branchName },
                  'branch',
                  I18n.t('BEE145' /* Filial */),
                  listBranch,
                  false,
                  this.goToElement,
                  1,
                )}
                {this.createSelectDropDown(
                  { value: grouperCode, label: grouperName },
                  'grouper',
                  I18n.t('BEE1516' /* Agrupador */),
                  listInventory,
                  grouperDisabled,
                  this.goToElement,
                  2,
                )}
              </div>
            )}
            footerContent={(
              <button
                type="button"
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={this.checkFields}
              >
                {I18n.t('BEE407' /* Buscar */)}
              </button>
            )}
          />
        )}
        {!searchPage && (
          <InventorySheet
            restoreHomePage={() => {
              this.setState({ ...this.initialState });
              this.getBranchesOptions();
            }}
            getInventorySheetsList={this.getInventorySheetsList}
            state={this.state}
            setValue={this.setValue}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getInventorySheetsList: (grouperCode, branchCode) => dispatch(getInventorySheetsList(grouperCode, branchCode)),
  getInventoryOptions: (branchCode) => dispatch(getInventoryOptions(branchCode, 5)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UpdateInventorySheet));
