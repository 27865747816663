import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import Select from '../../../../components/form/select';

import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';

import { getBranchesOptions } from '../../../../app/store/actions/branches';
import { printLotLabels } from '../../../../app/store/actions/labels';
import { generateLots, getLotInfo, getOptionsLots } from '../../../../app/store/actions/lots';
import FormatHelpers from '../../../../helpers/format';

import WikiHelp from '../../../../components/tooltip/wikiHelp';
import ROUTES from '../../../../config/routes';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};
class LotForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      branchCode: '',
      branchName: '',
      initial: '',
      lastLot: 0,
      quantity: 1,
      showPrintDialog: false,
      printStart: 0,
      printEnd: 0,
      showPrinterDialog: false,
      printerOptions: [],
      lots: [],
      printerSelected: null,
      label: '< >',
    };
  }

  async componentDidMount() {
    this.getLotInfo();
  }

  getLotInfo = async () => {
    const lotInfo = await this.props.getLotInfo();
    const {
      branchCode, branchName, lastLot, userPrinter, printerOptions,
    } = lotInfo;
    const { printerSelected } = this.state;

    const listBranch = !!branchCode && !!branchName ? null : await this.props.getBranchesOptions();

    this.setState({
      listBranch,
      branch: listBranch ? listBranch[0].value : null,
      branchCode,
      branchName,
      lastLot,
      printerOptions,
      printerSelected: printerSelected === null
        ? userPrinter
        : printerSelected !== userPrinter
          ? printerSelected
          : userPrinter,
    });
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled, maxLength) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>
    </div>
  );

  createSelect = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <select
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

  createSelectPrint = (value, attr, label, items) => (
    <div className="form-group p-2">
      <label>{label}</label>
      <select
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  createSelectDropDownRange = (value, attr, label, items) => (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-2">{label}</label>
      <div className="col-md-4">
        <Select
          value={value.from || ''}
          onChange={(e) => this.setValue(attr.from, e.value)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={value.from}
        />
      </div>

      <div className="col-md-1 d-flex justify-content-center align-items-center pb-1">
        <div style={{ fontSize: '0.8rem', marginRight: 16 }}><i className="fas fa-chevron-left" /></div>
        <div style={{ fontSize: '0.8rem', marginLeft: 16 }}><i className="fas fa-chevron-right" /></div>
      </div>

      <div className="col-md-4">
        <Select
          value={value.to || ''}
          onChange={(e) => this.setValue(attr.to, e.value)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={value.to}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    if (attr === 'initial') {
      if ((value.length <= 3) || (value === '')) {
        await this.setState({
          [`${attr}`]: value,
        });
      }
    } else if (attr === 'quantity') {
      await this.setState({
        [`${attr}`]: FormatHelpers.formatNumber(value, 0, false, true),
      });
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }
  };

  generateLots = async () => {
    const { branchCode, quantity, initial } = this.state;

    try {
      const result = await this.props.generateLots(branchCode, parseInt(quantity, 10), initial);
      await this.getLotInfo();

      const firstBap = result[0].code;
      const lastBap = result[result.length - 1].code;
      const printMessage = firstBap === lastBap
        ? I18n.t('BEE1411', { 0: `L${firstBap}${initial}` } /* Lote %{0} gerado com sucesso! */)
        : I18n.t('BEE1412', { 0: `L${firstBap}${initial}`, 1: `L${lastBap}${initial}` } /* Lotes %{0} a %{1} gerados com sucesso! */);
      this.setState({
        showPrintConfirmation: false,
        printStart: firstBap,
        printEnd: lastBap,
        lots: result,
      });
      this.printLot();
    } catch (error) {
      addNotification(
        'danger',
        I18n.t('BEE1387' /* Gerar Lote */),
        I18n.t('BEE1413' /* Erro ao gerar Lote! */),
        'top-right',
      );
    }
  };

  showReprintDialog = async () => {
    const { lastLot, branchCode, label } = this.state;
    const lots = await this.props.getOptionsLots(branchCode, label);

    this.setState({
      showPrintDialog: true,
      printStart: (lots && lots.length) ? lots[0].value : 0,
      printEnd: lastLot,
      lots,
    });
  };

  hidePrintDialog = () => {
    const { lastLot } = this.state;
    this.setState({
      showPrintDialog: false,
      printStart: lastLot,
      printEnd: lastLot,
    });
  };

  printLot = async () => {
    const { printerOptions } = this.state;

    if (!printerOptions || printerOptions.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE1414', { 0: I18n.t('BEE428' /* Lote */).toUpperCase() } /* Impressão %{0} */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrinterDialog: true,
        showPrintDialog: false,
      });
    } else {
      await this.printLotLabels();
    }
  };

  printLotLabels = async () => {
    const {
      lots, printStart, printEnd, printerSelected, initial, printerOptions,
    } = this.state;
    let codeInit = `L${printStart}${initial}`;
    let codeEnd = `L${printEnd}${initial}`;

    const filteredLots = lots.filter((lot) => lot.code >= printStart && lot.code <= printEnd);
    codeInit = `L${printStart}${filteredLots[0].initial}`;
    codeEnd = `L${printEnd}${filteredLots[filteredLots.length - 1].initial}`;

    await this.props.printLotLabels((filteredLots.length)
      ? filteredLots : lots, printerSelected || printerOptions[0].value);

    // this.getLotInfo();
    addNotification(
      'success',
      I18n.t('BEE1414', { 0: I18n.t('BEE428' /* Lote */).toUpperCase() } /* Impressão %{0} */),
      I18n.t('BEE1416', {
        0: codeInit,
        1: codeEnd,
        2: (printerSelected) || printerOptions[0].value,
      } /* Imprimindo etiquetas %{0} a %{1} na impressora %{2} */),
      'top-right',
    );

    this.setState({
      showPrinterDialog: false,
      initial: '',
      quantity: 1,
    });
  };

  hidePrinterDialog = () => {
    this.setState({
      showPrinterDialog: false,
    });
  };

  printConfirmation = () => {
    if (!parseInt(this.state.quantity, 10)) {
      addNotification(
        'danger',
        I18n.t('BEE1380' /* Etiqueta Lote */),
        I18n.t('BEE2293' /* A quantidade tem que ser maior que 0 */),
        'top-right',
      );
    } else if (parseInt(this.state.quantity, 10) === 1) this.generateLots();
    else this.setState({ showPrintConfirmation: true });
  };

  render() {
    const {
      branchCode, branchName, lastLot, quantity, initial, showPrintDialog,
      showPrinterDialog, printStart, printEnd,
      printerOptions, printerSelected, branch, listBranch, lots, showPrintConfirmation,
    } = this.state;

    const branchLabel = !!branchCode && !!branchName ? `${branchCode} - ${branchName}` : '';
    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE1371' /* Etiquetas */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1380' /* Etiqueta Lote */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE1380' /* Etiqueta Lote */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.MOVEMENT_LABEL_LOT_HELP} />
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  {(branchLabel
                    ? this.createInput(
                      branchLabel,
                      'branchLabel',
                      I18n.t('BEE145' /* Filial */),
                      '',
                      'text',
                      true,
                      true,
                    )
                    : this.createSelect(branch, 'branch', `${I18n.t('BEE145' /* Filial */)}:`, listBranch || [])
                  )}
                  {this.createInput(
                    lastLot,
                    'lastLot',
                    I18n.t('BEE1385' /* Última Sequência de Lote Gerada */),
                    '',
                    'number',
                    true,
                    true,
                  )}
                  {this.createInput(
                    initial,
                    'initial',
                    I18n.t('BEE1384' /* Sigla */),
                    '',
                    'text',
                    false,
                    false,
                  )}
                  {this.createInput(
                    FormatHelpers.formatNumber(quantity, 0, false, true),
                    'quantity',
                    I18n.t('BEE1267' /* Quantidade a gerar */),
                    '',
                    'number',
                    false,
                    false,
                    2,
                  )}
                </PanelBody>
                <PanelFooter>
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-white p-5 m-5"
                    onClick={() => this.showReprintDialog()}
                  >
                    { I18n.t('BEE1269' /* Reimprimir */)}

                  </OneClickButton>
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={() => this.printConfirmation()}
                  >
                    {I18n.t('BEE1387' /* Gerar Lote */)}

                  </OneClickButton>
                </PanelFooter>
              </Panel>
            </form>
            {(showPrintDialog && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1270' /* Imrpimir */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1269' /* Reimprimir */)}
              onConfirm={() => this.printLot()}
              onCancel={() => this.hidePrintDialog()}
              style={{ width: '300px !important' }}
            >
              <div style={{ width: 600 }}>
                {this.createSelectDropDownRange(
                  { from: printStart, to: printEnd },
                  { from: 'printStart', to: 'printEnd' },
                  I18n.t('BEE1273' /* Intervalo */),
                  lots,
                )}
              </div>
            </SweetAlert>
            ))}
            {(showPrinterDialog && (
            <SweetAlert
              confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1324' /* Selecionar Impressora */)}
              onConfirm={() => this.printLotLabels()}
              onCancel={() => this.hidePrinterDialog()}
            >
              {this.createSelectPrint(
                printerSelected,
                'printerSelected',
                I18n.t('BEE328' /* Impressora */),
                printerOptions,
              )}
            </SweetAlert>
            ))}
            {(showPrintConfirmation && (
            <SweetAlert
              warning
              showCancel
              confirmBtnText={I18n.t('BEE1800' /* Prosseguir */)}
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="danger"
              title={I18n.t('BEE1975' /* Verificação de Quantidade */)}
              onConfirm={() => this.generateLots()}
              onCancel={() => this.setState({ showPrintConfirmation: false })}
            >
              <h5>
                {I18n.t('BEE1974', { 0: quantity } /* A quantidade informada é %{0}. Deseja prosseguir? */)}
              </h5>
            </SweetAlert>
            )
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getLotInfo: () => dispatch(getLotInfo()),
  getOptionsLots: (branchCode) => dispatch(getOptionsLots(branchCode)),
  generateLots: (branchCode, quantity, initial) => dispatch(generateLots(branchCode, quantity, initial)),
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  printLotLabels: (lots, printerSelected) => dispatch(printLotLabels(lots, printerSelected)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LotForm));
