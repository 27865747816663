/* eslint-disable import/prefer-default-export */
/* eslint-disable react/function-component-definition */
/* eslint-disable max-len */
/* eslint-disable semi */
import React from 'react';

export const DropFile = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M50.0156 92.9688C49.1516 92.9688 48.4531 92.2703 48.4531 91.4063V61.7219C48.4531 60.8578 49.1516 60.1594 50.0156 60.1594C50.8797 60.1594 51.5781 60.8578 51.5781 61.7219V91.4063C51.5781 92.2703 50.8797 92.9688 50.0156 92.9688Z" fill="#F17B1E" />
    <path d="M40.6264 89.8594H28.1436C27.2795 89.8594 26.5811 89.1609 26.5811 88.2969C26.5811 87.4328 27.2795 86.7344 28.1436 86.7344H40.6264C41.4904 86.7344 42.1889 87.4328 42.1889 88.2969C42.1889 89.1609 41.4904 89.8594 40.6264 89.8594Z" fill="#F17B1E" />
    <path d="M40.6265 89.8594C39.7624 89.8594 39.064 89.1609 39.064 88.2969V61.6734C39.064 60.8094 39.7624 60.1109 40.6265 60.1109C41.4905 60.1109 42.189 60.8094 42.189 61.6734V88.2969C42.189 89.1609 41.4905 89.8594 40.6265 89.8594Z" fill="#F17B1E" />
    <path d="M29.6875 77.3469H17.1953C16.3313 77.3469 15.6328 76.6484 15.6328 75.7844C15.6328 74.9203 16.3313 74.2219 17.1953 74.2219H29.6875C30.5516 74.2219 31.25 74.9203 31.25 75.7844C31.25 76.6484 30.5516 77.3469 29.6875 77.3469Z" fill="#F17B1E" />
    <path d="M29.6875 77.3469C28.8234 77.3469 28.125 76.6484 28.125 75.7844V61.6734C28.125 60.8094 28.8234 60.1109 29.6875 60.1109C30.5516 60.1109 31.25 60.8094 31.25 61.6734V75.7844C31.25 76.6484 30.5516 77.3469 29.6875 77.3469Z" fill="#F17B1E" />
    <path d="M71.8876 89.8594H59.4048C58.5407 89.8594 57.8423 89.1609 57.8423 88.2969C57.8423 87.4328 58.5407 86.7344 59.4048 86.7344H71.8876C72.7517 86.7344 73.4501 87.4328 73.4501 88.2969C73.4501 89.1609 72.7517 89.8594 71.8876 89.8594Z" fill="#F17B1E" />
    <path d="M59.4048 89.8594C58.5407 89.8594 57.8423 89.1609 57.8423 88.2969V61.6734C57.8423 60.8094 58.5407 60.1109 59.4048 60.1109C60.2688 60.1109 60.9673 60.8094 60.9673 61.6734V88.2969C60.9673 89.1609 60.2673 89.8594 59.4048 89.8594Z" fill="#F17B1E" />
    <path d="M82.8359 77.3469H70.3438C69.4797 77.3469 68.7812 76.6484 68.7812 75.7844C68.7812 74.9203 69.4797 74.2219 70.3438 74.2219H82.8359C83.7 74.2219 84.3984 74.9203 84.3984 75.7844C84.3984 76.6484 83.6984 77.3469 82.8359 77.3469Z" fill="#F17B1E" />
    <path d="M70.3438 77.3469C69.4797 77.3469 68.7812 76.6484 68.7812 75.7844V61.6734C68.7812 60.8094 69.4797 60.1109 70.3438 60.1109C71.2078 60.1109 71.9062 60.8094 71.9062 61.6734V75.7844C71.9062 76.6484 71.2062 77.3469 70.3438 77.3469Z" fill="#F17B1E" />
    <path d="M74.2188 16.4062H74.2031C70.0469 7.1875 60.7656 0.78125 50 0.78125C36.375 0.78125 25.1406 11.0297 23.625 24.25L23.4375 24.2188H21.875C13.25 24.2188 6.25 31.2172 6.25 39.8438C6.25 48.4687 13.25 55.4688 21.875 55.4688H74.2188C85.0156 55.4688 93.75 46.7156 93.75 35.9375C93.75 25.1562 85.0156 16.4062 74.2188 16.4062Z" fill="#D9E0E7" />
    <path d="M14.0625 72.6562C15.7969 72.6562 17.1875 74.0594 17.1875 75.7812C17.1875 77.5141 15.7969 78.9062 14.0625 78.9062C12.3437 78.9062 10.9375 77.5141 10.9375 75.7812C10.9375 74.0594 12.3437 72.6562 14.0625 72.6562Z" fill="#F17B1E" />
    <path d="M25.0156 85.1719C26.75 85.1719 28.1406 86.5609 28.1406 88.2969C28.1406 90.0156 26.75 91.4219 25.0156 91.4219C23.2969 91.4219 21.8906 90.0156 21.8906 88.2969C21.8906 86.5594 23.2969 85.1719 25.0156 85.1719Z" fill="#F17B1E" />
    <path d="M50.0156 91.4062C51.7359 91.4062 53.1406 92.7953 53.1406 94.5312C53.1406 96.25 51.7359 97.6562 50.0156 97.6562C48.2969 97.6562 46.8906 96.25 46.8906 94.5312C46.8906 92.7953 48.2969 91.4062 50.0156 91.4062Z" fill="#F17B1E" />
    <path d="M75.0156 85.1719C76.7359 85.1719 78.1406 86.5609 78.1406 88.2969C78.1406 90.0156 76.7359 91.4219 75.0156 91.4219C73.2828 91.4219 71.8906 90.0156 71.8906 88.2969C71.8906 86.5594 73.2812 85.1719 75.0156 85.1719Z" fill="#F17B1E" />
    <path d="M85.9531 72.6562C87.6875 72.6562 89.0781 74.0594 89.0781 75.7812C89.0781 77.5141 87.6875 78.9062 85.9531 78.9062C84.2344 78.9062 82.8281 77.5141 82.8281 75.7812C82.8281 74.0594 84.2344 72.6562 85.9531 72.6562Z" fill="#F17B1E" />
    <path d="M6.25 39.8438C6.25 48.4687 13.25 55.4688 21.875 55.4688H74.2188C85.0156 55.4688 93.75 46.7156 93.75 35.9375V42.1391C93.75 52.9203 85.0156 61.6703 74.2188 61.6703H70.3438H59.4063H40.625H29.6875H21.875C13.25 61.6703 6.25 54.6703 6.25 46.0453V39.8438Z" fill="#B6BFC8" />
    <path d="M50 8.59375C60.3594 8.59375 68.75 16.9984 68.75 27.3438C68.75 37.7016 60.3594 46.0938 50 46.0938C39.6563 46.0938 31.25 37.7016 31.25 27.3438C31.25 16.9984 39.6563 8.59375 50 8.59375Z" fill="#F17B1E" />
    <path d="M14.0703 80.4719C11.4859 80.4719 9.38281 78.3687 9.38281 75.7844C9.38281 73.2 11.4859 71.0969 14.0703 71.0969C16.6547 71.0969 18.7578 73.2 18.7578 75.7844C18.7578 78.3687 16.6547 80.4719 14.0703 80.4719ZM14.0703 74.2219C13.2078 74.2219 12.5078 74.9234 12.5078 75.7844C12.5078 76.6484 13.2078 77.3469 14.0703 77.3469C14.9328 77.3469 15.6328 76.6484 15.6328 75.7844C15.6328 74.9234 14.9328 74.2219 14.0703 74.2219Z" fill="#F17B1E" />
    <path d="M25.0186 92.9844C22.4342 92.9844 20.3311 90.8813 20.3311 88.2969C20.3311 85.7125 22.4342 83.6094 25.0186 83.6094C27.6029 83.6094 29.7061 85.7125 29.7061 88.2969C29.7061 90.8813 27.6029 92.9844 25.0186 92.9844ZM25.0186 86.7344C24.1561 86.7344 23.4561 87.4359 23.4561 88.2969C23.4561 89.1609 24.1561 89.8594 25.0186 89.8594C25.8811 89.8594 26.5811 89.1609 26.5811 88.2969C26.5811 87.4359 25.8795 86.7344 25.0186 86.7344Z" fill="#F17B1E" />
    <path d="M50.0156 99.2188C47.4312 99.2188 45.3281 97.1156 45.3281 94.5312C45.3281 91.9469 47.4312 89.8438 50.0156 89.8438C52.6 89.8438 54.7031 91.9469 54.7031 94.5312C54.7031 97.1156 52.6 99.2188 50.0156 99.2188ZM50.0156 92.9688C49.1531 92.9688 48.4531 93.6703 48.4531 94.5312C48.4531 95.3953 49.1531 96.0938 50.0156 96.0938C50.8781 96.0938 51.5781 95.3953 51.5781 94.5312C51.5781 93.6703 50.8781 92.9688 50.0156 92.9688Z" fill="#F17B1E" />
    <path d="M85.9609 80.4719C83.3766 80.4719 81.2734 78.3687 81.2734 75.7844C81.2734 73.2 83.3766 71.0969 85.9609 71.0969C88.5453 71.0969 90.6484 73.2 90.6484 75.7844C90.6484 78.3687 88.5453 80.4719 85.9609 80.4719ZM85.9609 74.2219C85.0984 74.2219 84.3984 74.9234 84.3984 75.7844C84.3984 76.6484 85.0984 77.3469 85.9609 77.3469C86.8234 77.3469 87.5234 76.6484 87.5234 75.7844C87.5234 74.9234 86.8219 74.2219 85.9609 74.2219Z" fill="#F17B1E" />
    <path d="M75.0127 92.9844C72.4283 92.9844 70.3252 90.8813 70.3252 88.2969C70.3252 85.7125 72.4283 83.6094 75.0127 83.6094C77.5971 83.6094 79.7002 85.7125 79.7002 88.2969C79.7002 90.8813 77.5971 92.9844 75.0127 92.9844ZM75.0127 86.7344C74.1502 86.7344 73.4502 87.4359 73.4502 88.2969C73.4502 89.1609 74.1502 89.8594 75.0127 89.8594C75.8752 89.8594 76.5752 89.1609 76.5752 88.2969C76.5752 87.4359 75.8752 86.7344 75.0127 86.7344Z" fill="#F17B1E" />
    <path fillRule="evenodd" clipRule="evenodd" d="M57.1674 27.2701C57.7775 27.8803 57.7775 28.8697 57.1674 29.4799L50.9174 35.7299C50.6243 36.0229 50.2269 36.1875 49.8125 36.1875C49.3981 36.1875 49.0007 36.0229 48.7076 35.7299L42.4576 29.4799C41.8475 28.8697 41.8475 27.8803 42.4576 27.2701C43.0678 26.66 44.0572 26.66 44.6674 27.2701L48.25 30.8528L48.25 20.5625C48.25 19.6996 48.9496 19 49.8125 19C50.6754 19 51.375 19.6996 51.375 20.5625L51.375 30.8528L54.9576 27.2701C55.5678 26.66 56.5572 26.66 57.1674 27.2701Z" fill="white" />
  </svg>
);

export const DropFileSucces = (props) => (
  <svg width={props.width} height={props.height} viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M50.0156 92.9688C49.1516 92.9688 48.4531 92.2703 48.4531 91.4063V61.7219C48.4531 60.8578 49.1516 60.1594 50.0156 60.1594C50.8797 60.1594 51.5781 60.8578 51.5781 61.7219V91.4063C51.5781 92.2703 50.8797 92.9688 50.0156 92.9688Z" fill="#1EF14F" />
    <path d="M40.6264 89.8594H28.1436C27.2795 89.8594 26.5811 89.1609 26.5811 88.2969C26.5811 87.4328 27.2795 86.7344 28.1436 86.7344H40.6264C41.4904 86.7344 42.1889 87.4328 42.1889 88.2969C42.1889 89.1609 41.4904 89.8594 40.6264 89.8594Z" fill="#1EF14F" />
    <path d="M40.6265 89.8594C39.7624 89.8594 39.064 89.1609 39.064 88.2969V61.6734C39.064 60.8094 39.7624 60.1109 40.6265 60.1109C41.4905 60.1109 42.189 60.8094 42.189 61.6734V88.2969C42.189 89.1609 41.4905 89.8594 40.6265 89.8594Z" fill="#1EF14F" />
    <path d="M29.6875 77.3469H17.1953C16.3313 77.3469 15.6328 76.6484 15.6328 75.7844C15.6328 74.9203 16.3313 74.2219 17.1953 74.2219H29.6875C30.5516 74.2219 31.25 74.9203 31.25 75.7844C31.25 76.6484 30.5516 77.3469 29.6875 77.3469Z" fill="#1EF14F" />
    <path d="M29.6875 77.3469C28.8234 77.3469 28.125 76.6484 28.125 75.7844V61.6734C28.125 60.8094 28.8234 60.1109 29.6875 60.1109C30.5516 60.1109 31.25 60.8094 31.25 61.6734V75.7844C31.25 76.6484 30.5516 77.3469 29.6875 77.3469Z" fill="#1EF14F" />
    <path d="M71.8876 89.8594H59.4048C58.5407 89.8594 57.8423 89.1609 57.8423 88.2969C57.8423 87.4328 58.5407 86.7344 59.4048 86.7344H71.8876C72.7517 86.7344 73.4501 87.4328 73.4501 88.2969C73.4501 89.1609 72.7517 89.8594 71.8876 89.8594Z" fill="#1EF14F" />
    <path d="M59.4048 89.8594C58.5407 89.8594 57.8423 89.1609 57.8423 88.2969V61.6734C57.8423 60.8094 58.5407 60.1109 59.4048 60.1109C60.2688 60.1109 60.9673 60.8094 60.9673 61.6734V88.2969C60.9673 89.1609 60.2673 89.8594 59.4048 89.8594Z" fill="#1EF14F" />
    <path d="M82.8359 77.3469H70.3438C69.4797 77.3469 68.7812 76.6484 68.7812 75.7844C68.7812 74.9203 69.4797 74.2219 70.3438 74.2219H82.8359C83.7 74.2219 84.3984 74.9203 84.3984 75.7844C84.3984 76.6484 83.6984 77.3469 82.8359 77.3469Z" fill="#1EF14F" />
    <path d="M70.3438 77.3469C69.4797 77.3469 68.7812 76.6484 68.7812 75.7844V61.6734C68.7812 60.8094 69.4797 60.1109 70.3438 60.1109C71.2078 60.1109 71.9062 60.8094 71.9062 61.6734V75.7844C71.9062 76.6484 71.2062 77.3469 70.3438 77.3469Z" fill="#1EF14F" />
    <path d="M74.2188 16.4062H74.2031C70.0469 7.1875 60.7656 0.78125 50 0.78125C36.375 0.78125 25.1406 11.0297 23.625 24.25L23.4375 24.2188H21.875C13.25 24.2188 6.25 31.2172 6.25 39.8438C6.25 48.4687 13.25 55.4688 21.875 55.4688H74.2188C85.0156 55.4688 93.75 46.7156 93.75 35.9375C93.75 25.1562 85.0156 16.4062 74.2188 16.4062Z" fill="#D9E0E7" />
    <path d="M14.0625 72.6562C15.7969 72.6562 17.1875 74.0594 17.1875 75.7812C17.1875 77.5141 15.7969 78.9062 14.0625 78.9062C12.3437 78.9062 10.9375 77.5141 10.9375 75.7812C10.9375 74.0594 12.3437 72.6562 14.0625 72.6562Z" fill="#1EF14F" />
    <path d="M25.0156 85.1719C26.75 85.1719 28.1406 86.5609 28.1406 88.2969C28.1406 90.0156 26.75 91.4219 25.0156 91.4219C23.2969 91.4219 21.8906 90.0156 21.8906 88.2969C21.8906 86.5594 23.2969 85.1719 25.0156 85.1719Z" fill="#1EF14F" />
    <path d="M50.0156 91.4062C51.7359 91.4062 53.1406 92.7953 53.1406 94.5312C53.1406 96.25 51.7359 97.6562 50.0156 97.6562C48.2969 97.6562 46.8906 96.25 46.8906 94.5312C46.8906 92.7953 48.2969 91.4062 50.0156 91.4062Z" fill="#1EF14F" />
    <path d="M75.0156 85.1719C76.7359 85.1719 78.1406 86.5609 78.1406 88.2969C78.1406 90.0156 76.7359 91.4219 75.0156 91.4219C73.2828 91.4219 71.8906 90.0156 71.8906 88.2969C71.8906 86.5594 73.2812 85.1719 75.0156 85.1719Z" fill="#1EF14F" />
    <path d="M85.9531 72.6562C87.6875 72.6562 89.0781 74.0594 89.0781 75.7812C89.0781 77.5141 87.6875 78.9062 85.9531 78.9062C84.2344 78.9062 82.8281 77.5141 82.8281 75.7812C82.8281 74.0594 84.2344 72.6562 85.9531 72.6562Z" fill="#1EF14F" />
    <path d="M6.25 39.8438C6.25 48.4687 13.25 55.4688 21.875 55.4688H74.2188C85.0156 55.4688 93.75 46.7156 93.75 35.9375V42.1391C93.75 52.9203 85.0156 61.6703 74.2188 61.6703H70.3438H59.4063H40.625H29.6875H21.875C13.25 61.6703 6.25 54.6703 6.25 46.0453V39.8438Z" fill="#B6BFC8" />
    <path d="M50 8.59375C60.3594 8.59375 68.75 16.9984 68.75 27.3438C68.75 37.7016 60.3594 46.0938 50 46.0938C39.6563 46.0938 31.25 37.7016 31.25 27.3438C31.25 16.9984 39.6563 8.59375 50 8.59375Z" fill="#1EF14F" />
    <path d="M14.0703 80.4719C11.4859 80.4719 9.38281 78.3687 9.38281 75.7844C9.38281 73.2 11.4859 71.0969 14.0703 71.0969C16.6547 71.0969 18.7578 73.2 18.7578 75.7844C18.7578 78.3687 16.6547 80.4719 14.0703 80.4719ZM14.0703 74.2219C13.2078 74.2219 12.5078 74.9234 12.5078 75.7844C12.5078 76.6484 13.2078 77.3469 14.0703 77.3469C14.9328 77.3469 15.6328 76.6484 15.6328 75.7844C15.6328 74.9234 14.9328 74.2219 14.0703 74.2219Z" fill="#1EF14F" />
    <path d="M25.0186 92.9844C22.4342 92.9844 20.3311 90.8813 20.3311 88.2969C20.3311 85.7125 22.4342 83.6094 25.0186 83.6094C27.6029 83.6094 29.7061 85.7125 29.7061 88.2969C29.7061 90.8813 27.6029 92.9844 25.0186 92.9844ZM25.0186 86.7344C24.1561 86.7344 23.4561 87.4359 23.4561 88.2969C23.4561 89.1609 24.1561 89.8594 25.0186 89.8594C25.8811 89.8594 26.5811 89.1609 26.5811 88.2969C26.5811 87.4359 25.8795 86.7344 25.0186 86.7344Z" fill="#1EF14F" />
    <path d="M50.0156 99.2188C47.4312 99.2188 45.3281 97.1156 45.3281 94.5312C45.3281 91.9469 47.4312 89.8438 50.0156 89.8438C52.6 89.8438 54.7031 91.9469 54.7031 94.5312C54.7031 97.1156 52.6 99.2188 50.0156 99.2188ZM50.0156 92.9688C49.1531 92.9688 48.4531 93.6703 48.4531 94.5312C48.4531 95.3953 49.1531 96.0938 50.0156 96.0938C50.8781 96.0938 51.5781 95.3953 51.5781 94.5312C51.5781 93.6703 50.8781 92.9688 50.0156 92.9688Z" fill="#1EF14F" />
    <path d="M85.9609 80.4719C83.3766 80.4719 81.2734 78.3687 81.2734 75.7844C81.2734 73.2 83.3766 71.0969 85.9609 71.0969C88.5453 71.0969 90.6484 73.2 90.6484 75.7844C90.6484 78.3687 88.5453 80.4719 85.9609 80.4719ZM85.9609 74.2219C85.0984 74.2219 84.3984 74.9234 84.3984 75.7844C84.3984 76.6484 85.0984 77.3469 85.9609 77.3469C86.8234 77.3469 87.5234 76.6484 87.5234 75.7844C87.5234 74.9234 86.8219 74.2219 85.9609 74.2219Z" fill="#1EF14F" />
    <path d="M75.0127 92.9844C72.4283 92.9844 70.3252 90.8813 70.3252 88.2969C70.3252 85.7125 72.4283 83.6094 75.0127 83.6094C77.5971 83.6094 79.7002 85.7125 79.7002 88.2969C79.7002 90.8813 77.5971 92.9844 75.0127 92.9844ZM75.0127 86.7344C74.1502 86.7344 73.4502 87.4359 73.4502 88.2969C73.4502 89.1609 74.1502 89.8594 75.0127 89.8594C75.8752 89.8594 76.5752 89.1609 76.5752 88.2969C76.5752 87.4359 75.8752 86.7344 75.0127 86.7344Z" fill="#1EF14F" />
    <path fillRule="evenodd" clipRule="evenodd" d="M60.0659 20.803C60.5889 21.2536 60.6476 22.0429 60.197 22.5659L49.4278 35.0659C48.9839 35.5811 48.2096 35.6469 47.6851 35.214L40.9543 29.6585C40.4219 29.219 40.3465 28.4312 40.786 27.8988C41.2255 27.3663 42.0133 27.291 42.5457 27.7304L48.3326 32.5069L58.303 20.9341C58.7536 20.4111 59.5428 20.3524 60.0659 20.803Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M57.9242 20.6078C58.555 19.8756 59.66 19.7934 60.3922 20.4242C61.1245 21.0551 61.2066 22.16 60.5758 22.8923L49.8066 35.3922C49.1852 36.1135 48.1011 36.2057 47.3668 35.5996L40.6361 30.0441C39.8907 29.4288 39.7852 28.3258 40.4004 27.5805C41.0156 26.8351 42.1186 26.7296 42.864 27.3448L48.2733 31.8096L57.9242 20.6078ZM59.7395 21.1818C59.4257 20.9115 58.9521 20.9467 58.6818 21.2605L48.7114 32.8332C48.5339 33.0393 48.2241 33.0657 48.0143 32.8925L42.2275 28.116C41.908 27.8524 41.4353 27.8976 41.1716 28.217C40.908 28.5365 40.9532 29.0092 41.2726 29.2729L48.0034 34.8284C48.3181 35.0881 48.7827 35.0487 49.049 34.7395L59.8182 22.2396C60.0886 21.9257 60.0533 21.4522 59.7395 21.1818Z" fill="white" />
  </svg>
)
