import ResupplyRules from '../../server/resupplyRules';
import { addLoading, removeLoading } from './loading';

export const setResupplyRules = (rules) => ({
  type: 'SET_RESUPPLY_RULES',
  rules,
});

export const updateRule = (rules) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ResupplyRules.updateRule(rules);
  } finally {
    dispatch(removeLoading());
  }
};

export const getResupplyRules = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    const rules = await ResupplyRules.getRules();
    return rules;
  } finally {
    dispatch(removeLoading());
  }
};

export const createRulesResupply = (rules) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ResupplyRules.createRulesResupply(rules);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteRule = (ruleId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await ResupplyRules.deleteRule(ruleId);
  } finally {
    dispatch(removeLoading());
  }
};
