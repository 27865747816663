import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from './../../../../components/form/button';
import { Panel, PanelHeader, PanelBody, PanelFooter } from './../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createPriority, updatePriority, getPriority
} from '../../../../app/store/actions/priorities';

class PriorityForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      afterBilling: false,
      considerDate: false,
      storeBilling: false,
      disabled: false,
      ignoreCutPlan: false,
      withdraw: false,
      sticksRow: false,
      note: '',
      disableCode: true,
      disableName: true,
      disableAfterBilling: true,
      disableConsiderDate: true,
      disableStoreBilling: true,
      disableDisabled: true,
      disableIgnoreCutPlan: true,
      disableWithdraw: true,
      disableSticksRow: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getPrioritySelected(false);
    }
  }

  getPrioritySelected = async () => {
    if (this.props.location.state.priority) {
      const { priority } = this.props.location.state;
      const prioritySelected = await priority;

      if (prioritySelected) {
        this.setState({
          prioritySelected,
          priorityId: prioritySelected.id,
          code: prioritySelected.code,
          name: prioritySelected.name,
          afterBilling: prioritySelected.afterBilling,
          considerDate: prioritySelected.considerDate,
          storeBilling: prioritySelected.storeBilling,
          disabled: prioritySelected.disabled,
          ignoreCutPlan: prioritySelected.ignoreCutPlan,
          withdraw: prioritySelected.withdraw,
          sticksRow: prioritySelected.sticksRow,
          note: prioritySelected.note,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableAfterBilling: false,
        disableConsiderDate: false,
        disableStoreBilling: false,
        disableDisabled: false,
        disableIgnoreCutPlan: false,
        disableWithdraw: false,
        disableSticksRow: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableAfterBilling: false,
        disableConsiderDate: false,
        disableStoreBilling: false,
        disableDisabled: false,
        disableIgnoreCutPlan: false,
        disableWithdraw: false,
        disableSticksRow: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  )

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <div className="row" style={{ marginTop: '8px', marginLeft: '0px' }}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={e => this.handleInputChange(e)}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}></label>
          </div>
        </div>
      </div>
    </div>
  );

  handleInputChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.type === 'radio' ? parseInt(target.value) : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  createPrioritySubmit = async () => {
    const {
      code, name, afterBilling, considerDate, storeBilling, disabled, ignoreCutPlan,
      withdraw, sticksRow, note,
    } = this.state;

    try {
      const newPriority = await this.props.createPriority({
        code,
        name,
        afterBilling, 
        considerDate,
        storeBilling, 
        disabled,
        ignoreCutPlan,
        withdraw, 
        sticksRow, 
        note
      });

      if (newPriority) {
        await addNotification(
          'success', I18n.t('BEE626' /* Prioridades */), I18n.t('BEE650', { 0: code } /* Prioridade %{0} incluída com sucesso! */), 'top-right'
        );

        this.props.history.push(ROUTES.PRIORITY_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE626' /* Prioridades */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE626' /* Prioridades */), I18n.t('BEE651', { 0: code } /* Erro ao incluir a prioridade %{0} */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE626' /* Prioridades */), I18n.t('BEE651', { 0: code } /* Erro ao incluir a prioridade %{0} */), 'top-right'
        );
      }
    }
  };

  updatePrioritySubmit = async () => {
    const {
      code, name, afterBilling, considerDate, storeBilling, disabled, ignoreCutPlan,
      withdraw, sticksRow, note, priorityId
    } = this.state;

    try {
      const updPriority = await this.props.updatePriority({
        priorityId,
        name,
        afterBilling, 
        considerDate,
        storeBilling, 
        disabled,
        ignoreCutPlan,
        withdraw, 
        sticksRow, 
        note
      });

      if (updPriority) {
        await addNotification('success', I18n.t('BEE652' /* Atualizar Prioridade */), I18n.t('BEE653', { 0: code } /* Prioridade %{0} atualizada com sucesso! */), 'top-right');

        this.props.history.push(ROUTES.PRIORITY_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE652' /* Atualizar Prioridade */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE652' /* Atualizar Prioridade */), I18n.t('BEE654', { 0: code } /* Erro ao atualizar a prioridade %{0} */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE652' /* Atualizar Prioridade */), I18n.t('BEE654', { 0: code } /* Erro ao atualizar a prioridade %{0} */), 'top-right'
        );
      }
    }
  };

  render() {
    const {
      code, name, afterBilling, considerDate, storeBilling, disabled, ignoreCutPlan,
      withdraw, sticksRow, note
    } = this.state;

    const {
      mode, disableCode, disableName, disableAfterBilling, disableConsiderDate,
      disableStoreBilling, disableDisabled, disableIgnoreCutPlan, disableWithdraw,
      disableSticksRow, disableNote,showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';
    let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE644' /* Nova Prioridadde */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE645' /* Editar Prioridade */);
      breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE646' /* Detalhes Prioridade */);
      breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE626' /* Prioridades */)}</li>
            <li className="breadcrumb-item active">{breadActive}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton={true} />
                <PanelBody>
                  {this.createInput(
                    code, 'code', `${I18n.t('BEE82' /* Código */)}:`, I18n.t('BEE647' /* Informe a prioridade (obrigatório) */), 'text', true, disableCode,
                  )}
                  {this.createInput(
                    name, 'name', `${I18n.t('BEE83' /* Nome */)}:`, I18n.t('BEE648' /* Informe o nome da prioridade (obrigatório) */), 'text', true, disableName,
                  )}
                  {this.createToggleBox(
                    afterBilling, 'afterBilling', `${I18n.t('BEE628' /* Faturamento Posterior */)}:`, disableAfterBilling)
                  }
                  {this.createToggleBox(
                    considerDate, 'considerDate', `${I18n.t('BEE629' /* Considerar Data */)}:`, disableConsiderDate
                  )}
                  {this.createToggleBox(
                    storeBilling, 'storeBilling', `${I18n.t('BEE630' /* Faturamento Loja */)}:`, disableStoreBilling
                  )}
                  {this.createToggleBox(
                    disabled, 'disabled', `${I18n.t('BEE631' /* Inativa */)}:`, disableDisabled
                  )}
                  {this.createToggleBox(
                    ignoreCutPlan, 'ignoreCutPlan', `${I18n.t('BEE632' /* Ignora Plano de Corte */)}:`, disableIgnoreCutPlan
                  )}
                  {this.createToggleBox(
                    withdraw, 'withdraw', `${I18n.t('BEE633' /* Retira */)}:`, disableWithdraw
                  )}
                  {this.createToggleBox(
                    sticksRow, 'sticksRow', `${I18n.t('BEE649' /* Fura fila */)}:`, disableSticksRow
                  )}
                  {this.createInput(
                    note, 'note', `${I18n.t('BEE135' /* Observação */)}:`, I18n.t('BEE136' /* Informe a observação */), 'text', false, disableNote,
                  )}
                </PanelBody>
                <PanelFooter>
                  <Link to={{pathname: ROUTES.PRIORITY_LIST, state: {dice: this.props.location.dice}}}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createPrioritySubmit()}
                    >{I18n.t('BEE138' /* Criar */)}</OneClickButton>
                  )}
                  {(showUpdate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updatePrioritySubmit()}
                    >{I18n.t('BEE139' /* Atualizar */)}</OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getPriority: depositId => dispatch(getPriority(depositId)),
  createPriority: deposit => dispatch(createPriority(deposit)),
  updatePriority: deposit => dispatch(updatePriority(deposit)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PriorityForm));