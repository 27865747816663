import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';
import Format from '../../../../helpers/format';

import ROUTES from '../../../../config/routes';

import { getPackagesList, deletePackage } from '../../../../app/store/actions/packages';

class PackageList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      packageUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false
      }
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: rows => {
          return (
            <div style={{ textAlign: 'center' }}>
              <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
              <UncontrolledButtonDropdown>
                <DropdownToggle color="default" caret className="btn-xs"></DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => this.showPackageDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                  <DropdownItem onClick={() => this.showEditPackage(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                  <DropdownItem onClick={() => this.showCopyPackage(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                  <DropdownItem onClick={() => this.showDeletePackage(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>
          );
        }
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250
      }, {
        Header: `${I18n.t('BEE579' /* Peso */)} (${I18n.t('BEE2327' /* G */)})`,
        accessor: 'weight',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: row => {
          return (
            <div style={{ textAlign: 'center' }}>
              <span>{Format.formatNumber(row.value, 0, false)}</span>
            </div>
          )
        }
      }, {
        Header: `${I18n.t('BEE386' /* Altura */)} (${I18n.t('BEE2325' /* cm */)})`,
        accessor: 'height',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: row => {
          return (
            <div style={{ textAlign: 'center' }}>
              <span>{Format.formatNumber(row.value, 0, false)}</span>
            </div>
          )
        }
      }, {
        Header: `${I18n.t('BEE387' /* Largura */)}  (${I18n.t('BEE2325' /* cm */)})`,
        accessor: 'width',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: row => {
          return (
            <div style={{ textAlign: 'center' }}>
              <span>{Format.formatNumber(row.value, 0, false)}</span>
            </div>
          )
        }
      }, {
        Header: `${I18n.t('BEE388' /* Comprimento */)} (${I18n.t('BEE2325' /* cm */)})`,
        accessor: 'length',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: row => {
          return (
            <div style={{ textAlign: 'center' }}>
              <span>{Format.formatNumber(row.value, 0, false)}</span>
            </div>
          )
        }
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true
      });
      await this.getPackages();
    } else {
      await this.getPackages();
    }
  }

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      packageUpdate: null,
    });
  }

  getPackages = async () => {
    await this.props.getPackagesList();
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  deletePackage = async (delPck) => {
    const result = await this.props.deletePackage(delPck._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'danger', I18n.t('BEE849' /* Eliminar embalagem */),I18n.t('BEE850' /* Embalagem eliminada com sucesso ! */), 'top-right'
      );
    }
    await this.getPackages();
  }

  showPackageDetail = (pckg) => {
    this.props.history.push({
      pathname: ROUTES.PACKAGE_DETAIL,
      state: {
        package: pckg._original,
      },
      dice: {
        page: this.state.page,
      }
    });
  }

  showEditPackage = (pckg) => {
    this.props.history.push({
      pathname: ROUTES.PACKAGE_EDIT,
      state: {
        package: pckg._original,
      }, 
      dice: {
        page: this.state.page,
      }
    });
  }

  showCopyPackage = (pckg) => {
    this.props.history.push({
      pathname: ROUTES.PACKAGE_COPY,
      state: {
        package: pckg._original,
      }
    });
  }

  showDeletePackage = (pckg) => {
    this.setState({ showModalDelete: true, packageUpdate: pckg });
  }

  
  render() {
    const { packageUpdate, showModalDelete, page,} = this.state;
    const { packagesList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE28' /* Embalagens */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE28' /* Embalagens */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.PACKAGE_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1"></i> {I18n.t('BEE851' /* Incluir embalagem */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getPackages
          }}
          filterable
          data={packagesList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) ? page : (!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page}
          onPageChange={(page) =>  ((page >= 0) ? this.setState({page}) : this.setState({page: 0}))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete &&
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deletePackage(packageUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE852', {0:packageUpdate.code} /*   A embalagem %{0} será eliminada ! */)}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  packagesList: state.packages && state.packages.packagesList,
});

const mapDispatchToProps = dispatch => ({
  getPackagesList: () => dispatch(getPackagesList()),
  deletePackage: (packageId) => dispatch(deletePackage(packageId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PackageList));