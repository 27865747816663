import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import InputMask from 'react-input-mask';

import { Panel, PanelHeader, PanelBody, PanelFooter } from '../../../../components/panel/panel';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createDepartment, updateDepartment, getDepartment, deleteDepartment
} from '../../../../app/store/actions/departments';

class DepartmentForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      note: '',
      disableCode: true,
      disableName: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getDepartmentSelected();
    }
  }

  getDepartmentSelected = async () => {
    if (this.props.location.state.department) {
      const { department } = this.props.location.state;

      const departmentSelected = await this.props.getDepartment(department.id);

      if (departmentSelected) {
        this.setState({
          departmentSelected,
          departmentId: departmentSelected.id,
          code: departmentSelected.code,
          name: departmentSelected.name,
          note: departmentSelected.note,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          required={required}
          disabled={disabled}
        ></InputMask>
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  createDepartmentSubmit = async () => {
    const {code, name, note } = this.state;

    try {
      const newDepartment = await this.props.createDepartment({
        code,
        name,
        note,
      });

      if (newDepartment) {
        if (newDepartment.success && newDepartment.success === false) {
          await addNotification(
            'danger', I18n.t('BEE797' /* Novo Departamento */), I18n.t('BEE799', { 0: code } /* Erro ao incluir o Departamento %{0}! */), 'top-right'
          );
        } else {
          await addNotification(
            'success', I18n.t('BEE797' /* Novo Departamento */), I18n.t('BEE800', { 0: code } /* Novo Departamento %{0} incluído com sucesso! */), 'top-right'
          );

          this.props.history.push(ROUTES.DEPARTMENT_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE797' /* Novo Departamento */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE797' /* Novo Departamento */), I18n.t('BEE799', { 0: code } /* Erro ao incluir o Departamento %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE797' /* Novo Departamento */), I18n.t('BEE799', { 0: code } /* Erro ao incluir o Departamento %{0}! */), 'top-right'
        );
      }
    }
  };

  updateDepartmentSubmit = async () => {
    const {
      name, departmentId, note, 
    } = this.state;

    try {
      const updDepartment = await this.props.updateDepartment({
        departmentId,
        name,
        note,
      });

      if (updDepartment) {
        if (updDepartment.success && updDepartment.success === false) {
          await addNotification(
            'danger', I18n.t('BEE802' /* Atualizar Departamento */), I18n.t('BEE801', { 0: name } /* Erro ao atualizar o Departamento %{0}! */), 'top-right'
          );
        } else {
          await addNotification('success', I18n.t('BEE802' /* Atualizar Departamento */), I18n.t('BEE937', { 0: name } /* Atualização do Departamento %{0} efetuado com sucesso! */), 'top-right');

          this.props.history.push(ROUTES.DEPARTMENT_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE802' /* Atualizar Departamento */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE802' /* Atualizar Departamento */), I18n.t('BEE801', { 0: name } /* Erro ao atualizar o Departamento %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE802' /* Atualizar Departamento */), I18n.t('BEE801', { 0: name } /* Erro ao atualizar o Departamento %{0}! */), 'top-right'
        );
      }
      await addNotification('danger', I18n.t('BEE802' /* Atualizar Departamento */), I18n.t('BEE801', { 0: name } /* Erro ao atualizar o Departamento %{0}! */), 'top-right');
    }
  };

  render() {
    const { code, name, note } = this.state;

    const {
      mode, disableCode, disableName, disableNote, showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';
//    let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE797' /* Novo Departamento */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE796' /* Editar Departamento */);
  //    breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE798' /* Detalhes Departamento */);
    //  breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE25' /* Usuários */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE731' /* Departamentos */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton={true} />
                <PanelBody>
                  {this.createInput(
                    code, 'code', `${I18n.t('BEE82' /* Código */)}:`, I18n.t('BEE794' /* Informe o código do departamento (obrigatório) */), 'text', true, disableCode,
                  )}
                  {this.createInput(
                    name, 'name', `${I18n.t('BEE83' /* Nome */)}:`, I18n.t('BEE795' /* Nome do Departamento */), 'text', false, disableName,
                  )}
                  {this.createInput(
                    note, 'note', `${I18n.t('BEE135' /* Observação */)}:`, I18n.t('BEE136' /* Informe a observação */), 'text', false, disableNote,
                  )}
                </PanelBody>
                <PanelFooter>
                  <Link to={{pathname: ROUTES.DEPARTMENT_LIST, state: {dice: this.props.location.dice}}}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createDepartmentSubmit()}
                    >{I18n.t('BEE138' /* Criar */)}</OneClickButton>
                  )}
                  {(showUpdate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateDepartmentSubmit()}
                    >{I18n.t('BEE139' /* Atualizar */)}</OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getDepartment: departmentId => dispatch(getDepartment(departmentId)),
  createDepartment: department => dispatch(createDepartment(department)),
  updateDepartment: department => dispatch(updateDepartment(department)),
  deleteDepartment: departmentId => dispatch(deleteDepartment(departmentId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DepartmentForm));
