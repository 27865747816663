/* eslint-disable eqeqeq */
import SettingsService from '../app/server/settings';

const serverUrls = {
  localhost: 'http://localhost:3333/',
  'localhost:3000': 'http://localhost:3333/', // DESENV LOCAL
  '192.168.50.121:8380': 'http://192.168.50.119:8380/', // HOMOLOG DIMENSIONAL
  '192.168.50.121:8480': 'http://192.168.50.119:8480/', // HOMOLOG NORTEL
  '192.168.50.121:8381': 'http://192.168.50.119:8381/', // DESENV DIMENSIONAL
  '192.168.50.121:8481': 'http://192.168.50.119:8481/', // DESENV NORTEL
  '192.168.50.124:8380': 'http://192.168.50.123:8380/', // DIMENSIONAL PRODUÇÃO
  '192.168.50.127:8480': 'http://192.168.50.126:8480/', // NORTEL PRODUÇÃO
};

const serverNames = {
  'localhost:3000': 'Localhost',
  '192.168.50.121:8380': 'Homologação Dimensional',
  '192.168.50.121:8480': 'Homologação Nortel',
  '192.168.50.121:8381': 'Desenvolvimento Dimensional',
  '192.168.50.121:8481': 'Desenvolvimento Nortel',
  '192.168.50.124:8380': 'Produção Dimensional',
  '192.168.50.127:8480': 'Produção Nortel',
};

const getBaseUrl = () => {
  const { host, protocol } = window.location;

  // DOCKER
  if (process.env.REACT_APP_BEESTOCK_DOCKER_CONTAINER == '1') return `${protocol}//${host}/api/`;

  const url = serverUrls[host];
  if (url) {
    return url;
  }
  return serverUrls.localhost;
};

const getDisplayName = async () => {
  const enviroment = await SettingsService.getSettingsBeehost();

  const { host } = window.location;

  const displayName = serverNames[host];

  if (enviroment) {
    return enviroment;
  }

  return displayName;
};

export default {
  serverUrls,
  getBaseUrl,
  getDisplayName,
};
