import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';

import AllocationTable from './allocationTable';
import BalanceTable from './balanceTable';
import OrderTable from './orderTable';
import ProductTable from './productTable';

import {
  changeAllocationProduct, createCutCablePlan,
  getProductsToCableCutPlan,
} from '../../../app/store/actions/outboundCableCut';

import Form from '../../../components/form/form';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';

import { returnFiltersByRules } from '../../reports/utils';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class CableCutPlanForm extends React.Component {
  constructor(props) {
    super(props);

    this.tableColumns = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'outboundOrder.branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'outboundProduct.productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'outboundOrder.customer.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'outboundOrder.orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1808' /* Pedido Cliente */),
        accessor: 'outboundOrder.customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
      }, {
        Header: I18n.t('BEE444' /* Data de Entrega */),
        accessor: 'outboundOrder.deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('L')}
          </span>
        ),
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>{parseFloat(row.value)}</span>
        ),
      },
    ];

    this.state = {
      productCodeFrom: '',
      productCodeTo: '',
      orderNumberFrom: '',
      orderNumberTo: '',
      customerCodeFrom: '',
      customerCodeTo: '',
      priorityCodeFrom: '',
      priorityCodeTo: '',
      checkedOrders: [],
      totalSelected: 0,
      balancesList: [],
      balanceSelect: null,
      productToCutSelect: null,
      outboundOrderList: [],
      outboundOrderProductSelect: null,
      allocationProductList: [],
      showFilterModal: false,
      showAllocationsModal: false,
      showConfirmCutPlan: false,
      showConfirmAllocation: false,
    };
  }

  componentDidMount() {
    this.getProductsToCut();
  }

  getProductsToCut = async () => {
    const {
      productCodeFrom, productCodeTo, orderNumberFrom, orderNumberTo, customerCodeFrom, customerCodeTo,
      priorityCodeFrom, priorityCodeTo,
    } = this.state;

    this.setState({
      showFilterModal: false,
      balancesList: [],
      balanceSelect: null,
      productToCutSelect: null,
      outboundOrderList: [],
      outboundOrderProductSelect: null,
      allocationProductList: [],
      totalSelected: 0,
    });

    const filters = returnFiltersByRules([
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['orderNumber', { type: 'between', value: [orderNumberFrom, orderNumberTo] }],
      ['customerCode', { type: 'between', value: [customerCodeFrom, customerCodeTo] }],
      ['priorityCode', { type: 'between', value: [priorityCodeFrom, priorityCodeTo] }],
    ]);

    await this.props.getProductsToCableCutPlan(filters);
  };

  setBalanceSelect = (balanceSelect) => {
    this.setState({
      balanceSelect,
    });
  };

  setOutboundOrderProductSelect = (outboundOrderProductSelect) => {
    this.setState({
      outboundOrderProductSelect,
      allocationProductList: outboundOrderProductSelect.allocations,
    });
  };

  setProductToCut = (productToCutSelect) => {
    this.setState({
      productToCutSelect,
      outboundOrderList: productToCutSelect.orders,
      balancesList: productToCutSelect.balances,
      balanceSelect: null,
      outboundOrderProductSelect: null,
      allocationProductList: [],
      checkedOrders: [],
      totalSelected: 0,
    });
  };

  setAllocationSelect = (allocationProductSelect) => {
    this.setState({
      allocationProductSelect,
    });
  };

  showFilters = () => {
    this.setState({
      showFilterModal: true,
    });
  };

  closeFilters = () => {
    this.setState({
      showFilterModal: false,
    });
  };

  showAllocations = async () => {
    const { productToCutSelect } = this.state;
    if (productToCutSelect) {
      this.setState({
        showAllocationsModal: true,
      });
    }
  };

  closeAllocations = () => {
    this.setState({
      showAllocationsModal: false,
    });
  };

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  createAllocationModal = () => {
    const { productToCutSelect } = this.state;

    const allocations = productToCutSelect ? productToCutSelect.allocations : [];

    return (
      <Modal size="lg" isOpen={this.state.showAllocationsModal} toggle={() => this.closeAllocations()}>
        <ModalHeader toggle={() => this.closeAllocations()} className="text-center">
          {I18n.t('BEE1432' /* Alocações */).toUpperCase()}
        </ModalHeader>
        <ModalBody>
          <Table
            panelHeaderProps={{ noButton: true }}
            filterable
            data={allocations}
            columns={this.tableColumns}
            defaultPageSize={10}
            defaultSorted={this.defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
          />
        </ModalBody>
      </Modal>
    );
  };

  createFilterModal = () => {
    const {
      productCodeFrom, productCodeTo, orderNumberFrom, orderNumberTo, customerCodeFrom, customerCodeTo,
      priorityCodeFrom, priorityCodeTo,
    } = this.state;

    return (
      <Modal size="lg" isOpen={this.state.showFilterModal} toggle={() => this.closeFilters()}>
        <ModalHeader toggle={() => this.closeFilters()}>
          <span className="text-center">
            {I18n.t('BEE546' /* Filtros */).toUpperCase()}
          </span>
        </ModalHeader>
        <ModalBody>
          <Form
            noHeader
            leftType
            headerProps={{
              className: 'm-b-0',
            }}
            setValue={this.setValue}
            inputs={(formContext) => ([
              formContext.createInputRange(
                { from: productCodeFrom, to: productCodeTo },
                { from: 'productCodeFrom', to: 'productCodeTo' },
                'Produto:',
                { from: '', to: 'Z'.repeat(20) },
              ),
              formContext.createInputRange(
                { from: orderNumberFrom, to: orderNumberTo },
                { from: 'orderNumberFrom', to: 'orderNumberTo' },
                'Documento:',
                { from: '', to: 'Z'.repeat(20) },
              ),
              formContext.createInputRange(
                { from: customerCodeFrom, to: customerCodeTo },
                { from: 'customerCodeFrom', to: 'customerCodeTo' },
                'Cliente:',
                { from: '', to: 'Z'.repeat(20) },
              ),
              formContext.createInputRange(
                { from: priorityCodeFrom, to: priorityCodeTo },
                { from: 'priorityCodeFrom', to: 'priorityCodeTo' },
                'Prioridade:',
                { from: '', to: 'Z'.repeat(20) },
              ),
            ])}
            footerContent={(
              <>
                <button
                  type="button"
                  className="btn btn-120 btn-white p-5 m-5"
                  onClick={this.closeFilters}
                >
                  {I18n.t('BEE99' /* Cancelar */)}
                </button>
                <button
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={this.getProductsToCut}
                >
                  {I18n.t('BEE407' /* Buscar */)}
                </button>
              </>
            )}
          />
        </ModalBody>
      </Modal>
    );
  };

  createCutCablePlan = () => {
    const { checkedOrders } = this.state;

    if (!checkedOrders || checkedOrders.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE1417' /* Plano de Corte de Cabos */),
        'Nenhum Documento selecionado para geração dos Planos de Corte!',
        'top-right',
      );
    } else {
      this.setState({
        showConfirmCutPlan: true,
      });
    }
  };

  changeProductAllocation = () => {
    const { balanceSelect, checkedOrders } = this.state;

    const outboundOrderProductIds = [];

    checkedOrders.forEach((value, index) => {
      if (value) outboundOrderProductIds.push(index);
    });

    if (outboundOrderProductIds.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE1417' /* Plano de Corte de Cabos */),
        'Nenhum Documento selecionado!',
        'top-right',
      );
    } else if (!balanceSelect) {
      addNotification(
        'danger',
        I18n.t('BEE1417' /* Plano de Corte de Cabos */),
        'Nenhum Saldo Estoque selecionado!',
        'top-right',
      );
    } else {
      this.setState({
        showConfirmAllocation: true,
      });
    }
  };

  changeProductAllocationSubmit = async () => {
    const { balanceSelect, checkedOrders } = this.state;

    try {
      this.setState({
        showConfirmAllocation: false,
      });

      const outboundOrderProductIds = [];

      checkedOrders.forEach((value, index) => {
        if (value) outboundOrderProductIds.push(index);
      });

      const result = await this.props.changeAllocationProduct(outboundOrderProductIds, balanceSelect.id);

      if (result && result.success === false) {
        await addNotification(
          'danger',
          I18n.t('BEE1417' /* Plano de Corte de Cabos */),
          'Erro ao tentar efetivar a troca de alocação!',
          'top-right',
        );
      } else {
        await addNotification(
          'success',
          I18n.t('BEE1417' /* Plano de Corte de Cabos */),
          'Troca de Alocação e geração do Plano de Corte efetuada com sucesso!',
          'top-right',
        );

        this.getProductsToCut();
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE1417' /* Plano de Corte de Cabos */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE1417' /* Plano de Corte de Cabos */),
            'Erro ao tentar efetivar a troca de alocação!',
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE1417' /* Plano de Corte de Cabos */),
          'Erro ao tentar efetivar a troca de alocação!',
          'top-right',
        );
      }
    }
  };

  createCutCablePlanSubmit = async () => {
    const { checkedOrders } = this.state;

    try {
      this.setState({
        showConfirmCutPlan: false,
      });

      const outboundOrderProductIds = [];

      checkedOrders.forEach((value, index) => {
        if (value) outboundOrderProductIds.push(index);
      });

      if (outboundOrderProductIds.length > 0) {
        const result = await this.props.createCutCablePlan(outboundOrderProductIds);

        if (result && result.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE1417' /* Plano de Corte de Cabos */),
            'Erro ao gerar Plano de Corte!',
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE1417' /* Plano de Corte de Cabos */),
            'Plano de Corte gerado com sucesso!',
            'top-right',
          );

          this.getProductsToCut();
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE1417' /* Plano de Corte de Cabos */),
          'Nenhum Aloção selecionada!',
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE1417' /* Plano de Corte de Cabos */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE1417' /* Plano de Corte de Cabos */),
            'Erro ao gerar Plano de Corte!',
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE1417' /* Plano de Corte de Cabos */),
          'Erro ao gerar Plano de Corte!',
          'top-right',
        );
      }
    }
  };

  render() {
    const { productsToCutCablePlanList = [] } = this.props;
    const { balanceSelect, balancesList } = this.state;

    const {
      productToCutSelect, outboundOrderList = [], outboundOrderProductSelect, totalSelected,
      allocationProductList = [], allocationProductSelect, checkedOrders, showConfirmCutPlan, showConfirmAllocation,
    } = this.state;

    const title = I18n.t('BEE1417' /* Plano de Corte de Cabos */);

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
            <li className="breadcrumb-item active">{title}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
          <WikiHelp wikiPath={ROUTES.OUTBOUND_CABLE_CUT_PLAN_HELP} />
        </div>
        <div className="d-sm-flex align-items-center mb-3">
          <button
            type="button"
            className="btn btn-inverse mr-2 text-truncate"
            onClick={() => this.showFilters()}
          >
            <i className="fa fa-filter fa-fw text-white-transparent-5 ml-n1" />
            {I18n.t('BEE546' /* Filtros */)}
          </button>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => this.getProductsToCut()}
          >
            <i className="fa fa-sync" />
          </button>
        </div>
        <div className="row">
          <div className="col-xl-6">
            <ProductTable
              productsToCutList={productsToCutCablePlanList}
              productToCutSelect={productToCutSelect}
              setProductToCut={this.setProductToCut}
            />
          </div>
          <div className="col-xl-6">
            <OrderTable
              productToCutSelect={productToCutSelect}
              outboundOrderList={outboundOrderList}
              setOutboundOrderProductSelect={this.setOutboundOrderProductSelect}
              outboundOrderProductSelect={outboundOrderProductSelect}
              checked={checkedOrders}
              setValue={this.setValue}
              totalSelected={totalSelected}
            />
            <AllocationTable
              productToCutSelect={productToCutSelect}
              allocationProductList={allocationProductList}
              setAllocationSelect={this.setAllocationSelect}
              allocationProductSelect={allocationProductSelect}
            />
            <BalanceTable
              balanceSelect={balanceSelect}
              setBalanceSelect={this.setBalanceSelect}
              balancesList={balancesList}
              productToCutSelect={productToCutSelect}
              createCutCablePlan={this.createCutCablePlan}
              showAllocationsModal={this.showAllocations}
              changeProductAllocation={this.changeProductAllocation}
              totalSelected={totalSelected}
              checkedOrders={checkedOrders}
              outboundOrderList={outboundOrderList}
            />
          </div>
        </div>
        {this.createFilterModal()}
        {this.createAllocationModal()}
        {(showConfirmCutPlan
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.createCutCablePlanSubmit()}
              onCancel={() => this.setState({
                showConfirmCutPlan: false,
              })}
            >
              {I18n.t('BEE3199' /* Será efetuada a geração dos Planos de Corte para os Documentos selecionados */)}
            </SweetAlert>
          )
        )}
        {(showConfirmAllocation
          && (
            <SweetAlert
              warning
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="warning"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.changeProductAllocationSubmit()}
              onCancel={() => this.setState({
                showConfirmAllocation: false,
              })}
            >
              {I18n.t(
                'BEE3200',
                /* Serão efetuadas as trocas das alocações selecionadas e a geração do Plano de Corte! */
              )}
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productsToCutCablePlanList: state.cableCutPlan && state.cableCutPlan.productsToCutCablePlanList,
});

const mapDispatchToProps = (dispatch) => ({
  getProductsToCableCutPlan: (filters) => dispatch(getProductsToCableCutPlan(filters)),
  createCutCablePlan: (outboundOrderProductIds) => dispatch(createCutCablePlan(outboundOrderProductIds)),
  changeAllocationProduct: (
    outboundOrderProductIds,
    stockBalaceId,
  ) => dispatch(changeAllocationProduct(outboundOrderProductIds, stockBalaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CableCutPlanForm));
