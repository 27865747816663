/* eslint-disable max-len */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import Table from '../../../components/table/Table';
import addNotification from '../../../components/notification';
import InventoryHelpers from '../../../helpers/inventory';
import {
  getInventoryList, setUpdateStatusInventory,
  getAllInventorySheets,
} from '../../../app/store/actions/inventory';
import InventorySheetTable from './inventorySheetTable';
import UserConfirmationModal from '../../../components/pages/userConfirmationModal';
import ROUTES from '../../../config/routes';

class InventoryMonitorList extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE572' /* Inventário */) },
      { value: I18n.t('BEE2136' /* Monitor de Inventário */), active: true },
    ];

    this.state = {
      inventoryDataList: [],
      defaultPageSize: 5,
      showUserModal: false,
      showMessageCancel: false,
      cancelNote: '',
      listSheets: [],
      selectedInventory: {},
      defaultSorted: [{ id: 'orderNumber', desc: false }],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    };

    this.tableColumns = [{
      Header: I18n.t('BEE55' /* Ações */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      width: 100,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
          <UncontrolledButtonDropdown>
            <DropdownToggle color="default" caret className="btn-xs" />
            <DropdownMenu>
              {(row.original.status < 5)
                && (
                <DropdownItem onClick={() => this.setState({ showUserModal: true })}>
                  {I18n.t('BEE99' /* Cancelar */)}
                </DropdownItem>
                )}
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        </div>
      ),
    }, {
      Header: I18n.t('BEE1516' /* Agrupador */),
      accessor: 'grouperCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
    }, {
      Header: I18n.t('BEE145' /* Filial */),
      accessor: 'branchCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
    }, {
      Header: I18n.t('BEE182' /* Depósito */),
      accessor: 'depositCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
    }, {
      Header: I18n.t('BEE200' /* Tipo */),
      accessor: 'inventoryType',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
      filterMethod: (filter, row) => {
        if (parseInt(filter.value, 10) === parseInt(row.inventoryType, 10)) {
          return true;
        }
      },
      Cell: (row) => (
        <span>{InventoryHelpers.inventoryType(row.value)}</span>
      ),
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
          {InventoryHelpers.inventoryType().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          ))}
        </select>
      ),
    }, {
      Header: I18n.t('BEE204' /* Situação */),
      accessor: 'status',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      filterMethod: (filter, row) => {
        if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
          return true;
        }
      },
      Cell: (row) => (
        <span>{InventoryHelpers.inventoryStatus(row.value)}</span>
      ),
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
          {InventoryHelpers.inventoryStatus().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          ))}
        </select>
      ),
    }, {
      Header: I18n.t('BEE2113' /* Data Inventário */),
      accessor: 'inventoryAt',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
      filterMethod: (filter, row) => {
        const input = _.lowerCase(filter.value);
        const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
        const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
        if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
          return true;
        }
      },
      Cell: (row) => (
        <span>
          {' '}
          {row.value ? moment(row.value).format('L') : ''}
        </span>
      ),
    }, {
      Header: I18n.t('BEE355' /* Curva */),
      accessor: 'curve',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 80,
    }];
  }

  componentDidMount() {
    this.inventoryList();
  }

  checkSelected = async () => {
    const { inventoryDataList, selectedInventory } = this.state;

    if (!inventoryDataList.length) {
      this.setState({
        listSheets: [],
        selectedInventory: {},
      });
    } else if (selectedInventory && selectedInventory.inventoryId) {
      const checkSelected = inventoryDataList.find(
        (element) => (element.inventoryId === selectedInventory.inventoryId),
      );
      if (!checkSelected) {
        this.setState({
          listSheets: [],
          selectedInventory: {},
        });
      } else {
        this.getAllInventorySheets();
      }
    }
  };

  inventoryList = async () => {
    const { startDate, endDate } = this.state;
    try {
      const inventoryDataList = await this.props.getInventoryList(
        moment(startDate).format(),
        moment(endDate).format(),
      );
      if (inventoryDataList.length) this.setState({ inventoryDataList: [...inventoryDataList] });
      else this.setState({ inventoryDataList: [] });
    } catch (err) {
      this.setState({ inventoryDataList: [] });
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2136' /* Monitor de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2136' /* Monitor de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
    this.checkSelected();
  };

  getAllInventorySheets = async () => {
    const { selectedInventory } = this.state;
    try {
      const inventorySheetsList = await this.props.getAllInventorySheets(selectedInventory.inventoryId);
      if (inventorySheetsList.length) this.setState({ listSheets: [...inventorySheetsList] });
      else this.setState({ listSheets: [] });
    } catch (err) {
      this.setState({ listSheets: [] });
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2136' /* Monitor de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2136' /* Monitor de Inventário */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });
    this.inventoryList();
  };

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 mt-4">
      <label htmlFor={label}>{label}</label>
      <div className="col">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  userConfirmation = async (confirm) => {
    this.setState({ showUserModal: false });
    if (confirm) this.setState({ showMessageCancel: true });
  };

  selectLine = async (selectedInventory) => {
    await this.setState({ selectedInventory });
    this.getAllInventorySheets();
  };

  setUpdateStatusInventorySheet = async () => {
    const { selectedInventory, cancelNote } = this.state;
    this.setState({
      showMessageCancel: false,
      cancelNote: '',
    });
    try {
      const result = await this.props.setUpdateStatusInventory(selectedInventory.inventoryId, cancelNote);
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2136' /* Monitor de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2136' /* Monitor de Inventário */),
          I18n.t('BEE1789' /* Atualizado com sucesso! */),
          'top-right',
        );
        await this.inventoryList();
        await this.getAllInventorySheets();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2136' /* Monitor de Inventário */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2136' /* Monitor de Inventário */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      defaultPageSize, defaultSorted, inventoryDataList,
      selectedInventory, showMessageCancel, cancelNote,
    } = this.state;

    return (
      <>
        <Table
          customRangeMessage={I18n.t('BEE3342', { 0:(I18n.t('BEE3346' /* data do inventário */)) })}
          downloadCSV
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE2136' /* Monitor de Inventário */)}
          wikiHelp={ROUTES.MOVEMENT_INVENTORY_MONITOR_HELP}
          filterable
          data={inventoryDataList}
          columns={this.tableColumns}
          expander
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          panelHeaderProps={{
            onClickReload: this.inventoryList,
            children: I18n.t('BEE572' /* Inventário */).toUpperCase(),
          }}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          getTrProps={(state, rowInfo) => {
            if (rowInfo && rowInfo.row) {
              return {
                onClick: () => (this.selectLine(rowInfo.original)),
                style: {
                  background: selectedInventory
                  && rowInfo.original.inventoryId === selectedInventory.inventoryId ? 'silver' : 'white',
                  cursor: 'pointer',
                },
              };
            } return {};
          }}
        />
        <InventorySheetTable
          listSheets={this.state.listSheets}
        />
        <UserConfirmationModal
          openModal={this.state.showUserModal}
          confirm={(confirm) => this.userConfirmation(confirm)}
        />
        {(showMessageCancel
          && (
          <SweetAlert
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => ((cancelNote)
              ? this.setUpdateStatusInventorySheet()
              : addNotification(
                'danger',
                I18n.t('BEE2136' /* Monitor de Inventário */),
                I18n.t('BEE1977' /* O campo motivo e obrigatório */),
                'top-right',
              ))}
            onCancel={() => this.setState({ showMessageCancel: false, cancelNote: '' })}
          >
            <h5>
              {I18n.t('BEE2194' /* Esta ação não sera possível ser desfeita!Para prosseguir informe o motivo do cancelamento */)}
            </h5>
            {this.createTextArea(
              cancelNote,
              'cancelNote',
              I18n.t('BEE724' /* Motivo */),
              '',
              3,
              true,
              false,
            )}
          </SweetAlert>
          ))}
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getInventoryList: (filterStartDate, filterEndDate) => dispatch(getInventoryList(filterStartDate, filterEndDate)),
  setUpdateStatusInventory: (inventoryId, cancelNote) => dispatch(setUpdateStatusInventory(inventoryId, cancelNote)),
  getAllInventorySheets: (inventoryId) => dispatch(getAllInventorySheets(inventoryId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(InventoryMonitorList));
