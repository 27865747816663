import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import { deleteBranch, getBranchesList } from '../../../../app/store/actions/branches';
import Table from '../../../../components/table/Table';

import Permissions from '../../../../app/services/permissions';

class BranchList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      branchUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.draggableColumns = {
      mode: 'reorder',
      draggable: ['code', 'name', 'companyName', 'cnpj', 'email', 'city', 'state', 'country'],
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showBranchDetail(rows.row)}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showEditBranch(rows.row)}>
                  {I18n.t('BEE57' /* Editar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showCopyBranch(rows.row)}>
                  {I18n.t('BEE80' /* Copiar */)}
                </DropdownItem>
                {!Permissions.checkPermission(ROUTES.P_ACCESS_BRANCH_PARAMETERS) ? (
                  <DropdownItem onClick={() => this.showBranchSettings(rows.row)}>
                    {I18n.t('BEE439' /* Parâmetros */)}
                  </DropdownItem>
                ) : null}
                <DropdownItem onClick={() => this.showDeleteBranch(rows.row)}>
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE112' /* Empresa */),
        accessor: 'companyName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE85' /* CNPJ */),
        accessor: 'cnpj',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE86' /* E-mail */),
        accessor: 'email',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE53' /* Cidade */),
        accessor: 'city',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE87' /* Estado */),
        accessor: 'state',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE88' /* País */),
        accessor: 'country',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getBranches();
    } else {
      await this.getBranches();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      branchUpdate: null,
    });
  };

  getBranches = async () => {
    await this.props.getBranchesList();
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  deleteBranch = async (branch) => {
    const result = await this.props.deleteBranch({ branchId: branch._original.id });

    this.setInitState();
    if (result) {
      addNotification(
        'danger',
        I18n.t('BEE140' /* Eliminar Filial */),
        I18n.t('BEE141', { 0: branch.code } /* Filial %{0} eliminada com sucesso! */),
        'top-right',
      );
    }
    await this.getBranches();
  };

  showBranchDetail = (branch) => {
    this.props.history.push({
      pathname: ROUTES.BRANCH_DETAIL,
      state: {
        branch: branch._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditBranch = (branch) => {
    this.props.history.push({
      pathname: ROUTES.BRANCH_EDIT,
      state: {
        branch: branch._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyBranch = (branch) => {
    this.props.history.push({
      pathname: ROUTES.BRANCH_COPY,
      state: {
        branch: branch._original,
      },
    });
  };

  showBranchSettings = (branch) => {
    this.props.history.push({
      pathname: ROUTES.BRANCH_SETTINGS,
      state: {
        branch: branch._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showDeleteBranch = (branch) => {
    this.setState({ showModalDelete: true, branchUpdate: branch });
  };

  render() {
    const { branchUpdate, showModalDelete, page } = this.state;
    const { branchesList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE16' /* Filiais */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE16' /* Filiais */)}
          wikiHelp={ROUTES.BRANCH_HELP}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.BRANCH_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE142' /* Incluir Filial */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getBranches,
          }}
          filterable
          data={branchesList}
          columns={this.tableColumns}
          draggableColumns={this.draggableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteBranch(branchUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE143', { 0: branchUpdate.code } /* A filial %{0} será eliminada! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  branchesList: state.branches && state.branches.branchesList,
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesList: () => dispatch(getBranchesList()),
  deleteBranch: (branchId) => dispatch(deleteBranch(branchId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BranchList));
