import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';

import OneClickButton from '../../../components/form/button';
import ROUTES from '../../../config/routes';
import InventoryHelpers from '../../../helpers/inventory';

import addNotification from '../../../components/notification';
import { PanelPage } from '../../../components/pages/pages';
import Form from '../../../components/form/form';
import Table from '../../../components/table/Table';

import FormatHelpers from '../../../helpers/format';

import { getBranchOptions } from '../../../app/store/actions/customerBranch';
import { getUsersOptions } from '../../../app/store/actions/users';
import {
  createApprovalLevel, getApprovalLevelUser,
  getApprovalLevelDetail, createApprovalLevelUser,
  deleteApprovalLevelUser, updateApprovalLevel,
} from '../../../app/store/actions/approvalLevel';


class CustomerForm extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      { value: (<React.Fragment><i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}</React.Fragment>) },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE572' /* Inventário */) },
    ];

    this.state = {
      approvalLevelId: null,
      branchCode: '',
      branchName: '',
      branchOptions: [],
      userCode: null,
      userName: '',
      usersOptions: [],
      inventoryType: 1,
      note: '',
      minimumValue: 0,
      maximumValue: 0,
      linkUsersList: [],
      defaultPageSize: 5,
      pageSizeOptions: [5, 10, 15],
      defaultSorted: [{ id: 'id', desc: false }],
      showModalLinkUsers: false,
      showCancel: false,
      showCreate: false,
      disabled: true,
      showModalDelete: false,
      selectedUser: {},
      title: I18n.t('BEE2050' /* Detalhes Alçada de aprovação */),
    };

    this.initialState = this.state;

    this.tableColumns = [{
      Header: I18n.t('BEE1' /* Usuário */),
      accessor: 'userLogin',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
    }, {
      Header: I18n.t('BEE2051' /* Alçada Mínima */),
      accessor: 'minimumValue',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
      Cell: row => {
        return FormatHelpers.formatNumber(row.value ? row.value : 0, 2, true);
      }
    }, {
      Header: I18n.t('BEE2052' /* Alçada Máxima */),
      accessor: 'maximumValue',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
      Cell: row => {
        return FormatHelpers.formatNumber(row.value ? row.value : 0, 2, true);
      }
    },]

  }

  setValue = async (attr, value) => {
    if (attr === 'minimumValue' || attr === 'maximumValue') {
      if (value >= 0) {
        this.setState({
          [`${attr}`]: value,
        });
      } else {
        this.setState({
          [`${attr}`]: this.state[`${attr}`],
        });
      }
    } else {
      this.setState({
        [`${attr}`]: value,
      });
    }
  }

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  }

  closeModalLinkUsers = () => {
    this.setState({
      userCode: null,
      userName: '',
      minimumValue: 0,
      maximumValue: 0,
      showModalLinkUsers: false
    })
  }

  getBranchOptions = async () => {
    try {
      const branchOptions = await this.props.getBranchOptions()

      if (branchOptions && branchOptions.success === false) {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');

      } else {
        this.setState({ branchOptions })
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');
      }
    }
  }

  getUsersOptions = async () => {
    try {
      const usersOptions = await this.props.getUsersOptions()

      if (usersOptions && usersOptions.success === false) {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');

      } else {
        this.setState({ usersOptions })
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');
      }
    }
  }

  createApprovalLevel = async () => {
    const { branchCode, inventoryType, note, linkUsersList } = this.state;
    try {
      const result = await this.props.createApprovalLevel({ branchCode, inventoryType, note, linkUsersList })

      if (result && result.success === false) {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');

      } else {
        addNotification('success', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE2057' /* Registro criado com sucesso */), 'top-right');
        this.props.history.push(ROUTES.APPROVAL_LEVEL_LIST);
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');
      }
    }
  }

  updateApprovalLevel = async () => {
    const { approvalLevelId, branchCode, inventoryType, note } = this.state;
    try {
      const result = await this.props.updateApprovalLevel(approvalLevelId, branchCode, inventoryType, note)

      if (result && result.success === false) {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');

      } else {
        addNotification('success', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE2065' /* Registro atualizado */), 'top-right');
        this.props.history.push(ROUTES.APPROVAL_LEVEL_LIST);
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');
      }
    }
  }

  getApprovalLevelDetail = async () => {
    const { approvalLevelId } = this.state;
    try {
      const approvalLevelDetail = await this.props.getApprovalLevelDetail(approvalLevelId)

      if (approvalLevelDetail && approvalLevelDetail.success === false) {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');

      } else {
        this.setState({
          branchCode: approvalLevelDetail.branchCode,
          branchName: approvalLevelDetail.branchName,
          inventoryType: approvalLevelDetail.inventoryType,
          note: approvalLevelDetail.note,
        })
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');
      }
    }
  }

  getApprovalLevelUser = async () => {
    const { approvalLevelId } = this.state;
    try {
      const linkUsersList = await this.props.getApprovalLevelUser(approvalLevelId)

      if (linkUsersList && linkUsersList.success === false) {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');

      } else {
        this.setState({ linkUsersList })
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');
      }
    }
  }

  approvalLevelSelected = async () => {
    if (this.props.location.state.approvalLevel) {
      const { approvalLevel } = this.props.location.state;
      if (approvalLevel) {
        await this.setState({ approvalLevelId: approvalLevel.id });
        this.getApprovalLevelDetail();
        this.getApprovalLevelUser();
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    switch (mode) {
      case 'create':
      case 'copy':
        this.setState({
          showCancel: true,
          showCreate: true,
          disabled: false,
          title: I18n.t('BEE2048' /* Nova Alçada de aprovação */),
          mode,
        });
        this.breadcrumb.push({ value: I18n.t('BEE2048' /* Nova Alçada de aprovação */), active: true });
        this.getBranchOptions();
        this.getUsersOptions();
        if (mode === 'copy') this.approvalLevelSelected();
        this.tableColumns = [{
          Header: I18n.t('BEE55' /* Ações */),
          accessor: 'action',
          style: { overflow: 'visible', alignSelf: 'center' },
          filterable: false,
          sortable: false,
          width: 100,
          Cell: rows => (
            <div style={{ textAlign: 'center' }}>
              <button
                type="button"
                className="btn btn-default btn-xs"
                onClick={() => this.setState({ selectedUser: rows.row._original, showModalDelete: true })}
              >{I18n.t('BEE608' /* Desvincular */)}</button>
            </div>
          ),
        }, ...this.tableColumns];
        break;
      case 'edit':
        this.setState({
          showCancel: true,
          showCreate: false,
          disabled: false,
          title: I18n.t('BEE2049' /* Editar Alçada de aprovação */),
          mode,
        });
        this.breadcrumb.push({ value: I18n.t('BEE2049' /* Editar Alçada de aprovação */), active: true });
        this.getBranchOptions();
        this.getUsersOptions();
        this.approvalLevelSelected();
        this.tableColumns = [{
          Header: I18n.t('BEE55' /* Ações */),
          accessor: 'action',
          style: { overflow: 'visible', alignSelf: 'center' },
          filterable: false,
          sortable: false,
          width: 100,
          Cell: rows => (
            <div style={{ textAlign: 'center' }}>
              <button
                type="button"
                className="btn btn-default btn-xs"
                onClick={() => this.setState({ selectedUser: rows.row._original, showModalDelete: true })}
              >{I18n.t('BEE608' /* Desvincular */)}</button>
            </div>
          ),
        }, ...this.tableColumns];
        break;
      default:
        this.setState({
          ...this.initialState,
          mode,
        })
        this.breadcrumb.push({ value: I18n.t('BEE2050' /* Detalhes Alçada de aprovação */), active: true });
        this.approvalLevelSelected();
        break;
    }
  }

  deleteUserLink = async () => {
    const { linkUsersList, mode, selectedUser } = this.state;
    this.setState({ selectedUser: {}, showModalDelete: false })
    if (mode === 'edit') {
      try {
        const result = await this.props.deleteApprovalLevelUser(selectedUser.approvalLevelUserId)

        if (result && result.success === false) {
          addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');

        } else {
          this.getApprovalLevelUser();
          addNotification('success', I18n.t('BEE2044' /* Alçada de aprovação */),
            I18n.t('BEE2064' /* Usuário desvinculado com sucesso */), 'top-right');
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details) ?
            `${error.code} - ${error.details} | ${error.message}`
            : error.message;
          addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */), messageError, 'top-right');

        } else {
          addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
            I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');
        }
      }
    } else {
      linkUsersList.splice(linkUsersList.findIndex((ele) => ele.userId === selectedUser.userId), 1)
      this.setState({
        linkUsersList: [...linkUsersList],
      })
    }
  }

  linkUsers = async () => {
    const {
      userCode, userName, minimumValue,
      maximumValue, linkUsersList, mode, approvalLevelId
    } = this.state;

    if (!userCode) {
      addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
        I18n.t('BEE2054' /* Usuário não selecionado */), 'top-right');
    } else if (minimumValue === maximumValue) {
      addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
        I18n.t('BEE2344' /* O valor da Alçada Máxima não pode ser igual ao valor da Alçada Mínima */), 'top-right');
    } else if (minimumValue > maximumValue) {
      addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
        I18n.t('BEE2343' /* O valor da Alçada Máxima deve ser maior que o valor da Alçada Mínima */), 'top-right');
    } else {
      if (mode === 'edit') {
        try {
          const result = await this.props.createApprovalLevelUser({ approvalLevelId, userId: userCode, maximumValue, minimumValue })

          if (result && result.success === false) {
            addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
              I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');
          } else {
            this.closeModalLinkUsers();
            this.getApprovalLevelUser();
            addNotification('success', I18n.t('BEE2044' /* Alçada de aprovação */),
              I18n.t('BEE2063' /* Usuário vinculado com sucesso */), 'top-right');
          }
        } catch (err) {
          const error = err.response && err.response.data && err.response.data.error;

          if (error && error.message) {
            const messageError = (error.details) ?
              `${error.code} - ${error.details} | ${error.message}`
              : error.message;
            addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */), messageError, 'top-right');

          } else {
            addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
              I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');
          }
        }
      } else {
        if (linkUsersList.length && linkUsersList.find(ele => ele.userId === userCode)) {
          addNotification('danger', I18n.t('BEE2044' /* Alçada de aprovação */),
            I18n.t('BEE2056' /* Usuário já esta vinculado */), 'top-right');

        } else {
          this.closeModalLinkUsers();
          this.setState({
            linkUsersList: [...linkUsersList, { userId: userCode, userLogin: userName, minimumValue, maximumValue }]
          })
        }
      }
    }
  }

  renderModalLinkUsers = () => {
    const {
      userCode, userName, usersOptions,
      showModalLinkUsers, minimumValue,
      maximumValue, mode,
    } = this.state;

    return (
      <Modal isOpen={showModalLinkUsers} toggle={() => this.closeModalLinkUsers()} size="lg">
        <ModalHeader toggle={() => this.closeModalLinkUsers()}>
          {I18n.t('BEE620' /* Vincular Usuários */)}
        </ModalHeader>
        <ModalBody>
          <Form
            noPanel
            setValue={this.setValue}
            setValueDrop={this.setValueDrop}
            inputs={(formContext) => ([
              formContext.createSelectDropDown(
                { value: userCode, label: userName }, 'user',
                `${I18n.t('BEE1' /* Usuário */)}: `, usersOptions, false
              ),
              formContext.createNumberFormat(
                minimumValue, 'minimumValue',
                `${I18n.t('BEE2051' /* Alçada Mínima */)}:`,
                undefined, false, 'R$ ',
              ),
              formContext.createNumberFormat(
                maximumValue, 'maximumValue',
                `${I18n.t('BEE2052' /* Alçada Máxima */)}:`,
                undefined, false, 'R$ ',
              ),
            ])}
          />
        </ModalBody>
        <ModalFooter>
          <button className="btn btn-white"
            onClick={() => this.closeModalLinkUsers()}>
            {I18n.t('BEE553' /* Fechar */)}
          </button>
          <button
            className={'btn btn-success'}
            onClick={() => this.linkUsers()}
          >
            {(mode === 'edit')
              ? I18n.t('BEE601' /* Vincular */)
              : I18n.t('BEE493' /* Salvar */)
            }
          </button>
        </ModalFooter>
      </Modal>
    )
  }

  componentDidMount() {
    this.setMode();
  }

  render() {
    const {
      showCancel, showCreate, title, branchCode,
      branchName, disabled, inventoryType, defaultPageSize, pageSizeOptions,
      branchOptions, note, defaultSorted, linkUsersList, showModalDelete, selectedUser
    } = this.state;

    return (
      <>
        <PanelPage
          noButton
          breadcrumb={this.breadcrumb}
          title={title}
          content={(
            <>
              <Form
                noPanel
                setValue={this.setValue}
                setValueDrop={this.setValueDrop}
                inputs={(formContext) => ([
                  formContext.createSelectDropDown(
                    { value: branchCode, label: branchName }, 'branch',
                    `${I18n.t('BEE145' /* Filial */)}: `, branchOptions, disabled
                  ),
                  formContext.createSelect(
                    inventoryType, 'inventoryType',
                    `${I18n.t('BEE2046' /* Tipo Inventário */)}: `, InventoryHelpers.inventoryType(), disabled
                  ),
                  formContext.createTextArea(
                    note, 'note', `${I18n.t('BEE135' /* Observação */)}:`,
                    I18n.t('BEE136' /* Informe a observação */), 3, false, disabled,
                  ),
                ])}
              />
              <Table
                headerTitle={' '}
                panelHeaderProps={{
                  noButton: disabled,
                  onClickReload: () => this.getApprovalLevelUser(),
                  children: I18n.t('BEE619' /* Usuários Vinculados */).toUpperCase(),
                }}
                actionButtons={!disabled && (
                  <div className="ml-auto">
                    <OneClickButton
                      type="button"
                      className="btn btn-240 btn-success btn-rounded pr-3"
                      onClick={() => this.setState({ showModalLinkUsers: true })}
                    >
                      <i className="fa fa-link mr-1" />
                      {I18n.t('BEE620' /* Vincular Usuários */)}
                    </OneClickButton>
                  </div>
                )}
                filterable
                data={linkUsersList}
                columns={this.tableColumns}
                expander
                defaultPageSize={defaultPageSize}
                defaultSorted={defaultSorted}
                pageSizeOptions={pageSizeOptions}
                defaultFilterMethod={(filter, row) => {
                  const input = _.lowerCase(filter.value);
                  const value = _.lowerCase(row[filter.id]);
                  if (_.includes(value, input)) {
                    return true;
                  }
                }}
              />
            </>
          )}
          footerContent={(
            <>
              <Link to={{ pathname: ROUTES.APPROVAL_LEVEL_LIST }}>
                {(showCancel
                  ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                  : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
              </Link>
              {showCancel && (
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={(showCreate) ? this.createApprovalLevel : this.updateApprovalLevel}
                >{(showCreate) ? I18n.t('BEE138' /* Criar */) : I18n.t('BEE139' /* Atualizar */)}
                </OneClickButton>
              )}
            </>
          )}
        />
        {this.renderModalLinkUsers()}
        {(showModalDelete &&
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteUserLink()}
            onCancel={() => this.setState({ showModalDelete: false, selectedUser: {} })}
          >
            {I18n.t('BEE2067', { 0: selectedUser.userLogin } /* O usuário %{0} será desvinculado deste alçada */)}
          </SweetAlert>
        )}
      </>
    )
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getBranchOptions: () => dispatch(getBranchOptions()),
  getUsersOptions: () => dispatch(getUsersOptions()),
  createApprovalLevel: (parametersForCreation) => dispatch(createApprovalLevel(parametersForCreation)),
  createApprovalLevelUser: (parametersForCreation) => dispatch(createApprovalLevelUser(parametersForCreation)),
  getApprovalLevelUser: (approvalLevelId) => dispatch(getApprovalLevelUser(approvalLevelId)),
  getApprovalLevelDetail: (approvalLevelId) => dispatch(getApprovalLevelDetail(approvalLevelId)),
  deleteApprovalLevelUser: (approvalLevelUserId) => dispatch(deleteApprovalLevelUser(approvalLevelUserId)),
  updateApprovalLevel: (approvalLevelId, branchCode, inventoryType, note) => dispatch(updateApprovalLevel(approvalLevelId, branchCode, inventoryType, note)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerForm));