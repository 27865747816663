/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

class cardsStats extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {

    };
  }

  createWidgetStats = () => {
    const { cards = [], sizeColumns = 2 } = this.props;
    return (
      <div className="d-xl-flex flex-wrap" style={{ gap: '20px' }}>
        <div
          className="flex-column pe-auto"
          style={{ flex: '1' }}
        >
          {
            cards.filter((card) => card.isMain === true).map((card) => (
              <div
                key={`CardMain${card.title}`}
                className="d-flex mb-3 text-white bg-dark h-100 rounded text-center flex-column justify-content-center"
                style={{}}
              >
                <p className="stats-main-title mt-2">{card.title}</p>
                <p className="stats-main-number">{card.quantity}</p>
              </div>
            ))
          }
        </div>
        <div
          className="flex-column"
          style={{ flex: '3' }}
        >
          <div
            className="row"
            style={{ rowGap: '15px' }}
          >
            {cards.filter((card) => card.isMain === false || card.isMain === undefined).map((card) => (
              <div
                key={`CardNormal${card.title}`}
                className={`col-xl-${sizeColumns + 1}`}
                style={{ height: '120px' }}
              >
                <div
                  className="d-flex flex-column text-center text-white rounded bg-dark h-100"
                  style={{ marginLeft: '20px' }}
                >
                  <div className="stats-title f-w-600 mt-2" style={{ flex: 1, fontSize: '0.9rem' }}>
                    {card.title}
                  </div>
                  <div style={{ flex: 1, fontSize: '3rem' }}>
                    {card.quantity}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.createWidgetStats()}
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = () => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(cardsStats));
