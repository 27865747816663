import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { generateReport } from '../../app/store/actions/reports';
import { getStorageAddressTypesOptions } from '../../app/store/actions/storageAdressTypes';
import FilterDefault from '../../components/filters/FilterDefault';
import OneClickButton from '../../components/form/button';
import Form from '../../components/form/form';
import Select from '../../components/form/select';
import DualList from '../../components/pages/DualList';
import { PanelPage } from '../../components/pages/pages';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportStockEmptyAddresses extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
                </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE32' /* Estoque */) },
      { value: I18n.t('BEE1753' /* Endereços Vazios */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE82' /* Código */),
        value: 'code',
      }, {
        label: I18n.t('BEE1853' /* Nome */),
        value: 'name',
      }, {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE200' /* Tipo */),
        value: 'type',
      }, {
        label: I18n.t('BEE1854' /* Setor */),
        value: 'sector',
      }, {
        label: I18n.t('BEE121' /* Rua */),
        value: 'street',
      }, {
        label: I18n.t('BEE281' /* Coluna */),
        value: 'column',
      }, {
        label: I18n.t('BEE283' /* Nível */),
        value: 'level',
      }, {
        label: I18n.t('BEE285' /* Gaveta */),
        value: 'drawer',
      }, {
        label: I18n.t('BEE207' /* Bloqueado */),
        value: 'blocked',
      }, {
        label: I18n.t('BEE1855' /* Tamanho */),
        value: 'size',
      }, {
        label: I18n.t('BEE386' /* Altura */),
        value: 'height',
      }, {
        label: I18n.t('BEE387' /* Largura */),
        value: 'width',
      }, {
        label: I18n.t('BEE388' /* Comprimento */),
        value: 'length',
      }, {
        label: I18n.t('BEE727' /* Capacidade (Volume) */),
        value: 'capacityVolume',
      }, {
        label: I18n.t('BEE726' /* Capacidade (Peso) */),
        value: 'capacityWeight',
      }, {
        label: I18n.t('BEE1754' /* Código Região */),
        value: 'rangeCode',
      }, {
        label: I18n.t('BEE697' /* Compartilha Endereço */),
        value: 'shareAddress',
      }, {
        label: I18n.t('BEE355' /* Curva */),
        value: 'curve',
      }, {
        label: I18n.t('BEE135' /* Observação */),
        value: 'note',
      }, {
        label: I18n.t('BEE575' /* Usuário de criação */),
        value: 'createdUser',
      }, {
        label: I18n.t('BEE578' /* Data de criação */),
        value: 'createdAt',
      }, {
        label: I18n.t('BEE576' /* Usuário de alteração */),
        value: 'updatedUser',
      }, {
        label: I18n.t('BEE577' /* Data de alteração */),
        value: 'updatedAt',
      },
    ];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      codeFrom: '',
      codeTo: '',
      sectorFrom: '',
      sectorTo: '',
      streetFrom: '',
      streetTo: '',
      columnFrom: '',
      columnTo: '',
      levelFrom: '',
      levelTo: '',
      drawerFrom: '',
      drawerTo: '',
      type: '',
      typeName: '',
      rangeFrom: '',
      rangeTo: '',
      curve: [],
      F: '',
      M: '',
      R: '',
    };
  }

  async componentDidMount() {
    const listType = await this.props.getStorageAddressTypesOptions();
    const allOption = { value: '', label: I18n.t('BEE793' /* Todos */) };

    listType.push(allOption);

    this.setState({
      listType,
    });
  }

  createSelectDropDown = (value, attr, label, items, required = false) => (
    <div key={value} className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-2">{label}</label>
      <div className="col-md-4">
        <Select
          value={value.label ? value : ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          required={required}
          styles={colourStyles}
        />
      </div>
    </div>
  );

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
    if (attr === 'size' && value) {
      this.props.addressSizesList.forEach((element) => {
        if (element.code === value.value) {
          this.setState({
            curve: element.curve,
          });
        }
      });
    } else if (attr === 'size') {
      this.setState({
        curve: '',
      });
    }
  };

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      codeFrom,
      codeTo,
      sectorFrom,
      sectorTo,
      streetFrom,
      streetTo,
      columnFrom,
      columnTo,
      levelFrom,
      levelTo,
      drawerFrom,
      drawerTo,
      rangeFrom,
      rangeTo,
      type,
      F,
      M,
      R,
    } = this.state;

    const { location } = this.props;

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const curve = [];
    if (F) curve.push('F');
    if (M) curve.push('M');
    if (R) curve.push('R');

    const filters = returnFiltersByRules([
      ['curve', { type: 'in', value: curve }],
      ['type', { type: 'in', value: (type === '') ? '' : [type] }],
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['code', { type: 'between', value: [codeFrom.toLocaleUpperCase(), codeTo.toLocaleUpperCase()] }],
      ['sector', { type: 'between', value: [sectorFrom.toLocaleUpperCase(), sectorTo.toLocaleUpperCase()] }],
      ['street', { type: 'between', value: [streetFrom.toLocaleUpperCase(), streetTo.toLocaleUpperCase()] }],
      ['column', { type: 'between', value: [columnFrom.toLocaleUpperCase(), columnTo.toLocaleUpperCase()] }],
      ['level', { type: 'between', value: [levelFrom.toLocaleUpperCase(), levelTo.toLocaleUpperCase()] }],
      ['drawer', { type: 'between', value: [drawerFrom.toLocaleUpperCase(), drawerTo.toLocaleUpperCase()] }],
      ['rangeCode', { type: 'between', value: [rangeFrom, rangeTo] }],
    ]);

    this.props.generateReport(I18n.t('BEE1753' /* Endereços Vazios */).toLowerCase(), 'xlsx', location.pathname, headers, filters);
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      selected,
      branchCodeFrom,
      branchCodeTo,
      codeFrom,
      codeTo,
      sectorFrom,
      sectorTo,
      streetFrom,
      streetTo,
      columnFrom,
      columnTo,
      levelFrom,
      levelTo,
      drawerFrom,
      drawerTo,
      rangeFrom,
      rangeTo,
      type,
      typeName,
      listType,
      F,
      M,
      R,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE1753' /* Endereços Vazios */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ),
                  formContext.createInputRange(
                    { from: codeFrom.toLocaleUpperCase(), to: codeTo.toLocaleUpperCase() },
                    { from: 'codeFrom', to: 'codeTo' },
                    `${I18n.t('BEE82' /* Código */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: rangeFrom, to: rangeTo },
                    { from: 'rangeFrom', to: 'rangeTo' },
                    `${I18n.t('BEE581' /* Range */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: sectorFrom.toLocaleUpperCase(), to: sectorTo.toLocaleUpperCase() },
                    { from: 'sectorFrom', to: 'sectorTo' },
                    `${I18n.t('BEE1854' /* Setor */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: streetFrom.toLocaleUpperCase(), to: streetTo.toLocaleUpperCase() },
                    { from: 'streetFrom', to: 'streetTo' },
                    `${I18n.t('BEE121' /* Rua */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: columnFrom.toLocaleUpperCase(), to: columnTo.toLocaleUpperCase() },
                    { from: 'columnFrom', to: 'columnTo' },
                    `${I18n.t('BEE281' /* Coluna */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: levelFrom.toLocaleUpperCase(), to: levelTo.toLocaleUpperCase() },
                    { from: 'levelFrom', to: 'levelTo' },
                    `${I18n.t('BEE283' /* Nível */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: drawerFrom.toLocaleUpperCase(), to: drawerTo.toLocaleUpperCase() },
                    { from: 'drawerFrom', to: 'drawerTo' },
                    `${I18n.t('BEE285' /* Gaveta */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createCheckBoxes([{
                    label: 'F',
                    value: F,
                    attr: 'F',
                  }, {
                    label: 'M',
                    value: M,
                    attr: 'M',
                  }, {
                    label: 'R',
                    value: R,
                    attr: 'R',
                  }], `${I18n.t('BEE355' /* Curva */)}:`),

                  this.createSelectDropDown(
                    { value: type, label: typeName },
                    'type',

                    `${I18n.t('BEE200' /* Tipo */)}:`,
                    listType,
                    true,
                  ),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',
    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (nameDoc, type, route, columns, filters) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
  getStorageAddressTypesOptions: () => dispatch(getStorageAddressTypesOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportStockEmptyAddresses);
