/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';

import Select from 'react-select';
import {
  UncontrolledPopover, PopoverHeader,
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import { endOfDay, startOfDay, subDays } from 'date-fns';
import moment from 'moment';
import DateRangePicker from '../../../components/date/DateRangePicker.jsx';
import ROUTES from '../../../config/routes';
import {
  getInboundOrdersAverageTimes,
  setDashboardInboundOrdersDates, setSelectedUserBranches,
} from '../../../app/store/actions/inboundOrders';
import InboundOrderHelpers from '../../../helpers/inboundOrders';
import Table from '../../../components/table/Table';
import BarChart from '../../../components/charts/BarChart';
import LineChart from '../../../components/charts/LineChart';
import OneClickButton from '../../../components/form/button';

class AverageTimes extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      defaultSorted: [{
        id: 'orderNumber',
        desc: false,
      }],
      typeDashboard: 'Document',
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.showInboundOrderDetail(rows.row)}
                >
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE342' /* Fornecedor */),
        accessor: 'supplier.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 180,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{InboundOrderHelpers.inboundOrderStatus(row.value)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {InboundOrderHelpers.inboundOrderStatus().map((element, index) => (
              (element.value !== 2 && element.value !== 8) && (
                <option
                  key={index}
                  value={element.value}
                >
                  {element.label}
                </option>
              )
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE444' /* Data de Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('L')}
          </span>
        ),
      }, {
        Header: I18n.t('BEE405' /* Volumes */),
        accessor: 'expectedVolumes',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 85,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{parseInt(row.value || 0, 10)}</span>
          </div>
        ),
      }, {
        Header: 'Produtos',
        accessor: 'products.length',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{row.value || 0}</span>
          </div>
        ),
      },
      {
        Header: () => (
          <span id="progressHeader">
            {I18n.t('BEE54' /* Progresso */)}
            <UncontrolledPopover trigger="hover" placement="top" target="progressHeader">
              <PopoverHeader style={{ textAlign: 'center' }}>
                {I18n.t('BEE2551' /* Apresenta a % de itens armazenados do documento */)}
              </PopoverHeader>
            </UncontrolledPopover>
          </span>
        ),
        accessor: 'progress',
        minWidth: 100,
        Cell: (row) => {
          const progress = row.value;

          return (
            <div className="progress rounded-corner" id="progressHeader">
              <div
                className="progress-bar f-s-10 f-w-600"
                style={{
                  width: `${progress}%`,
                  // eslint-disable-next-line no-nested-ternary
                  backgroundColor: ((progress > 70) ? '#00acac' : ((progress > 40) ? '#f59c1a' : '#ff5b57')),
                  transition: 'all .2s ease-out',
                  minWidth: '30px',
                }}
              >
                {progress}
                %
              </div>
            </div>
          );
        },
      },
    ];

    this.breadcrumb = [
      { value: I18n.t('BEE42' /* Dashboard */), iconClassName: 'fa fa-chart-line m-t-10 m-r-5' },
      { value: I18n.t('BEE36' /* Recebimento */) },
      { value: I18n.t('BEE2517' /* Análise Tempos Médios */), active: true },
    ];
  }

  async componentDidMount() {
    if (!this.props.location.state || !Object.keys(this.props.location.state).length) {
      const startDate = moment().subtract(30, 'd');
      const endDate = moment();
      const dates = { startDate, endDate };

      await this.props.setDashboardInboundOrdersDates(dates);

      await this.getInboundOrders();
    }

    this.props.history.push({
      state: {},
    });
  }

  showInboundOrderDetail = (inboundOrder) => {
    this.props.history.push({
      pathname: ROUTES.INBOUND_ORDER_DETAIL,
      state: {
        inboundOrder: inboundOrder._original,
        dashboardRoute: 'averageTimes',
      },
      dice: {
        startDate: moment(this.state.startDate).format('MM/DD/YYYY'),
        endDate: moment(this.state.endDate).format('MM/DD/YYYY'),
        currentRange: this.state.currentRange,
        page: this.state.page,
      },
    });
  };

  handleDateApplyEvent = async (value) => {
    const startDate = value[0];
    const endDate = value[1];

    const dates = { startDate, endDate };
    await this.props.setDashboardInboundOrdersDates(dates);
  };

  handleBranchesSelected = async (event) => {
    const selectedBranches = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const element of event) {
      selectedBranches.push({
        value: element.value,
        label: element.label,
      });
    }

    await this.props.setSelectedUserBranches(selectedBranches);
  };

  getInboundOrders = async () => {
    const {
      startDate, endDate,
    } = this.props.dates;

    const branchesToSearch = [];
    if (this.props.selectedBranches) {
      // eslint-disable-next-line no-restricted-syntax
      for (const { value } of this.props.selectedBranches) {
        branchesToSearch.push(value);
      }
    }

    await this.props.getInboundOrdersAverageTimes(startDate, endDate, branchesToSearch);

    const existOrder = {};

    this.setState({
      selectedOrder: existOrder || {},
    });
  };

  handleTypeDashboard = async (type) => {
    if (this.state.typeDashboard !== type) {
      this.setState({
        typeDashboard: type,
      });
    }
  };

  render() {
    const startDate = startOfDay(subDays(new Date(), 30));
    const endDate = endOfDay(new Date());
    const { inboundOrdersList = [], charts = [], averageTimeCards = [] } = this.props;
    const {
      defaultSorted, selectedOrder, typeDashboard,
    } = this.state;

    return (
      <div>
        {!!this.breadcrumb && this.breadcrumb.length && (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.breadcrumb.map((it, index) => (
                <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                  {
                    it.iconClassName
                      ? (
                        <>
                          <i className="fa fa-chart-line m-t-10 m-r-5" />
                          {' '}
                          {it.value}
                        </>
                      ) : (
                        it.value
                      )
                  }
                </li>
              ))}
            </ol>
          </div>
        )}
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE1274' /* Dashboard Recebimento */)}
          </h1>
        </div>
        <div className="d-sm-flex">
          <div className="d-flex mt-2">
            <DateRangePicker
              onChange={(value) => {
                this.handleDateApplyEvent(value);
              }}
              defaultValue={[startDate, endDate]}
            />
            <UncontrolledPopover trigger="hover" placement="top" target="dateRangePicker">
              <PopoverHeader>
                { I18n.t('BEE3342', { 0:(I18n.t('BEE3344' /* data de criação */)) } /* O intervalo segue o critério pela %{0} */) }
              </PopoverHeader>
            </UncontrolledPopover>
          </div>
          <div className="d-flex mt-2">
            <Select
              value={this.props.selectedBranches}
              isMulti
              onChange={(event) => this.handleBranchesSelected(event || [])}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              isSearchable
              styles={{
                control: (defaultStyles) => ({
                  ...defaultStyles,
                  minHeight: 36,
                  width: '300px',
                  border: 0,
                  boxShadow: 'none',
                  color: '#FFF',
                  backgroundColor: '#2d353c',
                  fontWeight: '600',
                  borderRadius: '5px 0px 0px 5px',
                  outline: 'none',
                }),
                placeholder: (defaultStyles) => ({
                  ...defaultStyles,
                  color: '#ffffff',
                }),
                dropdownIndicator: (defaultStyles) => ({
                  ...defaultStyles,
                  paddingTop: 0,
                  paddingBottom: 0,
                  color: '#ffffff',
                }),
                clearIndicator: (defaultStyles) => ({
                  ...defaultStyles,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
              }}
              maxMenuHeight={300}
              placeholder={I18n.t('BEE2424' /* Todas as filiais estão selecionadas */)}
              options={this.props.userBranches}
            />
            <div style={{ borderRadius: '0px 5px 5px 0px', backgroundColor: '#2d353c' }}>
              <button
                className="h-100 border-0 bg-transparent"
                style={{ fontSize: '22px' }}
                type="button"
                onClick={() => this.getInboundOrders()}
              >
                <i className="fa fa-search text-white" style={{ fontSize: '20px' }} />
              </button>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2517' /* Análise Tempos Médios */)}
          </h1>
          <div>
            <OneClickButton
              type="button"
              className={
                  `btn btn-120 ${typeDashboard === 'Document' ? 'btn-primary' : 'btn-white'} btn-rounded p-5 m-5`
                }
              onClick={() => this.handleTypeDashboard('Document')}
            >
              {I18n.t('BEE2559' /* Por Documento */)}

            </OneClickButton>

            <OneClickButton
              type="button"
              className={`btn btn-120 ${typeDashboard === 'Line' ? 'btn-primary' : 'btn-white'} btn-rounded p-5 m-5`}
              onClick={() => this.handleTypeDashboard('Line')}
            >
              {I18n.t('BEE2560' /* Por Linha */)}

            </OneClickButton>
          </div>
        </div>
        <div className="d-flex flex-row mt-2 mb-4 w-100" style={{ height: '300px', fontSize: '1.5rem' }}>
          <div
            className="d-flex flex-column h-100 w-25 rounded bg-dark text-white align-items-center
            justify-content-center text-center"
            style={{ flex: 'none' }}
          >
            <div>
              <p style={{ fontSize: '2.5rem' }}>
                <strong>
                  {!!averageTimeCards[typeDashboard]
                  && averageTimeCards[typeDashboard].fromStartToFinishTimesAverageTime}
                </strong>
              </p>
            </div>
            <div>
              <p>{I18n.t('BEE2525' /* Tempo Médio Total */)}</p>
              <p>
                (
                {I18n.t('BEE2557' /* Integração ao Armazenamento */)}
                )
              </p>
            </div>
          </div>
          <div className="d-flex flex-column h-100 ml-3 text-white" style={{ flex: '1' }}>
            <div className="bg-dark h-100 d-flex align-items-center text-left rounded mb-1">
              <p className="my-0 p-0 ml-2">
                <strong>
                  {!!averageTimeCards[typeDashboard]
                 && averageTimeCards[typeDashboard].untilConferenceTimeAverageTime}
                </strong>
                {` ${I18n.t('BEE2558' /* Até o Início da Conferência */)}`}
              </p>
            </div>
            <div className="bg-dark h-100 d-flex align-items-center text-left rounded mt-1 mb-1">
              <p className="my-0 p-0 ml-2">
                <strong>
                  {!!averageTimeCards[typeDashboard]
                && averageTimeCards[typeDashboard].inConferenceTimesAverageTime}

                </strong>
                {` ${I18n.t('BEE1768' /* Em Conferência */)}`}
              </p>
            </div>
            <div className="bg-dark h-100 d-flex align-items-center text-left rounded mt-1 mb-1">
              <p className="my-0 p-0 ml-2">
                <strong>
                  {!!averageTimeCards[typeDashboard]
                && averageTimeCards[typeDashboard].inStorageTimesAverageTime}

                </strong>
                {`  ${I18n.t('BEE475' /* Em Armazenagem */)}`}
              </p>
            </div>
          </div>
        </div>
        <div>
          <BarChart
            barChartValues={!!charts[typeDashboard] && charts[typeDashboard].averageTimeBarChartInfo}
            height={200}
            useFormatter
            decimalNumber={2}
            title={I18n.t('BEE2531' /* Tempo gasto por etapa */)}
            onClickReload={this.getOutboundAverageTimeDashboard}
          />
        </div>
        <div>
          <Table
            downloadCSV
            onClickCollapse
            panelHeaderProps={{
              onClickReload: () => this.getInboundOrders(),
              pageDescription: I18n.t('BEE793' /* Todos */).toUpperCase(),
            }}
            filterable
            data={inboundOrdersList}
            columns={this.tableColumns}
            expander
            defaultPageSize={10}
            defaultSorted={defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            noDataText={I18n.t('BEE2423' /* Não há dados na data informada */)}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: {
                    background: selectedOrder && rowInfo.original.id === selectedOrder.id ? 'silver' : 'white',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
          />
        </div>
        {/*
        <div>
          <LineChart
            title={I18n.t('BEE2532' /)}
            lineChartCheckData={!!charts[typeDashboard] && charts[typeDashboard].averageTimeHistoryChartInfo}
          />
        </div>
        */}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  inboundOrdersList: state.inboundOrders && state.inboundOrders.inboundOrdersList,
  averageTimeCards: state.inboundOrders && state.inboundOrders.averageTimeCards,
  currentRange: state.inboundOrders.currentRange,
  userBranches: state.inboundOrders.userBranches,
  charts: (state.inboundOrders && state.inboundOrders.chartsPanel),
  selectedBranches: state.inboundOrders.selectedBranches,
  dates: state.inboundOrders.dates,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedUserBranches: (selectedBranches) => dispatch(setSelectedUserBranches(selectedBranches)),
  getInboundOrdersAverageTimes:
    (
      startDate,
      endDate,
      selectedBranches,
      typeDashboard,
    ) => dispatch(getInboundOrdersAverageTimes(startDate, endDate, selectedBranches, typeDashboard)),
  setDashboardInboundOrdersDates: (dates) => dispatch(setDashboardInboundOrdersDates(dates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(AverageTimes));
