import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';
import Select from '../../../../components/form/select';

import ROUTES from '../../../../config/routes';

import {
  createStorageAddressType, getStorageAddressType, updateStorageAddressType,
} from '../../../../app/store/actions/storageAdressTypes';
import StorageAddressTypeHelpers from '../../../../helpers/storageAddressTypes';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    isDisabled, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};

class StorageAddressTypeForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      status: '',
      name: '',
      code: '',
      note: '',
      disableStatus: true,
      disableName: true,
      disableCode: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getStorageAddressTypeSelected();
    }
  }

  getStorageAddressTypeSelected = async () => {
    if (this.props.location.state.addressType) {
      const { addressType } = this.props.location.state;

      const addrressTypeSelected = await this.props.getStorageAddressType(addressType.id);

      if (addrressTypeSelected) {
        this.setState({
          addressTypeId: addrressTypeSelected.id,
          name: addrressTypeSelected.name,
          code: addrressTypeSelected.code,
          note: addrressTypeSelected.note,
        });

        const statusOption = StorageAddressTypeHelpers.status().find((op) => op.value === addrressTypeSelected.status);
        this.setValueDrop('status', statusOption);
      }
    }
  };

  setMode = () => {
    let mode;

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableStatus: false,
        disableName: false,
        disableCode: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableStatus: false,
        disableName: false,
        disableNote: false,
        disableCode: true,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value || ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  createStorageAddressTypeSubmit = async () => {
    const {
      status, name, note, code,
    } = this.state;

    try {
      const newAddressType = await this.props.createStorageAddressType({
        status,
        name,
        note,
        code,
      });

      if (newAddressType && newAddressType.success === false) {
        await addNotification(
          'danger',
          I18n.t('BEE737' /* Novo Tipo de Endereço */),
          I18n.t('BEE739', { 0: name } /* Erro ao incluir o tipo de endereço %{0} */),
          'top-right',
        );
      } else {
        await addNotification(
          'success',
          I18n.t('BEE737' /* Novo Tipo de Endereço */),
          I18n.t('BEE738', { 0: name } /* Novo tipo de endereço %{0} criado com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.ADDRESS_TYPE_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE737' /* Novo Tipo de Endereço */),
            `${error.status} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE737' /* Novo Tipo de Endereço */),
            I18n.t('BEE739', { 0: name } /* Erro ao incluir o tipo de endereço %{0} */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE737' /* Novo Tipo de Endereço */),
          I18n.t('BEE739', { 0: name } /* Erro ao incluir o tipo de endereço %{0} */),
          'top-right',
        );
      }
    }
  };

  updateStorageAddressTypeSubmit = async () => {
    const {
      addressTypeId, status, name, note, code,
    } = this.state;

    try {
      const updAddressType = await this.props.updateStorageAddressType({
        addressTypeId,
        name,
        status,
        note,
        code,
      });

      if (updAddressType) {
        await addNotification(
          'success',
          I18n.t('BEE740' /* Atualizar Tipo de Endereço */),
          I18n.t('BEE741', { 0: name } /* Tipo de endereço %{0} atualizado com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.ADDRESS_TYPE_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE740' /* Atualizar Tipo de Endereço */),
            `${error.status} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE740' /* Atualizar Tipo de Endereço */),
            I18n.t('BEE742', { 0: name } /* Erro ao atualizar o tipo de endereço %{0} */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE740' /* Atualizar Tipo de Endereço */),
          I18n.t('BEE742', { 0: name } /* Erro ao atualizar o tipo de endereço %{0} */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      status, statusName, name, note, code,
    } = this.state;

    const {
      mode, disableStatus, disableName, disableNote, disableCode, showCreate, showUpdate, showCancel,
    } = this.state;

    const statusLabel = `${StorageAddressTypeHelpers.status(status)}`;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE737' /* Novo Tipo de Endereço */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE743' /* Editar Tipo de Endereço */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE744' /* Detalhes do Tipo de Endereço */);
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE732' /* Tipos de Endereço */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE737' /* Novo Tipo de Endereço */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  {this.createInput(
                    code,
                    'code',
                    `${I18n.t('BEE82' /* Código */)}:`,
                    I18n.t('BEE1200' /* Informe o código (obrigatório) */),
                    'text',
                    true,
                    disableCode,
                  )}
                  {this.createInput(
                    name,
                    'name',
                    `${I18n.t('BEE277' /* Descrição */)}:`,
                    I18n.t('BEE778' /* Informe a descrição (obrigatório) */),
                    'text',
                    true,
                    disableName,
                  )}
                  {(disableStatus
                    ? this.createInput(
                      statusLabel,
                      'status',
                      `${I18n.t('BEE224' /* Status */)}:`,
                      '',
                      'text',
                      true,
                      disableStatus,
                    )
                    : this.createSelectDropDown(
                      { value: status, label: statusName },
                      'status',
                      `${I18n.t('BEE224' /* Status */)}:`,
                      StorageAddressTypeHelpers.status(),
                    )
                  )}
                  {this.createInput(
                    note,
                    'note',
                    `${I18n.t('BEE135' /* Observação */)}:`,
                    I18n.t('BEE136' /* Informe a observação */),
                    'text',
                    false,
                    disableNote,
                  )}
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.ADDRESS_TYPE_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      ))}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createStorageAddressTypeSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}
                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateStorageAddressTypeSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}
                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getStorageAddressType: (addressTypeId) => dispatch(getStorageAddressType(addressTypeId)),
  createStorageAddressType: (addressType) => dispatch(createStorageAddressType(addressType)),
  updateStorageAddressType: (addressType) => dispatch(updateStorageAddressType(addressType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorageAddressTypeForm));
