import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import OneClickButton from '../../components/form/button';
import { PanelPage } from '../../components/pages/pages';
import DualList from '../../components/pages/DualList';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportMovementExpeditionOutboundList extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          { I18n.t('BEE12' /* Início */)}
                </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE1087' /* Documentos de Saída */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE443' /* Documento */),
        value: 'orderNumber',
      }, {
        label: I18n.t('BEE145' /* Filial */),
        value: 'branchCode',
      }, {
        label: I18n.t('BEE381' /* Código do Cliente */),
        value: 'customerCode',
      }, {
        label: I18n.t('BEE200' /* Tipo */),
        value: 'orderType',
      }, {
        label: I18n.t('BEE1095' /* Pedido parcial */),
        value: 'partialOrder',
      }, {
        label: I18n.t('BEE301' /* Transportadora */),
        value: 'carrierCode',
      }, {
        label: I18n.t('BEE1885' /* Código de Prioridade */),
        value: 'priorityCode',
      }, {
        label: I18n.t('BEE224' /* Status */),
        value: 'status',
      }, {
        label: I18n.t('BEE444' /* Data de Entrega */),
        value: 'deliveryDate',
      }, {
        label: I18n.t('BEE1098' /* Tipo de Entrega */),
        value: 'deliveryType',
      }, {
        label: I18n.t('BEE613' /* Urgente */),
        value: 'urgent',
      }, {
        label: I18n.t('BEE1685' /* Código da Ordem do Cliente */),
        value: 'customerOrderCode',
      }, {
        label: I18n.t('BEE1884' /* Código de pedido ERP */),
        value: 'erpOrderCode',
      }, {
        label: I18n.t('BEE1883' /* Representante */),
        value: 'representative',
      }, {
        label: I18n.t('BEE1686' /* Data Ordem */),
        value: 'orderDate',
      }, {
        label: I18n.t('BEE1687' /* Valor Total */),
        value: 'totalValue',
        numFmt: '"R$"#,##0.00;[Red]\-"R$"#,##0.00',
      }, {
        label: I18n.t('BEE1100' /* Cliente de entrega */),
        value: 'shipToCustomerCode',
      }, {
        label: I18n.t('BEE1101' /* Nome entrega */),
        value: 'shipToCustomerName',
      }, {
        label: I18n.t('BEE1102' /* CEP Entrega */),
        value: 'shipToPostalCode',
      }, {
        label: I18n.t('BEE1715' /* Código IBGE de Envio */),
        value: 'shipToIbgeCode',
      }, {
        label: I18n.t('BEE1103' /* Rua entrega */),
        value: 'shipToStreet',
      }, {
        label: I18n.t('BEE1104' /* Número entrega */),
        value: 'shipToNumber',
      }, {
        label: I18n.t('BEE1105' /* Complemento entrega */),
        value: 'shipToComplement',
      }, {
        label: I18n.t('BEE1106' /* Bairro entrega */),
        value: 'shipToDistrict',
      }, {
        label: I18n.t('BEE1107' /* Cidade entrega */),
        value: 'shipToCity',
      }, {
        label: I18n.t('BEE1108' /* Estado entrega */),
        value: 'shipToState',
      }, {
        label: I18n.t('BEE1109' /* País entrega */),
        value: 'shipToCountry',
      }, {
        label: I18n.t('BEE1395' /* Início Separação */),
        value: 'pickStartAt',
      }, {
        label: I18n.t('BEE1722' /* Usuário Inicio Separação */),
        value: 'pickStartUser',
      }, {
        label: I18n.t('BEE1430' /* Fim Separação */),
        value: 'pickEndAt',
      }, {
        label: I18n.t('BEE1716' /* Usuário Final Separação */),
        value: 'pickEndUser',
      }, {
        label: I18n.t('BEE1421' /* Início Conferência */),
        value: 'checkStartAt',
      }, {
        label: I18n.t('BEE1422' /* Usuário Conferência */),
        value: 'checkStartUser',
      }, {
        label: I18n.t('BEE451' /* Data Fim Conferência */),
        value: 'checkEndAt',
      }, {
        label: I18n.t('BEE450' /* Usuário Final Conferência */),
        value: 'checkEndUser',
      }, {
        label: I18n.t('BEE1812' /* Quantidade de produtos */),
        value: 'quantityOfProducts',
      }, {
        label: I18n.t('BEE135' /* Observação */),
        value: 'note',
      }, {
        label: I18n.t('BEE575' /* Usuário de criação */),
        value: 'createdUser',
      }, {
        label: I18n.t('BEE578' /* Data de criação */),
        value: 'createdAt',
      }, {
        label: I18n.t('BEE576' /* Usuário de alteração */),
        value: 'updatedUser',
      }, {
        label: I18n.t('BEE577' /* Data de alteração */),
        value: 'updatedAt',
      },
    ];

    this.state = {
      selected: [],
      branchCodeFrom: '',
      branchCodeTo: '',
      deliveryDateFrom: '',
      deliveryDateTo: '',
      orderDateFrom: '',
      orderDateTo: '',
      orderNumberFrom: '',
      orderNumberTo: '',
      customerCodeFrom: '',
      customerCodeTo: '',
      customerOrderCodeFrom: '',
      customerOrderCodeTo: '',
      productCodeFrom: '',
      productCodeTo: '',
      carrierCodeFrom: '',
      carrierCodeTo: '',
      shipToCityFrom: '',
      shipToCityTo: '',
      shipToStateFrom: '',
      shipToStateTo: '',
      // picked: '',
      // pendingCut: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      orderNumberFrom,
      orderNumberTo,
      branchCodeFrom,
      branchCodeTo,
      customerCodeFrom,
      customerCodeTo,
      customerOrderCodeFrom,
      customerOrderCodeTo,
      productCodeFrom,
      productCodeTo,
      carrierCodeFrom,
      carrierCodeTo,
      shipToCityFrom,
      shipToCityTo,
      shipToStateFrom,
      shipToStateTo,
      deliveryDateFrom,
      deliveryDateTo,
      orderDateFrom,
      orderDateTo,
      // picked,
      // pendingCut,

    } = this.state;
    const { location } = this.props;
    // const statusDocument = [];
    // if (picked) statusDocument.push('PICKED');
    // if (pendingCut) statusDocument.push('PENDING_CUT');

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));

    const filters = returnFiltersByRules([
      ['branchCode', { type: 'between', value: [branchCodeFrom, branchCodeTo] }],
      ['orderNumber', { type: 'between', value: [orderNumberFrom, orderNumberTo] }],
      ['customerCode', { type: 'between', value: [customerCodeFrom, customerCodeTo] }],
      ['customerOrderCode', { type: 'between', value: [customerOrderCodeFrom, customerOrderCodeTo] }],
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['carrierCode', { type: 'between', value: [carrierCodeFrom, carrierCodeTo] }],
      ['shipToCity', { type: 'between', value: [shipToCityFrom, shipToCityTo] }],
      ['shipToState', { type: 'between', value: [shipToStateFrom, shipToStateTo] }],
      ['deliveryDate', { type: 'between', value: [deliveryDateFrom, deliveryDateTo] }],
      ['orderDate', { type: 'between', value: [orderDateFrom, orderDateTo] }],
      // ['status', statusDocument ],
    ]);

    this.props.generateReport(
      I18n.t('BEE1087' /* Documentos de Saída */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => {
    console.log(newState);
    this.setState({ ...newState });
  };

  render() {
    const {
      selected,
      orderNumberFrom,
      orderNumberTo,
      branchCodeFrom,
      branchCodeTo,
      customerCodeFrom,
      customerCodeTo,
      customerOrderCodeFrom,
      customerOrderCodeTo,
      productCodeFrom,
      productCodeTo,
      carrierCodeFrom,
      carrierCodeTo,
      shipToCityFrom,
      shipToCityTo,
      shipToStateFrom,
      shipToStateTo,
      deliveryDateFrom,
      deliveryDateTo,
      orderDateFrom,
      orderDateTo,
      // picked,
      // pendingCut,

    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE1087' /* Documentos de Saída */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: branchCodeFrom, to: branchCodeTo },
                    { from: 'branchCodeFrom', to: 'branchCodeTo' },
                    `${I18n.t('BEE145' /* Filial */)}:`,
                    { from: '', to: 'Z'.repeat(10) },
                  ), formContext.createInputRange(
                    { from: orderNumberFrom, to: orderNumberTo },
                    { from: 'orderNumberFrom', to: 'orderNumberTo' },
                    `${I18n.t('BEE443' /* Documento */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createDateInputRange(
                    { from: deliveryDateFrom, to: deliveryDateTo },
                    { from: 'deliveryDateFrom', to: 'deliveryDateTo' },
                    `${I18n.t('BEE444' /* Data de Entrega */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: customerCodeFrom, to: customerCodeTo },
                    { from: 'customerCodeFrom', to: 'customerCodeTo' },
                    `${I18n.t('BEE381' /* Código do Cliente */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: customerOrderCodeFrom, to: customerOrderCodeTo },
                    { from: 'customerOrderCodeFrom', to: 'customerOrderCodeTo' },
                    `${I18n.t('BEE1808' /* Pedido Cliente */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createDateInputRange(
                    { from: orderDateFrom, to: orderDateTo },
                    { from: 'orderDateFrom', to: 'orderDateTo' },
                    `${I18n.t('BEE1097' /* Data Pedido */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: carrierCodeFrom, to: carrierCodeTo },
                    { from: 'carrierCodeFrom', to: 'carrierCodeTo' },
                    `${I18n.t('BEE301' /* Transportadora */)}:`,
                    { from: '', to: 'Z'.repeat(30) },
                  ),
                  formContext.createInputRange(
                    { from: shipToCityFrom, to: shipToCityTo },
                    { from: 'shipToCityFrom', to: 'shipToCityTo' },
                    `${I18n.t('BEE1107' /* Cidade entrega */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: shipToStateFrom, to: shipToStateTo },
                    { from: 'shipToStateFrom', to: 'shipToStateTo' },
                    `${I18n.t('BEE1108' /* Estado entrega */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE225' /* Produto */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  // formContext.createCheckBoxes([
                  //     { attr: 'pendingCut', value: pendingCut, label: I18n.t('BEE471' /* Pendente Corte de Cabos */) },
                  //     { attr: 'picked', value: picked, label: I18n.t('BEE1783' /* Pendente Conferência */) },
                  //   ], `${I18n.t('BEE1759' /* Status do Documento */)}:`),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
                )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
                )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportMovementExpeditionOutboundList);
