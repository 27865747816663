/* eslint-disable max-len */
import moment from 'moment-timezone';

import RestService from '../services/rest';

// GET
async function getInventorySheetsList(grouperCode, branchCode) {
  return RestService.getAuthenticated('inventory/list/inventorySheet', { grouperCode, branchCode });
}

async function getInventoryList(filterStartDate, filterEndDate) {
  return RestService.getAuthenticated(
    `inventory/list?filterStartDate=${filterStartDate}&filterEndDate=${filterEndDate}`,
  );
}

async function getInventorySheetCounting(inventoryCount, grouperCode, branchCode) {
  return RestService.getAuthenticated('inventory/list/inventorySheetCounting', {
    inventoryCount,
    grouperCode,
    branchCode,
  });
}

async function getInventoryOptions(branchCode, statusFilter) {
  return RestService.getAuthenticated('inventory/list/inventoryOptions', { branchCode, statusFilter });
}

async function getAllGroupersByBranch(branchCode, statusFilter, startDate, endDate) {
  return RestService.getAuthenticated('inventory/list/getAllGroupersByBranch', {}, {}, { params: { branchCode, statusFilter, startDate, endDate } });
}

async function getDepositsByBranch(branchCode, startDate, endDate) {
  return RestService.getAuthenticated('inventory/list/getDepositsByBranch', {}, {}, { params: { branchCode, startDate, endDate } });
}

async function getInventoryDashboardSheetsList(branchCode, groupersCodes, depositsCodes, startDate, endDate) {
  return RestService.getAuthenticated('inventory/list/inventoryDashboardSheet', {}, {}, {
    params: {
      branchCode, groupersCodes, depositsCodes, startDate, endDate,
    },
  });
}

async function getNewGrouperCode(branchCode, type, date) {
  const sDate = moment(date).format();
  return RestService.getAuthenticated('inventory/newGrouper', { branchCode, type, date: sDate });
}

async function getAllInventorySheets(inventoryId) {
  return RestService.getAuthenticated('inventory/list/allInventorySheets', { inventoryId });
}

// POST
async function getListGenerateInventory(tableFilter) {
  return RestService.postAuthenticated('inventory/list/productByAddress', tableFilter);
}

async function inventorySheetCreation(sheetType, inventoryId, addressCode, productCode = '') {
  return RestService.postAuthenticated('inventory/inventorySheetCreation', {
    sheetType, inventoryId, addressCode, productCode,
  });
}

async function createInventory(params, sheetList) {
  return RestService.postAuthenticated('inventory/create', { ...params, sheetList });
}

async function printInventory(grouperCode) {
  const response = await RestService.postAuthenticated('inventory/printInventory', { grouperCode }, {
    'Content-Type': 'application/json',
    Accept: 'application/xlsx',
  }, { responseType: 'arraybuffer' });

  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  const nameDoc = grouperCode;
  const filePath = `${`${nameDoc.normalize('NFD').replace(/[\u0300-\u036f]/g, '')}_${moment().toISOString()}`.replace(/[\s+\-.]/g, '_')}.xlsx`;
  link.setAttribute('download', filePath);
  document.body.appendChild(link);
  link.click();
}

// PUT
async function reopenInventory(inventorySheetId) {
  return RestService.putAuthenticated('inventory/reopenInventory', { inventorySheetId });
}

async function changingCountingUser(checkedInventorySheetIds, inventoryCount, userId, typeAction) {
  return RestService.putAuthenticated('inventory/changingCountingUser', {
    checkedInventorySheetIds, inventoryCount, userId, typeAction,
  });
}

async function approveInventorySheets(inventoryId, inventorySheetIds) {
  return RestService.putAuthenticated('inventory/approveSheets', { inventoryId, inventorySheetIds });
}

async function cancelInventorySheets(inventoryId, inventorySheetIds, cancelNote) {
  return RestService.putAuthenticated('inventory/cancelSheets', { inventoryId, inventorySheetIds, cancelNote });
}

async function setUpdateStatusInventorySheet(inventoryId, inventorySheetIds, statusValue, cancelNote = '') {
  return RestService.putAuthenticated('inventory/updateStatusInventorySheet', {
    inventoryId, inventorySheetIds, statusValue, cancelNote,
  });
}

async function setUpdateStatusInventory(inventoryId, cancelNote) {
  return RestService.putAuthenticated('inventory/updateStatusInventory', { inventoryId, cancelNote });
}

async function finalizeInventoySheets(inventoryId, inventorySheetIds) {
  return RestService.putAuthenticated('inventory/finalizeSheets', { inventoryId, inventorySheetIds });
}

async function disapprovedInventorySheets(inventoryId, inventorySheetIds, disapprovedNote) {
  return RestService.putAuthenticated('inventory/disapprovedInventorySheets', {
    inventoryId,
    inventorySheetIds,
    disapprovedNote,
  });
}

export default {
  getListGenerateInventory,
  getInventorySheetsList,
  reopenInventory,
  inventorySheetCreation,
  getInventoryList,
  createInventory,
  getInventorySheetCounting,
  changingCountingUser,
  setUpdateStatusInventorySheet,
  setUpdateStatusInventory,
  getAllInventorySheets,
  getInventoryOptions,
  getAllGroupersByBranch,
  getDepositsByBranch,
  approveInventorySheets,
  cancelInventorySheets,
  finalizeInventoySheets,
  disapprovedInventorySheets,
  printInventory,
  getNewGrouperCode,
  getInventoryDashboardSheetsList,
};
