import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getDepositsList, deleteDeposit } from '../../../../app/store/actions/deposits';

class DepositList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      depositUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showDepositDetail(rows.row)}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showEditDeposit(rows.row)}>
                  {I18n.t('BEE57' /* Editar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showCopyDeposit(rows.row)}>
                  {I18n.t('BEE80' /* Copiar */)}
                </DropdownItem>
                <DropdownItem onClick={() => this.showDeleteDeposit(rows.row)}>
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE171' /* Aloca Saldo */),
        accessor: 'allocateBalance',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE36' /* Recebimento */),
        accessor: 'inbound',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE571' /* Revenda */),
        accessor: 'resale',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE572' /* Inventário */),
        accessor: 'inventory',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE176' /* Divergência */),
        accessor: 'divergence',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE573' /* Avaria */),
        accessor: 'damage',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE177' /* Rejeitados */),
        accessor: 'rejected',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE574' /* Qualidade */),
        accessor: 'quality',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      }, {
        Header: I18n.t('BEE789' /* Sobra */),
        accessor: 'spare',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      },
      {
        Header: I18n.t('BEE2832' /* Intermediário */),
        accessor: 'intermediary',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      },
      {
        Header: I18n.t('BEE2873' /* Fábrica */),
        accessor: 'factory',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      },
      {
        Header: I18n.t('BEE2875' /* Kanban */),
        accessor: 'kanban',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? I18n.t('BEE172' /* Sim */) : I18n.t('BEE173' /* Não */)}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          if (_.includes('sim', input)) {
            return id === true;
          } if (_.includes('nao', input)) {
            return id === false;
          }
        },
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getDeposits();
    } else {
      await this.getDeposits();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      depositUpdate: null,
    });
  };

  getDeposits = async () => {
    await this.props.getDepositsList();
  };

  deleteDeposit = async (deposit) => {
    const result = await this.props.deleteDeposit(deposit._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'danger',
        I18n.t('BEE178' /* Eliminar Depósito */),
        I18n.t('BEE179', { 0: deposit.code } /* Depósito %{0} eliminado com sucesso! */),
        'top-right',
      );
    }
    await this.getDeposits();
  };

  showDepositDetail = (deposit) => {
    this.props.history.push({
      pathname: ROUTES.DEPOSIT_DETAIL,
      state: {
        deposit: deposit._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditDeposit = (deposit) => {
    this.props.history.push({
      pathname: ROUTES.DEPOSIT_EDIT,
      state: {
        deposit: deposit._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyDeposit = (deposit) => {
    this.props.history.push({
      pathname: ROUTES.DEPOSIT_COPY,
      state: {
        deposit: deposit._original,
      },
    });
  };

  showDeleteDeposit = (deposit) => {
    this.setState({ showModalDelete: true, depositUpdate: deposit });
  };

  render() {
    const { depositUpdate, showModalDelete, page } = this.state;
    const { depositsList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE18' /* Depósitos */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          wikiHelp={ROUTES.DEPOSIT_HELP}
          headerTitle={I18n.t('BEE18' /* Depósitos */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.DEPOSIT_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE180' /* Incluir Depósito */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getDeposits,
          }}
          filterable
          data={depositsList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((
            !this.state.firstRun
            && this.props.location.state
            && this.props.location.state.dice && this.props.location.state.dice.page)
            ? this.props.location.state.dice.page : page)}
          onPageChange={(Page) => ((Page >= 0) ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteDeposit(depositUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE181', { 0: depositUpdate.code } /* O depósito %{0} será eliminado! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  depositsList: state.deposits && state.deposits.depositsList,
});

const mapDispatchToProps = (dispatch) => ({
  getDepositsList: () => dispatch(getDepositsList()),
  deleteDeposit: (depositId) => dispatch(deleteDeposit(depositId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DepositList));
