import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import {
  UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import DragAndDrop from '../../../../components/bulkImport/DragAndDrop';
import ImportCaption from '../../../../components/bulkImport/ImportCaption';
import OneClickButton from '../../../../components/form/button';

import { getJsonContent } from '../../../../app/store/actions/bulkImport';

import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import {
  getPickingAreaList,
  deletePickingArea,
  openBulkImportPickingArea,
  bulkImportAddressesPickingArea,
} from '../../../../app/store/actions/pickingAreas';

class PickingAreaList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      pickingAreaUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.showPickingAreaDetail(rows.row)}
                >
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.showEditPickingArea(rows.row)}
                >
                  {I18n.t('BEE57' /* Editar */)}
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.showCopyPickingArea(rows.row)}
                >
                  {I18n.t('BEE80' /* Copiar */)}
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.showDeletePickingArea(rows.row)}
                >
                  {I18n.t('BEE81' /* Eliminar */)}
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.showLinkAddressPickingArea(rows.row)}
                >
                  {I18n.t('BEE596' /* Vincular Endereços */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE2170' /* Descrição Produto */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
      }, {
        Header: I18n.t('BEE2613' /* Descrição da Área de Picking */),
        accessor: 'description',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'deposit.code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      },
    ];

    this.csvPreviewTable = [
      {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        sortable: false,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        sortable: false,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
      }, {
        Header: I18n.t('BEE1755' /* Código Endereço */),
        accessor: 'storageAddressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
      }, {
        Header: I18n.t('BEE2499' /* Ação */),
        accessor: 'action',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 50,
        sortable: false,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'error.error',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        sortable: false,
        Cell: (row) => (
          <span>
            {this.renderStatusValue(row.original)}
          </span>
        ),
      }, {
        Header: I18n.t('BEE2521' /* Linha do arquivo */),
        accessor: 'error.line',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 60,
        sortable: false,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'error.message',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        sortable: false,
      },
    ];

    this.renderStatusValue = (row) => {
      if (row?.error?.error === '') {
        return (
          <span style={{ color: 'green', fontWeight: '700' }}>
            {I18n.t('BEE2523' /* Processado com sucesso */)}
          </span>
        );
      }
      if (row?.error?.error) {
        return <span style={{ color: 'red', fontWeight: '700' }}>{I18n.t('BEE2520' /* Processado com erro */)}</span>;
      }
      if (!row.error) {
        return (
          <span style={{ color: '#F17B1E', fontWeight: '700' }}>
            {I18n.t('BEE2524' /* Aguardando processamento */)}
          </span>
        );
      }
    };
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getPickingAreas();
    } else {
      await this.getPickingAreas();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      pickingAreaUpdate: null,
    });
  };

  getPickingAreas = async () => {
    await this.props.getPickingAreaList();
  };

  deletePickingArea = async (pickingArea) => {
    const result = await this.props.deletePickingArea(pickingArea._original.id);
    this.setInitState();
    if (result.success) {
      addNotification(
        'success',
        I18n.t('BEE2611' /* Eliminar Área de Picking */),
        I18n.t('BEE2612', { 0: pickingArea.code } /* Área de Picking %{0} eliminado com sucesso! */),
        'top-right',
      );
    } else {
      addNotification(
        'danger',
        I18n.t('BEE2611' /* Eliminar Área de Picking */),
        result.message,
        'top-right',
      );
    }
    await this.getPickingAreas();
  };

  showPickingAreaDetail = (pickingArea) => {
    this.props.history.push({
      pathname: ROUTES.PICKING_AREA_DETAIL,
      state: {
        pickingArea: pickingArea._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditPickingArea = (pickingArea) => {
    this.props.history.push({
      pathname: ROUTES.PICKING_AREA_EDIT,
      state: {
        pickingArea: pickingArea._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyPickingArea = (pickingArea) => {
    this.props.history.push({
      pathname: ROUTES.PICKING_AREA_COPY,
      state: {
        pickingArea: pickingArea._original,
      },
    });
  };

  showDeletePickingArea = (pickingArea) => {
    this.setState({ showModalDelete: true, pickingAreaUpdate: pickingArea });
  };

  showLinkAddressPickingArea = (pickingArea) => {
    this.props.history.push({
      pathname: ROUTES.PICKING_AREA_LINK_ADDRESS,
      state: {
        pickingArea: pickingArea._original,
      },
    });
  };

  validProcessFile = async () => {
    if (this.props.jsonCsv === null) {
      addNotification(
        'danger',
        I18n.t('BEE2509' /* Importação em massa */),
        I18n.t('BEE2511' /* Nenhum arquivo selecionado */),
        'top-right',
      );
    } else await this.processFile();
  };

  processFile = async () => {
    const { jsonCsv } = this.props;
    const addressesPickingAreaErrors = await this.props.generateBulkImport(jsonCsv);
    await this.props.getJsonContent(addressesPickingAreaErrors);
  };

  cancelBulkImport = () => {
    this.props.setStatusBulkImport(!this.props.showBulkImport);
    this.props.getJsonContent(null);
  };

  render() {
    const { pickingAreaUpdate, showModalDelete, page } = this.state;
    const { showBulkImport, pickingAreasList = [], jsonCsv = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2570' /* Áreas de Picking */)}</li>
          </ol>
        </div>
        {
          this.props.showBulkImport ? (
            <>
              <h1 className="page-header mb-0">{I18n.t('BEE2570' /* Áreas de Picking */)}</h1>
              <div className="slideUpTransition">
                <div className="d-flex align-items-center mb-md-3 mb-2" />
                <div className="row">
                  <div className="col-xl-12">
                    <Panel>
                      <PanelHeader noButton />
                      <PanelBody>
                        <div className="row">
                          <div
                            style={{
                              justifyContent: 'space-evenly',
                              display: 'flex',
                              flex: 1,
                            }}
                          >
                            <ImportCaption
                              headers={[
                                { label: 'productCode', key: 'productCode' },
                                { label: 'branchCode', key: 'branchCode' },
                                { label: 'depositCode', key: 'depositCode' },
                                { label: 'storageAddressCode', key: 'storageAddressCode' },
                                { label: 'action', key: 'action' },
                              ]}
                              data={[
                                {
                                  productCode: '10',
                                  branchCode: '04041',
                                  depositCode: 'RV1',
                                  storageAddressCode: 'S08R10M01A01G08',
                                  action: 'C',
                                },
                                {
                                  productCode: '1000',
                                  branchCode: '04041',
                                  depositCode: 'RV1',
                                  storageAddressCode: 'S04R04M01A01G02',
                                  action: 'C',
                                },
                              ]}
                              filename="VincularPickingAreaXEnderecoEmLote.csv"
                              legend={[
                                {
                                  nameField: 'productCode',
                                  observationField: I18n.t('BEE1999' /* Código Produto */),
                                }, {
                                  nameField: 'branchCode',
                                  observationField: I18n.t('BEE1994' /* Código Filial */),
                                }, {
                                  nameField: 'depositCode',
                                  observationField: I18n.t('BEE431' /* Código do Depósito */),
                                }, {
                                  nameField: 'storageAddressCode',
                                  observationField: I18n.t('BEE1755' /* Código Endereço */),
                                }, {
                                  nameField: 'action',
                                  observationField: I18n.t(
                                    I18n.t('BEE2502', { 0: 'C', 1: 'D' }),
                                    /*
                                      Ação a ser executada '%{0}” ou “%{1}”! O código '%{0}” representa
                                      a ação de Criare o código '%{1}” representa a ação de Deletar
                                    */
                                  ),
                                },
                              ]}
                            />
                            <DragAndDrop />
                          </div>
                        </div>
                      </PanelBody>
                      <PanelFooter className="text-right">
                        <OneClickButton
                          type="submit"
                          className="btn btn-white m-5"
                          style={{ paddingRight: '25px', paddingLeft: '25px' }}
                          onClick={this.cancelBulkImport}
                        >
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                        <OneClickButton
                          type="submit"
                          style={{ paddingRight: '25px', paddingLeft: '25px' }}
                          className="btn btn-primary m-5"
                          onClick={() => this.validProcessFile()}
                        >
                          {I18n.t('BEE2496' /* Processar Arquivo */)}
                        </OneClickButton>
                      </PanelFooter>
                    </Panel>
                  </div>
                </div>
              </div>
              <div>
                <Table
                  downloadCSV
                  filterable
                  data={jsonCsv}
                  columns={this.csvPreviewTable}
                  expander
                  defaultPageSize={10}
                  defaultSorted={this.defaultSorted}
                  page={(page) || ((!this.state.firstRun
                     && this.props.location.state && this.props.location.state.dice
                     && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
                  onPageChange={(Page) => ((Page >= 0) ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
                  defaultFilterMethod={(filter, row) => {
                    const input = _.lowerCase(filter.value);
                    const value = _.lowerCase(row[filter.id]);
                    if (_.includes(value, input)) {
                      return true;
                    }
                  }}
                />
              </div>
            </>
          ) : (
            <Table
              downloadCSV
              wikiHelp={ROUTES.PICKING_AREA_HELP}
              headerTitle={I18n.t('BEE2570' /* Áreas de Picking */)}
              actionButtons={(
                <div className="ml-auto d-flex">
                  <div>
                    <button
                      type="button"
                      className="btn btn-success btn-sm btn-rounded pl-2"
                      onClick={() => this.props.setStatusBulkImport(!showBulkImport)}
                    >
                      <i className="fa fa-link mr-1" />
                      {I18n.t('BEE2614' /* Vincular Endereços / Remover em lote */)}
                    </button>
                  </div>

                  <Link to={ROUTES.PICKING_AREA_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3 ml-1">
                    <i className="fa fa-plus mr-1" />
                    {' '}
                    {I18n.t('BEE2571' /* Incluir Nova Áreas de Picking */)}
                  </Link>
                </div>
          )}
              panelHeaderProps={{
                onClickReload: this.getPickingAreas,
              }}
              filterable
              data={pickingAreasList}
              columns={this.tableColumns}
              expander
              defaultPageSize={10}
              defaultSorted={this.defaultSorted}
              page={(page) || (
                (!this.state.firstRun && this.props.location.state
           && this.props.location.state.dice && this.props.location.state.dice.page)
                  ? this.props.location.state.dice.page : page)}
              onPageChange={(Page) => ((Page >= 0)
                ? this.setState({ page: Page }) : this.setState({ page: 0 }))}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
            />
          )
        }
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deletePickingArea(pickingAreaUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE2610', { 0: pickingAreaUpdate.description } /* A Área de Picking %{0} será eliminado! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  pickingAreasList: state.pickingArea && state.pickingArea.pickingAreasList,
  showBulkImport: state.pickingArea.showBulkImport,
  jsonCsv: state.bulkImport.jsonCsv || [],
  fileCsv: state.bulkImport.fileCsv,
});

const mapDispatchToProps = (dispatch) => ({
  getPickingAreaList: () => dispatch(getPickingAreaList()),
  setStatusBulkImport: (showBulkImport) => dispatch(openBulkImportPickingArea(showBulkImport)),
  deletePickingArea: (pickingAreaId) => dispatch(deletePickingArea(pickingAreaId)),
  generateBulkImport: (jsonCsv) => dispatch(bulkImportAddressesPickingArea(jsonCsv)),
  getJsonContent: (jsonCsv) => dispatch(getJsonContent(jsonCsv)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PickingAreaList));
