import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import SweetAlert from 'react-bootstrap-sweetalert';

import { userConfirmation } from '../../app/store/actions/users'
import addNotification from '../notification';

class userConfirmationModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
    }
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row ">
      <div className="col-md-2">
        <label className="col-form-label">{label}</label>
      </div>
      <div className="col-md-9">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
      <div className="col-1" />
    </div>
  );

  showConfirm = async () => {
    const { userLogged } = this.props;
    const { password } = this.state;
    if (password === '') {
      addNotification('danger', I18n.t('BEE920' /* Senha usuário */), I18n.t('BEE1256' /* Senha deve ser informada ! */), 'top-right');
    } else {
      const result = await this.props.userConfirmation(userLogged.login, password);
      if (result && result.login) {
        this.props.confirm(true);
        // addNotification('success', I18n.t('BEE920' /* Senha usuário */), I18n.t('BEE1791' /* usuário autenticado */), 'top-right');
      } else {
        addNotification('danger', I18n.t('BEE920' /* Senha usuário */), I18n.t('BEE1077' /* Usuário ou senha incorretos ! */), 'top-right');
      }

      this.setState({
        password: ''
      });
    }
  }

  showCancel = () => {
    this.props.confirm(false);
    this.setState({
      password: ''
    });
  }

  render() {
    const { userLogged, openModal = false } = this.props;
    const { password, } = this.state;

    return (
      openModal && (
        <SweetAlert
          showCancel
          confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
          cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="default"
          title={I18n.t('BEE920' /* Senha usuário */)}
          onConfirm={() => this.showConfirm()}
          onCancel={() => this.showCancel()}
        >
          <div>
            <br />
            <br />
            {this.createInput(
              (userLogged && userLogged.login) ? userLogged.login : '', 'user', `${I18n.t('BEE1' /* Usuário */)}:`, '', 'text', false, true,
            )}
            {this.createInput(
              password, 'password', `${I18n.t('BEE2' /* Senha */)}:`, I18n.t('BEE894' /* Informe a senha (obrigatório) */) , 'password', true, false ,
            )}
          </div>
        </SweetAlert>
      )
    );
  }
}

const mapStateToProps = state => ({
  userLogged: state.app.userLogged,
});

const mapDispatchToProps = dispatch => ({
  userConfirmation: (login, password) => dispatch(userConfirmation(login, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(userConfirmationModal));