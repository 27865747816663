import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import OneClickButton from '../../components/form/button';
import { PanelPage } from '../../components/pages/pages';
import DualList from '../../components/pages/DualList';
import Form from '../../components/form/form';
import { generateReport } from '../../app/store/actions/reports';
import FilterDefault from '../../components/filters/FilterDefault';
import { returnFiltersByRules } from './utils';
import ROUTES from '../../config/routes';
import ReportsBackgroundModal from '../../components/pages/reportsBackgroundModal';

class ReportAdminProductProducts extends React.PureComponent {
  constructor() {
    super();

    this.breadcrumb = [
      {
        value: (<>
          <i className="fas fa-home fa-fw m-t-10 m-r-5" />
          {' '}
          {I18n.t('BEE12' /* Início */)}
                </>),
      },
      { value: I18n.t('BEE47' /* Relatórios */) },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE27' /* Produtos */) },
      { value: I18n.t('BEE27' /* Produtos */), active: true },
    ];

    this.options = [
      {
        label: I18n.t('BEE82' /* Código */),
        value: 'productCode',
      }, {
        label: I18n.t('BEE277' /* Descrição */),
        value: 'name',
      }, {
        label: I18n.t('BEE692' /* Descrição Completa */),
        value: 'fullName',
      }, {
        label: I18n.t('BEE695' /* Código Complementar */),
        value: 'complementCode',
      }, {
        label: I18n.t('BEE696' /* Informação Complementar */),
        value: 'complementInfo',
      }, {
        label: I18n.t('BEE204' /* Situação */),
        value: 'status',
      }, {
        label: I18n.t('BEE135' /* Observação */),
        value: 'note',
      }, {
        label: I18n.t('BEE698' /* Armazena Próximo Picking */),
        value: 'storeNextPicking',
      }, {
        label: I18n.t('BEE699' /* Fraciona */),
        value: 'fractional',
      }, {
        label: I18n.t('BEE700' /* Efetuar Picking */),
        value: 'picking',
      }, {
        label: I18n.t('BEE29' /* Unidades de Medida */),
        value: 'unitMeasure',
      }, {
        label: I18n.t('BEE562' /* Volume */),
        value: 'volume',
      }, {
        label: I18n.t('BEE386' /* Altura */),
        value: 'height',
      }, {
        label: I18n.t('BEE387' /* Largura */),
        value: 'width',
      }, {
        label: I18n.t('BEE388' /* Comprimento */),
        value: 'length',
      }, {
        label: I18n.t('BEE564' /* Peso Bruto */),
        value: 'grossWeight',
      }, {
        label: I18n.t('BEE563' /* Peso Líquido */),
        value: 'netWeight',
      }, {
        label: I18n.t('BEE701' /* Controlar Vencimento */),
        value: 'controlExpirationDate',
      }, {
        label: I18n.t('BEE702' /* Entrada Pré Vencimento */),
        value: 'inboundPreExpiration',
      }, {
        label: I18n.t('BEE703' /* Saída Pré Vencimento */),
        value: 'outboundPreExpiration',
      }, {
        label: I18n.t('BEE705' /* Dias de Vida */),
        value: 'lifeDays',
      }, {
        label: I18n.t('BEE706' /* Sobra Mínima */),
        value: 'minimalLeftOver',
      }, {
        label: I18n.t('BEE707' /* Tipo de Controle do Produto */),
        value: 'productControlType',
      }, {
        label: I18n.t('BEE708' /* Tipo de Controle de Estoque */),
        value: 'stockControlType',
      }, {
        label: I18n.t('BEE709' /* Origem */),
        value: 'origin',
      }, {
        label: I18n.t('BEE710' /* Classificação Fiscal */),
        value: 'taxClassification',
      }, {
        label: I18n.t('BEE711' /* Unidade de Negócio */),
        value: 'businessUnit',
      }, {
        label: I18n.t('BEE712' /* Fator Conversão */),
        value: 'conversionFactor',
      }, {
        label: I18n.t('BEE713' /* Lote Múltiplo Venda */),
        value: 'multipleSale',
      }, {
        label: I18n.t('BEE694', { 0: 1 } /* Nível %{0} */),
        value: 'level1',
      }, {
        label: I18n.t('BEE694', { 0: 2 } /* Nível %{0} */),
        value: 'level2',
      }, {
        label: I18n.t('BEE694', { 0: 3 } /* Nível %{0} */),
        value: 'level3',
      }, {
        label: I18n.t('BEE694', { 0: 4 } /* Nível %{0} */),
        value: 'level4',
      }, {
        label: I18n.t('BEE694', { 0: 5 } /* Nível %{0} */),
        value: 'level5',
      }, {
        label: I18n.t('BEE575' /* Usuário de criação */),
        value: 'createdUser',
      }, {
        label: I18n.t('BEE578' /* Data de criação */),
        value: 'createdAt',
      }, {
        label: I18n.t('BEE576' /* Usuário de alteração */),
        value: 'updatedUser',
      }, {
        label: I18n.t('BEE577' /* Data de alteração */),
        value: 'updatedAt',
      },
    ];

    this.state = {
      selected: [],
      productCodeFrom: '',
      productCodeTo: '',
      nameFrom: '',
      nameTo: '',
      taxClassificationFrom: '',
      taxClassificationTo: '',
      businessUnitFrom: '',
      businessUnitTo: '',
      status: '',
      storeNextPicking: '',
      fractional: '',
      picking: '',
      Serial: '',
      serialNumber: '',
      lot: '',
      reference: '',
      physicist: '',
      total: '',
      consigned: '',
      directDebit: '',
      specific: '',
    };
  }

  onChangeDualList = (selected) => {
    this.setState({ selected });
  };

  setValue = async (attr, value, label) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  onGenerateSpreadsheet = () => {
    const {
      selected,
      productCodeFrom,
      productCodeTo,
      nameFrom,
      nameTo,
      taxClassificationFrom,
      taxClassificationTo,
      businessUnitFrom,
      businessUnitTo,
      status,
      storeNextPicking,
      fractional,
      picking,
      Serial,
      serialNumber,
      lot,
      reference,
      physicist,
      total,
      consigned,
      directDebit,
      specific,
    } = this.state;
    const { location } = this.props;
    const productControlType = [];
    const stockControlType = [];

    if (Serial) productControlType.push(1);
    if (serialNumber) productControlType.push(2);
    if (lot) productControlType.push(3);
    if (reference) productControlType.push(4);

    if (physicist) stockControlType.push(1);
    if (total) stockControlType.push(2);
    if (consigned) stockControlType.push(3);
    if (directDebit) stockControlType.push(4);
    if (specific) stockControlType.push(5);

    const headers = selected.map((it) => ({ ...(this.options.find((itb) => itb.value === it) || {}) }));
    const filters = returnFiltersByRules([
      ['productCode', { type: 'between', value: [productCodeFrom, productCodeTo] }],
      ['name', { type: 'between', value: [nameFrom, nameTo] }],
      ['taxClassification', { type: 'between', value: [taxClassificationFrom, taxClassificationTo] }],
      ['businessUnit', { type: 'between', value: [businessUnitFrom, businessUnitTo] }],
      ['status', { type: 'and', value: status }],
      ['storeNextPicking', { type: 'and', value: storeNextPicking }],
      ['fractional', { type: 'and', value: fractional }],
      ['picking', { type: 'and', value: picking }],
      ['productControlType', { type: 'in', value: productControlType }],
      ['stockControlType', { type: 'in', value: stockControlType }],
    ]);

    this.props.generateReport(
      I18n.t('BEE27' /* Produtos */).toLowerCase(),
      'xlsx',
      location.pathname,
      headers,
      filters,
    );
  };

  onSelectFilter = (newState) => this.setState({ ...newState });

  render() {
    const {
      selected,
      productCodeFrom,
      productCodeTo,
      nameFrom,
      nameTo,
      taxClassificationFrom,
      taxClassificationTo,
      businessUnitFrom,
      businessUnitTo,
      status,
      storeNextPicking,
      fractional,
      picking,
      serial,
      serialNumber,
      lot,
      reference,
      physicist,
      total,
      consigned,
      directDebit,
      specific,
    } = this.state;

    const { location = {} } = this.props;

    return (
      <PanelPage
        breadcrumb={this.breadcrumb}
        title={`${I18n.t('BEE528' /* Relatório */)} - ${I18n.t('BEE27' /* Produtos */)}`}
        wikiHelp={ROUTES.REPORT_HELP}
        content={(
          <div style={{ marginLeft: 20, marginRight: 20 }}>
            <FilterDefault stateToSave={this.state} route={location.pathname} onSelectFilter={this.onSelectFilter} />
            <div className="d-flex mb-md-4 mb-4 mt-4" />
            <DualList
              id="dualselect"
              options={this.options}
              selected={selected}
              onChange={this.onChangeDualList}
              preserveSelectOrder
              showOrderButtons
              showHeaderLabels
            />
            <div className="d-flex align-items-center mb-md-3 mb-1 mt-5">
              <h1 className="page-header mb-0">
                {I18n.t('BEE546' /* Filtros */)}
              </h1>
            </div>
            <hr />
            <div style={{ marginTop: 30 }}>
              <Form
                noPanel
                leftType
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createInputRange(
                    { from: productCodeFrom, to: productCodeTo },
                    { from: 'productCodeFrom', to: 'productCodeTo' },
                    `${I18n.t('BEE82' /* Código */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: nameFrom, to: nameTo },
                    { from: 'nameFrom', to: 'nameTo' },
                    `${I18n.t('BEE277' /* Descrição */)}:`,
                    { from: '', to: 'Z'.repeat(100) },
                  ),
                  formContext.createInputRange(
                    { from: taxClassificationFrom, to: taxClassificationTo },
                    { from: 'taxClassificationFrom', to: 'taxClassificationTo' },
                    `${I18n.t('BEE710' /* Classificação Fiscal */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createInputRange(
                    { from: businessUnitFrom, to: businessUnitTo },
                    { from: 'businessUnitFrom', to: 'businessUnitTo' },
                    `${I18n.t('BEE711' /* Unidade de Negócio */)}:`,
                    { from: '', to: 'Z'.repeat(20) },
                  ),
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE499' /* Ativo */),
                      value: 1,
                    },
                    {
                      label: I18n.t('BEE498' /* Obsoleto */),
                      value: 0,
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], status, 'status', I18n.t('BEE204' /* Situação */)),
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: 1,
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: 0,
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], storeNextPicking, 'storeNextPicking', I18n.t('BEE698' /* Armazena Próximo Picking */)),
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: 1,
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: 0,
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], fractional, 'fractional', I18n.t('BEE699' /* Fraciona */)),
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: 1,
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: 0,
                    },
                    {
                      label: I18n.t('BEE203' /* Ambos */),
                      value: '',
                    },
                  ], picking, 'picking', I18n.t('BEE700' /* Efetuar Picking */)),
                  formContext.createCheckBoxes([
                    {
                      label: I18n.t('BEE500' /* Serial */),
                      value: serial,
                      attr: 'serial',

                    }, {
                      label: I18n.t('BEE501' /* Número Série */),
                      value: serialNumber,
                      attr: 'serialNumber',
                    }, {
                      label: I18n.t('BEE428' /* Lote */),
                      value: lot,
                      attr: 'lot',
                    }, {
                      label: I18n.t('BEE502' /* Referência */),
                      value: reference,
                      attr: 'reference',
                    },
                  ], `${I18n.t('BEE707' /* Tipo de Controle do Produto */)}:`),
                  formContext.createCheckBoxes([
                    {
                      label: I18n.t('BEE503' /* Físico */),
                      value: physicist,
                      attr: 'physicist',
                    }, {
                      label: I18n.t('BEE504' /* Total */),
                      value: total,
                      attr: 'total',
                    }, {
                      label: I18n.t('BEE505' /* Consignado */),
                      value: consigned,
                      attr: 'consigned',
                    }, {
                      label: I18n.t('BEE506' /* Débito Direto */),
                      value: directDebit,
                      attr: 'directDebit',
                    }, {
                      label: I18n.t('BEE507' /* Específico */),
                      value: specific,
                      attr: 'specific',
                    },
                  ], `${I18n.t('BEE708' /* Tipo de Controle de Estoque */)}:`),
                ])}
              />
            </div>
            <ReportsBackgroundModal />
          </div>
        )}
        footerContent={(
          <OneClickButton
            type="button"
            style={{ width: 'auto', padding: 10 }}
            className="btn btn-primary p-5 m-5"
            onClick={this.onGenerateSpreadsheet}
          >
            {I18n.t('BEE544' /* Gerar Planilha */)}
          </OneClickButton>
        )}
      />
    );
  }
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
  generateReport: (
    nameDoc,
    type,
    route,
    columns,
    filters,
  ) => dispatch(generateReport(nameDoc, type, route, columns, filters)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportAdminProductProducts);
