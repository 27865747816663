import RestService from '../services/rest';

// GET
async function getInboundStorageProducts(baptismLabel) {
  return RestService.getAuthenticated(
    `inboundStorage/baptism?baptismLabel=${baptismLabel}`,
  );
}

async function getInboundStorageProductByEan(ean, sequence, baptismLabel) {
  return RestService.getAuthenticated(
    `inboundStorage/productByEan?baptism=${baptismLabel}&ean=${ean}&sequence=${sequence}`,
  );
}

// PUT
async function confirmStorage(
  baptism,
  inboundOrderProductId,
  addressSelected,
  quantity,
  lotNumber,
  sequence,
  productLotId,
  productPartialId,
  divergenceNoteStorage,
  divergenceStorage,
  expirationDate,
  serialGroupId,
) {
  return RestService.putAuthenticated('inboundStorage/confirmStorage', {
    baptism,
    inboundOrderProductId,
    addressSelected,
    quantity,
    lotNumber,
    sequence,
    productLotId,
    productPartialId,
    divergenceNoteStorage,
    divergenceStorage,
    expirationDate,
    serialGroupId,
  });
}

async function syncStorage(inboundOrderProductId) {
  return RestService.putAuthenticated('inboundStorage/syncStorage', {
    inboundOrderProductId,
  });
}

export default {
  getInboundStorageProducts,
  getInboundStorageProductByEan,
  confirmStorage,
  syncStorage,
};
