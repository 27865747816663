import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import SweetAlert from 'react-bootstrap-sweetalert';
import CriteriaRules from '../../../helpers/resupplyStatus';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import { deleteRule, getResupplyRules } from '../../../app/store/actions/resupplyRules';
import addNotification from '../../../components/notification';

class GenerateResupplyList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rulesResupply: [],
      showModalDelete: false,
      ruleUpdate: null,
      rulesName: null,
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        minWidth: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button
              type="button"
              className="btn btn-default btn-xs"
            >
              {I18n.t('BEE55' /* Ações */)}
            </button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showRulesDetail(rows.row)}>
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>

                <>
                  <DropdownItem onClick={() => this.showEditRules(rows.row)}>
                    {I18n.t('BEE57' /* Editar */)}
                  </DropdownItem>
                  <DropdownItem onClick={() => this.showCopyRules(rows.row)}>
                    {I18n.t('BEE80' /* Copiar */)}
                  </DropdownItem>
                  <DropdownItem onClick={() => this.showDeleteRule(rows.row._original)}>
                    {I18n.t('BEE81' /* Eliminar */)}
                  </DropdownItem>
                </>

              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2667' /* Código da Regra */),
        accessor: 'rulesCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2668' /* Família Material */),
        accessor: 'materialFamily',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Cell: (row) => (
          <span>
            {' '}
            {`${row.original.materialFamily}`}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2463' /* Curvas */),
        accessor: 'curves',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <span>
            {' '}
            {`${row.original.curves}`}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE27' /* Produtos */),
        id: 'productCodeFrom_productCodeto',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Cell: (row) => (
          <span>
            {' '}
            {`${row.original.productCodeFrom} 
            < > 
            ${row.original.productCodeTo}`}
          </span>
        ),
        filterable: false,
      }, {
        Header: I18n.t('BEE20' /* Endereços */),
        id: 'storageAddressCodeFrom_storageAddressCodeFrom',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Cell: (row) => (
          <span>
            {' '}
            {`${row.original.storageAddressCodeFrom} 
            < > 
            ${row.original.storageAddressCodeTo}`}
          </span>
        ),
        filterable: false,
      }, {
        Header: I18n.t('BEE279' /* Setor */),
        id: 'sectorFrom_sectorTo',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Cell: (row) => (
          <span>
            {' '}
            {`${row.original.sectorFrom} 
            < > 
            ${row.original.sectorTo}`}
          </span>
        ),
        filterable: false,
      }, {
        Header: I18n.t('BEE121' /* Rua */),
        id: 'storageRangeCodeFrom_storageRangeCodeTo',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Cell: (row) => (
          <span>
            {' '}
            {`${row.original.storageRangeCodeFrom}
             < > 
             ${row.original.storageRangeCodeTo}`}
          </span>
        ),
        filterable: false,
      }, {
        Header: I18n.t('BEE281' /* Coluna */),
        id: 'columnFrom_columnTo',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Cell: (row) => (
          <span>
            {' '}
            {`${row.original.columnFrom} 
            < > 
            ${row.original.columnTo}`}
          </span>
        ),
        filterable: false,
      }, {
        Header: I18n.t('BEE283' /* Nível */),
        id: 'levelFrom_levelTo',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Cell: (row) => (
          <span>
            {' '}
            {`${row.original.levelFrom} 
            < > 
            ${row.original.levelTo}`}
          </span>
        ),
        filterable: false,
      }, {
        Header: I18n.t('BEE285' /* Gaveta */),
        id: 'drawerFrom_drawerTo',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Cell: (row) => (
          <span>
            {' '}
            {`${row.original.drawerFrom} 
            < > 
            ${row.original.drawerTo}`}
          </span>
        ),
        filterable: false,
      }, {
        Header: I18n.t('BEE2669' /* Critério do Ressuprimento */),
        accessor: 'resupplyCriteria',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 450,
        Cell: (row) => (
          <span>{CriteriaRules.resupplyCriteria(row.value)}</span>
        ),
        Filter: ({ filter, onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {CriteriaRules.resupplyCriteria().map((element, index) => (
              <option key={index} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        ),
      },
    ];
  }

  componentDidMount() {
    this.getRules();
  }

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      ruleUpdate: null,
      rulesName: null,
    });
  };

  getRules = async () => {
    try {
      const rulesResupply = await this.props.getResupplyRules();
      this.setState({ rulesResupply });
    } catch (e) {
      addNotification(
        'danger',
        I18n.t('BEE1627' /* Erro */),
        I18n.t('BEE1969' /* Erro ao buscar dados */),
        'top-right',
      );
    }
  };

  filterColumn = ({ filter, onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      value={(filter && filter.value) || ''}
      onChange={(e) => onChange(e.target.value)}
      autoComplete="nope"
    />
  );

  showRulesDetail = (row) => {
    this.props.history.push({
      pathname: ROUTES.MOVEMENT_RESUPPLY_RULES_DETAIL,
      state: {
        rules: row._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditRules = (row) => {
    this.props.history.push({
      pathname: ROUTES.MOVEMENT_RESUPPLY_RULES_EDIT,
      state: {
        rules: row._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyRules = (row) => {
    this.props.history.push({
      pathname: ROUTES.MOVEMENT_RESUPPLY_RULES_COPY,
      state: {
        rules: row._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showDeleteRule = (rule) => {
    this.setState({ showModalDelete: true, ruleUpdate: rule.id, rulesName: rule.rulesCode });
  };

  deleteRule = async (ruleId) => {
    const result = await this.props.deleteRule(ruleId);

    this.setInitState();
    if (result) {
      addNotification(
        'danger',
        I18n.t('BEE2670' /* Eliminar Regra */),
        I18n.t('BEE2671' /* Regra eliminada com sucesso! */),
        'top-right',
      );
    }
    await this.getRules();
  };

  render() {
    const {
      page, showModalDelete, ruleUpdate, rulesName,
    } = this.state;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE2642' /* Ressuprimento */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE2665' /* Regras Ressuprimento Automático */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          wikiHelp={ROUTES.MOVEMENT_RESUPPLY_GENERATE_HELP}
          headerTitle={I18n.t('BEE2665' /* Regras Ressuprimento Automático */)}
          actionButtons={(
            <div className="ml-auto">
              <Link
                to={ROUTES.MOVEMENT_RESUPPLY_RULES_CREATE}
                className="btn btn-success btn-sm btn-rounded pl-2 pr-3"
              >
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE2699' /* Incluir Regra */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getRules,
          }}
          filterable
          data={this.state.rulesResupply}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun
            && this.props.location.state && this.props.location.state.dice
            && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page)}
          onPageChange={(page1) => ((page1 >= 0) ? this.setState({ page: page1 }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
            <SweetAlert
              danger
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE101' /* Você tem certeza? */)}
              onConfirm={() => this.deleteRule(ruleUpdate)}
              onCancel={() => this.setInitState()}
            >
              A regra
              {' '}
              {rulesName}
              {' '}
              será eliminada!
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  getResupplyRules: () => dispatch(getResupplyRules()),
  deleteRule: (rulesId) => dispatch(deleteRule(rulesId)),
});

export default connect(undefined, mapDispatchToProps)(withRouter(GenerateResupplyList));
