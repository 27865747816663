/* eslint-disable class-methods-use-this */
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { I18n } from 'react-redux-i18n';
import { Panel, PanelBody, PanelHeader } from '../panel/panel';

class BarChart extends React.PureComponent {
  constructor() {
    super();

    this.state = {

    };
  }

  handleFormatter = (data) => {
    const { isVerticalBar, useFormatter = true, decimalNumber = 0 } = this.props;
    if (useFormatter) {
      data.dataLabels = {
        offsetY: isVerticalBar ? 10 : 0,
        formatter(val, opts) {
          if (isVerticalBar) {
            return [`Qtd: ${opts.w.config.series[opts.seriesIndex].data[opts.dataPointIndex]}`, `(${val.toFixed()}%)`];
          }
          if (decimalNumber) {
            return `${val.toFixed(decimalNumber)} %`.replace('.', ',');
          }
          return `Qtd: ${opts.w.config.series[opts.seriesIndex].data} (${val.toFixed()}%)`;
        },
      };
    }
    return data;
  };

  render() {
    const { barChartValues, height, title } = this.props;
    return (
      <Panel>
        <PanelHeader onClickCollapse onClickReload={this.props.onClickReload}>
          {title}
        </PanelHeader>
        <PanelBody>
          <div id="chart" style={{ height: `${this.props.height ? this.props.height : '150'}px` }}>
            {barChartValues
              ? (
                <div>
                  <ReactApexChart
                    options={this.handleFormatter(barChartValues.options)}
                    series={barChartValues.series}
                    type="bar"
                    height={height || 150}
                  />
                </div>
              )
              : (
                <div
                  className="d-flex flex-column w-100"
                  style={{ height: `${this.props.height ? this.props.height : '150'}px` }}
                >
                  <div
                    className="d-flex align-items-center justify-content-center user-select-none"
                    style={{
                      flex: 'auto', color: '#9b8a79', fontSize: '1rem', fontFamily: 'Helvetica',
                    }}
                  >
                    {I18n.t('BEE2423' /* Não há dados no filtro informado */)}
                  </div>
                </div>
              )}
          </div>
        </PanelBody>
      </Panel>
    );
  }
}

export default BarChart;
