export const getJson = (jsonCsv) => ({
  type: 'GET_JSON_CSV',
  jsonCsv,
});

export const getFile = (fileCsv) => ({
  type: 'GET_FILE_CSV',
  fileCsv,
});

export const getJsonContent = (json) => async (dispatch) => {
  dispatch(getJson(json));
};
