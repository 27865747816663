import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';
import { PanelPage } from '../../../../components/pages/pages';
import ROUTES from '../../../../config/routes';
import Form from '../../../../components/form/form';
import { getCustomerOptions, getBranchOptions, customerBranchCreate, customerBranchUpdate } from '../../../../app/store/actions/customerBranch'


class CustomerForm extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      { value: (<React.Fragment><i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}</React.Fragment>) },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE14' /* Gerais */) },
    ];

    this.state = {
      customerBranchId: '',
      branchCode: '',
      branchName: '',
      customerCode: '',
      customerName: '',
      note: '',
      showCancel: false,
      showCreate: false,
      restriction: false,
      disabled: true,
      title: I18n.t('BEE2026' /* Detalhes Cliente x Filial */),
      customerOptions: [],
      branchOptions: [],
    };

    this.initialState = this.state;
  }

  componentDidMount() {
    this.setMode();
  }

  customerBranchSelected = async () => {
    if (this.props.location.state.customerBranch) {
      const { customerBranch } = this.props.location.state;
      if (customerBranch) {
        this.setState({
          customerBranchId: customerBranch.id,
          branchCode: customerBranch.branchCode,
          branchName: customerBranch.branchName,
          customerCode: customerBranch.customerCode,
          customerName: customerBranch.customerName,
          restriction: customerBranch.restriction,
          note: customerBranch.note,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    switch (mode) {
      case 'create':
      case 'copy':
        this.setState({
          showCancel: true,
          showCreate: true,
          disabled: false,
          title: I18n.t('BEE2024' /* Novo Cliente x Filial */)
        });
        this.breadcrumb.push({ value: I18n.t('BEE2024' /* Novo Cliente x Filial */), active: true });
        this.getCustomerOptions();
        this.getBranchOptions();
        if (mode === 'copy') this.customerBranchSelected();
        break;
      case 'edit':
        this.setState({
          showCancel: true,
          showCreate: false,
          disabled: false,
          title: I18n.t('BEE2025' /* Editar Cliente x Filial */)
        });
        this.breadcrumb.push({ value: I18n.t('BEE2025' /* Editar Cliente x Filial */), active: true });
        this.getCustomerOptions();
        this.getBranchOptions();
        this.customerBranchSelected();
        break;
      default:
        this.setState({
          ...this.initialState,
        })
        this.breadcrumb.push({ value: I18n.t('BEE2026' /* Detalhes Cliente x Filial */), active: true });
        this.customerBranchSelected();
        break;
    }
  }

  setValue = async (attr, value,) => {
    this.setState({
      [`${attr}`]: value,
    });
  }

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  }

  getCustomerOptions = async () => {
    try {
      const customerOptions = await this.props.getCustomerOptions()

      if (customerOptions && customerOptions.success === false) {
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');

      } else {
        this.setState({ customerOptions })
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');
      }
    }
  }

  getBranchOptions = async () => {
    try {
      const branchOptions = await this.props.getBranchOptions()

      if (branchOptions && branchOptions.success === false) {
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');

      } else {
        this.setState({ branchOptions })
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE1969' /* Erro ao buscar dados */), 'top-right');
      }
    }
  }

  customerBranchCreate = async () => {
    const { branchCode, customerCode, restriction, note } = this.state;
    try {
      const result = await this.props.customerBranchCreate({ customerCode, branchCode, restriction, note })

      if (result && result.success === false) {
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');

      } else {
        this.props.history.push(ROUTES.CUSTOMER_BRANCH_LIST);
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');
      }
    }
  }

  customerBranchUpdate = async () => {
    const { customerBranchId, branchCode, customerCode, restriction, note } = this.state;
    try {
      const result = await this.props.customerBranchUpdate({ customerBranchId, customerCode, branchCode, restriction, note })

      if (result && result.success === false) {
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');

      } else {
        this.props.history.push(ROUTES.CUSTOMER_BRANCH_LIST);
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details) ?
          `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */), messageError, 'top-right');

      } else {
        addNotification('danger', I18n.t('BEE2020' /* Cliente x Filial */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */), 'top-right');
      }
    }
  }

  render() {
    const {
      showCancel, showCreate, title, branchCode, customerCode,
      branchName, customerName, restriction, disabled, customerOptions,
      branchOptions, note,
    } = this.state;

    return (
      <>
        <PanelPage
          noButton
          breadcrumb={this.breadcrumb}
          title={title}
          content={(
            <div>
              <Form
                noPanel
                setValue={this.setValue}
                setValueDrop={this.setValueDrop}
                inputs={(formContext) => ([
                  formContext.createSelectDropDown(
                    { value: branchCode, label: branchName }, 'branch',
                    `${I18n.t('BEE145' /* Filial */)}: `, branchOptions, disabled
                  ),
                  formContext.createSelectDropDown(
                    { value: customerCode, label: customerName }, 'customer',
                    `${I18n.t('BEE50' /* Cliente */)}: `, customerOptions, disabled
                  ),
                  formContext.createRadioButtons([
                    {
                      label: I18n.t('BEE172' /* Sim */),
                      value: true,
                      disabled: disabled,
                    },
                    {
                      label: I18n.t('BEE173' /* Não */),
                      value: false,
                      disabled: disabled,
                    },
                  ], restriction, 'restriction', `${I18n.t('BEE2022' /* Restrição */)}: `, undefined, '', 'col-md-0'),
                  formContext.createTextArea(
                    note, 'note', `${I18n.t('BEE135' /* Observação */)}:`,
                    I18n.t('BEE136' /* Informe a observação */), 3, false, disabled,
                  ),
                ])}
              />
            </div>
          )}
          footerContent={(
            <>
              <Link to={{ pathname: ROUTES.CUSTOMER_BRANCH_LIST }}>
                {(showCancel
                  ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                  : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
              </Link>
              {showCancel && (
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={(showCreate) ? this.customerBranchCreate : this.customerBranchUpdate}
                >{(showCreate) ? I18n.t('BEE138' /* Criar */) : I18n.t('BEE139' /* Atualizar */)}
                </OneClickButton>
              )}
            </>
          )}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getCustomerOptions: () => dispatch(getCustomerOptions()),
  getBranchOptions: () => dispatch(getBranchOptions()),
  customerBranchCreate: (parametersForCreation) => dispatch(customerBranchCreate(parametersForCreation)),
  customerBranchUpdate: (customerBranchId, customerCode, branchCode, restriction, note) => dispatch(customerBranchUpdate(customerBranchId, customerCode, branchCode, restriction, note)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CustomerForm));