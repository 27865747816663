import React from 'react';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import OneClickButton from '../../../components/form/button';
import addNotification from '../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../components/panel/panel';

import { doStockTransfer, getInformation, getProductByEan } from '../../../app/store/actions/stocks';
import { getSerialGroupDetail } from '../../../app/store/actions/serialGroup';
import { getStorageAddressesOptions } from '../../../app/store/actions/storageAddresses';
import Select from '../../../components/form/select';
import { getDepositsOptions } from '../../../app/store/actions/deposits';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

class TransferForm extends React.Component {
  constructor(props) {
    super(props);

    this.colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, {
        isDisabled, isSelected,
      }) => ({
        ...styles,
        backgroundColor: isDisabled ? 'grey' : 'white',
        color: isDisabled ? 'grey' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        textAlign: 'left',

        ':hover': {
          ...styles[':hover'],
          backgroundColor: isSelected ? 'grey' : '#eb900a',
          color: 'white',
        },
      }),
    };

    this.state = {
      ean: '',
      productCode: null,
      productName: null,
      unitMeasure: null,
      quantity: null,
      pickupAddress: null,
      pickupAddressName: '',
      storageAddress: null,
      storageAddressName: '',
      lotNumber: null,
      showForm: false,
      showLotNumber: false,
      controlExpirationDate: false,
      expirationDate: '',
      productSelected: null,
      isSerialControl: false,
      listDeposit: [],
      depositCode: '',
      depositName: '',
    };
  }

  async componentDidMount() {
    const listStorageAddress = await this.props.getStorageAddressesOptions();
    const listDeposit = await this.props.getDepositsOptions();

    this.setState({
      listStorageAddress,
      listDeposit,
    });
  }

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value.toUpperCase())}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals, disabled = false) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <NumberFormat
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={(!this.props.fractionalQuantity) ? 0 : decimals}
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue);
          }}
          disabled={disabled}
          fixedDecimalScale
          defaultValue={0}
        />
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items, disabled = false) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value || ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          isDisabled={disabled}
          styles={this.colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  customDateStyle = ({ className, children }) => (
    <div>
      <div className="bg-primary rounded p-2">
        <h5 className="text-light text-center pt-2">
          Bee
          <span className="text-dark">Stock</span>
        </h5>
      </div>
      <div className={className}>
        <div>{children}</div>
      </div>
    </div>
  );

  createDateInput = (value, attr, label, disabled, id = null) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <div style={{ display: 'flex' }}>
          <DatePicker
            isClearable={!disabled}
            id={id && id}
            selected={value || ''}
            className="form-control"
            dateFormat="dd/MM/yyyy"
            value={value || ''}
            onChange={(e) => this.setValue(attr, e)}
            calendarContainer={this.customDateStyle}
            disabled={disabled}
            // customInput={
            //   <InputMask
            //     className="form-control"
            //     mask={'99/99/9999'}
            //   ></InputMask>
            // }
          />
        </div>
      </div>
    </div>
  );

  goToElement = (e, id, attr) => {
    if (e.keyCode === 13) {
      if (attr === 'ean') {
        if (!this.state.showForm) {
          this.eanSearch();
        }
      } else if (attr === 'lotNumber' && this.state.controlExpirationDate) {
        document.getElementById(3).focus();
        document.getElementById(3).select();
      } else if (attr === 'serialGroupCode') {
        if (!this.state.showForm) {
          this.serialGroupSearch();
        }
      }
    }
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Code`]: value ? value.code || value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });

    if (attr === 'pickupAddress' && value && value.code) {
      this.getInformation();
    } else if (attr === 'deposit' && value && value.value) {
      const { user } = this.props;
      const isSerialControl = (value.serialLabel
        && (user && user.mainBranchData ? user.mainBranchData.serialControlDeposit : false)) || false;

      this.setState({ isSerialControl });
    }
  };

  resetForm = () => {
    this.setState({
      ean: '',
      productCode: null,
      productName: null,
      unitMeasure: null,
      quantity: null,
      pickupAddress: null,
      pickupAddressName: '',
      storageAddress: null,
      storageAddressName: '',
      lotNumber: null,
      showForm: false,
      showLotNumber: false,
      controlExpirationDate: false,
      serialGroupCode: '',
      serialGroupId: null,
      depositCode: '',
      depositName: '',
    });
  };

  serialGroupSearch = async () => {
    const { serialGroupCode, depositCode } = this.state;

    try {
      if (serialGroupCode) {
        const serialGroup = await this.props.getSerialGroupDetail(serialGroupCode, depositCode);

        if (serialGroup) {
          await this.setSerialGroupSelected(serialGroup);
        } else {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            I18n.t('BEE2833', { 0: serialGroupCode } /* Etiqueta agrupadora %{0} não encontrada! */),
            'top-right',
          );
          this.setValue('showForm', false);
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE34' /* Transferência */),
          I18n.t('BEE2979' /* Informe a Etiqueta Agrupadora */),
          'top-right',
        );
        this.setValue('showForm', false);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            `${error.code} - ${error.message || error.details}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            I18n.t('BEE1969' /* Erro ao buscar dados */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE34' /* Transferência */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
      this.setValue('showForm', false);
    }
  };

  eanSearch = async () => {
    const { ean } = this.state;

    try {
      if (ean) {
        const product = await this.props.getProductByEan(ean);

        if (product) {
          await this.setProductSelected(product);
          const id = this.state.showLotNumber ? 2
            : this.state.controlExpirationDate && 3;
          if (id) {
            document.getElementById(id).focus();
            document.getElementById(id).select();
          }
        } else {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            I18n.t('BEE1143', { 0: ean } /* Produto não encontrado para o EAN %{0} ! */),
            'top-right',
          );
          this.setValue('showForm', false);
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE34' /* Transferência */),
          I18n.t('BEE1142', { 0: ean } /* Código EAN %{0} não é válido ! */),
          'top-right',
        );
        this.setValue('showForm', false);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            I18n.t('BEE1144' /* Erro ao transferir produto */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE34' /* Transferência */),
          I18n.t('BEE1144' /* Erro ao transferir produto */),
          'top-right',
        );
      }
      this.setValue('showForm', false);
    }
  };

  setProductSelected = async (productSelected) => {
    await this.setState({
      productSelected,
      productCode: productSelected.productCode,
      productName: productSelected.product.name,
      unitMeasure: productSelected.product.unitMeasure,
      quantity: '',
      pickupAddress: '',
      pickupAddressName: '',
      storageAddress: '',
      storageAddressName: '',
      lotNumber: '',
      showForm: true,
      showLotNumber: productSelected.product.stockControlType === 3,
      controlExpirationDate: productSelected.product.controlExpirationDate,
    });
  };

  setSerialGroupSelected = async (productSelected) => {
    await this.setState({
      productSelected,
      productCode: productSelected.productCode,
      productName: productSelected.product.name,
      unitMeasure: productSelected.product.unitMeasure,
      quantity: parseFloat(productSelected.quantity),
      pickupAddress: (productSelected.balance && productSelected.balance.address.id) || '',
      pickupAddressName: (productSelected.balance && productSelected.balance.addressCode) || '',
      storageAddress: '',
      storageAddressName: '',
      lotNumber: (productSelected.balance && productSelected.balance.lotNumber) || '',
      expirationDate: (productSelected.balance?.expirationDate
        && new Date(productSelected.balance.expirationDate)) || '',
      showForm: true,
      showLotNumber: productSelected.product.stockControlType === 3,
      controlExpirationDate: productSelected.product.controlExpirationDate,
      serialGroupCode: productSelected.code,
      serialGroupId: productSelected.id,
    });
  };

  getInformation = async () => {
    const {
      lotNumber, pickupAddressCode, productCode, showLotNumber,
    } = this.state;
    if (!showLotNumber && pickupAddressCode) {
      const information = await this.props.getInformation(pickupAddressCode, productCode, '');
      if (!!information && !!information.quantity) this.setState({ quantity: information.quantity });
    } else if (showLotNumber && lotNumber && pickupAddressCode) {
      const information = await this.props.getInformation(pickupAddressCode, productCode, lotNumber);
      if (!!information && !!information.quantity) this.setState({ quantity: information.quantity });
    }
  };

  transferSubmit = async () => {
    const {
      productCode,
      quantity,
      lotNumber,
      pickupAddress,
      storageAddress,
      productSelected,
      ean,
      expirationDate,
      depositCode,
      serialGroupId,
      serialGroupCode,
    } = this.state;

    try {
      if (quantity && quantity > 0) {
        if (productSelected.product.stockControlType === 3 && !lotNumber) {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            I18n.t('BEE1074' /* Lote deve ser informado ! */),
            'top-right',
          );
          return;
        }
        const stockTransfer = {
          ean,
          productCode,
          lotNumber,
          quantity,
          pickupAddressId: pickupAddress,
          storageAddressId: storageAddress,
          expirationDate,
          serialGroupCode,
          serialGroupId,
          depositCode,
        };

        const transfer = await this.props.doStockTransfer(stockTransfer);

        if (transfer && transfer.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            transfer.message ? transfer.message : I18n.t('BEE1144' /* Erro ao transferir produto */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE34' /* Transferência */),
            I18n.t('BEE1145' /* Transferência efetuada com sucesso */),
            'top-right',
          );
          this.resetForm();
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE34' /* Transferência */),
          I18n.t('BEE1146', { 0: quantity } /* Quantidade %{0} inválida ! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            I18n.t('BEE1144' /* Erro ao transferir produto */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE34' /* Transferência */),
          I18n.t('BEE1144' /* Erro ao transferir produto */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      ean, productCode, productName, unitMeasure, quantity, lotNumber, depositName,
      pickupAddress, pickupAddressName, storageAddress, storageAddressName,
      showForm, showLotNumber, listStorageAddress = {}, controlExpirationDate,
      expirationDate, isSerialControl, serialGroupCode, listDeposit, depositCode,
    } = this.state;

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE32' /* Estoque */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1401' /* Transferência Endereços */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {I18n.t('BEE1401' /* Transferência Endereços */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.STOCK_TRANSFER_ADDRESS_HELP} />
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <Panel>
              <PanelHeader noButton />
              <PanelBody>
                {
                  this.createSelectDropDown(
                    { value: depositCode, label: depositName },
                    'deposit',
                    `${I18n.t('BEE182' /* Depósito */)}:`,
                    listDeposit,
                    !!depositName,
                  )
                }
                {depositName && (isSerialControl
                  ? this.createInput(
                    serialGroupCode,
                    'serialGroupCode',
                    `${I18n.t('BEE2764' /* Etiqueta Agrupadora */)}:`,
                    I18n.t('BEE2979' /* Informe a Etiqueta Agrupadora */),
                    'text',
                    true,
                    showForm,
                    this.goToElement,
                    1,
                  )
                  : this.createInput(
                    ean,
                    'ean',
                    `${I18n.t('BEE377' /* Código do Barras */)}:`,
                    I18n.t('BEE1147' /* Informe o Código de Barras do Produto */),
                    'text',
                    true,
                    showForm,
                    this.goToElement,
                    1,
                  ))}
                {showForm && this.createInput(
                  productCode,
                  'productCode',
                  `${I18n.t('BEE225' /* Produto */)}:`,
                  '',
                  'text',
                  true,
                  true,
                )}
                {showForm && this.createInput(
                  productName,
                  'productName',
                  `${I18n.t('BEE277' /* Descrição */)}:`,
                  '',
                  'text',
                  true,
                  true,
                )}
                {showForm && this.createInput(
                  unitMeasure,
                  'unitMeasure',
                  `${I18n.t('BEE384' /* Unidade */)}:`,
                  '',
                  'text',
                  true,
                  true,
                )}
                {showForm && showLotNumber && this.createInput(
                  lotNumber,
                  'lotNumber',
                  `${I18n.t('BEE428' /* Lote */)}:`,
                  '',
                  'text',
                  true,
                  false,
                  this.goToElement,
                  2,
                )}
                {showForm && controlExpirationDate && this.createDateInput(
                  expirationDate,
                  'expirationDate',
                  `${I18n.t('BEE434' /* Data de Validade */)}:`,
                  isSerialControl,
                  3,
                )}
                {showForm && this.createSelectDropDown(
                  { value: pickupAddress, label: pickupAddressName },
                  'pickupAddress',
                  `${I18n.t('BEE495' /* Endereço Retirada */)}:`,
                  listStorageAddress,
                  isSerialControl,
                )}
                {showForm && this.createSelectDropDown(
                  { value: storageAddress, label: storageAddressName },
                  'storageAddress',
                  `${I18n.t('BEE496' /* Endereço Armazenamento */)}:`,
                  listStorageAddress,
                )}
                {showForm && this.createNumberFormat(
                  quantity,
                  'quantity',
                  `${I18n.t('BEE441' /* Quantidade */)}:`,
                  3,
                  isSerialControl,
                )}
              </PanelBody>
              <PanelFooter>
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-white p-5 m-5"
                  onClick={this.resetForm}
                >
                  {I18n.t('BEE99' /* Cancelar */)}
                </OneClickButton>
                {(!showForm
                  && (
                  <OneClickButton
                    type="submit"
                    className="btn btn-120 btn-primary p-5 m-5"
                    disabled={!depositName}
                    onClick={isSerialControl ? this.serialGroupSearch : this.eanSearch}
                  >
                    {I18n.t('BEE407' /* Buscar */)}
                  </OneClickButton>
                  )
                )}
                {(showForm
                  && (
                  <OneClickButton
                    type="submit"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={this.transferSubmit}
                  >
                    {I18n.t('BEE100' /* Confirmar */)}
                  </OneClickButton>
                  )
                )}
              </PanelFooter>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  fractionalQuantity: state.app.permissionsCompany && state.app.permissionsCompany.fractionalQuantity,
  user: state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getProductByEan: (ean) => dispatch(getProductByEan(ean)),
  getSerialGroupDetail: (code, depositCode) => dispatch(getSerialGroupDetail(code, depositCode)),
  doStockTransfer: (stockTransfer) => dispatch(doStockTransfer(stockTransfer)),
  getInformation: (
    addressCode,
    productCode,
    lotNumber,
  ) => dispatch(getInformation(addressCode, productCode, lotNumber)),
  getStorageAddressesOptions: () => dispatch(getStorageAddressesOptions()),
  getDepositsOptions: () => dispatch(getDepositsOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TransferForm));
