import moment from 'moment-timezone';
import RestService from '../services/rest';

// GET
async function getStorageAddresses(tableParams) {
  return RestService.postAuthenticated('storageAddresses/list/all', tableParams);
}

async function getStorageAddress(storageAddressId) {
  return RestService.getAuthenticated(`storageAddresses/detail?storageAddressId=${storageAddressId}`);
}

async function getStorageAddressesOptions(branchCode = '', depositCode = '') {
  return RestService
    .getAuthenticated(`storageAddresses/list/options?branchCode=${branchCode}&depositCode=${depositCode}`);
}

async function getStorageAddressesPickingArea(pickingAreaId) {
  return RestService
    .getAuthenticated('storageAddresses/list/pickingAreas', { pickingAreaId });
}

// POST
async function createStorageAddress(storageAddress) {
  return RestService.postAuthenticated('storageAddresses/create', storageAddress);
}

// POST
async function generateStorageAddresses(storageAddresses) {
  return RestService.postAuthenticated('storageAddresses/generate', storageAddresses);
}

// POST
async function saveListGeneratedAddresses(addressesList) {
  return RestService.postAuthenticated('storageAddresses/generate/save', addressesList);
}

async function deleteStorageAddress(storageAddressId) {
  return RestService.postAuthenticated('storageAddresses/delete', { storageAddressId });
}

async function blockStorageAddress(addressId, blocked, note) {
  return RestService.postAuthenticated('storageAddresses/block', { addressId, blocked, note });
}

async function previewBlockList(filters) {
  return RestService.postAuthenticated('storageAddresses/block/bulk/preview', { filters });
}

async function persistBlockList(blocked, filters, note) {
  return RestService.postAuthenticated('storageAddresses/block/bulk/persist', { blocked, filters, note });
}

// POST
async function exportAddressList(columns, rows, nameDoc, typeDoc) {
  const response = await RestService.postAuthenticated('storageAddresses/list/export', { columns, rows }, {
    'Content-Type': 'application/json',
    Accept: `application/${typeDoc}`,
  }, { responseType: 'arraybuffer' });
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  const filePath = `${nameDoc}_${moment().toISOString().replace(/-/g, '_')}.${typeDoc.toLowerCase()}`;
  link.setAttribute('download', filePath);
  document.body.appendChild(link);
  link.click();
}

// PUT
async function updateStorageAddress(storageAddress) {
  return RestService.putAuthenticated('storageAddresses/update', storageAddress);
}

async function updateStorageAddressesPickingArea(storageAddressId, pickingAreaId) {
  return RestService
    .putAuthenticated('storageAddresses/update/pickingAreas', { storageAddressId, pickingAreaId });
}

async function updateBulkImportAddressesPickingArea(jsonCsv) {
  return RestService.putAuthenticated('storageAddresses/update/bulkImportPickingArea', { jsonCsv });
}

export default {
  getStorageAddresses,
  createStorageAddress,
  updateStorageAddress,
  getStorageAddress,
  deleteStorageAddress,
  getStorageAddressesOptions,
  generateStorageAddresses,
  saveListGeneratedAddresses,
  exportAddressList,
  blockStorageAddress,
  previewBlockList,
  persistBlockList,
  getStorageAddressesPickingArea,
  updateStorageAddressesPickingArea,
  updateBulkImportAddressesPickingArea,

};
