import { I18n } from 'react-redux-i18n';


function status(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE733' /* Normal */);
      case 2:
        return I18n.t('BEE734' /* Excelente */);
      case 3:
        return I18n.t('BEE735' /* Reserva */);
      case 4:
        return I18n.t('BEE736' /* Picking */);
      case 5:
        return I18n.t('BEE177' /* Rejeitados */);
      case 6:
        return I18n.t('BEE573' /* Avaria */);
      case 7:
        return I18n.t('BEE2238' /* Falta */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE733' /* Normal */) },
      { value: 2, label: I18n.t('BEE734' /* Excelente */) },
      { value: 3, label: I18n.t('BEE735' /* Reserva */) },
      { value: 4, label: I18n.t('BEE736' /* Picking */) },
      { value: 5, label: I18n.t('BEE177' /* Rejeitados */) },
      { value: 6, label: I18n.t('BEE573' /* Avaria */) },
      { value: 7, label: I18n.t('BEE2238' /* Falta */) },
    ]
  }
};

export default {
  status,
};