/* eslint-disable no-restricted-syntax */
import { endOfDay, startOfDay, subDays } from 'date-fns';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';
import {
  getOutboundOrdersDashboardInfo, setDashboardCurrentRange, setSelectedUserBranches,
  setDashboardOutboundOrdersDates,
} from '../../app/store/actions/outboundOrders';
import DateRangePicker from '../../components/date/DateRangePicker.jsx';
import Table from '../../components/table/Table';
import ROUTES from '../../config/routes';
import OutboundOrderHelpers from '../../helpers/outboundOrder';
import CardsStats from './cardsStats';
import DoughnutChart from './doughnutChart';
import LineChart from './lineChart';

import { PopoverHeader, UncontrolledPopover } from 'reactstrap';

class OutboundOrderDashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      defaultSorted: [{
        id: 'orderNumber',
        desc: false,
      }],
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem
                  onClick={() => this.showOutboundOrderDetail(rows)}
                >
                  {I18n.t('BEE56' /* Detalhes */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1096' /* Pedido cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 130,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'orderType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE402' /* Nota Fiscal */),
        accessor: 'invoiceNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{OutboundOrderHelpers.outboundOrderStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (parseInt(filter.value, 10) === parseInt(row.status, 10)) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            id="filterSelect"
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {OutboundOrderHelpers.outboundOrderStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE301' /* Transportadora */),
        accessor: 'carrierName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE53' /* Cidade */),
        accessor: 'cityName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1057' /* Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('DD/MM/YYYY')}
          </span>
        ),
        Filter: this.filterColumn,
      },
    ];

    this.breadcrumb = [
      { value: I18n.t('BEE42' /* Dashboard */), iconClassName: 'fa fa-chart-line m-t-10 m-r-5' },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE2417' /* Status por Documento */), active: true },
    ];
  }

  async componentDidMount() {
    if (!this.props.location.state || !Object.keys(this.props.location.state).length) {
      const startDate = moment().subtract(30, 'd');
      const endDate = moment();
      const dates = { startDate, endDate };

      await this.props.setDashboardOutboundOrdersDates(dates);

      await this.getOutboundOrders();
    }

    this.props.history.push({
      state: {},
    });
  }

  quantitySet = (data, status) => data.filter((element) => element.status === status).length;

  showOutboundOrderDetail = (outboundOrder) => {
    const newBreadcrumb = this.breadcrumb.map((it) => {
      const newIt = { ...it };
      it.active = false;
      return newIt;
    });
    newBreadcrumb.push({ value: I18n.t('BEE1094' /* Detalhes Documento de Saída */), active: true });

    this.props.history.push({
      pathname: ROUTES.OUTBOUND_ORDER_DETAIL,
      state: {
        outboundOrder: outboundOrder.original,
        breadcrumb: newBreadcrumb,
        dashboardRoute: true,
      },
    });
  };

  getOutboundOrders = async () => {
    const {
      startDate, endDate,
    } = this.props.dates;
    const { currentRange } = this.props;

    const branchesToSearch = [];
    if (this.props.selectedBranches) {
      for (const { value } of this.props.selectedBranches) {
        branchesToSearch.push(value);
      }
    }

    await this.props.getOutboundOrdersDashboardInfo(startDate, endDate, true, branchesToSearch, currentRange);

    const existOrder = {};

    this.setState({
      selectedOrder: existOrder || {},
    });
  };

  handleDateApplyEvent = async (value) => {
    const startDate = value[0];
    const endDate = value[1];

    const dates = { startDate, endDate };
    await this.props.setDashboardOutboundOrdersDates(dates);
  };

  handleBranchesSelected = async (event) => {
    const selectedBranches = [];

    for (const element of event) {
      selectedBranches.push({
        value: element.value,
        label: element.label,
      });
    }

    await this.props.setSelectedUserBranches(selectedBranches);
  };

  render() {
    const {
      defaultSorted, selectedOrder,
    } = this.state;
    const startDate = (this.props.dates && this.props.dates.startDate) || startOfDay(subDays(new Date(), 30));
    const endDate = (this.props.dates && this.props.dates.endDate) || endOfDay(new Date());
    const { outboundOrdersList = [] } = this.props;
    const cards = [
      {
        title: I18n.t('BEE1833' /* Finalizado */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 10),
        isMain: true,
      },
      {
        title: I18n.t('BEE1947' /* Pendente Corte de Cabos */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 2),
        isMain: false,
      },
      {
        title: I18n.t('BEE1365' /* Pendente Separação */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 3),
        isMain: false,
      },
      {
        title: I18n.t('BEE1367' /* Em Separação */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 4),
        isMain: false,
      },
      {
        title: I18n.t('BEE1948' /* Pendente Conferência */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 5),
        isMain: false,
      },
      {
        title: I18n.t('BEE176' /* Divergência */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 11),
        isMain: false,
      },
      {
        title: I18n.t('BEE1946' /* Pendente Alocação */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 1),
        isMain: false,
      },
      {
        title: I18n.t('BEE1949' /* Pendente Docas */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 6),
        isMain: false,
      },
      {
        title: I18n.t('BEE1773' /* Pendente Romaneio */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 7),
        isMain: false,
      },
      {
        title: I18n.t('BEE1769' /* Pendente Consolidação */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 8),
        isMain: false,
      },
      {
        title: I18n.t('BEE2963' /* Pendente de Faturamento */),
        quantity: this.quantitySet(outboundOrdersList, 9),
        isMain: false,
      },
      {
        title: I18n.t('BEE64' /* Cancelado */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 12),
        isMain: false,
      },
      {
        title: I18n.t('BEE1899' /* Devolvido */).toUpperCase(),
        quantity: this.quantitySet(outboundOrdersList, 13),
        isMain: false,
      },
    ];
    return (
      <div>
        {!!this.breadcrumb && this.breadcrumb.length && (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.breadcrumb.map((it, index) => (
                <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                  {
                    it.iconClassName
                      ? (
                        <>
                          <i className="fa fa-chart-line m-t-10 m-r-5" />
                          {' '}
                          {it.value}
                        </>
                      ) : (
                        it.value
                      )
                  }
                </li>
              ))}
            </ol>
          </div>
        )}
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2417' /* Status por Documento */)}
          </h1>
        </div>
        <div className="d-sm-flex">
          <div className="d-flex mt-2">
            <DateRangePicker
              onChange={(value) => {
                this.handleDateApplyEvent(value);
              }}
              defaultValue={[new Date(startDate), new Date(endDate)]}
            />
            <UncontrolledPopover trigger="hover" placement="top" target="dateRangePicker">
              <PopoverHeader>
                { I18n.t('BEE3342', { 0:(I18n.t('BEE3344' /* data de criação */)) } /* O intervalo segue o critério pela %{0} */) }
              </PopoverHeader>
            </UncontrolledPopover>
          </div>
          <div className="d-flex mt-2">
            <Select
              value={this.props.selectedBranches}
              isMulti
              onChange={(event) => this.handleBranchesSelected(event || [])}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              isSearchable
              styles={{
                control: (defaultStyles) => ({
                  ...defaultStyles,
                  minHeight: 36,
                  width: '300px',
                  border: 0,
                  boxShadow: 'none',
                  color: '#FFF',
                  backgroundColor: '#2d353c',
                  fontWeight: '600',
                  borderRadius: '5px 0px 0px 5px',
                  outline: 'none',
                }),
                placeholder: (defaultStyles) => ({
                  ...defaultStyles,
                  color: '#ffffff',
                }),
                dropdownIndicator: (defaultStyles) => ({
                  ...defaultStyles,
                  paddingTop: 0,
                  paddingBottom: 0,
                  color: '#ffffff',
                }),
                clearIndicator: (defaultStyles) => ({
                  ...defaultStyles,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
              }}
              maxMenuHeight={300}
              placeholder={I18n.t('BEE2424' /* Todas as filiais estão selecionadas */)}
              options={this.props.userBranches}
            />
            <div style={{ borderRadius: '0px 5px 5px 0px', backgroundColor: '#2d353c' }}>
              <button
                className="h-100 border-0 bg-transparent"
                style={{ fontSize: '22px' }}
                type="button"
                onClick={() => this.getOutboundOrders()}
              >
                <i className="fa fa-search text-white" style={{ fontSize: '20px' }} />
              </button>
            </div>
          </div>
        </div>
        <h2 className="page-header mb-0 mt-3">
          {I18n.t('BEE2418' /* Análise de Documentos por Status */)}
        </h2>
        <CardsStats cards={cards} />
        <h2 className="page-header mb-0 mt-4">
          {I18n.t('BEE2419' /* Análise por Linhas */)}
        </h2>
        <div className="row">
          <div className="col-xl-6">
            <LineChart
              typeChart="line"
              lineChartCheckData={this.props.lineChartCheckData}
              title={I18n.t('BEE2421' /* Linhas conferidas por dia */)}
            />
          </div>
          <div className="col-xl-6">
            <DoughnutChart
              doughnutChartData={this.props.doughnutChartData}
              title={I18n.t('BEE2422' /* Quantidade de linhas pendentes por status */)}
            />
          </div>
        </div>
        <div>
          <Table
            downloadCSV
            onClickCollapse
            panelHeaderProps={{
              onClickReload: () => this.getOutboundOrders(),
              pageDescription: I18n.t('BEE793' /* Todos */).toUpperCase(),
            }}
            filterable
            data={outboundOrdersList}
            columns={this.tableColumns}
            expander
            defaultPageSize={10}
            defaultSorted={defaultSorted}
            defaultFilterMethod={(filter, row) => {
              const input = _.lowerCase(filter.value);
              const value = _.lowerCase(row[filter.id]);
              if (_.includes(value, input)) {
                return true;
              }
            }}
            noDataText={I18n.t('BEE2423' /* Não há dados na data informada */)}
            getTrProps={(state, rowInfo) => {
              if (rowInfo && rowInfo.row) {
                return {
                  style: {
                    background: selectedOrder && rowInfo.original.id === selectedOrder.id ? 'silver' : 'white',
                    cursor: 'pointer',
                  },
                };
              }
              return {};
            }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  outboundOrdersList: state.outboundOrders && state.outboundOrders.outboundOrdersList,
  userBranches: state.outboundOrders.userBranches,
  dates: state.outboundOrders.dates,
  currentRange: state.outboundOrders.currentRange,
  selectedBranches: state.outboundOrders.selectedBranches,
  lineChartCheckData: state.outboundOrders && state.outboundOrders.chartsPanel.lineChartCheck,
  doughnutChartData: state.outboundOrders && state.outboundOrders.chartsPanel.lineProductStatus,
});

const mapDispatchToProps = (dispatch) => ({
  getOutboundOrdersDashboardInfo:
    (
      startDate,
      endDate,
      getCharts,
      selectedBranches,
      currentRange,
    ) => dispatch(getOutboundOrdersDashboardInfo(startDate, endDate, getCharts, selectedBranches, currentRange)),
  setDashboardCurrentRange: (currentRange) => dispatch(setDashboardCurrentRange(currentRange)),
  setSelectedUserBranches: (selectedBranches) => dispatch(setSelectedUserBranches(selectedBranches)),
  setDashboardOutboundOrdersDates: (dates) => dispatch(setDashboardOutboundOrdersDates(dates)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundOrderDashboard));
