import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import OneClickButton from './../../../../components/form/button';
import { Panel, PanelHeader, PanelBody, PanelFooter } from './../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createDock, updateDock, getDock
} from '../../../../app/store/actions/docks';
import { getBranchesOptions } from '../../../../app/store/actions/branches';
import { getWarehousesOptions } from '../../../../app/store/actions/warehouses';
import Select from '../../../../components/form/select';

class DockForm extends React.Component {
  constructor(props) {
    super(props);

    const listDockType = [
      { value: 1, label: I18n.t('BEE201' /* Entrada */) },
      { value: 2, label: I18n.t('BEE202' /* Saída */) },
      { value: 3, label: I18n.t('BEE212' /* Ambas */) }
    ];

    const listStatus = [
      { value: 1, label: I18n.t('BEE205' /* Liberada */) },
      { value: 2, label: I18n.t('BEE206' /* Ocupada */) },
      { value: 3, label: I18n.t('BEE213' /* Bloqueada */) }
    ];

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      branch: null,
      brancName: '',
      warehouse: null,
      warehouseName: '',
      dockType: 1,
      status: 1,
      note: '',
      disableCode: true,
      disableName: true,
      disableBranchCode: true,
      disableWarehouseCode: true,
      disableDockType: true,
      disableStatus: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      listDockType,
      listStatus,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getDockSelected(false);
    }

    const listBranch = await this.props.getBranchesOptions();

    const listWarehouse = await this.props.getWarehousesOptions();

    this.setState({
      listBranch,
      listWarehouse,
    });
  }

  getDockSelected = async () => {
    if (this.props.location.state.dock) {
      const { dock } = this.props.location.state;
      const dockSelected = await dock;

      if (dockSelected) {
        this.setState({
          dockSelected,
          dockId: dockSelected.id,
          code: dockSelected.code,
          name: dockSelected.name,
          branch: dockSelected.branchCode,
          branchName: dockSelected.branchName,
          warehouse: dockSelected.warehouseCode,
          warehouseName: dockSelected.warehouseName,
          dockType: dockSelected.dockType,
          status: dockSelected.status,
          note: dockSelected.note,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableBranchCode: false,
        disableWarehouseCode: false,
        disableDockType: false,
        disableStatus: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableBranchCode: false,
        disableWarehouseCode: false,
        disableDockType: false,
        disableStatus: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled, maxLength = 524288) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
        />
      </div>
    </div>
  )

  createSelect = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <select
          className="form-control"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
        >
          {items.map(item => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value || ''}
          onChange={e => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  }

  createDockSubmit = async () => {
    const {
      code, name, branch, warehouse,
      dockType, status, note
    } = this.state;

    try {
      const newDock = await this.props.createDock({
        code,
        name,
        branchCode: branch,
        warehouseCode: warehouse,
        dockType: dockType,
        status: status,
        note
      });

      if (newDock) {
        await addNotification(
          'success', I18n.t('BEE199' /* Doca */), I18n.t('BEE214', { 0: code } /* Doca %{0} incluída com sucesso! */), 'top-right'
        );

        this.props.history.push(ROUTES.DOCKS_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE199' /* Doca */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE199' /* Doca */), I18n.t('BEE215', { 0: code } /* Erro ao incluir a Doca %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE199' /* Doca */), I18n.t('BEE215', { 0: code } /* Erro ao incluir a Doca %{0}! */), 'top-right'
        );
      }
    }
  };

  updateDockSubmit = async () => {
    const {
      dockId, code, name, branch, warehouse,
      dockType, status, note
    } = this.state;

    try {
      const updDock = await this.props.updateDock({
        dockId,
        name,
        branchCode: branch,
        warehouseCode: warehouse,
        dockType: dockType,
        status: status,
        note
      });

      if (updDock) {
        await addNotification('success', I18n.t('BEE216' /* Atualizar Doca */), I18n.t('BEE217', { 0: code } /* Atualização da Doca %{0} efetuada com sucesso! */), 'top-right');

        this.props.history.push(ROUTES.DOCKS_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE216' /* Atualizar Doca */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE216' /* Atualizar Doca */), I18n.t('BEE218', { 0: code } /* Erro ao atualizar a Doca %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE216' /* Atualizar Doca */), I18n.t('BEE218', { 0: code } /* Erro ao atualizar a Doca %{0}! */), 'top-right'
        );
      }
    }
  };

  descTypes(type) {
    if (type === 1) {
      return I18n.t('BEE201' /* Entrada */);
    } else if (type === 2) {
      return I18n.t('BEE202' /* Saída */);
    } else if (type === 3) {
      return I18n.t('BEE212' /* Ambas */);
    }
  }

  descStatus(status) {
    if (status === 1) {
      return I18n.t('BEE205' /* Liberada */);
    } else if (status === 2) {
      return I18n.t('BEE206' /* Ocupada */);
    }
    return I18n.t('BEE213' /* Bloqueada */);
  }

  render() {
    const {
      code, name, branch, branchName, warehouse, warehouseName,
      dockType, status, note, listDockType, listStatus,
      listBranch, listWarehouse,
    } = this.state;

    const {
      mode, disableCode, disableName, disableBranchCode,
      disableWarehouseCode, disableDockType, disableStatus,
      disableNote, showCreate, showUpdate, showCancel,
    } = this.state;

    let title = '';
  //  let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE219' /* Nova Doca */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE220' /* Editar Doca */);
  //    breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE221' /* Detalhes Doca */);
   //   breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE19' /* Docas */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton={true} />
                <PanelBody>
                  {this.createInput(
                    code, 'code', `${I18n.t('BEE82' /* Código */)}:`, I18n.t('BEE222' /* Informe a doca (obrigatório) */), 'text', true, disableCode, 20
                  )}
                  {this.createInput(
                    name, 'name', `${I18n.t('BEE83' /* Nome */)}:`, I18n.t('BEE223' /* Informe o nome do doca (obrigatório) */), 'text', true, disableName,
                  )}
                  {(disableBranchCode
                    ? this.createInput(branchName, 'branch', `${I18n.t('BEE145' /* Filial */)}:`, '', 'text', false, disableBranchCode)
                    : this.createSelectDropDown(
                      { value: branch, label: branchName }, 'branch', `${I18n.t('BEE145' /* Filial */)}:`, listBranch)
                  )}
                  {(disableWarehouseCode
                    ? this.createInput(warehouseName, 'warehouse', `${I18n.t('BEE156' /* Armazém */)}:`, '', 'text', false, disableWarehouseCode)
                    : this.createSelectDropDown(
                      { value: warehouse, label: warehouseName }, 'warehouse', `${I18n.t('BEE156' /* Armazém */)}:`, listWarehouse)
                  )}
                  {(disableDockType
                    ? this.createInput(this.descTypes(dockType), 'dockType', `${I18n.t('BEE200' /* Tipo */)}:`, '', 'text', false, disableDockType)
                    : this.createSelect(dockType, 'dockType', `${I18n.t('BEE200' /* Tipo */)}:`, listDockType))}
                  {(disableStatus
                    ? this.createInput(this.descStatus(status), 'status', `${I18n.t('BEE224' /* Status */)}:`, '', 'text', false, disableStatus)
                    : this.createSelect(status, 'status', `${I18n.t('BEE224' /* Status */)}:`, listStatus))}
                  {this.createInput(
                    note, 'note', `${I18n.t('BEE135' /* Observação */)}:`, I18n.t('BEE136' /* Informe a observação */), 'text', false, disableNote,
                  )}
                </PanelBody>
                <PanelFooter>
                  <Link to={{pathname: ROUTES.DOCKS_LIST, state: {dice: this.props.location.dice}}}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createDockSubmit()}
                    >{I18n.t('BEE138' /* Criar */)}</OneClickButton>
                  )}
                  {(showUpdate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateDockSubmit()}
                    >{I18n.t('BEE139' /* Atualizar */)}</OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const colourStyles = {
  control: styles => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    }
  }),
};

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getDock: depositId => dispatch(getDock(depositId)),
  createDock: deposit => dispatch(createDock(deposit)),
  updateDock: deposit => dispatch(updateDock(deposit)),
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getWarehousesOptions: () => dispatch(getWarehousesOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DockForm));