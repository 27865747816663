import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';
import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';

import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import ProductHelpers from '../../../../helpers/products';

import {
  createProduct, getProduct, updateProduct,
} from '../../../../app/store/actions/products';
import { getUnitsMeasureOptions } from '../../../../app/store/actions/unitsMeasure';
import { getMaterialsFamilyOptions } from '../../../../app/store/actions/materialsFamily';
import Select from '../../../../components/form/select';

class ProductForm extends React.Component {
  constructor(props) {
    super(props);

    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      activeTab: '1',
      mode: 'detail',
      productCode: '',
      name: '',
      fullName: '',
      complementCode: '',
      complementInfo: '',
      status: 1,
      note: '',
      disableProductCode: true,
      disableName: true,
      disableFullName: true,
      disableComplementCode: true,
      disableComplementInfo: true,
      disableStatus: true,
      disableNote: true,
      unitMeasure: '',
      unitMeasureName: '',
      materialFamily: '',
      materialFamilyName: '',
      volume: 0,
      height: 0,
      width: 0,
      length: 0,
      grossWeight: 0,
      netWeight: 0,
      conversionFactor: 0,
      multipleSale: 0,
      fractional: true,
      storeNextPicking: true,
      picking: true,
      productControlType: 1,
      stockControlType: 1,
      origin: 1,
      taxClassification: '',
      businessUnit: '',
      level1: '',
      level2: '',
      level3: '',
      level4: '',
      level5: '',
      controlExpirationDate: false,
      inboundPreExpiration: 0,
      outboundPreExpiration: 0,
      lifeDays: 0,
      overdueDays: 0,
      minimalLeftOver: 0,
      retailLoss: 0,
      disableUnitMeasure: true,
      disableMaterialFamily: true,
      disableHeight: true,
      disableWidth: true,
      disableLength: true,
      disableGrossWeight: true,
      disableNetWeight: true,
      disableConversionFactor: true,
      disableMultipleSale: true,
      disableFractional: true,
      disableStoreNextPicking: true,
      disablePicking: true,
      disableProductControlType: true,
      disableStockControlType: true,
      disableOrigin: true,
      disableTaxClassification: true,
      disableBusinessUnit: true,
      disableLevel1: true,
      disableLevel2: true,
      disableLevel3: true,
      disableLevel4: true,
      disableLevel5: true,
      disableControlExpirationDate: true,
      disableInboundPreExpiration: true,
      disableOutboundPreExpiration: true,
      disableLifeDays: true,
      disableOverdueDays: true,
      disableMinimalLeftOver: true,
      disableRetailLoss: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      listUnitMeasure: '',
      listMaterialFamily: '',
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getProductSelected();
    }

    const listUnitMeasure = await this.props.getUnitsMeasureOptions();
    const listMaterialFamily = await this.props.getMaterialsFamilyOptions();

    this.setState({
      listUnitMeasure,
      listMaterialFamily,
    });
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  getProductSelected = async () => {
    if (this.props.location.state.product) {
      const { product } = this.props.location.state;

      const productSelected = await this.props.getProduct(product.id);

      if (productSelected) {
        this.setState({
          productSelected,
          productId: productSelected.id,
          productCode: productSelected.productCode,
          name: productSelected.name,
          fullName: productSelected.fullName,
          complementCode: productSelected.complementCode,
          complementInfo: productSelected.complementInfo,
          status: productSelected.status,
          note: productSelected.note,
          unitMeasure: productSelected.unitMeasure,
          unitMeasureName: productSelected.unitMeasureName,
          materialFamily: productSelected.materialFamily,
          materialFamilyName: productSelected.materialFamilyName,
          volume: parseFloat(productSelected.volume),
          height: parseFloat(productSelected.height),
          width: parseFloat(productSelected.width),
          length: parseFloat(productSelected.length),
          grossWeight: parseFloat(productSelected.grossWeight),
          netWeight: parseFloat(productSelected.netWeight),
          fractional: productSelected.fractional,
          conversionFactor: parseFloat(productSelected.conversionFactor),
          multipleSale: parseFloat(productSelected.multipleSale),
          storeNextPicking: productSelected.storeNextPicking,
          picking: productSelected.picking,
          productControlType: productSelected.productControlType,
          stockControlType: productSelected.stockControlType,
          origin: productSelected.origin,
          taxClassification: productSelected.taxClassification,
          businessUnit: productSelected.businessUnit,
          level1: productSelected.level1,
          level2: productSelected.level2,
          level3: productSelected.level3,
          level4: productSelected.level4,
          level5: productSelected.level5,
          controlExpirationDate: productSelected.controlExpirationDate,
          inboundPreExpiration: productSelected.inboundPreExpiration,
          outboundPreExpiration: productSelected.outboundPreExpiration,
          lifeDays: productSelected.lifeDays,
          overdueDays: productSelected.overdueDays,
          minimalLeftOver: parseFloat(productSelected.minimalLeftOver),
          retailLoss: parseFloat(productSelected.retailLoss),
        });
      }
    }
  };

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableProductCode: false,
        disableName: false,
        disableFullName: false,
        disableComplementCode: false,
        disableComplementInfo: false,
        disableStatus: false,
        disableNote: false,
        disableUnitMeasure: false,
        disableMaterialFamily: false,
        disableHeight: false,
        disableWidth: false,
        disableLength: false,
        disableGrossWeight: false,
        disableNetWeight: false,
        disableConversionFactor: false,
        disableMultipleSale: false,
        disableFractional: false,
        disableStoreNextPicking: false,
        disablePicking: false,
        disableProductControlType: false,
        disableStockControlType: false,
        disableOrigin: false,
        disableTaxClassification: false,
        disableBusinessUnit: false,
        disableLevel1: false,
        disableLevel2: false,
        disableLevel3: false,
        disableLevel4: false,
        disableLevel5: false,
        disableControlExpirationDate: false,
        disableInboundPreExpiration: false,
        disableOutboundPreExpiration: false,
        disableLifeDays: false,
        disableOverdueDays: false,
        disableMinimalLeftOver: false,
        disableRetailLoss: false,
        showCreate: true,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableProductCode: true,
        disableName: true,
        disableFullName: true,
        disableComplementCode: true,
        disableComplementInfo: true,
        disableStatus: false,
        disableNote: false,
        disableUnitMeasure: true,
        disableMaterialFamily: true,
        disableHeight: false,
        disableWidth: false,
        disableLength: false,
        disableGrossWeight: false,
        disableNetWeight: false,
        disableConversionFactor: true,
        disableMultipleSale: true,
        disableFractional: true,
        disableStoreNextPicking: false,
        disablePicking: false,
        disableProductControlType: true,
        disableStockControlType: true,
        disableOrigin: true,
        disableTaxClassification: true,
        disableBusinessUnit: true,
        disableLevel1: true,
        disableLevel2: true,
        disableLevel3: true,
        disableLevel4: true,
        disableLevel5: true,
        disableControlExpirationDate: false,
        disableInboundPreExpiration: false,
        disableOutboundPreExpiration: false,
        disableLifeDays: false,
        disableOverdueDays: false,
        disableMinimalLeftOver: false,
        disableRetailLoss: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createSelect = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <select
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value || ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          required={required}
        />
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals, required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <NumberFormat
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={decimals}
          value={(value === 0 || value) ? value : ''}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue);
          }}
          fixedDecimalScale
          defaultValue={0}
          allowEmptyFormatting
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createTextArea = (value = '', attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <div className="row" style={{ marginTop: '8px', marginLeft: '0px' }}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleInputChange}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`} />
          </div>
        </div>
      </div>
    </div>
  );

  createRadioSet = (value, attr, label, items, disabled) => (
    <div className="form-group row m-b-15">
      <label className="col-md-4 col-form-label">{label}</label>
      <div className="col-md-5">
        {items.map((item, index) => (
          <div className="radio radio-css" key={index} style={{ textAlign: 'left' }}>
            <input
              type="radio"
              id={`cssRadio${attr}${item.value}`}
              value={item.value}
              name={attr}
              checked={item.value === value}
              disabled={disabled}
              onChange={this.handleInputChange}
            />
            <label htmlFor={`cssRadio${attr}${item.value}`}>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
    if (attr === 'height' || attr === 'width' || attr === 'length') this.volumeCalc();
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  volumeCalc = () => {
    const { height, width, length } = this.state;

    if (parseFloat(height) > 0 & parseFloat(width) > 0 & parseFloat(length) > 0) {
      const volume = parseFloat(height) * parseFloat(width) * parseFloat(length);

      this.setValue('volume', volume);
    } else {
      this.setValue('volume', 0);
    }
  };

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox'
      ? target.checked
      : target.type === 'radio' ? parseInt(target.value) : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  createProductSubmit = async () => {
    const {
      productCode, name, fullName, complementCode,
      complementInfo, status, unitMeasure, materialFamily, productControlType, stockControlType,
      volume, height, width, length, grossWeight, netWeight, origin, taxClassification,
      storeNextPicking, picking, fractional, conversionFactor, multipleSale,
      businessUnit, level1, level2, level3, level4, level5, note, controlExpirationDate,
      inboundPreExpiration, outboundPreExpiration, lifeDays, overdueDays, minimalLeftOver, retailLoss,
    } = this.state;

    try {
      const newProduct = await this.props.createProduct({
        productCode,
        name,
        fullName,
        complementCode,
        complementInfo,
        status,
        unitMeasure,
        materialFamily,
        productControlType,
        stockControlType,
        volume,
        height,
        width,
        length,
        grossWeight,
        netWeight,
        origin,
        taxClassification,
        storeNextPicking,
        picking,
        fractional,
        conversionFactor,
        multipleSale,
        businessUnit,
        level1,
        level2,
        level3,
        level4,
        level5,
        controlExpirationDate,
        inboundPreExpiration: inboundPreExpiration || 0,
        outboundPreExpiration: outboundPreExpiration || 0,
        lifeDays,
        overdueDays,
        minimalLeftOver,
        retailLoss,
        note,
      });

      if (newProduct) {
        if (newProduct.success && newProduct.success === false) {
          await addNotification('danger', I18n.t('BEE362' /* Novo Produto */), I18n.t('BEE363', { 0: productCode } /* Erro ao incluir o Produto %{0}! */), 'top-right');
        } else {
          await addNotification('success', I18n.t('BEE362' /* Novo Produto */), I18n.t('BEE364', { 0: productCode } /* Novo produto %{0} incluído com sucesso! */), 'top-right');

          this.props.history.push(ROUTES.PRODUCT_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE362' /* Novo Produto */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE362' /* Novo Produto */), I18n.t('BEE363', { 0: productCode } /* Erro ao incluir o Produto %{0}! */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE362' /* Novo Produto */), I18n.t('BEE363', { 0: productCode } /* Erro ao incluir o Produto %{0}! */), 'top-right');
      }
    }
  };

  updateProductSubmit = async () => {
    const {
      productId, productCode, name, fullName, complementCode,
      complementInfo, status, unitMeasure, materialFamily, productControlType, stockControlType,
      volume, height, width, length, grossWeight, netWeight, origin, taxClassification,
      storeNextPicking, picking, fractional, conversionFactor, multipleSale,
      businessUnit, level1, level2, level3, level4, level5, controlExpirationDate,
      inboundPreExpiration, outboundPreExpiration, lifeDays, overdueDays, minimalLeftOver, retailLoss, note,
    } = this.state;

    try {
      const updProduct = await this.props.updateProduct({
        productId,
        productCode,
        name,
        fullName,
        complementCode,
        complementInfo,
        status,
        unitMeasure,
        materialFamily,
        productControlType,
        stockControlType,
        volume,
        height,
        width,
        length,
        grossWeight,
        netWeight,
        origin,
        taxClassification,
        storeNextPicking,
        picking,
        fractional,
        conversionFactor,
        multipleSale,
        businessUnit,
        level1,
        level2,
        level3,
        level4,
        level5,
        controlExpirationDate,
        inboundPreExpiration: inboundPreExpiration || 0,
        outboundPreExpiration: outboundPreExpiration || 0,
        lifeDays,
        overdueDays: overdueDays || 0,
        minimalLeftOver,
        retailLoss,
        note,
      });

      if (updProduct) {
        if (updProduct.success && updProduct.success === false) {
          await addNotification('danger', I18n.t('BEE365' /* Atualizar Produto */), I18n.t('BEE366', { 0: productCode } /* Erro ao atualizar o produto %{0}! */), 'top-right');
        } else {
          await addNotification('success', I18n.t('BEE365' /* Atualizar Produto */), I18n.t('BEE367', { 0: productCode } /* Atualização do produto %{0} efetuada com sucesso! */), 'top-right');

          this.props.history.push(ROUTES.PRODUCT_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE365' /* Atualizar Produto */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE365' /* Atualizar Produto */), I18n.t('BEE366', { 0: productCode } /* Erro ao atualizar o produto %{0}! */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE365' /* Atualizar Produto */), I18n.t('BEE366', { 0: productCode } /* Erro ao atualizar o produto %{0}! */), 'top-right');
      }
    }
  };

  render() {
    const {
      productCode, name, fullName, complementCode, complementInfo, status, note,
      disableProductCode, disableName, disableFullName, disableComplementCode,
      disableComplementInfo, disableStatus, disableNote,
    } = this.state;

    const {
      unitMeasure, unitMeasureName, materialFamily, materialFamilyName, fractional, storeNextPicking, picking,
      disableUnitMeasure, disableMaterialFamily, disableHeight, disableWidth, disableLength,
      disableGrossWeight, disableNetWeight, disableFractional,
      disableStoreNextPicking, disablePicking,
    } = this.state;

    const {
      productControlType, stockControlType, origin, taxClassification,
      businessUnit, level1, level2, level3, level4, level5,
      disableProductControlType, disableStockControlType, disableOrigin, disableTaxClassification, disableBusinessUnit,
      disableConversionFactor, disableMultipleSale, disableLevel1, disableLevel2, disableLevel3, disableLevel4, disableLevel5,
    } = this.state;

    const {
      controlExpirationDate, inboundPreExpiration, outboundPreExpiration, lifeDays, overdueDays,
      disableControlExpirationDate, disableInboundPreExpiration,
      disableOutboundPreExpiration, disableLifeDays, disableOverdueDays, disableMinimalLeftOver, disableRetailLoss,
    } = this.state;

    const {
      mode, showCreate, showUpdate, showCancel, listUnitMeasure, listMaterialFamily,
    } = this.state;

    let {
      volume, height, width, length, grossWeight,
      netWeight, conversionFactor, multipleSale,
      minimalLeftOver, retailLoss,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE362' /* Novo Produto */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE368' /* Editar Produto */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE369' /* Detalhes Produto */);
    }

    if (mode === 'detail') {
      volume = parseFloat(volume).toFixed(3).replace('.', ',');
      height = parseFloat(height).toFixed(3).replace('.', ',');
      width = parseFloat(width).toFixed(3).replace('.', ',');
      length = parseFloat(length).toFixed(3).replace('.', ',');
      grossWeight = parseFloat(grossWeight).toFixed(4).replace('.', ',');
      netWeight = parseFloat(netWeight).toFixed(4).replace('.', ',');
      conversionFactor = parseFloat(conversionFactor).toFixed(5).replace('.', ',');
      multipleSale = parseFloat(multipleSale).toFixed(5).replace('.', ',');
      minimalLeftOver = parseFloat(minimalLeftOver).toFixed(3).replace('.', ',');
      retailLoss = parseFloat(retailLoss).toFixed(3).replace('.', ',');
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE362' /* Novo Produto */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>
                  <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '1' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('1');
                        }}
                      >
                        <span className="d-sm-none">Tab 1</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE191' /* Geral */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '2' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('2');
                        }}
                      >
                        <span className="d-sm-none">Tab 2</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE32' /* Estoque */)}</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={this.state.activeTab === '3' ? 'active' : ''}
                        onClick={() => {
                          this.toggleTab('3');
                        }}
                      >
                        <span className="d-sm-none">Tab 3</span>
                        <span className="d-sm-block d-none">{I18n.t('BEE371' /* Fiscal */)}</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="row">
                        <div className="col-xl-12">
                          {this.createInput(productCode, 'productCode', `${I18n.t('BEE82' /* Código */)}:`, I18n.t('BEE372' /* Informe o código do produto (obrigatório) */), 'text', true, disableProductCode)}
                          {this.createInput(name, 'name', `${I18n.t('BEE277' /* Descrição */)}:`, I18n.t('BEE373' /* Informe a descrição do produto (obrigatório) */), 'text', true, disableName)}
                          {this.createTextArea(fullName, 'fullName', `${I18n.t('BEE692' /* Descrição Completa */)}:`, I18n.t('BEE761' /* Informe a descrição completa do produto (obrigatório) */), 4, true, disableFullName)}
                          {this.createInput(complementCode, 'complementCode', `${I18n.t('BEE695' /* Código Complementar */)}:`, I18n.t('BEE762' /* Informe o código complementar do produto */), 'text', true, disableComplementCode)}
                          {this.createInput(complementInfo, 'complementInfo', `${I18n.t('BEE696' /* Informação Complementar */)}:`, I18n.t('BEE763' /* Informe a informação complementar do produto */), 'text', true, disableComplementInfo)}
                          {(disableStatus
                            ? this.createInput(ProductHelpers.productStatus(status), 'status', `${I18n.t('BEE204' /* Situação */)}:`, '', 'text', false, disableStatus)
                            : this.createSelect(status, 'status', `${I18n.t('BEE204' /* Situação */)}:`, ProductHelpers.listProductStatus())
                          )}
                          {this.createTextArea(note || '', 'note', `${I18n.t('BEE135' /* Observação */)}:`, I18n.t('BEE764' /* Informe a observação do produto */), 3, true, disableNote)}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="row">
                        <div className="col-xl-12">
                          {this.createToggleBox(storeNextPicking, 'storeNextPicking', `${I18n.t('BEE698' /* Armazena Próximo Picking */)}:`, disableStoreNextPicking)}
                          {this.createToggleBox(fractional, 'fractional', `${I18n.t('BEE699' /* Fraciona */)}:`, disableFractional)}
                          {this.createToggleBox(picking, 'picking', `${I18n.t('BEE700' /* Efetuar Picking */)}:`, disablePicking)}
                          {(disableUnitMeasure
                            ? this.createInput(unitMeasureName, 'unitMeasure', `${I18n.t('BEE29' /* Unidades de Medida */)}:`, '', 'text', false, disableUnitMeasure)
                            : this.createSelectDropDown({ value: unitMeasure, label: unitMeasureName }, 'unitMeasure', `${I18n.t('BEE29' /* Unidades de Medida */)}:`, listUnitMeasure)

                          )}
                          {(disableMaterialFamily
                            ? this.createInput(materialFamilyName, 'materialFamily', 'Famílial Material:', '', 'text', false, disableMaterialFamily)
                            : this.createSelectDropDown({ value: materialFamily, label: materialFamilyName }, 'materialFamily', 'Familia Material:', listMaterialFamily)

                          )}
                          {this.createInput(volume, 'volume', `${I18n.t('BEE562' /* Volume */)}:  (${I18n.t('BEE2326' /* cm³ */)})`, '', 'text', false, true)}
                          {(this.createNumberFormat(height, 'height', `${I18n.t('BEE386' /* Altura */)}: (${I18n.t('BEE2325' /* CM */)})`, 0, false, disableHeight))}
                          {(this.createNumberFormat(width, 'width', `${I18n.t('BEE387' /* Largura */)}: (${I18n.t('BEE2325' /* CM */)})`, 0, false, disableWidth))}
                          {(this.createNumberFormat(length, 'length', `${I18n.t('BEE388' /* Comprimento */)}: (${I18n.t('BEE2325' /* CM */)})`, 0, false, disableLength))}
                          {(this.createNumberFormat(grossWeight, 'grossWeight', `${I18n.t('BEE564' /* Peso Bruto */)}: (${I18n.t('BEE2327' /* G */)})`, 0, false, disableGrossWeight))}
                          {(this.createNumberFormat(netWeight, 'netWeight', `${I18n.t('BEE563' /* Peso Líquido */)}: (${I18n.t('BEE2327' /* G */)})`, 0, false, disableNetWeight))}
                          {this.createToggleBox(controlExpirationDate, 'controlExpirationDate', `${I18n.t('BEE701' /* Controlar Vencimento */)}:`, disableControlExpirationDate)}
                          {(this.createNumberFormat(inboundPreExpiration, 'inboundPreExpiration', `${I18n.t('BEE702' /* Entrada Pré Vencimento */)}:`, 0, true, disableInboundPreExpiration))}
                          {(this.createNumberFormat(outboundPreExpiration, 'outboundPreExpiration', `${I18n.t('BEE703' /* Saída Pré Vencimento */)}:`, 0, true, disableOutboundPreExpiration))}
                          {(this.createNumberFormat(overdueDays, 'overdueDays', `${I18n.t('BEE2018' /* Dias Vencidos */)}:`, 0, true, disableOverdueDays))}
                          {(this.createNumberFormat(lifeDays, 'lifeDays', `${I18n.t('BEE705' /* Dias de Vida */)}:`, 0, true, disableLifeDays))}
                          {(this.createNumberFormat(minimalLeftOver, 'minimalLeftOver', `${I18n.t('BEE706' /* Sobra Mínima */)}:`, 0, false, disableMinimalLeftOver))}
                          {(this.createNumberFormat(retailLoss, 'retailLoss', `${I18n.t('BEE1790' /* Retalho Perda */)}:`, 0, false, disableRetailLoss))}
                        </div>
                      </div>
                    </TabPane>
                    <TabPane tabId="3">
                      <div className="row">
                        <div className="col-xl-12">
                          {this.createRadioSet(productControlType, 'productControlType', `${I18n.t('BEE707' /* Tipo de Controle do Produto */)}:`, ProductHelpers.listProductControlType(), disableProductControlType)}
                          {this.createRadioSet(stockControlType, 'stockControlType', `${I18n.t('BEE708' /* Tipo de Controle de Estoque */)}:`, ProductHelpers.listStockControlType(), disableStockControlType)}
                          {this.createRadioSet(origin, 'origin', `${I18n.t('BEE709' /* Origem */)}:`, ProductHelpers.listProductOrigin(), disableOrigin)}
                          {this.createInput(taxClassification, 'taxClassification', `${I18n.t('BEE710' /* Classificação Fiscal */)}:`, I18n.t('BEE768' /* Informe a classificação fiscal do produto */), 'text', true, disableTaxClassification)}
                          {this.createInput(businessUnit, 'businessUnit', `${I18n.t('BEE711' /* Unidade de Negócio */)}:`, I18n.t('BEE769' /* Informe a unidade de negócio do produto */), 'text', true, disableBusinessUnit)}
                          {(disableConversionFactor
                            ? this.createInput(conversionFactor, 'conversionFactor', `${I18n.t('BEE712' /* Fator Conversão */)}:`, '', 'text', false, disableConversionFactor)
                            : this.createNumberFormat(conversionFactor, 'conversionFactor', `${I18n.t('BEE712' /* Fator Conversão */)}:`, 5)
                          )}
                          {(disableMultipleSale
                            ? this.createInput(multipleSale, 'multipleSale', `${I18n.t('BEE713' /* Lote Múltiplo Venda */)}:`, '', 'text', false, disableMultipleSale)
                            : this.createNumberFormat(multipleSale, 'multipleSale', `${I18n.t('BEE713' /* Lote Múltiplo Venda */)}:`, 5)
                          )}
                          {this.createInput(level1, 'level1', `${I18n.t('BEE694', { 0: 1 } /* Nível %{0} */)}:`, I18n.t('BEE770', { 0: 1 } /* Informe o nível %{0} de classificação do produto */), 'text', true, disableLevel1)}
                          {this.createInput(level2, 'level2', `${I18n.t('BEE694', { 0: 2 } /* Nível %{0} */)}:`, I18n.t('BEE770', { 0: 2 } /* Informe o nível %{0} de classificação do produto */), 'text', true, disableLevel2)}
                          {this.createInput(level3, 'level3', `${I18n.t('BEE694', { 0: 3 } /* Nível %{0} */)}:`, I18n.t('BEE770', { 0: 3 } /* Informe o nível %{0} de classificação do produto */), 'text', true, disableLevel3)}
                          {this.createInput(level4, 'level4', `${I18n.t('BEE694', { 0: 4 } /* Nível %{0} */)}:`, I18n.t('BEE770', { 0: 4 } /* Informe o nível %{0} de classificação do produto */), 'text', true, disableLevel4)}
                          {this.createInput(level5, 'level5', `${I18n.t('BEE694', { 0: 5 } /* Nível %{0} */)}:`, I18n.t('BEE770', { 0: 5 } /* Informe o nível %{0} de classificação do produto */), 'text', true, disableLevel5)}
                        </div>
                      </div>
                    </TabPane>
                  </TabContent>
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.PRODUCT_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createProductSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}

                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateProductSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}

                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getProduct: (productId) => dispatch(getProduct(productId)),
  createProduct: (product) => dispatch(createProduct(product)),
  updateProduct: (product) => dispatch(updateProduct(product)),
  getUnitsMeasureOptions: () => dispatch(getUnitsMeasureOptions()),
  getMaterialsFamilyOptions: () => dispatch(getMaterialsFamilyOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductForm));
