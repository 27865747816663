import React from 'react';
import { withRouter } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel';

class doughnutChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  handleFormatter = (data) => {
    data.dataLabels = {
      formatter(value) {
        return `${parseInt(value, 10)}%`;
      },
    };
    data.legend = {
      formatter(value, opts) {
        return `${parseInt(opts.w.config.series[opts.seriesIndex], 10)} - ${value}`;
      },
    };
    return data;
  };

  render() {
    const { doughnutChartData } = this.props;
    return (
      <Panel>
        <PanelHeader onClickCollapse>
          {this.props.title}
        </PanelHeader>
        <PanelBody>
          <div style={{ height: `${this.props.height ? this.props.height : '450px'}` }}>
            {doughnutChartData
              && (
              <Chart
                series={doughnutChartData.series}
                options={this.handleFormatter(doughnutChartData.options)}
                type="donut"
                height="100%"
              />
              )}
          </div>
        </PanelBody>
      </Panel>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(withRouter(doughnutChart));
