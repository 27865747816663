import React from 'react';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';

import {
  createProductBranch, getProductBranch, updateProductBranch,
} from '../../../../app/store/actions/productBranches';
import { getStorageAddressTypesOptions } from '../../../../app/store/actions/storageAdressTypes';
import Select from '../../../../components/form/select';
import addNotification from '../../../../components/notification';
import ROUTES from '../../../../config/routes';
import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import { getAllCurves } from '../../../../app/store/actions/curves';
import { getPackagesOptionsWithProductEans } from '../../../../app/store/actions/packages';
import { getBranchesOptions } from '../../../../app/store/actions/branches';
import Form from '../../../../components/form/form';
import { getProductByCode } from '../../../../app/store/actions/products';

const { colourStyles } = Form;
class ProductBranchForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      inputProductCode: this.createInput,
      productCode: '',
      productDescription: '',
      averageCost: 0,
      multipleSale: 0,
      restriction: false,
      leadTime: 0,
      defaultAddress: '',
      note: '',
      shareAddress: false,
      receivesExpired: false,
      pickingExpired: false,
      storeProductsWithDifferentLotsAtSameAddress: false,
      listBranch: [],
      branchCode: null,
      branchName: '',
      packageCode: null,
      packageName: '',
      listPackages: [],
      disablePackage: true,
      disableProductCode: true,
      disableBranchCode: true,
      disableAverageCost: true,
      disableMultipleSale: true,
      disableType: true,
      disableRestriction: true,
      disableLeadTime: true,
      disableCurve: true,
      disableDefaultAddress: true,
      disableNote: true,
      disableShareAddress: true,
      disableReceivesExpired: true,
      disablePickingExpired: true,
      disableStoreProductsWithDifferentLotsAtSameAddress: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      curves: [],
      curve: '',
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getProductBranchSelected();
    } else {
      const addressTypesSelected = await this.props.getStorageAddressTypesOptions();
      if (addressTypesSelected) this.setState({ listType: addressTypesSelected });
      const listBranch = await this.props.getBranchesOptions();
      this.setState({
        listBranch,
      });
    }
    this.getCurves();
  }

  handleInputChange(event) {
    const {
      name, type, checked, value,
    } = event.target;

    if (type === 'checkbox') this.setState({ [name]: checked });
    else if (type === 'radio') this.setState({ [name]: parseInt(value, 10) });
    else this.setState({ [name]: value });
  }

  getCurves = async () => {
    await this.props.getAllCurves();
    const curves = [];
    this.props.curves.map((element) => (curves.push({
      value: element.code,
      label: element.code,
    })));
    this.setState({
      curves: [...curves],
    });
  };

  // Verificar esse método daqui
  getProductBranchSelected = async () => {
    if (this.props.location.state.productBranch) {
      const { productBranch } = this.props.location.state;
      const productBranchSelected = await this.props.getProductBranch(productBranch.id);
      if (productBranchSelected) {
        const listBranch = await this.props.getBranchesOptions();
        const listPackages = await this.props.getPackagesOptionsWithProductEans(productBranchSelected.productCode);
        const addressTypesSelected = await this.props.getStorageAddressTypesOptions();

        const branch = listBranch.filter((item) => item.value === productBranchSelected.branchCode);

        this.setState({
          productBranchId: productBranchSelected.id,
          productCode: productBranchSelected.productCode,
          productDescription: this.props.location.state.productBranch.product.fullName,
          branchCode: productBranchSelected.branchCode,
          branchName: branch.length > 0 ? branch[0].label : '',
          averageCost: parseFloat(productBranchSelected.averageCost),
          restriction: productBranchSelected.restriction,
          leadTime: productBranchSelected.leadTime,
          curve: productBranchSelected.curve,
          defaultAddress: productBranchSelected.defaultAddress,
          note: productBranchSelected.note,
          shareAddress: productBranchSelected.shareAddress,
          storeProductsWithDifferentLotsAtSameAddress:
          productBranchSelected.storeProductsWithDifferentLotsAtSameAddress,
          receivesExpired: productBranchSelected.receivesExpired,
          pickingExpired: productBranchSelected.pickingExpired,
          multipleSale: parseFloat(productBranch.product.multipleSale),
          listBranch,
          listType: addressTypesSelected || [],
          packageCode: productBranchSelected.packageCode,
          packageName: productBranchSelected['package.code']
            ? `${productBranchSelected['package.code']} - ${productBranchSelected['package.name']}` : '',
          listPackages,
          typeName: (
            productBranchSelected
            && productBranchSelected['typeAddress.id']
            && productBranchSelected['typeAddress.name']
          )
            ? `${productBranchSelected['typeAddress.id']} - ${productBranchSelected['typeAddress.name']}`
            : '',
          type: (
            productBranchSelected && productBranchSelected['typeAddress.id']
          )
            ? productBranchSelected['typeAddress.id']
            : null,
        });
      }
    }
  };

  setMode = () => {
    let mode;
    if (this.props.isCreate) mode = 'create';
    else if (this.props.isCopy) mode = 'copy';
    else if (this.props.isEdit) mode = 'edit';
    else if (this.props.isDetail) mode = 'detail';
    else mode = '';

    if (mode === 'create') {
      this.setState({
        productCode: '',
        productDescription: '',
        averageCost: 0,
        multipleSale: 0,
        restriction: false,
        leadTime: 0,
        defaultAddress: '',
        note: '',
        type: null,
        typeName: '',
        shareAddress: false,
        receivesExpired: false,
        pickingExpired: false,
        storeProductsWithDifferentLotsAtSameAddress: false,
        branchCode: null,
        branchName: '',
        packageCode: null,
        packageName: '',
        listPackages: [],
        disablePackage: true,
        disableProductCode: false,
        disableBranchCode: true,
        disableAverageCost: true,
        disableMultipleSale: true,
        disableType: true,
        disableRestriction: true,
        disableLeadTime: true,
        disableCurve: true,
        disableDefaultAddress: true,
        disableNote: true,
        disableShareAddress: true,
        disableReceivesExpired: true,
        disablePickingExpired: true,
        disableStoreProductsWithDifferentLotsAtSameAddress: true,
        mode,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        inputProductCode: this.createInputButton,
      });
    } else if (mode === 'copy') {
      this.setState({
        mode,
        disableProductCode: false,
        disableBranchCode: false,
        disableAverageCost: false,
        disableMultipleSale: true,
        disableType: false,
        disableRestriction: false,
        disableLeadTime: false,
        disableCurve: false,
        disableNote: false,
        disableDefaultAddress: false,
        disableShareAddress: false,
        disableReceivesExpired: false,
        disablePickingExpired: false,
        disablePackage: false,
        disableStoreProductsWithDifferentLotsAtSameAddress: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        inputProductCode: this.createInputButton,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableProductCode: true,
        disableBranchCode: true,
        disableAverageCost: false,
        disableMultipleSale: true,
        disableType: false,
        disableRestriction: false,
        disableLeadTime: false,
        disableCurve: false,
        disableNote: false,
        disableDefaultAddress: false,
        disableShareAddress: false,
        disableReceivesExpired: false,
        disablePickingExpired: false,
        disableStoreProductsWithDifferentLotsAtSameAddress: false,
        disablePackage: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (ref, value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div id={attr} className="col-md-5">
        <input
          ref={(el) => { this[`productBrach-${ref}`] = el; }}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(event) => this.setValue(attr, event.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  goToElement = async (e, ref, attr) => {
    if (e.keyCode === 13) {
      if (attr === 'productCode') {
        await this.getProductDescription();
      }

      if (attr === 'mainBarCode') this[`productBranch-${ref + 1}`].select.focus();
      else this[`productBranch-${ref + 1}`].focus();
    }
  };

  getProductDescription = async () => {
    const { productCode, mode } = this.state;
    try {
      const productDetails = await this.props.getProductDetails(productCode);
      const listPackages = await this.props.getPackagesOptionsWithProductEans(productCode);
      await this.setState({
        disableProductCode: true,
        disableBranchCode: false,
        disableAverageCost: false,
        disableMultipleSale: true,
        disableType: false,
        disableRestriction: false,
        disableLeadTime: false,
        disableCurve: false,
        disableNote: false,
        disableDefaultAddress: false,
        disableShareAddress: false,
        disableReceivesExpired: false,
        disablePickingExpired: false,
        disablePackage: false,
        disableStoreProductsWithDifferentLotsAtSameAddress: false,
        productDescription: productDetails.fullName,
        listPackages,
      });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;
      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification(
          'danger',
          mode === 'create'
            ? I18n.t('BEE417' /* Novo Produto por Filial */) : I18n.t('BEE858' /* Atualizar produto por filial */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          mode === 'create'
            ? I18n.t('BEE417' /* Novo Produto por Filial */) : I18n.t('BEE858' /* Atualizar produto por filial */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  clearProductCode = () => {
    this.setState({ productCode: '', productDescription: '' /* outros estados a limpar */ });
  };

  createInputButton = (
    ref,
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
  ) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div id={attr} className="col-md-5 input-group ">
        <input
          ref={(el) => { this[`productBrach-${ref}`] = el; }}
          onKeyDown={keypressFunction && ((el) => keypressFunction(el, ref, attr))}
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(event) => this.setValue(attr, event.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <button
          type="button"
          className="btn btn-secondary"
          style={{ height: 35 }}
          onClick={this.clearProductCode}
        >
          <i className="fa fa-times" />
        </button>
      </div>
    </div>
  );

  createTextArea = (ref, value, attr, label, placeholder, rows, required, disabled, keypressFunction) => (
    <div className="form-group row m-b-15">
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5 input-group">
        <textarea
          ref={(el) => { this[`productBranch-${ref}`] = el; }}
          onKeyDown={keypressFunction && ((el) => keypressFunction(el, ref, attr))}
          className="form-control m-b-5"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  createSelectDropDown = (ref, value, attr, label, items, disabled = false, bAttrCode = false, keypressFunction) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div id={attr} className="col-md-5">
        <Select
          ref={(el) => { this[`productBranch-${ref}`] = el; }}
          value={(value && value.value && value.label) ? value : ''}
          onChange={(event) => this.setValueDrop(attr, event, bAttrCode)}
          options={items}
          isClearable
          isDisabled={disabled}
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          onKeyDown={keypressFunction && ((el) => keypressFunction(el, ref, attr))}
        />
      </div>
    </div>
  );

  createToggleBox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={label} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <div className="row" style={{ marginTop: '8px', marginLeft: '0px' }}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleInputChange.bind(this)}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`}>{}</label>
          </div>
        </div>
      </div>
    </div>
  );

  createSelect = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <select
          className="form-control"
          value={value || ''}
          onChange={(event) => this.setValue(attr, event.target.value)}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div id={attr} className="col-md-5">
        <NumberFormat
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={decimals}
          value={value || 0}
          onValueChange={(values) => { this.setValue(attr, values.floatValue); }}
          fixedDecimalScale
          defaultValue={0}
          allowEmptyFormatting
          allowLeadingZeros
        />
      </div>
    </div>
  );

  setValueDrop = async (attr, value, bAttrCode = false) => {
    await this.setState({
      [`${attr}${bAttrCode ? 'Code' : ''}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  createProductBranchSubmit = async () => {
    const {
      productCode, branchCode, averageCost, restriction, leadTime,
      curve, note, defaultAddress, shareAddress, receivesExpired,
      pickingExpired, type, storeProductsWithDifferentLotsAtSameAddress,
      curves, multipleSale, packageCode,
    } = this.state;

    const selectedCurve = curve || curves[0].value;

    try {
      const newProductBranch = await this.props.createProductBranch({
        productCode,
        branchCode,
        averageCost,
        restriction,
        leadTime,
        curve: selectedCurve,
        note,
        defaultAddress: defaultAddress.toLocaleUpperCase(),
        shareAddress,
        receivesExpired,
        addressType: type,
        pickingExpired,
        storeProductsWithDifferentLotsAtSameAddress,
        multipleSale,
        packageCode,
      });

      if (newProductBranch) {
        if (newProductBranch && newProductBranch.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE417' /* Novo Produto por Filial */),
            I18n.t('BEE856', { 0: productCode } /* Erro ao incluir o produto por filial %{0} ! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE417' /* Novo Produto por Filial */),
            I18n.t('BEE857', { 0: productCode } /* Novo produto por filial %{0} incluída com sucesso ! */),
            'top-right',
          );

          this.props.history.push(ROUTES.PRODUCT_BRANCH_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE417' /* Novo Produto por Filial */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE417' /* Novo Produto por Filial */),
            I18n.t('BEE856', { 0: productCode } /* Erro ao incluir o produto por filial %{0} ! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE417' /* Novo Produto por Filial */),
          I18n.t('BEE856', { 0: productCode } /* Erro ao incluir o produto por filial %{0} ! */),
          'top-right',
        );
      }
    }
  };

  updateProductBranchSubmit = async () => {
    const {
      productBranchId, productCode, branchCode, averageCost,
      restriction, leadTime, curve, note, defaultAddress,
      shareAddress, receivesExpired, pickingExpired, type,
      storeProductsWithDifferentLotsAtSameAddress, curves,
      packageCode,
    } = this.state;

    const selectedCurve = curve || curves[0].value;

    try {
      const updCustomerProduct = await this.props.updateProductBranch({
        productBranchId,
        productCode,
        branchCode,
        averageCost,
        restriction,
        leadTime,
        curve: selectedCurve,
        defaultAddress: defaultAddress.toLocaleUpperCase(),
        note,
        shareAddress,
        receivesExpired,
        addressType: type,
        pickingExpired,
        storeProductsWithDifferentLotsAtSameAddress,
        packageCode,
      });

      if (updCustomerProduct) {
        if (updCustomerProduct.success && updCustomerProduct.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE858' /* Atualizar produto por filial */),
            I18n.t('BEE859', { 0: productCode } /* Erro ao atualizar o produto por filial %{0} ! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE858' /* Atualizar produto por filial */),
            I18n.t('BEE860', { 0: productCode } /* Atualização do produto por filial %{0} efetuada com sucesso ! */),
            'top-right',
          );

          this.props.history.push(ROUTES.PRODUCT_BRANCH_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE858' /* Atualizar produto por filial */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE858' /* Atualizar produto por filial */),
            I18n.t('BEE859', { 0: productCode } /* Erro ao atualizar o produto por filial %{0} ! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE858' /* Atualizar produto por filial */),
          I18n.t('BEE859', { 0: productCode } /* Erro ao atualizar o produto por filial %{0} ! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      productCode, branchCode, defaultAddress, typeName, averageCost,
      restriction, shareAddress, receivesExpired, pickingExpired, productDescription,
      storeProductsWithDifferentLotsAtSameAddress, leadTime, curve, note,
      mode, disableProductCode, disableBranchCode, disableAverageCost, disableRestriction,
      disableType, disableLeadTime, disableCurve, disableNote, showCreate, showUpdate,
      showCancel, disableDefaultAddress, disableShareAddress, disableReceivesExpired,
      disablePickingExpired, disableStoreProductsWithDifferentLotsAtSameAddress,
      listType, type, curves, multipleSale, disableMultipleSale, inputProductCode,
      packageCode, packageName, listPackages, disablePackage, listBranch, branchName,
    } = this.state;

    let title = '';
    if (mode === 'create' || mode === 'copy') title = I18n.t('BEE417' /* Novo Produto por Filial */);
    else if (mode === 'edit') title = I18n.t('BEE862' /* Editar produto por filial */);
    else if (mode === 'detail') title = I18n.t('BEE861' /* Detalhes produto por filial */);

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {` ${I18n.t('BEE12' /* Início */)}`}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE27' /* Produtos */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE408' /* Produtos por Filial */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE417' /* Novo Produto por Filial */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  {inputProductCode(
                    1,
                    productCode,
                    'productCode',
                    `${I18n.t('BEE378' /* Código do Produto */)}:`,
                    I18n.t('BEE372' /* Informe o código do produto (obrigatório) */),
                    'text',
                    true,
                    disableProductCode,
                    this.goToElement,
                  )}
                  {this.createTextArea(
                    'productDescription',
                    productDescription,
                    'productFullDescription',
                    `${I18n.t('BEE758' /* Descrição do Produto */)}:`,
                    '',
                    3,
                    true,
                    true,
                  )}
                  {this.createSelectDropDown(
                    2,
                    { value: branchCode, label: branchName },
                    'branch',
                    `${I18n.t('BEE409' /* Código da Filial */)}:`,
                    listBranch,
                    disableBranchCode,
                    true,
                  )}
                  {this.createInput(
                    3,
                    `${defaultAddress}`.toLocaleUpperCase(),
                    'defaultAddress',
                    `${I18n.t('BEE423' /* Endereço Padrão */)}:`,
                    I18n.t('BEE1203' /* Informe o endereço padrão */),
                    'text',
                    true,
                    disableDefaultAddress,
                  )}
                  {(disableType
                    ? this.createInput(4, typeName, 'type', I18n.t('BEE200' /* Tipo */), '', 'text', false, disableType)
                    : this.createSelectDropDown(
                      4,
                      { value: type, label: typeName },
                      'type',
                      `${I18n.t('BEE200' /*  Tipo */)}:`,
                      listType,
                      false,
                    )
                  )}
                  {this.createSelectDropDown(
                    5,
                    { value: packageCode, label: packageName },
                    'package',
                    `${I18n.t('BEE2355' /* Embalagem Padrão */)}:`,
                    listPackages,
                    disablePackage,
                    true,
                  )}
                  {(disableAverageCost
                    ? this.createInput(
                      6,
                      averageCost,
                      'averageCost',
                      `${I18n.t('BEE410' /* Custo Médio */)}:`,
                      '',
                      'text',
                      true,
                      disableAverageCost,
                    )
                    : this.createNumberFormat(averageCost, 'averageCost', I18n.t('BEE410' /* Custo Médio */), 3)
                  )}
                  {(disableMultipleSale
                    ? this.createInput(
                      7,
                      multipleSale,
                      'multipleSale',
                      `${I18n.t('BEE713' /* Lote Múltiplo Venda */)}:`,
                      '',
                      'text',
                      true,
                      disableMultipleSale,
                    )
                    : this.createNumberFormat(
                      multipleSale,
                      'multipleSale',
                      `${I18n.t('BEE713' /* Lote Múltiplo Venda */)}:`,
                      1,
                    )
                  )}
                  {this.createToggleBox(
                    restriction,
                    'restriction',
                    `${I18n.t('BEE411' /* Restrições */)}:`,
                    disableRestriction,
                  )}
                  {this.createToggleBox(
                    shareAddress,
                    'shareAddress',
                    `${I18n.t('BEE697' /* Compartilha Endereço */)}:`,
                    disableShareAddress,
                  )}
                  {this.createToggleBox(
                    storeProductsWithDifferentLotsAtSameAddress,
                    'storeProductsWithDifferentLotsAtSameAddress',
                    `${I18n.t('BEE2371' /* Compartilha Lote */)}:`,
                    disableStoreProductsWithDifferentLotsAtSameAddress,
                  )}
                  {this.createToggleBox(
                    receivesExpired,
                    'receivesExpired',
                    `${I18n.t('BEE1456' /* Recebe Produto Vencido */)}:`,
                    disableReceivesExpired,
                  )}
                  {this.createToggleBox(
                    pickingExpired,
                    'pickingExpired',
                    `${I18n.t('BEE1457' /* Separa Produto Vencido */)}:`,
                    disablePickingExpired,
                  )}
                  {(disableLeadTime
                    ? this.createInput(
                      8,
                      leadTime,
                      'leadTime',
                      `${I18n.t('BEE412' /* Lead Time */)}:`,
                      I18n.t('BEE419' /* Informe o Lead time */),
                      'text',
                      true,
                      disableLeadTime,
                    )
                    : this.createNumberFormat(leadTime, 'leadTime', `${I18n.t('BEE412' /* Lead Time */)}:`, 0)
                  )}
                  {(disableCurve
                    ? this.createInput(
                      9,
                      curve,
                      'curve',
                      `${I18n.t('BEE355' /* Curva */)}:`,
                      I18n.t('BEE863' /* Informe a curva */),
                      'text',
                      true,
                      disableCurve,
                    )
                    : this.createSelect(
                      curve,
                      'curve',
                      `${I18n.t('BEE355' /* Curva */)}:`,
                      curves,
                    )
                  )}
                  {this.createInput(
                    10,
                    note,
                    'note',
                    `${I18n.t('BEE135' /* Observação */)}:`,
                    I18n.t('BEE136' /* Informe a observação */),
                    'text',
                    false,
                    disableNote,
                  )}
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.PRODUCT_BRANCH_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton
                          type="button"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton
                          type="button"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      )
                    )}
                  </Link>
                  {(showCreate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.createProductBranchSubmit()}
                      >
                        {I18n.t('BEE138' /* Criar */)}
                      </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.updateProductBranchSubmit()}
                      >
                        {I18n.t('BEE139' /* Atualizar */)}
                      </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  curves: state.curves?.curvesList,
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getProductBranch: (productBranchId) => dispatch(getProductBranch(productBranchId)),
  createProductBranch: (productBranch) => dispatch(createProductBranch(productBranch)),
  updateProductBranch: (productBranch) => dispatch(updateProductBranch(productBranch)),
  getStorageAddressTypesOptions: () => dispatch(getStorageAddressTypesOptions()),
  getAllCurves: () => dispatch(getAllCurves()),
  getPackagesOptionsWithProductEans: (productCode) => dispatch(getPackagesOptionsWithProductEans(productCode)),
  getProductDetails: (productCode) => dispatch(getProductByCode(productCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ProductBranchForm));
