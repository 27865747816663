import 'react-datepicker/dist/react-datepicker.css';

import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import SweetAlert from 'react-bootstrap-sweetalert';

import { SimpleForm } from '../../../components/panel/panel';
import addNotification from '../../../components/notification';

import ROUTES from '../../../config/routes';

import {
  createI18n, updateI18n, getI18n, getI18nNewKey, getTranslate,
} from '../../../app/store/actions/i18n';

class DevelopI18nForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.focusedValue = '';
    this.state = {
      mode: 'detail',
      key: '',
      ptBr: '',
      esCh: '',
      enUs: '',
      fixed: false,
      disableKey: true,
      disablePtBr: true,
      disableEsCh: true,
      disableEnUs: true,
      disableCheckbox: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      showAlertTranslate: false,
      showAlertAttr: '',
      showAlertValue: '',
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode === 'create') {
      await this.getLastKey();
    } else {
      await this.getI18nSelected();
    }
  }

  getI18nSelected = async () => {
    if (this.props.location.state.i18n) {
      const { i18n } = this.props.location.state;

      const i18nSelected = await this.props.getI18n(i18n.key);

      if (i18nSelected) {
        this.setState({
          i18nSelected,
          key: i18nSelected.key,
          ptBr: i18nSelected.ptBr,
          esCh: i18nSelected.esCh,
          enUs: i18nSelected.enUs,
          fixed: i18nSelected.fixed,
        });
      }
    }
  };

  getLastKey = async () => {
    const lastKey = await this.props.getI18nNewKey();

    this.setState({
      key: lastKey,
    });
  };

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableKey: true,
        disablePtBr: false,
        disableEsCh: false,
        disableEnUs: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        disableCheckbox: false,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableKey: true,
        disablePtBr: true,
        disableEsCh: true,
        disableEnUs: true,
        disableCheckbox: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div key={attr} className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          // onBlur={e => this.onBlur(attr, e.target.value)}
          onFocus={(e) => (this.focusedValue = e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={attr === 'key' ? disabled : !this.state.fixed}
        />
      </div>
    </div>
  );

  createCheckbox = (item, attr, label, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <div className="row" style={{ marginTop: '8px', marginLeft: '0px' }}>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              id={`customCheck${attr}`}
              className="custom-control-input"
              disabled={disabled}
              checked={item}
              name={attr}
              onChange={this.handleCheckboxChange}
            />
            <label className="custom-control-label" htmlFor={`customCheck${attr}`} />
          </div>
        </div>
      </div>
    </div>
  );

  handleCheckboxChange = () => {
    this.setState((prevState) => ({
      fixed: !prevState.fixed,
      disablePtBr: !prevState.fixed,
      disableEsCh: !prevState.fixed,
      disableEnUs: !prevState.fixed,
    }));
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  onBlur = (attr, value) => {
    if (value !== this.focusedValue) {
      this.setState({ showAlertAttr: attr, showAlertValue: value, showAlertTranslate: true });
    }
  };

  onConfirmTranslate = async (attr, value) => {
    let translatedOne = '';
    let translatedTwo = '';
    const { getTranslate } = this.props;

    try {
      if (attr === 'ptBr' && value) {
        translatedOne = await getTranslate(value, 'pt', 'es');
        translatedTwo = await getTranslate(value, 'pt', 'en');

        this.setState({ esCh: translatedOne, enUs: translatedTwo, showAlertTranslate: false });
      } else if (attr === 'esCh' && value) {
        translatedOne = await getTranslate(value, 'es', 'pt');
        translatedTwo = await getTranslate(value, 'es', 'en');

        this.setState({ ptBr: translatedOne, enUs: translatedTwo, showAlertTranslate: false });
      } else if (attr === 'enUs' && value) {
        translatedOne = await getTranslate(value, 'en', 'pt');
        translatedTwo = await getTranslate(value, 'en', 'es');

        this.setState({ ptBr: translatedOne, esCh: translatedTwo, showAlertTranslate: false });
      }
    } catch (e) {
      // something wrong here
    }
  };

  updateI18nSubmit = async () => {
    const {
      key, ptBr, esCh, enUs, fixed,
    } = this.state;

    try {
      const updI18n = await this.props.updateI18n({
        key, ptBr, esCh, enUs, fixed,
      });

      if (updI18n && updI18n instanceof Array && updI18n.length) {
        await addNotification(
          'success',
          I18n.t('BEE236' /* Atualizar Idioma */),
          I18n.t('BEE237', { 0: key } /* Atualização do Idioma %{0} efetuado com sucesso! */),

          'top-right',
        );
        this.props.history.push(ROUTES.DEVELOP_I18N_LIST);
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE236' /* Atualizar Idioma */),
          I18n.t('BEE238', { 0: key } /* Erro ao atualizar o Idioma %{0}! */),

          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE236' /* Atualizar Idioma */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE236' /* Atualizar Idioma */),
            I18n.t('BEE238', { 0: key } /* Erro ao atualizar o Idioma %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE236' /* Atualizar Idioma */),
          I18n.t('BEE238', { 0: key } /* Erro ao atualizar o Idioma %{0}! */),
          'top-right',
        );
      }
      await addNotification(
        'danger',
        I18n.t('BEE236' /* Atualizar Idioma */),
        I18n.t('BEE238', { 0: key } /* Erro ao atualizar o Idioma %{0}! */),
        'top-right',
      );
    }
  };

  render() {
    const {
      mode, key, ptBr, esCh, enUs, disableKey, disablePtBr, disableEsCh,
      disableEnUs, showCreate, showUpdate, showCancel, showAlertTranslate,
      showAlertAttr, showAlertValue,
    } = this.state;

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE233' /* Novo Idioma */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE239' /* Editar Idioma */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE240' /* Detalhes Idioma */);
    }

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE48' /* Desenvolvedor */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE49' /* Idiomas */)}</li>
          </ol>
        </div>
        <SimpleForm
          title={title}
          noButton
          linkBack={{ pathname: ROUTES.DEVELOP_I18N_LIST, state: { dice: this.props.location.dice } }}
          showCancel={showCancel}
          showCreate={showCreate}
          showUpdate={showUpdate}
          onSubmitUpdate={() => this.updateI18nSubmit()}
          inputs={[
            this.createInput(
              key,
              'key',
              `${I18n.t('BEE227' /* Chave */)}:`,
              I18n.t('BEE241' /* Informe a chave do Idioma (obrigatório) */),
              'text',
              true,
              disableKey,
            ),
            this.createInput(
              ptBr,
              'ptBr',
              `${I18n.t('BEE228' /* Português */)}:`,
              I18n.t('BEE242' /* Informe o idioma para português */),
              'text',
              false,
              disablePtBr,
            ),
            this.createInput(
              esCh,
              'esCh',
              `${I18n.t('BEE229' /* Espanhol */)}:`,
              I18n.t('BEE243' /* Informe o idioma para espanhol */),
              'text',
              false,
              disableEsCh,
            ),
            this.createInput(
              enUs,
              'enUs',
              `${I18n.t('BEE230' /* Inglês */)}:`,
              I18n.t('BEE244' /* Informe o idioma para inglês */),
              'text',
              false,
              disableEnUs,
            ),
            this.createCheckbox(
              this.state.fixed,
              'isFixed',
              I18n.t('BEE507' /* Específico */),
              this.state.disableCheckbox,
            ),
          ]}
        />
        {(showAlertTranslate
          && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="info"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE245' /* Traduzir para outros idiomas? */)}
              onConfirm={() => this.onConfirmTranslate(showAlertAttr, showAlertValue)}
              onCancel={() => this.setState({ showAlertTranslate: false })}
            >
              {I18n.t('BEE246' /* Deseja aplicar uma tradução padrão para os demais idiomas? */)}
            </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getI18n: (key) => dispatch(getI18n(key)),
  createI18n: (i18n) => dispatch(createI18n(i18n)),
  updateI18n: (i18n) => dispatch(updateI18n(i18n)),
  getI18nNewKey: () => dispatch(getI18nNewKey()),
  getTranslate: (text, from, to) => dispatch(getTranslate(text, from, to)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(DevelopI18nForm));
