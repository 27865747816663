/* eslint-disable jsx-a11y/label-has-associated-control */
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import ReactTable from 'react-table';
import ReactTags from 'react-tag-autocomplete';
import {
  Modal, ModalBody, ModalHeader, Nav, NavItem, NavLink, PopoverHeader, TabContent, TabPane, UncontrolledPopover,
} from 'reactstrap';

import { getAccessProfileOptions } from '../../../../app/store/actions/accessProfile';
import { getBranchesOptions, getBranchesSuggestions } from '../../../../app/store/actions/branches';
import { getDepartmentsOptions } from '../../../../app/store/actions/departments';
import { getUserFunctionsOptions } from '../../../../app/store/actions/userFunctions';
import { createUser, getUser, updateUser } from '../../../../app/store/actions/users';
import { getWorkShiftsOptions } from '../../../../app/store/actions/workShifts';
import OneClickButton from '../../../../components/form/button';
import Select from '../../../../components/form/select';
import addNotification from '../../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import { generatePassword } from '../../../../components/password/generator';
import ROUTES from '../../../../config/routes';
import UserHelpers from '../../../../helpers/users';
import PrinterLinkModal from './printerLinkModal';
import UserPrintersTable from './userPrintersTable';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};
class UserForm extends React.Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);

    this.state = {
      activeTab: '1',
      status: 1,
      type: 3,
      mode: 'detail',
      name: '',
      login: '',
      password: generatePassword(),
      email: '',
      code: '',
      department: null,
      userFunction: null,
      workShift: null,
      accessProfile: null,
      phone: '',
      branchLine: '',
      cellPhone: '',
      printer: null,
      departmentName: '',
      userFunctionName: '',
      workShiftName: '',
      accessProfileName: '',
      listDepartment: '',
      listShift: '',
      listAccessProfile: '',
      listFunction: '',
      disableLogin: true,
      disableName: true,
      disableType: true,
      disableStatus: true,
      disablePassword: true,
      disableEmail: true,
      disableCode: true,
      disableDepartment: true,
      disableUserFunction: true,
      disableWorkShift: true,
      disableAccessProfile: true,
      disablePhone: true,
      disableBranchLine: true,
      disableCellPhone: true,
      disablePrinter: true,
      disablePermission: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      listBranch: [],
      userBranches: [],
      showModalDelete: false,
      branchDelete: null,
      showModalAddition: false,
      branchAddition: null,
      showBranchList: false,
      disableMainBranch: true,
      mainBranch: null,
      mainBranchName: '',
      printerName: '',
      listBranchOptions: [],
      showPrinterLinkBtn: false,
      showPrinterLinkModal: false,
      listPrinter: [],
      showPrinterLinkTab: true,
      listUserTyp: [],
    };

    this.tableColumns = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      },
    ];
  }

  async componentDidMount() {
    this.setMode();
    if (this.state.mode !== 'create') {
      await this.getUserSelected();
    }

    const listDepartment = await this.props.getDepartmentsOptions();
    const listFunction = await this.props.getUserFunctionsOptions();
    const listShift = await this.props.getWorkShiftsOptions();
    const listAccessProfile = await this.props.getAccessProfileOptions();
    const listBranch = await this.props.getBranchesSuggestions();
    const listBranchOptions = await this.props.getBranchesOptions();

    const listUserTyp = (this.state.mode !== 'detail' && this.props.userLogged.type === 2)
      ? [UserHelpers.listUserType()[1], UserHelpers.listUserType()[2]]
      : UserHelpers.listUserType();

    this.setState(() => ({
      listDepartment,
      listFunction,
      listShift,
      listAccessProfile,
      listBranch,
      listBranchOptions,
      listUserTyp,
    }));
  }

  async componentDidUpdate(prevProps) {
    if (this.props.userLogged !== prevProps.userLogged) {
      await this.setMode();
      await this.getUserSelected();
    }
  }

  handleInputChange(event) {
    const { target } = event;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  }

  showDelete = (i) => {
    const { disablePermission } = this.state;

    if (!disablePermission) this.setState({ showModalDelete: true, branchDelete: i });
  };

  copyPassword = () => {
    // Navigator clipboard api precisa estar em um ambiente seguro (https)
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(this.state.password);
    } else {
      // Se for http, será necessário realizar uma adaptação tecnica
      const textArea = document.createElement('textarea');
      textArea.value = this.state.password;

      // Move a textarea para um lugar não visivel
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  };

  showAddition = (i) => {
    this.setState({ showModalAddition: true, branchAddition: i });
  };

  showBranchList = () => {
    this.setState({ showBranchList: true });
  };

  handleDelete = (i) => {
    const userBranches = this.state.userBranches.slice(0);
    userBranches.splice(i, 1);
    this.setState({ userBranches, showModalDelete: false });
  };

  handleAddition = (branch) => {
    const userBranches = [].concat(this.state.userBranches, branch);
    this.setState({ userBranches, showModalAddition: false, showBranchList: false });
  };

  getUserSelected = async () => {
    if (this.props.location.state.user) {
      const { user } = this.props.location.state;
      const { userLogged } = this.props;

      if (userLogged) {
        const userSelected = await this.props.getUser(user.id);

        if (userSelected) {
          const listPrinter = userSelected.userPrinters.map((userPrinter) => ({
            value: userPrinter.printer.code,
            label: `${userPrinter.printer.code} - ${userPrinter.printer.name}`,
          }));

          this.setState({
            userSelected,
            userId: userSelected.id,
            name: userSelected.name,
            login: userSelected.login,
            type: (userLogged.type <= 2) ? userSelected.type : userLogged.type,
            status: userSelected.status,
            email: userSelected.email,
            code: userSelected.code,
            department: userSelected.department,
            departmentName: userSelected.departmentName,
            userFunction: userSelected.userFunction,
            userFunctionName: userSelected.userFunctionName,
            workShift: userSelected.workShift,
            workShiftName: userSelected.workShiftName,
            accessProfile: userSelected.accessProfile,
            accessProfileName: userSelected.accessProfileName,
            phone: userSelected.phone,
            branchLine: userSelected.branchLine,
            cellPhone: userSelected.cellPhone,
            printer: userSelected.printer,
            userBranches: userSelected.userBranches,
            mainBranch: userSelected.mainBranch,
            mainBranchName: userSelected.mainBranchName,
            printerName: userSelected.printerName,
            listPrinter,
          });
        }
      }
    }
  };

  setMode = () => {
    const { userLogged } = this.props;
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (userLogged) {
      if (mode === 'create' || mode === 'copy') {
        this.setState({
          mode,
          showCreate: true,
          showCancel: true,
          disableLogin: false,
          disableName: false,
          disableType: !((userLogged.type <= 2)),
          disableStatus: false,
          disablePassword: false,
          disableEmail: false,
          disableCode: false,
          disableDepartment: false,
          disableUserFunction: false,
          disableWorkShift: false,
          disableAccessProfile: false,
          disablePhone: false,
          disableBranchLine: false,
          disableCellPhone: false,
          disablePrinter: false,
          disablePermission: false,
          disableMainBranch: false,
          showPrinterLinkBtn: false,
          showPrinterLinkTab: false,
        });
      } else if (mode === 'edit') {
        this.setState({
          mode,
          showUpdate: true,
          showCancel: true,
          disableLogin: true,
          disableName: false,
          disableType: !((userLogged.type <= 2)),
          disableStatus: true,
          disablePassword: true,
          disableEmail: false,
          disableCode: false,
          disableDepartment: false,
          disableUserFunction: false,
          disableWorkShift: false,
          disableAccessProfile: false,
          disablePhone: false,
          disableBranchLine: false,
          disableCellPhone: false,
          disablePrinter: false,
          disablePermission: false,
          disableMainBranch: false,
          showPrinterLinkBtn: true,
          showPrinterLinkTab: true,
        });
      }
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  displayPassword = () => (
    <div className="form-group row m-b-15">
      <label className="col-form-label col-md-4">{`${I18n.t('BEE2' /* Senha */)}:`}</label>
      <div className="col-md-5">
        <div className="form-control">
          <div className="row">
            <div
              style={{ textAlign: 'initial' }}
              className="col-md-9"
            >
              {this.state.password}
            </div>
            <button
              id="tooltipCopy"
              type="button"
              style={{ marginLeft: 'auto', backgroundColor: '#fff' }}
              onClick={this.copyPassword}
            >
              <i
                className="fas fa-copy fa-2x"
                style={{ lineHeight: '0.59em' }}
              />
              <UncontrolledPopover trigger="hover" placement="top" target="tooltipCopy">
                <PopoverHeader>
                  {I18n.t('BEE80' /* Copiar */)}
                </PopoverHeader>
              </UncontrolledPopover>
            </button>
          </div>
        </div>
      </div>
    </div>
  );

  createSelect = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <select
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
        >
          {items.map((item) => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

  createSelectDropDown = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <Select
          value={value || ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          required={required}
        />
      </div>
    </div>
  );

  createPermissionInput = (userBranches, listBranch, disabled = true) => (
    <div className="form-group row">
      <label className="col-form-label col-md-4">{`${I18n.t('BEE682' /* Permissão Filiais */)}:`}</label>
      <div className="col-md-5 text-left">
        {!disabled
          && (
            <button type="button" className="btn btn-primary m-b-5" onClick={() => this.showBranchList()}>
              <i className="fa fa-plus" />
            </button>
          )}
        <ReactTags
          allowBackspace={false}
          placeholder=""
          tags={userBranches}
          suggestions={listBranch}
          handleDelete={this.showDelete.bind(this)}
          handleAddition={() => { }}
          inputAttributes={{ disabled: true }}
        />
      </div>
    </div>
  );

  createModalBranchList = () => {
    const { showBranchList, listBranch, userBranches } = this.state;

    const newList = listBranch.slice(0);

    if (userBranches) {
      userBranches.forEach((branch) => {
        const index = newList.findIndex((item) => item.id === branch.id);
        if (index >= 0) newList.splice(index, 1);
      });
    }

    return (
      <Modal isOpen={showBranchList} toggle={() => this.setState({ showBranchList: !showBranchList })}>
        <ModalHeader
          toggle={() => this.setState({ showBranchList: !showBranchList })}
        >
          {I18n.t('BEE16' /* Filiais */)}
        </ModalHeader>
        <ModalBody>
          <Panel>
            <PanelHeader noButton />
            <ReactTable
              showPagination={false}
              filterable
              data={newList}
              columns={this.tableColumns}
              expander
              defaultPageSize={newList ? newList.length : 1}
              defaultSorted={[{ id: 'code', desc: false }]}
              defaultFilterMethod={(filter, row) => {
                const input = _.lowerCase(filter.value);
                const value = _.lowerCase(row[filter.id]);
                if (_.includes(value, input)) {
                  return true;
                }
              }}
              className="-highlight"
              loadingText={I18n.t('BEE97' /* Carregando... */)}
              noDataText={I18n.t('BEE98' /* Nenhuma linha encontrada */)}
              getTrProps={(state, rowInfo) => {
                if (rowInfo && rowInfo.row) {
                  return {
                    onClick: () => {
                      this.showAddition(rowInfo.original);
                    },
                    style: {
                      cursor: 'pointer',
                    },
                  };
                }
                return {};
              }}
            />
          </Panel>
        </ModalBody>
      </Modal>
    );
  };

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  openPrinterLinkModal = () => {
    this.setState({
      showPrinterLinkModal: true,
    });
  };

  closePrinterLinkModal = async () => {
    this.setState({
      showPrinterLinkModal: false,
    });
    await this.getUserSelected();
  };

  createUserSubmit = async () => {
    const {
      name, login, type, status, password, email, code, department, userFunction,
      workShift, phone, branchLine, cellPhone, printer, accessProfile, userBranches, mainBranch,
    } = this.state;
    try {
      const newUser = await this.props.createUser({
        name,
        login,
        type,
        status,
        password,
        email,
        code,
        department,
        userFunction,
        workShift,
        accessProfile,
        phone: phone ? phone.replace(/\D/g, '') : '',
        branchLine,
        cellPhone: cellPhone ? cellPhone.replace(/\D/g, '') : '',
        printer,
        userBranches: userBranches.map((branch) => branch.id).toString(),
        mainBranch,
      });

      if (newUser) {
        if (newUser.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE883' /* Novo usuário */),
            I18n.t('BEE884', { 0: login } /* Erro ao incluir o usuário %{0} ! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE883' /* Novo usuário */),
            I18n.t('BEE885', { 0: login } /* Novo usuário %{0} incluído com sucesso ! */),
            'top-right',
          );

          this.props.history.push(ROUTES.USER_LIST);
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE883' /* Novo usuário */),
          I18n.t('BEE884', { 0: login } /* Erro ao incluir o usuário %{0} ! */),
          'top-right',
        );
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE883' /* Novo usuário */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE883' /* Novo usuário */),
            I18n.t('BEE884', { 0: login } /* Erro ao incluir o usuário %{0} ! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE883' /* Novo usuário */),
          I18n.t('BEE884', { 0: login } /* Erro ao incluir o usuário %{0} ! */),
          'top-right',
        );
      }
    }
  };

  updateUserSubmit = async () => {
    const {
      name, userId, login, type, email, code, department, userFunction, workShift, phone, branchLine, cellPhone,
      printer, accessProfile, userBranches, mainBranch,
    } = this.state;

    try {
      const updUser = await this.props.updateUser({
        name,
        userId,
        type,
        email,
        code,
        department,
        userFunction,
        workShift,
        accessProfile,
        phone: phone ? phone.replace(/\D/g, '') : '',
        branchLine,
        cellPhone: cellPhone ? cellPhone.replace(/\D/g, '') : '',
        printer,
        userBranches: userBranches.map((branch) => branch.id).toString(),
        mainBranch,
      });

      if (updUser) {
        if (updUser.success && updUser.success === false) {
          await addNotification(
            'danger',
            I18n.t('BEE887' /* Atualizar usuário */),
            I18n.t('BEE888', { 0: login } /* Erro ao atualizar o usuário %{0} ! */),
            'top-right',
          );
        } else {
          await addNotification(
            'success',
            I18n.t('BEE887' /* Atualizar usuário */),
            I18n.t('BEE889', { 0: login } /* Atualização do usuário %{0} efetuada com sucesso! */),
            'top-right',
          );

          this.props.history.push(ROUTES.USER_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE887' /* Atualizar usuário */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE887' /* Atualizar usuário */),
            I18n.t('BEE888', { 0: login } /* Erro ao atualizar o usuário %{0} ! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE887' /* Atualizar usuário */),
          I18n.t('BEE888', { 0: login } /* Erro ao atualizar o usuário %{0} ! */),
          'top-right',
        );
      }
    }
  };

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    const {
      listDepartment, listShift, listFunction, listAccessProfile, listBranch, userBranches,
      listBranchOptions, listUserTyp,
    } = this.state;

    const {
      name, login, type, status, email, code, department, userFunction,
      workShift, phone, branchLine, cellPhone, printer, departmentName, userFunctionName, workShiftName,
      accessProfile, accessProfileName,
    } = this.state;

    const {
      mode, showCreate, showUpdate, showCancel, disableLogin, disableName, disableType, disableStatus,
      disablePassword, disableEmail, disableCode, disableDepartment,
      disableUserFunction, disableWorkShift, disablePhone, disableBranchLine, disableCellPhone, disablePrinter,
      disableAccessProfile, disablePermission,
    } = this.state;

    const {
      branchDelete, showModalDelete, branchAddition, showModalAddition,
    } = this.state;

    const {
      disableMainBranch, mainBranch, mainBranchName, printerName, listPrinter,
    } = this.state;

    const {
      showPrinterLinkBtn, showPrinterLinkModal, userSelected, showPrinterLinkTab,
    } = this.state;

    const userPrinters = userSelected ? userSelected.userPrinters : [];

    let title = '';
    //   let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE883' /* Novo usuário */);
    } else if (mode === 'edit') {
      //    breadActive = `${login} - ${name}`;
    } else if (mode === 'detail') {
      //     breadActive = `${login} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE25' /* Usuários */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE25' /* Usuários */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel className="panel-with-tabs">
                <PanelHeader noButton />
                <PanelBody>
                  <div className="row">
                    <div className="col mb-3">
                      <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === '1' ? 'active' : ''}
                            onClick={() => {
                              this.toggleTab('1');
                            }}
                          >
                            <span className="d-sm-none">Tab 1</span>
                            <span className="d-sm-block d-none">{I18n.t('BEE370' /* Dados */)}</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === '2' ? 'active' : ''}
                            onClick={() => {
                              this.toggleTab('2');
                            }}
                          >
                            <span className="d-sm-none">Tab 2</span>
                            <span className="d-sm-block d-none">{I18n.t('BEE191' /* Geral */)}</span>
                          </NavLink>
                        </NavItem>
                        {showPrinterLinkTab && (
                          <NavItem>
                            <NavLink
                              className={this.state.activeTab === '3' ? 'active' : ''}
                              onClick={() => {
                                this.toggleTab('3');
                              }}
                            >
                              <span className="d-sm-none">Tab 3</span>
                              <span className="d-sm-block d-none">{I18n.t('BEE21' /* Impressoras */)}</span>
                            </NavLink>
                          </NavItem>
                        )}
                      </Nav>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="1">
                          <div className="row">
                            <div className="col-xl-12">
                              {this.createInput(
                                login,
                                'login',
                                `${I18n.t('BEE623' /* Login */)}:`,
                                I18n.t('BEE892' /* Informe o login (obrigatório) */),
                                'text',
                                true,
                                disableLogin,
                              )}
                              {this.createInput(
                                name,
                                'name',
                                `${I18n.t('BEE83' /* Nome */)}:`,
                                I18n.t('BEE893' /* Informe o nome completo (obrigatório) */),
                                'text',
                                true,
                                disableName,
                              )}
                              {(disableType
                                ? this.createInput(
                                  UserHelpers.userType(type),
                                  'type',

                                  `${I18n.t('BEE200' /* Tipo */)}:`,

                                  '',

                                  'text',

                                  false,

                                  disableType,
                                )
                                : this.createSelect(
                                  type,
                                  'type',
                                  `${I18n.t('BEE200' /* Tipo */)}:`,
                                  listUserTyp,
                                ))}
                              {(!disableStatus
                                && this.createSelect(
                                  status,
                                  'status',
                                  `${I18n.t('BEE224' /* Status */)}:`,
                                  UserHelpers.listUserStatus(),
                                ))}

                              {!disablePassword && this.displayPassword()}

                              {this.createInput(
                                email,
                                'email',
                                `${I18n.t('BEE897' /* Email */)}`,
                                I18n.t('BEE898' /* Informe o email */),
                                'email',
                                false,
                                disableEmail,
                              )}
                              {this.createInput(
                                code,
                                'code',
                                `${I18n.t('BEE677' /* Matrícula */)}:`,
                                I18n.t('BEE899' /* Informe a matrícula (obrigatório) */),
                                'text',
                                true,
                                disableCode,
                              )}
                              {(disableDepartment
                                ? this.createInput(
                                  departmentName,
                                  'department',
                                  `${I18n.t('BEE622' /* Departamento */)}:`,
                                  '',
                                  'text',
                                  false,
                                  disableDepartment,
                                )
                                : this.createSelectDropDown(
                                  { value: department, label: departmentName },
                                  'department',

                                  `${I18n.t('BEE622' /* Departamento */)}:`,

                                  listDepartment,
                                )
                              )}
                              {(disableUserFunction
                                ? this.createInput(
                                  userFunctionName,
                                  'userFunction',
                                  `${I18n.t('BEE678' /* Função */)}:`,
                                  '',
                                  'text',
                                  false,
                                  disableUserFunction,
                                )
                                : this.createSelectDropDown(
                                  { value: userFunction, label: userFunctionName },
                                  'userFunction',

                                  `${I18n.t('BEE678' /* Função */)}:`,

                                  listFunction,
                                )
                              )}
                              {(disableWorkShift
                                ? this.createInput(
                                  workShiftName,
                                  'workShift',
                                  `${I18n.t('BEE900' /* Turnos */)}:`,
                                  '',
                                  'text',
                                  false,
                                  disableWorkShift,
                                )
                                : this.createSelectDropDown(
                                  { value: workShift, label: workShiftName },
                                  'workShift',

                                  `${I18n.t('BEE900' /* Turnos */)}:`,

                                  listShift,
                                )
                              )}
                              {(disablePhone
                                ? this.createInput(
                                  phone,
                                  'phone',
                                  `${I18n.t('BEE133' /* Telefone */)}:`,
                                  '',
                                  'text',
                                  false,
                                  disablePhone,
                                )
                                : this.createInputMask(
                                  phone,
                                  'phone',
                                  `${I18n.t('BEE133' /* Telefone */)}:`,
                                  I18n.t('BEE134' /* Informe o telefone */),
                                  phone && phone.length < 11 ? '(99) 9999-9999' : '(99) 99999-9999',
                                  'tel',
                                  false,
                                )
                              )}
                              {(disableBranchLine
                                ? this.createInput(
                                  branchLine,
                                  'branchLine',
                                  `${I18n.t('BEE680' /* Ramal */)}:`,
                                  I18n.t('BEE901' /* Informe o ramal */),
                                  'text',
                                  false,
                                  disableBranchLine,
                                )
                                : this.createInputMask(
                                  branchLine,
                                  'branchLine',
                                  `${I18n.t('BEE680' /* Ramal */)}:`,
                                  I18n.t('BEE901' /* Informe o ramal */),
                                  '9999',
                                  'tel',
                                  false,
                                )
                              )}
                              {(disableCellPhone
                                ? this.createInput(
                                  cellPhone,
                                  'cellPhone',
                                  `${I18n.t('BEE681' /* Celular */)}:`,
                                  '',
                                  'text',
                                  false,
                                  disableCellPhone,
                                )
                                : this.createInputMask(
                                  cellPhone,
                                  'cellPhone',
                                  `${I18n.t('BEE681' /* Celular */)}:`,
                                  I18n.t('BEE902' /* Informe o celular */),
                                  cellPhone && cellPhone.length < 13 ? '+99 (99) 9999-9999' : '+99 (99) 99999-9999',
                                  'tel',
                                  false,
                                )
                              )}
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="2">
                          <div className="row">
                            <div className="col-xl-12">
                              {(disableAccessProfile
                                ? this.createInput(
                                  accessProfileName,
                                  'accessProfile',
                                  `${I18n.t('BEE247' /* Perfil de Acesso */)}:`,
                                  '',
                                  'text',
                                  false,
                                  disableAccessProfile,
                                )
                                : this.createSelectDropDown(
                                  { value: accessProfile, label: accessProfileName },
                                  'accessProfile',

                                  `${I18n.t('BEE247' /* Perfil de Acesso */)}:`,

                                  listAccessProfile,
                                )
                              )}
                              {(disableMainBranch
                                ? this.createInput(
                                  mainBranch,
                                  'mainBranch',
                                  `${I18n.t('BEE903' /* Filial principal */)}:`,
                                  '',
                                  'text',
                                  false,
                                  disableMainBranch,
                                )
                                : this.createSelectDropDown(
                                  { value: mainBranch, label: mainBranchName },
                                  'mainBranch',

                                  `${I18n.t('BEE903' /* Filial principal */)}:`,

                                  listBranchOptions,
                                )
                              )}
                              {(disablePrinter
                                ? this.createInput(
                                  printer,
                                  'printer',
                                  `${I18n.t('BEE904' /* Impressora principal */)}:`,
                                  '',
                                  'text',
                                  false,
                                  disablePrinter,
                                )
                                : this.createSelectDropDown(
                                  { value: printer, label: printerName },
                                  'printer',

                                  `${I18n.t('BEE904' /* Impressora principal */)}:`,

                                  listPrinter,
                                )
                              )}
                              {this.createPermissionInput(userBranches, listBranch, disablePermission)}
                            </div>
                          </div>
                        </TabPane>
                        <TabPane tabId="3">
                          <UserPrintersTable
                            title={I18n.t('BEE910' /* Impressoras vinculadas */)}
                            userPrinters={userPrinters}
                            showPrinterLinkBtn={showPrinterLinkBtn}
                            openPrinterLinkModal={this.openPrinterLinkModal}
                            userSelected={userSelected}
                            getUserSelected={this.getUserSelected}
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.USER_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton
                          type="submit"
                          className="btn btn-120 btn-white p-5 m-5"
                        >
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      )
                    )}
                  </Link>
                  {(showCreate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.createUserSubmit()}
                      >
                        {I18n.t('BEE138' /* Criar */)}

                      </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                      <OneClickButton
                        type="button"
                        className="btn btn-120 btn-primary p-5 m-5"
                        onClick={() => this.updateUserSubmit()}
                      >
                        {I18n.t('BEE139' /* Atualizar */)}

                      </OneClickButton>
                    )
                  )}
                </PanelFooter>
                {this.createModalBranchList()}
              </Panel>
              {(showModalDelete
                && (
                  <SweetAlert
                    danger
                    showCancel
                    cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                    confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="default"
                    title={I18n.t('BEE101' /* Você tem certeza? */)}
                    onConfirm={() => this.handleDelete(branchDelete)}
                    onCancel={() => this.setState({ showModalDelete: false })}
                  >
                    {I18n.t('BEE911' /* Será removida a permissão do usuário para a filial selecionada ! */)}
                  </SweetAlert>
                )
              )}
              {(showModalAddition
                && (
                  <SweetAlert
                    success
                    showCancel
                    cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                    confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="default"
                    title={I18n.t('BEE101' /* Você tem certeza? */)}
                    onConfirm={() => this.handleAddition(branchAddition)}
                    onCancel={() => this.setState({ showModalAddition: false })}
                  >
                    {I18n.t('BEE912' /* Será incluída a permissão da filial selecionada para o usuário ! */)}
                  </SweetAlert>
                )
              )}
            </form>

            <PrinterLinkModal
              showPrinterLinkModal={showPrinterLinkModal}
              closePrinterLinkModal={this.closePrinterLinkModal}
              userSelected={userSelected}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app && state.app.userLogged,
});

const mapDispatchToProps = (dispatch) => ({
  getUser: (userId) => dispatch(getUser(userId)),
  createUser: (user) => dispatch(createUser(user)),
  updateUser: (user) => dispatch(updateUser(user)),
  getDepartmentsOptions: () => dispatch(getDepartmentsOptions()),
  getUserFunctionsOptions: () => dispatch(getUserFunctionsOptions()),
  getWorkShiftsOptions: () => dispatch(getWorkShiftsOptions()),
  getAccessProfileOptions: () => dispatch(getAccessProfileOptions()),
  getBranchesSuggestions: () => dispatch(getBranchesSuggestions()),
  getBranchesOptions: () => dispatch(getBranchesOptions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(UserForm));
