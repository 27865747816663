import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import ROUTES from '../../../../config/routes';

import addNotification from '../../../../components/notification';
import Form from '../../../../components/form/form';

import {
  getCapacity, getCapacityOptions, getCapacityOptionsProducts, createCapacity, updateCapacity,
} from '../../../../app/store/actions/capacities';

class CapacityForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disableCodeProduct: true,
      disableDescription: true,
      disableBranch: true,
      disableStorageAddressSize: true,
      disableMaximumCapacity: true,
      disableRefPointUnity: true,
      disableRefPointPercentage: true,
      disableStatus: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      capacityId: null,
      productCode: '',
      productName: '',
      branchCode: '',
      branchName: '',
      statusCode: '',
      statusName: '',
      maximumCapacity: null,
      referencePointUnity: null,
      referencePointPercentage: null,
      storageAddressSizeCode: '',
      storageAddressSizeName: '',
      listProduct: [],
      listBranch: [],
      listStorageAddressSize: [],
      listStatus: [],
    };
  }

  async componentDidMount() {
    const { mode } = this.props;
    await this.setMode(mode);

    const dataOptions = await this.props.getCapacityOptions();

    if (mode !== 'create') {
      await this.getCapacity();
    }

    this.setState({
      listProduct: dataOptions?.products,
      listBranch: dataOptions?.branches,
      listStorageAddressSize: dataOptions?.storageAddressSizes,
      listStatus: dataOptions?.status,
    });
  }

  getCapacity = async () => {
    if (this.props.location.state.capacity) {
      const { capacity } = this.props.location.state;
      const { mode } = this.props;

      if (mode === 'detail') {
        this.setState({
          capacityId: capacity.id,
          productCode: capacity.productCode,
          productName: capacity.description,
          maximumCapacity: capacity.maximumCapacity,
          referencePointUnity: capacity.referencePointUnity,
          referencePointPercentage: capacity.referencePointPercentage,
          statusCode: capacity.statusCode,
          statusName: capacity.statusName,
          branchCode: capacity.branchCode,
          branchName: capacity.branchName,
          storageAddressSizeCode: capacity.sizeCode,
          storageAddressSizeName: capacity.sizeName,
        });
      } else {
        const capacitySelected = await this.props.getCapacity(capacity.id);

        if (capacitySelected) {
          this.setState({
            capacityId: capacity.id,
            productCode: capacity.productCode,
            productName: capacity.description,
            maximumCapacity: capacity.maximumCapacity,
            referencePointUnity: capacity.referencePointUnity,
            referencePointPercentage: capacity.referencePointPercentage,
            statusCode: capacity.statusCode,
            statusName: capacity.statusName,
            branchCode: capacity.branchCode,
            branchName: capacity.branchName,
            storageAddressSizeCode: capacity.sizeCode,
            storageAddressSizeName: capacity.sizeName,
          });
        }
      }
    }
  };

  setMode = (mode) => {
    if (mode === 'create' || mode === 'copy') {
      this.setState({
        disableCodeProduct: false,
        disableDescription: true,
        disableBranch: false,
        disableStorageAddressSize: false,
        disableMaximumCapacity: false,
        disableRefPointUnity: false,
        disableRefPointPercentage: false,
        disableStatus: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        productCode: '',
        productName: '',
        branchCode: '',
        branchName: '',
        statusCode: '',
        statusName: '',
        storageAddressSizeCode: '',
        storageAddressSizeName: '',
        maximumCapacity: null,
        referencePointUnity: null,
        referencePointPercentage: null,
      });
    }

    if (mode === 'edit') {
      this.setState({
        disableCodeProduct: true,
        disableDescription: true,
        disableBranch: true,
        disableStorageAddressSize: false,
        disableMaximumCapacity: false,
        disableRefPointUnity: false,
        disableRefPointPercentage: false,
        disableStatus: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  setValue = async (attr, value) => {
    const { maximumCapacity, referencePointUnity, referencePointPercentage } = this.state;
    if (maximumCapacity) {
      if (value) {
        if (attr === 'referencePointUnity') {
          const valueRefPointPercentage = Math.round((value * 100) / maximumCapacity);
          if (valueRefPointPercentage !== this.state.referencePointPercentage && valueRefPointPercentage <= 100) {
            this.setState({
              referencePointPercentage: (valueRefPointPercentage === 0 ? 1 : valueRefPointPercentage),
            });
          }
        } else if (attr === 'referencePointPercentage') {
          if (value > 100) {
            value = 100;
          }

          const valueRefPointUnity = Math.round((value / 100) * maximumCapacity);
          this.setState({
            referencePointUnity: (valueRefPointUnity === 0 ? 1 : valueRefPointUnity),
          });
        } else if (attr === 'maximumCapacity') {
          if (referencePointUnity && referencePointUnity > 0 && parseInt(value, 10) < maximumCapacity) {
            this.setState({
              referencePointUnity: null,
              referencePointPercentage: null,
            });
          } else if (referencePointUnity && referencePointUnity > 0) {
            const valueRefPointPercentage = Math.round((referencePointUnity * 100) / value);
            this.setState({
              referencePointPercentage: (valueRefPointPercentage === 0 ? 1 : valueRefPointPercentage),
            });
          } else if (referencePointPercentage && referencePointPercentage > 0) {
            const valueRefPointUnity = Math.round((referencePointPercentage / 100) * value);
            this.setState({
              referencePointUnity: (valueRefPointUnity === 0 ? 1 : valueRefPointUnity),
            });
          }
        }
      } else {
        this.setState({
          referencePointUnity: value,
          referencePointPercentage: value,
        });
      }
    }

    if (attr === 'maximumCapacity') {
      value = parseInt(value, 10);
    } else if (attr === 'referencePointUnity') {
      value = parseInt(value, 10);
      value = (value > maximumCapacity) ? maximumCapacity : value;
    } else if (attr === 'referencePointPercentage') {
      value = parseInt(value, 10);
      value = (value > 100) ? 100 : value;
    }

    this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  setFilterDrop = _.debounce(async (attr, value) => {
    const {
      productCode,
    } = this.state;

    if (productCode === value) {
      return;
    }

    const { products } = await this.props.getCapacityOptionsProducts(value);

    const dataSelected = products?.filter((item) => item.value === value);

    if (dataSelected.length === 1) {
      await this.setState({
        [`${attr}Code`]: dataSelected[0].value ? dataSelected[0].value : null,
        [`${attr}Name`]: dataSelected[0].value ? dataSelected[0].label : '',
      });
    } else {
      await this.setState({
        [`${attr}Code`]: null,
        [`${attr}Name`]: '',
      });
    }

    this.setState({
      listProduct: products,
    });
  }, 1000);

  actionCapacitySubmit = async () => {
    const {
      productCode,
      productName,
      branchCode,
      statusCode,
      storageAddressSizeCode,
      maximumCapacity,
      referencePointUnity,
      referencePointPercentage,
      showCreate,
      capacityId,
    } = this.state;

    try {
      if (showCreate) {
        const newCapacity = await this.props.createCapacity({
          productCode,
          description: productName,
          branchCode,
          storageAddressSizeCode,
          maximumCapacity,
          referencePointUnity,
          referencePointPercentage,
          status: statusCode,
        });

        if (newCapacity) {
          addNotification(
            'success',
            I18n.t('BEE2659' /* Cadastrar Capacidade */),
            I18n.t('BEE2696' /* Capacidade criada com sucesso! */),
            'top-right',
          );

          this.props.history.push(ROUTES.CAPACITY_LIST);
        }
      } else {
        const updCapacity = await this.props.updateCapacity({
          capacityId,
          productCode,
          description: productName,
          branchCode,
          storageAddressSizeCode,
          maximumCapacity,
          referencePointUnity,
          referencePointPercentage,
          status: statusCode,
        });

        if (updCapacity) {
          addNotification(
            'success',
            I18n.t('BEE389' /* Capacidade */),
            I18n.t('BEE2697' /* Capacidade alterada com sucesso! */),
            'top-right',
          );

          this.props.history.push(ROUTES.CAPACITY_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            showCreate
              ? I18n.t('BEE2659' /* Cadastrar Capacidade */) : I18n.t('BEE2708' /* Alterar Capacidade */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            showCreate
              ? I18n.t('BEE2659' /* Cadastrar Capacidade */) : I18n.t('BEE2708' /* Alterar Capacidade */),
            showCreate
              ? I18n.t('BEE2709', { 0: productName }/* Erro ao incluir a capacidade %{0}! */)
              : I18n.t('BEE2710', { 0: productName } /* Erro ao alterar a capacidade %{0}! */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          showCreate
            ? I18n.t('BEE2659' /* Cadastrar Capacidade */) : I18n.t('BEE2708' /* Alterar Capacidade */),
          showCreate
            ? I18n.t('BEE2709', { 0: productName }/* Erro ao incluir a capacidade %{0}! */)
            : I18n.t('BEE2710', { 0: productName } /* Erro ao alterar a capacidade %{0}! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      branchCode,
      branchName,
      statusCode,
      statusName,
      storageAddressSizeCode,
      storageAddressSizeName,
      productCode,
      productName,
      maximumCapacity,
      referencePointUnity,
      referencePointPercentage,
      listProduct,
      listBranch,
      listStatus,
      listStorageAddressSize,
    } = this.state;

    const {
      disableCodeProduct,
      disableDescription,
      disableBranch,
      disableStorageAddressSize,
      disableMaximumCapacity,
      disableRefPointUnity,
      disableRefPointPercentage,
      disableStatus,
      showCreate,
      showUpdate,
      showCancel,
    } = this.state;

    const title = I18n.t('BEE389' /* Capacidade */);

    const modeDescription = {
      create: I18n.t('BEE138' /* Criar */),
      detail: I18n.t('BEE56' /* Detalhes */),
      edit: I18n.t('BEE57' /* Editar */),
      copy: I18n.t('BEE80' /* Copiar */),
    };

    const breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE14' /* Gerais */) },
      { value: I18n.t('BEE389' /* Capacidade */) },
      { value: modeDescription[this.props.mode], active: true },
    ];

    return (
      <Form
        title={title}
        noButton
        linkBack={{ pathname: ROUTES.CAPACITY_LIST, state: { dice: this.props.location.dice } }}
        showCreate={showCreate}
        showCancel={showCancel}
        showUpdate={showUpdate}
        setValue={this.setValue}
        setValueDrop={this.setValueDrop}
        setFilterDrop={this.setFilterDrop}
        onSubmitCreate={this.actionCapacitySubmit}
        onSubmitUpdate={this.actionCapacitySubmit}
        breadcrumb={breadcrumb}
        inputs={(formContext) => ([
          formContext.createSelectDropDown(
            { value: branchCode, label: branchName },
            'branch',
            `${I18n.t('BEE145' /* Filial */)}:`,
            listBranch,
            disableBranch,
          ),
          formContext.createSelectDropDownFilter(
            { code: productCode, label: productName },
            'product',
            `${I18n.t('BEE378' /* Código do Produto */)}:`,
            listProduct,
            disableCodeProduct,
          ),
          formContext.createInput(
            productName,
            'productName',
            `${I18n.t('BEE277' /* Descrição */)}:`,
            '',
            'text',
            true,
            disableDescription,
          ),
          formContext.createSelectDropDown(
            { value: storageAddressSizeCode, label: storageAddressSizeName },
            'storageAddressSize',
            `${I18n.t('BEE1491' /* Classificação de Endereço */)}:`,
            listStorageAddressSize,
            disableStorageAddressSize,
          ),
          formContext.createInput(
            maximumCapacity,
            'maximumCapacity',
            `${I18n.t('BEE2662' /* Capacidade Máxima (UN) */)}:`,
            '',
            'number',
            true,
            disableMaximumCapacity,
            null,
            5,
          ),
          formContext.createInput(
            referencePointUnity,
            'referencePointUnity',
            `${I18n.t('BEE2663' /* Ponto de Ressuprimento (UN) */)}:`,
            '',
            'number',
            true,
            (!disableRefPointUnity ? !maximumCapacity : true),
          ),
          formContext.createInput(
            referencePointPercentage,
            'referencePointPercentage',
            `${I18n.t('BEE2664' /* Ponto de Ressuprimento (%) */)}:`,
            '',
            'number',
            true,
            (!disableRefPointPercentage ? !maximumCapacity : true),
          ),
          formContext.createSelectDropDown(
            { value: statusCode, label: statusName },
            'status',
            `${I18n.t('BEE224' /* Status */)}:`,
            listStatus,
            disableStatus,
          ),
        ])}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getCapacityOptions: () => dispatch(getCapacityOptions()),
  getCapacityOptionsProducts: (productFilter) => dispatch(getCapacityOptionsProducts(productFilter)),
  getCapacity: (capacityId) => dispatch(getCapacity(capacityId)),
  createCapacity: (capacity) => dispatch(createCapacity(capacity)),
  updateCapacity: (capacity) => dispatch(updateCapacity(capacity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CapacityForm));
