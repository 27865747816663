import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import InputMask from 'react-input-mask';

import {
  TabContent, TabPane, Nav, NavItem, NavLink,
} from 'reactstrap';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import OneClickButton from '../../../../components/form/button';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';

import {
  createIndirectTask, updateIndirectTask, getIndirectTask, deleteIndirectTask,
} from '../../../../app/store/actions/indirectTasks';

import UserLinkModal from './userLinkModal';
import UsersIndirectTasksTable from './usersIndirectTasksTable';

class IndirectTaskForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 'main',
      mode: 'detail',
      code: '',
      name: '',
      note: '',
      disableCode: true,
      disableName: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      showUserLinkModal: false,
      showUserLinkBtn: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getIndirectTaskSelected();
    }
  }

  getIndirectTaskSelected = async () => {
    if (this.props.location.state.indirectTask) {
      const { indirectTask } = this.props.location.state;

      const indirectTaskSelected = await this.props.getIndirectTask(indirectTask.id);

      if (indirectTaskSelected) {
        this.setState({
          indirectTaskSelected,
          indirectTaskId: indirectTaskSelected.id,
          code: indirectTaskSelected.code,
          name: indirectTaskSelected.name,
          note: indirectTaskSelected.note,
        });
      }
    }
  };

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        showUserLinkBtn: false,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
        showUserLinkBtn: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  };

  createIndirectTaskSubmit = async () => {
    const { code, name, note } = this.state;

    try {
      const newIndirectTask = await this.props.createIndirectTask({
        code,
        name,
        note,
      });

      if (newIndirectTask) {
        if (newIndirectTask.success && newIndirectTask.success === false) {
          await addNotification('danger', I18n.t('BEE1669' /* Nova Tarefa Indireta */), I18n.t('BEE1670', { 0: code } /* Erro ao incluir a tarefa %{0} */), 'top-right');
        } else {
          await addNotification('success', I18n.t('BEE1669' /* Nova Tarefa Indireta */), I18n.t('BEE1671', { 0: code } /* Nova tarefa %{0} adicionada com sucesso! */), 'top-right');

          this.props.history.push(ROUTES.INDIRECT_TASKS_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE1669' /* Nova Tarefa Indireta */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE1669' /* Nova Tarefa Indireta */), I18n.t('BEE1670', { 0: code } /* Erro ao incluir a tarefa %{0} */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE1669' /* Nova Tarefa Indireta */), I18n.t('BEE1670', { 0: code } /* Erro ao incluir a tarefa %{0} */), 'top-right');
      }
    }
  };

  updateIndirectTaskSubmit = async () => {
    const {
      name, indirectTaskId, note,
    } = this.state;

    try {
      const updIndirectTask = await this.props.updateIndirectTask({
        indirectTaskId,
        name,
        note,
      });

      if (updIndirectTask) {
        if (updIndirectTask.success && updIndirectTask.success === false) {
          await addNotification('danger', I18n.t('BEE1672' /* Atualizar Tarefa Indireta */), I18n.t('BEE1673', { 0: name } /* Erro ao atualizar a tarefa %{0} */), 'top-right');
        } else {
          await addNotification('success', I18n.t('BEE1672' /* Atualizar Tarefa Indireta */), I18n.t('BEE1674', { 0: name } /* Tarefa %{0} atualizada com sucesso! */), 'top-right');

          this.props.history.push(ROUTES.INDIRECT_TASKS_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE1672' /* Atualizar Tarefa Indireta */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification('danger', I18n.t('BEE1672' /* Atualizar Tarefa Indireta */), I18n.t('BEE1673', { 0: name } /* Erro ao atualizar a tarefa %{0} */), 'top-right');
        }
      } else {
        await addNotification('danger', I18n.t('BEE1672' /* Atualizar Tarefa Indireta */), I18n.t('BEE1673', { 0: name } /* Erro ao atualizar a tarefa %{0} */), 'top-right');
      }
      await addNotification('danger', I18n.t('BEE1672' /* Atualizar Tarefa Indireta */), I18n.t('BEE1673', { 0: name } /* Erro ao atualizar a tarefa %{0} */), 'top-right');
    }
  };

  openUserLinkModal = () => {
    this.setState({
      showUserLinkModal: true,
    });
  };

  closeUserLinkModal = async () => {
    this.setState({
      showUserLinkModal: false,
    });
    await this.getIndirectTaskSelected();
  };

  toggleTab = (activeTab) => {
    this.setState({ activeTab });
  };

  render() {
    const { code, name, note } = this.state;

    const {
      mode, disableCode, disableName, disableNote, showCreate, showUpdate, showCancel,
      indirectTaskSelected, showUserLinkModal, showUserLinkBtn,
    } = this.state;

    const usersIndirectTasks = indirectTaskSelected ? indirectTaskSelected.userIndirectTasks : [];

    let title = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE1669' /* Nova Tarefa Indireta */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE1675' /* Editar Tarefa Indireta */);
    } else if (mode === 'detail') {
      title = I18n.t('BEE1676' /* Detalhes Tarefa Indireta */);
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE25' /* Usuários */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1668' /* Tarefas Indiretas */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  <div className="row">
                    <div className="col mb-3">
                      <Nav tabs justified fill style={{ backgroundColor: 'lightgray' }}>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === 'main' ? 'active' : ''}
                            onClick={() => {
                              this.toggleTab('main');
                            }}
                          >
                            {I18n.t('BEE191' /* Geral */)}
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === 'users' ? 'active' : ''}
                            onClick={() => {
                              this.toggleTab('users');
                            }}
                          >
                            {I18n.t('BEE25' /* Usuários */)}
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <TabContent activeTab={this.state.activeTab}>
                        <TabPane tabId="main">
                          {this.createInput(code, 'code', `${I18n.t('BEE82' /* Código */)}:`, I18n.t('BEE1200' /* Informe o código (obrigatório) */), 'text', true, disableCode)}
                          {this.createInput(name, 'name', `${I18n.t('BEE83' /* Nome */)}:`, I18n.t('BEE1677' /* Informe o nome da tarefa */), 'text', false, disableName)}
                          {this.createInput(note, 'note', `${I18n.t('BEE135' /* Observação */)}:`, I18n.t('BEE136' /* Informe a observação */), 'text', false, disableNote)}
                        </TabPane>
                        <TabPane tabId="users">
                          <UsersIndirectTasksTable
                            title={I18n.t('BEE619' /* Usuários Vinculados */)}
                            usersIndirectTasks={usersIndirectTasks}
                            showUserLinkBtn={showUserLinkBtn}
                            openUserLinkModal={this.openUserLinkModal}
                            indirectTaskSelected={indirectTaskSelected}
                            getIndirectTaskSelected={this.getIndirectTaskSelected}
                          />
                        </TabPane>
                      </TabContent>
                    </div>
                  </div>
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.INDIRECT_TASKS_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createIndirectTaskSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}

                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateIndirectTaskSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}

                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>

            <UserLinkModal
              showUserLinkModal={showUserLinkModal}
              closeUserLinkModal={this.closeUserLinkModal}
              indirectTaskSelected={indirectTaskSelected}
            />

          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getIndirectTask: (indirectTaskId) => dispatch(getIndirectTask(indirectTaskId)),
  createIndirectTask: (indirectTask) => dispatch(createIndirectTask(indirectTask)),
  updateIndirectTask: (indirectTask) => dispatch(updateIndirectTask(indirectTask)),
  deleteIndirectTask: (indirectTaskId) => dispatch(deleteIndirectTask(indirectTaskId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IndirectTaskForm));
