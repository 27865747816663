import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert';

import { outboundOrderWithdrawal } from '../../../app/store/actions/dockControl';
import Table from '../../../components/table/Table';
import addNotification from '../../../components/notification';
import OutboundOrderHelpers from '../../../helpers/outboundOrder';
import Permissions from '../../../app/services/permissions';
import ROUTES from '../../../config/routes';

class TableOutboundOrder extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalWithdrawal: false,
      selectedOutboundOrder: {},
      defaultPageSize: 5,
      pageSizeOptions: [5, 10, 15],
      defaultSorted: [{ id: 'orderNumber', desc: false }],
    };

    this.tableColumns = [{
      Header: I18n.t('BEE55' /* Ações */),
      accessor: 'action',
      style: { overflow: 'visible', alignSelf: 'center' },
      filterable: false,
      sortable: false,
      width: 100,
      Cell: (rows) => (
        <div style={{ textAlign: 'center' }}>
          {(rows.original.status <= 7 || rows.original.status >= 12) && (
          <button
            type="button"
            onClick={() => this.setState({ showModalWithdrawal: true, selectedOutboundOrder: rows.original })}
            className="btn btn-primary"
          >
            {I18n.t('BEE633' /* Retira */)}
          </button>
          )}
        </div>
      ),
    }, {
      Header: I18n.t('BEE1378' /* Documento */),
      accessor: 'orderNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE50' /* Cliente */),
      accessor: 'customerName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE1808' /* Pedido Cliente */),
      accessor: 'customerOrderCode',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
    }, {
      Header: I18n.t('BEE200' /* Tipo */),
      accessor: 'orderType',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 100,
    }, {
      Header: I18n.t('BEE402' /* Nota Fiscal */),
      accessor: 'invoiceNumber',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 120,
    }, {
      Header: I18n.t('BEE301' /* Transportadora */),
      accessor: 'carrierName',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 250,
    }, {
      Header: I18n.t('BEE1098' /* Tipo de Entrega */),
      accessor: 'deliveryType',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE444' /* Data de Entrega */),
      accessor: 'deliveryDate',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
      filterMethod: (filter, row) => {
        const input = _.lowerCase(filter.value);
        const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY');
        const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY');
        if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
          return true;
        }
      },
      Cell: (row) => (
        <span>
          {' '}
          {row.value ? moment(row.value).format('L') : ''}
        </span>
      ),
    }, {
      Header: I18n.t('BEE1936' /* Total de Volumes */),
      accessor: 'totalAmount',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE1935' /* Volumes Conferidos */),
      accessor: 'addedQuantity',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 150,
    }, {
      Header: I18n.t('BEE224' /* Status */),
      accessor: 'status',
      style: { alignSelf: 'center', textAlign: 'center' },
      minWidth: 200,
      Cell: (row) => (
        <div style={{ textAlign: 'center' }}>
          <span>{OutboundOrderHelpers.outboundOrderStatus(row.value)}</span>
        </div>
      ),
      filterMethod: (filter, row) => {
        if (parseInt(filter.value) === parseInt(row.status)) {
          return true;
        }
      },
      Filter: ({ onChange }) => (
        <select
          type="text"
          style={{ width: '100%' }}
          onChange={(event) => { onChange(event.target.value); }}
        >
          <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
          {OutboundOrderHelpers.outboundOrderStatus().map((elem, index) => (
            <option value={elem.value} key={index}>{elem.label}</option>
          ))}
        </select>
      ),
    }];
  }

  withdrawalOutboundOrder = async () => {
    const { selectedOutboundOrder } = this.state;
    const { outboundOrderList = [] } = this.props;
    const hasPermission = !Permissions.checkPermission(ROUTES.P_EXPEDITION_ALLOWS_REMOVE_DOCK_CANCELED);
    this.setState({ showModalWithdrawal: false, selectedOutboundOrder: {} });
    try {
      const result = await this.props.outboundOrderWithdrawal(selectedOutboundOrder.outboundOrderId, hasPermission);
      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2072' /* Monitor de Controle de Docas */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else if (outboundOrderList.length === 1) {
        this.props.listDockControl();
        this.props.setOutboundOrderDetails();
      } else {
        this.props.outboundOrderDetails(selectedOutboundOrder.dockControlId);
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2072' /* Monitor de Controle de Docas */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2072' /* Monitor de Controle de Docas */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      defaultPageSize, defaultSorted, pageSizeOptions,
      showModalWithdrawal, selectedOutboundOrder,
    } = this.state;
    const { outboundOrderList = [] } = this.props;

    return (
      <>
        <Table
          panelHeaderProps={{
            noButton: true,
            children: I18n.t('BEE462' /* Documentos */).toLocaleUpperCase(),
          }}
          filterable
          data={outboundOrderList}
          columns={this.tableColumns}
          expander
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          pageSizeOptions={pageSizeOptions}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalWithdrawal
          && (
          <SweetAlert
            info
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE1849' /* Continuar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.withdrawalOutboundOrder()}
            onCancel={() => this.setState({ showModalWithdrawal: false, selectedOutboundOrder: {} })}
          >
            {I18n.t(
              'BEE2076',
              { 0: selectedOutboundOrder.orderNumber },
              /* O documento %{0} sera retirado do controle */
            )}
          </SweetAlert>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  outboundOrderList: state.outboundOrders && state.outboundOrders.outboundOrderDetailsList,
});

const mapDispatchToProps = (dispatch) => ({
  outboundOrderWithdrawal: (outboundOrderId, hasPermission) => (
    dispatch(outboundOrderWithdrawal(outboundOrderId, hasPermission))
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(TableOutboundOrder));
