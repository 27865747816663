import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import ROUTES from '../../../../config/routes';

import addNotification from '../../../../components/notification';
import Form from '../../../../components/form/form';

import {
  getPickingArea, getPickingAreaOptions, getPickingAreaOptionsProducts, createPickingArea, updatePickingArea,
} from '../../../../app/store/actions/pickingAreas';

class PickingAreaForm extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      disableCodeProduct: true,
      disableDescription: true,
      disableBranch: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      pickingAreaId: null,
      productCode: '',
      productName: '',
      description: '',
      branchCode: '',
      branchName: '',
      listProduct: [],
      listBranch: [],
      note: '',
      depositCode: '',
      depositName: '',
      listDeposit: [],
      disableDeposit: true,
    };
  }

  async componentDidMount() {
    const { mode } = this.props;
    await this.setMode(mode);

    const dataOptions = await this.props.getPickingAreaOptions();

    if (mode !== 'create') {
      await this.getPickingAreaSelected();
    } else if (dataOptions?.branches.length === 1) {
      this.setState({
        branchCode: dataOptions?.branches[0].value,
        branchName: dataOptions?.branches[0].label,
        listDeposit: dataOptions.deposits,
      });
    }

    this.setState({
      listProduct: dataOptions?.products,
      listBranch: dataOptions?.branches,
      listDeposit: dataOptions?.deposits,
    });
  }

  getPickingAreaSelected = async () => {
    if (this.props.location.state.pickingArea) {
      const { pickingArea } = this.props.location.state;
      const { mode } = this.props;

      if (mode === 'detail') {
        this.setState({
          productCode: pickingArea.productCode,
          productName: pickingArea.product.name,
          description: pickingArea.description,
          branchCode: pickingArea.branchCode,
          branchName: `${pickingArea.branchCode} - ${pickingArea.branch.name}`,
          note: pickingArea.note,
          depositCode: pickingArea.depositCode,
          depositName: `${pickingArea.deposit.code} - ${pickingArea.deposit.name}`,
        });
      } else {
        const pickingAreaSelected = await this.props.getPickingArea(pickingArea.id);

        if (pickingAreaSelected) {
          this.setState({
            pickingAreaId: pickingArea.id,
            productCode: pickingArea.productCode,
            productName: pickingArea.product.name,
            description: pickingArea.description,
            branchCode: pickingArea.branchCode,
            branchName: `${pickingArea.branchCode} - ${pickingArea.branch.name}`,
            note: pickingArea.note,
            depositCode: pickingArea.depositCode,
            depositName: `${pickingArea.deposit.code} - ${pickingArea.deposit.name}`,
          });
        }
      }
    }
  };

  setMode = (mode) => {
    if (mode === 'create' || mode === 'copy') {
      this.setState({
        disableCodeProduct: false,
        disableDescription: false,
        disableBranch: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
        description: '',
        productCode: '',
        productName: '',
        branchCode: '',
        branchName: '',
        note: '',
        depositCode: '',
        depositName: '',
        disableDeposit: false,
      });
    }

    if (mode === 'edit') {
      this.setState({
        disableCodeProduct: true,
        disableDescription: true,
        disableBranch: true,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
        disableDeposit: true,
      });
    }
  };

  setValue = async (attr, value) => {
    this.setState({
      [`${attr}`]: value,
    });
  };

  setValueDrop = async (attr, value) => {
    if (attr === 'branch') {
      if (value) {
        await this.setState({
          [`${attr}Code`]: value ? value.value : null,
          [`${attr}Name`]: value ? value.label : '',
        });
      } else {
        await this.setState({
          [`${attr}Code`]: null,
          [`${attr}Name`]: '',
        });
      }
    } else {
      await this.setState({
        [`${attr}Code`]: value ? value.value : null,
        [`${attr}Name`]: value ? value.label : '',
      });
    }
  };

  setFilterDrop = _.debounce(async (attr, value) => {
    const {
      productCode,
    } = this.state;

    if (productCode === value) {
      return;
    }

    const { products } = await this.props.getPickingAreaOptionsProducts(value);

    const dataSelected = products?.filter((item) => item.value === value);

    if (dataSelected.length === 1) {
      await this.setState({
        [`${attr}Code`]: dataSelected[0].value ? dataSelected[0].value : null,
        [`${attr}Name`]: dataSelected[0].value ? dataSelected[0].label : '',
      });
    } else {
      await this.setState({
        [`${attr}Code`]: null,
        [`${attr}Name`]: '',
      });
    }

    this.setState({
      listProduct: products,
    });
  }, 1000);

  actionPickingAreaSubmit = async () => {
    const {
      productCode, description, branchCode, note, showCreate, pickingAreaId,
      depositCode,
    } = this.state;

    try {
      if (showCreate) {
        const newPickingArea = await this.props.createPickingArea({
          productCode, description, branchCode, note, depositCode,
        });

        if (newPickingArea.id) {
          addNotification(
            'success',
            I18n.t('BEE2604' /* Criar Área de Picking */),
            I18n.t('BEE2608', { 0: description } /* Área de Picking %{0} incluída com sucesso! */),
            'top-right',
          );

          this.props.history.push(ROUTES.PICKING_AREA_LIST);
        } else {
          addNotification(
            'danger',
            showCreate
              ? I18n.t('BEE2604' /* Criar Área de Picking */) : I18n.t('BEE2605' /* Atualizar Área de Picking */),
            showCreate
              ? I18n.t('BEE2609', { 0: description }/* Erro ao incluir a Área de Picking %{0}! */)
              : I18n.t('BEE2607', { 0: description } /* Erro ao atualizar a Área de Picking %{0}! */),
            'top-right',
          );
        }
      } else {
        const updPickingArea = await this.props.updatePickingArea({
          pickingAreaId, productCode, description, branchCode, note, depositCode,
        });

        if (updPickingArea) {
          addNotification(
            'success',
            I18n.t('BEE2605' /* Atualizar Área de Picking */),
            I18n.t('BEE2606', { 0: description } /* Atualização da Área de Picking %{0} efetuada com sucesso! */),
            'top-right',
          );

          this.props.history.push(ROUTES.PICKING_AREA_LIST);
        }
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            showCreate
              ? I18n.t('BEE2604' /* Criar Área de Picking */) : I18n.t('BEE2605' /* Atualizar Área de Picking */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            showCreate
              ? I18n.t('BEE2604' /* Criar Área de Picking */) : I18n.t('BEE2605' /* Atualizar Área de Picking */),
            showCreate
              ? I18n.t('BEE2609', { 0: description }/* Erro ao incluir a Área de Picking %{0}! */)
              : I18n.t('BEE2607', { 0: description } /* Erro ao atualizar a Área de Picking %{0}! */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          showCreate
            ? I18n.t('BEE2604' /* Criar Área de Picking */) : I18n.t('BEE2605' /* Atualizar Área de Picking */),
          showCreate
            ? I18n.t('BEE2609' /* Erro ao incluir a Área de Picking %{0}! */)
            : I18n.t('BEE2607' /* Erro ao atualizar a Área de Picking %{0}! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      description,
      branchCode,
      branchName,
      productCode,
      productName,
      note,
      listProduct,
      listBranch,
      depositCode,
      depositName,
      listDeposit,
    } = this.state;

    const {
      disableCodeProduct,
      disableDescription,
      disableBranch,
      disableNote,
      showCreate,
      showUpdate,
      showCancel,
      disableDeposit,
    } = this.state;

    const title = I18n.t('BEE2570' /* Áreas de Picking */);

    const modeDescription = {
      create: I18n.t('BEE138' /* Criar */),
      detail: I18n.t('BEE56' /* Detalhes */),
      edit: I18n.t('BEE57' /* Editar */),
      copy: I18n.t('BEE80' /* Copiar */),
    };

    const breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            { I18n.t('BEE12' /* Início */) }
          </>
        ),
      },
      { value: I18n.t('BEE1389' /* Cadastros */) },
      { value: I18n.t('BEE14' /* Gerais */) },
      { value: I18n.t('BEE2570' /* Áreas de Picking */) },
      { value: modeDescription[this.props.mode], active: true },
    ];

    return (
      <Form
        title={title}
        noButton
        linkBack={{ pathname: ROUTES.PICKING_AREA_LIST, state: { dice: this.props.location.dice } }}
        showCreate={showCreate}
        showCancel={showCancel}
        showUpdate={showUpdate}
        setValue={this.setValue}
        setValueDrop={this.setValueDrop}
        setFilterDrop={this.setFilterDrop}
        onSubmitCreate={this.actionPickingAreaSubmit}
        onSubmitUpdate={this.actionPickingAreaSubmit}
        breadcrumb={breadcrumb}
        inputs={(formContext) => ([
          formContext.createSelectDropDownFilter(
            { code: productCode, label: productName },
            'product',
            `${I18n.t('BEE378' /* Código do Produto */)}:`,
            listProduct,
            disableCodeProduct,
          ),
          formContext.createInput(
            productName,
            'productName',
            `${I18n.t('BEE277' /* Descrição */)}:`,
            '',
            'text',
            true,
            true,
          ),
          formContext.createInput(
            description,
            'description',
            `${I18n.t('BEE2613' /* Descrição da Área de Picking */)}:`,
            '',
            'text',
            true,
            disableDescription,
          ),
          formContext.createSelectDropDown(
            { value: branchCode, label: branchName },
            'branch',
            `${I18n.t('BEE145' /* Filial */)}:`,
            listBranch,
            disableBranch,
          ),
          formContext.createSelectDropDown(
            { value: depositCode, label: depositName },
            'deposit',
            `${I18n.t('BEE182' /* Depósito */)}:`,
            listDeposit,
            disableDeposit,
          ),
          formContext.createInput(
            note,
            'note',
            `${I18n.t('BEE135' /* Observação */)}:`,
            '',
            'text',
            true,
            disableNote,
          ),
        ])}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getPickingAreaOptions: () => dispatch(getPickingAreaOptions()),
  getPickingAreaOptionsProducts: (productFilter) => dispatch(getPickingAreaOptionsProducts(productFilter)),
  getPickingArea: (pickingAreaId) => dispatch(getPickingArea(pickingAreaId)),
  createPickingArea: (pickingArea) => dispatch(createPickingArea(pickingArea)),
  updatePickingArea: (pickingArea) => dispatch(updatePickingArea(pickingArea)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PickingAreaForm));
