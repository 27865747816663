import React from 'react';
import { withRouter } from 'react-router-dom';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel';

class lineChart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const { lineChartCheckData } = this.props;

    return (
      <Panel>
        <PanelHeader onClickCollapse>
          {this.props.title}
        </PanelHeader>
        <PanelBody>
          <div style={{ height: `${this.props.height ? this.props.height : '450px'}` }}>
            {lineChartCheckData
              && (
                <Chart
                  options={lineChartCheckData.options}
                  series={lineChartCheckData.series}
                  type={this.props.typeChart}
                  height="450px"
                />
              )}
          </div>
        </PanelBody>
      </Panel>
    );
  }
}

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(withRouter(lineChart));
