import { I18n } from 'react-redux-i18n';

function barCodeType(status) {
  if (status || status === 0) {
    switch (status) {
      case 1:
        return I18n.t('BEE2592' /* EAN13 */);
      case 2:
        return I18n.t('BEE2593' /* DUN14 */);
      case 3:
        return I18n.t('BEE2594' /* DISPLAY */);
      case 4:
        return I18n.t('BEE2595' /* OUTROS */);
      default:
        return status;
    }
  } else if (!status) {
    return [
      { value: 1, label: I18n.t('BEE2592' /* EAN13 */) },
      { value: 2, label: I18n.t('BEE2593' /* DUN14 */) },
      { value: 3, label: I18n.t('BEE2594' /* DISPLAY */) },
      { value: 4, label: I18n.t('BEE2595' /* OUTROS */) },
    ];
  }
}

function listMainBarCode() {
  return [
    { value: true, label: I18n.t('BEE172' /* Sim */) },
    { value: false, label: I18n.t('BEE173' /* Não */) },
  ];
}

export default {
  barCodeType,
  listMainBarCode,
};
