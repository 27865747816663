import _ from 'lodash';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { Link, withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { deleteCompany, getCompaniesList } from '../../../../app/store/actions/companies';

class CompanyList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      companyUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false,
      },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.showCompanyDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showEditCompany(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showCopyCompany(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showDeleteCompany(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE83' /* Nome */),
        accessor: 'companyName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE84' /* Razão Social */),
        accessor: 'corporateName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
      }, {
        Header: I18n.t('BEE85' /* CNPJ */),
        accessor: 'cnpj',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE86' /* E-mail */),
        accessor: 'email',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
      }, {
        Header: I18n.t('BEE53' /* Cidade */),
        accessor: 'city',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE87' /* Estado */),
        accessor: 'state',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      }, {
        Header: I18n.t('BEE88' /* País */),
        accessor: 'country',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
      },
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true,
      });
      await this.getCompanies();
    } else {
      await this.getCompanies();
    }
  };

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      companyUpdate: null,
    });
  };

  getCompanies = async () => {
    await this.props.getCompaniesList();
  };

  deleteCompany = async (company) => {
    const result = await this.props.deleteCompany(company._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'danger',
        I18n.t('BEE89' /* Eliminar Empresa */),
        I18n.t('BEE90', { 0: company.code } /* Empresa %{0} eliminada com sucesso! */),
        'top-right',
      );
    }
    await this.getCompanies();
  };

  showCompanyDetail = (company) => {
    this.props.history.push({
      pathname: ROUTES.COMPANY_DETAIL,
      state: {
        company: company._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showEditCompany = (company) => {
    this.props.history.push({
      pathname: ROUTES.COMPANY_EDIT,
      state: {
        company: company._original,
      },
      dice: {
        page: this.state.page,
      },
    });
  };

  showCopyCompany = (company) => {
    this.props.history.push({
      pathname: ROUTES.COMPANY_COPY,
      state: {
        company: company._original,
      },
    });
  };

  showDeleteCompany = (company) => {
    this.setState({ showModalDelete: true, companyUpdate: company });
  };

  render() {
    const { companyUpdate, showModalDelete, page } = this.state;
    const { companiesList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE13' /* Administração */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE15' /* Empresas */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE15' /* Empresas */)}
          wikiHelp={ROUTES.COMPANY_HELP}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.COMPANY_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1" />
                {' '}
                {I18n.t('BEE91' /* Incluir Empresa */)}
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getCompanies,
          }}
          filterable
          data={companiesList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) || ((!this.state.firstRun && this.props.location.state
            && this.props.location.state.dice && this.props.location.state.dice.page)
            ? this.props.location.state.dice.page : page)}
          onPageChange={(page) => ((page >= 0) ? this.setState({ page }) : this.setState({ page: 0 }))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteCompany(companyUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE102', { 0: companyUpdate.code } /* A empresa %{0} será eliminada! */)}
          </SweetAlert>
          )
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companiesList: state.companies && state.companies.companiesList,
});

const mapDispatchToProps = (dispatch) => ({
  getCompaniesList: () => dispatch(getCompaniesList()),
  deleteCompany: (companyId) => dispatch(deleteCompany(companyId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CompanyList));
