import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown,
} from 'reactstrap';

import { changeAllocationProduct } from '../../../app/store/actions/outboundCableCut';
import {
  productAllocationAndStockBalances, productAllocationsList,
} from '../../../app/store/actions/outboundOrderAllocations';
import addNotification from '../../../components/notification';
import Table from '../../../components/table/Table';
import ROUTES from '../../../config/routes';
import FormatHelpers from '../../../helpers/format';
import ProductsHelpers from '../../../helpers/products';
import ModalChangeAllocation from './modalChangeAllocation';

class allocatedProductList extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE41' /* Expedição */) },
      { value: I18n.t('BEE2007' /* Produtos Alocados */), active: true },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: (rows) => (
          <div style={{ textAlign: 'center' }}>
            <button type="button" className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs" />
              <DropdownMenu>
                <DropdownItem onClick={() => this.dataProductAllocation(
                  rows.original.outboundOrderId,
                  rows.original.productCode,

                  rows.original.branchCode,
                )}
                >
                  {I18n.t('BEE2983' /* Consulta Alocações */)}
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        ),
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE443' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'productFullName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1808' /* Pedido Cliente */),
        accessor: 'customerOrderCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE50' /* Cliente */),
        accessor: 'customerName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE1516' /* Agrupador */),
        accessor: 'serialGroup',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1688' /* Quantidade */),
        accessor: 'quantityDemanded',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE482' /* Alocada */),
        accessor: 'allocatedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE2040' /* Separada */),
        accessor: 'pickedQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>{FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false)}</span>
        ),
      }, {
        Header: I18n.t('BEE444' /* Data de Entrega */),
        accessor: 'deliveryDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE773' /* Controle Estoque */),
        accessor: 'stockControlType',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>
            {' '}
            {ProductsHelpers.stockControlType(row.value || 0)}
          </span>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option key="all" value="">{I18n.t('BEE793' /* Todos */)}</option>
            {ProductsHelpers.stockControlType().map((element) => (
              <option key={`${element.value}`} value={element.value}>{element.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE434' /* Data de Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L') : ''}
          </span>
        ),
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE578' /* Data de Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: (row) => (
          <span>
            {' '}
            {moment(row.value).format('L')}
          </span>
        ),
      },
    ];

    this.state = {
      productsList: [],
      productAllocation: {},
      openAllocationModal: false,
      defaultPageSize: 10,
      defaultSorted: [{ id: 'productCode', desc: false }],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
    };
  }

  componentDidMount() {
    this.productAllocationsList();
  }

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });
    this.productAllocationsList();
  };

  productAllocationsList = async () => {
    try {
      const productsList = await this.props.productAllocationsList(
        moment(this.state.startDate).format(),
        moment(this.state.endDate).format(),
      );

      if (productsList && productsList.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2007' /* Produtos Alocados */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({
          productsList,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2007' /* Produtos Alocados */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2007' /* Produtos Alocados */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  dataProductAllocation = async (outboundOrderId, productCode, branchCode) => {
    try {
      const productAllocation = await this.props.productAllocationAndStockBalances(
        outboundOrderId,
        productCode,

        branchCode,
      );

      if (productAllocation && productAllocation.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2007' /* Produtos Alocados */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        this.setState({
          productAllocation,
        });
        this.showModalChangeAllocation();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2007' /* Produtos Alocados */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2007' /* Produtos Alocados */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  showModalChangeAllocation = () => {
    this.setState({
      openAllocationModal: true,
    });
  };

  render() {
    const {
      defaultPageSize, defaultSorted, productsList, productAllocation,
    } = this.state;

    return (
      <>
        <Table
          breadcrumb={this.breadcrumb}
          headerTitle={I18n.t('BEE2007' /* Produtos Alocados */)}
          wikiHelp={ROUTES.OUTBOUND_ALLOCATED_PRODUCT_HELP}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          filterable
          expander
          downloadCSV
          data={productsList}
          columns={this.tableColumns}
          defaultPageSize={defaultPageSize}
          defaultSorted={defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        <ModalChangeAllocation
          openAllocationModal={this.state.openAllocationModal}
          closeModalChangeAllocation={(update = false) => {
            this.setState({ openAllocationModal: false });
            if (update) this.productAllocationsList();
          }}
          allocations={productAllocation.allocations}
          balances={productAllocation.balances}
          changeAllocationProduct={(
            outboundOrderProductIds,
            stockBalaceId,
          ) => this.props.changeAllocationProduct(outboundOrderProductIds, stockBalaceId)}
        />
      </>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  productAllocationsList: (filterStartDate, filterEndDate) => dispatch(productAllocationsList(
    filterStartDate,
    filterEndDate,
  )),
  productAllocationAndStockBalances: (
    outboundOrderId,
    productCode,
    branchCode,
  ) => dispatch(productAllocationAndStockBalances(outboundOrderId, productCode, branchCode)),
  changeAllocationProduct: (
    outboundOrderProductIds,
    stockBalaceId,
  ) => dispatch(changeAllocationProduct(outboundOrderProductIds, stockBalaceId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(allocatedProductList));
