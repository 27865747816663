import ServerUtils from '../../data/server';
import { axiosInstance } from '../initialization';
import AuthService from './auth';
import { I18nLanguages } from './i18n';
import { store } from '../../config/configureStore';

async function request(method, uri, data = {}, headers = {}, extras = {}) {
  if (!headers['Content-Type']) headers['Content-Type'] = 'application/json';

  try {
    const response = await axiosInstance({
      method,
      url: `${ServerUtils.getBaseUrl()}${uri}`,
      data,
      headers,
      ...extras,
    });
    if (response && response.data) {
      if (response.data.success) return response.data.data;
      return response.data;
    }
    return response;
  } catch (err) {
    throw err;
  }
}

function publicHeader(header = {}) {
  header.Authorization = 'Basic YmVlc3RvY2t3ZWI6YmVlc3RvY2t3ZWJkZXNlbnZvbHZpZG9wb3JzenNvbHVjb2Vz';
  header['content-type'] = 'application/x-www-form-urlencoded';
  return header;
}

function urlencodedTrans(data) {
  const str = [];
  const keys = Object.keys(data);
  keys.forEach((key) => { str.push(`${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`); });
  return str.join('&');
}

function setDataAppLanguage(data = {}) {
  const { locale } = store.getState().app || {};
  if (locale) {
    data.appLanguage = I18nLanguages(locale);
  }
}

async function getRest(uri, data, header, extras) {
  setDataAppLanguage(data);
  return request('get', `${uri}${data ? `?${urlencodedTrans(data)}` : ''}`, {}, header, extras);
}

async function postRest(uri, data, header, extras) {
  setDataAppLanguage(data);
  return request('post', uri, urlencodedTrans(data), publicHeader(header), extras);
}

async function putRest(uri, data, header, extras) {
  setDataAppLanguage(data);
  return request('put', uri, urlencodedTrans(data), publicHeader(header), extras);
}

function authenticatedHeader(header = {}) {
  const auth = AuthService.get();
  if (auth) header.Authorization = `Bearer ${auth.access_token}`;
  return header;
}

async function getAuthenticated(uri, data, header, extras) {
  setDataAppLanguage(data);
  return request('get', `${uri}${data ? `?${urlencodedTrans(data)}` : ''}`, {}, authenticatedHeader(header), extras);
}

async function postAuthenticated(uri, data, header, extras) {
  setDataAppLanguage(data);
  return request('post', uri, data, authenticatedHeader(header), extras);
}

async function putAuthenticated(uri, data, header, extras) {
  setDataAppLanguage(data);
  return request('put', uri, data, authenticatedHeader(header), extras);
}

async function deleteAuthenticated(uri, data, header, extras) {
  setDataAppLanguage(data);
  return request('delete', uri, data, authenticatedHeader(header), extras);
}

export default {
  getRest,
  postRest,
  putRest,
  getAuthenticated,
  postAuthenticated,
  putAuthenticated,
  deleteAuthenticated,
};
