import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import InputMask from 'react-input-mask';

import OneClickButton from '../../../../components/form/button';
import {
  Panel, PanelHeader, PanelBody, PanelFooter,
} from '../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';
import getAddressByCep from '../../../../app/services/viaCep';

import {
  createSupplier, updateSupplier, getSupplier,
} from '../../../../app/store/actions/suppliers';

class SupplierForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      businessName: '',
      email: '',
      phone: '',
      cnpj: '',
      stateRegistration: '',
      postalCode: '',
      ibge: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
      note: '',
      disableCode: true,
      disableName: true,
      disableBusinessName: true,
      disableEmail: true,
      disablePhone: true,
      disableCNPJ: true,
      disableStateRegistration: true,
      disablePostalCode: true,
      disableStreet: true,
      disableNumber: true,
      disableComplement: true,
      disableDistrict: true,
      disableCity: true,
      disableState: true,
      disableCountry: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getSupplierSelected();
    }
  }

  getSupplierSelected = async () => {
    if (this.props.location.state.supplier) {
      const { supplier } = this.props.location.state;
      const supplierSelected = await this.props.getSupplier(supplier.id);

      if (supplierSelected) {
        this.setState({
          // supplierSelected,
          supplierId: supplierSelected.id,
          code: supplierSelected.code,
          name: supplierSelected.name,
          businessName: supplierSelected.businessName,
          email: supplierSelected.email,
          phone: supplierSelected.phone,
          cnpj: supplierSelected.cnpj,
          stateRegistration: supplierSelected.stateRegistration,
          postalCode: supplierSelected.postalCode,
          ibge: supplierSelected.ibge,
          street: supplierSelected.street,
          number: supplierSelected.number,
          complement: supplierSelected.complement,
          district: supplierSelected.district,
          city: supplierSelected.city,
          state: supplierSelected.state,
          country: supplierSelected.country,
          note: supplierSelected.note,
        });
      }
    }
  };

  setMode = () => {
    let mode;

    if (this.props.isCreate) {
      mode = 'create';
    } else if (this.props.isCopy) {
      mode = 'copy';
    } else if (this.props.isEdit) {
      mode = 'edit';
    } else if (this.props.isDetail) {
      mode = 'detail';
    }

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableBusinessName: false,
        disableEmail: false,
        disablePhone: false,
        disableCNPJ: false,
        disableStateRegistration: false,
        disablePostalCode: false,
        disableStreet: false,
        disableNumber: false,
        disableComplement: false,
        disableDistrict: false,
        disableCity: false,
        disableState: false,
        disableCountry: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: true,
        disableBusinessName: true,
        disableEmail: true,
        disablePhone: true,
        disableCNPJ: true,
        disableStateRegistration: true,
        disablePostalCode: true,
        disableStreet: true,
        disableNumber: true,
        disableComplement: true,
        disableDistrict: true,
        disableCity: true,
        disableState: true,
        disableCountry: true,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          id={attr}
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label htmlFor={attr} className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          required={required}
          disabled={disabled}
          id={attr}
        />
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    if (attr === 'postalCode') {
      const newPostalCode = value;
      const { postalCode } = this.state;

      if (newPostalCode !== postalCode) {
        await this.setState({
          [`${attr}`]: value,
        });

        const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

        if (re.test(newPostalCode)) {
          await this.searchViaCep(newPostalCode);
        } else {
          await this.cleanAddress();
        }
      }
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }
  };

  cleanAddress = async () => {
    await this.setState({
      ibge: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
    });
  };

  searchViaCep = async (postalCode) => {
    const keepOnlyNumber = (value) => value.replace(/\D/g, '');
    const address = await getAddressByCep(keepOnlyNumber(postalCode));

    if (address.erro) {
      await this.cleanAddress();
    } else {
      await this.setState({
        street: address.logradouro,
        ibge: address.ibge,
        number: '',
        complement: '',
        district: address.bairro,
        city: address.localidade,
        state: address.uf,
        country: I18n.t('BEE103' /* Brasil */),
      });
    }
  };

  createSupplierSubmit = async () => {
    const {
      code, name, businessName, email, phone, cnpj, stateRegistration,
      postalCode, ibge, street, number, complement, district,
      city, state, country, note,
    } = this.state;

    try {
      const newSupplier = await this.props.createSupplier({
        code,
        name,
        businessName,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        ibge,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
        note,
      });

      if (newSupplier) {
        await addNotification(
          'success',
          I18n.t('BEE342' /* Fornecedor */),
          I18n.t('BEE343', { 0: code } /* Fornecedor %{0} incluído com sucesso! */),
          'top-right',
        );

        this.props.history.push(ROUTES.SUPPLIER_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE112' /* Empresa */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE342' /* Fornecedor */),
            I18n.t('BEE344', { 0: code } /* Erro ao incluir a Fornecedor %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE342' /* Fornecedor */),
          I18n.t('BEE344', { 0: code } /* Erro ao incluir a Fornecedor %{0}! */),
          'top-right',
        );
      }
    }
  };

  updateSupplierSubmit = async () => {
    const {
      supplierId, code, name, businessName, email, phone, cnpj, stateRegistration,
      postalCode, ibge, street, number, complement, district,
      city, state, country, note,
    } = this.state;

    try {
      const updSupplier = await this.props.updateSupplier({
        supplierId,
        code,
        name,
        businessName,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        ibge,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
        note,
      });

      if (updSupplier) {
        await addNotification(
          'success',
          I18n.t('BEE345' /* Atualizar Fornecedor */),
          I18n.t(
            'BEE346',
            { 0: code }, /* Atualização do Fornecedor %{0} efetuada com sucesso! */
          ),
          'top-right',
        );

        this.props.history.push(ROUTES.SUPPLIER_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE345' /* Atualizar Fornecedor */),
            `${error.code} - ${error.details || error.message}`,
            'top-right',
          );
        } else {
          await addNotification(
            'danger',
            I18n.t('BEE345' /* Atualizar Fornecedor */),
            I18n.t('BEE347', { 0: code } /* Erro ao atualizar o Fornecedor %{0}! */),
            'top-right',
          );
        }
      } else {
        await addNotification(
          'danger',
          I18n.t('BEE345' /* Atualizar Fornecedor */),
          I18n.t('BEE347', { 0: code } /* Erro ao atualizar o Fornecedor %{0}! */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      code, name, businessName, email, phone, cnpj, stateRegistration,
      postalCode, street, number, complement, district,
      city, state, country, note,
    } = this.state;

    const {
      mode, disableCode, disableName, disableBusinessName, disableEmail, disablePhone, disableCNPJ,
      disableStateRegistration, disablePostalCode, disableStreet, disableNumber,
      disableComplement, disableDistrict, disableCity, disableState, disableCountry,
      disableNote, showCancel, showCreate, showUpdate,
    } = this.state;

    let title = '';
    //    let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE348' /* Novo Fornecedor */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE349' /* Editar Fornecedor */);
      //     breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE350' /* Detalhes Fornecedor */);
      //    breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE23' /* Fornecedores */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE348' /* Novo Fornecedor */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form onSubmit={(e) => e.preventDefault()}>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  {this.createInput(
                    code,
                    'code',
                    `${I18n.t('BEE82' /* Código */)}:`,
                    I18n.t('BEE351' /* Informe o fornecedor (obrigatório) */),
                    'text',
                    true,
                    disableCode,
                  )}
                  {this.createInput(
                    name,
                    'name',
                    `${I18n.t('BEE84' /* Razão Social */)}:`,
                    I18n.t('BEE114' /* Informe a razão social (obrigatório) */),
                    'text',
                    false,
                    disableName,
                  )}
                  {this.createInput(
                    businessName,
                    'businessName',
                    `${I18n.t('BEE352' /* Nome Fantasia */)}:`,
                    I18n.t('BEE353' /* Informe o nome fantasia */),
                    'text',
                    false,
                    disableBusinessName,
                  )}
                  {this.createInputMask(
                    cnpj,
                    'cnpj',
                    `${I18n.t('BEE85' /* CNPJ */)}:`,
                    I18n.t('BEE116' /* Informe o CNPJ (obrigatório) */),
                    '99.999.999/9999-99',
                    'text',
                    true,
                    disableCNPJ,
                  )}
                  {this.createInput(
                    stateRegistration,
                    'stateRegistration',
                    `${I18n.t('BEE117' /* Inscrição Estadual */)}:`,
                    I18n.t('BEE118' /* Informe a inscrição estadual (obrigatório) */),
                    'text',
                    true,
                    disableStateRegistration,
                  )}
                  {this.createInputMask(
                    postalCode,
                    'postalCode',
                    `${I18n.t('BEE119' /* CEP */)}:`,
                    I18n.t('BEE120' /* Informe o CEP (obrigatório) */),
                    '99999-999',
                    'text',
                    true,
                    disablePostalCode,
                  )}
                  {this.createInput(
                    street,
                    'street',
                    `${I18n.t('BEE121' /* Rua */)}:`,
                    I18n.t('BEE122' /* Informe a rua */),
                    'text',
                    false,
                    disableStreet,
                  )}
                  {this.createInput(
                    number,
                    'number',
                    `${I18n.t('BEE123' /* Número */)}:`,
                    I18n.t('BEE124' /* Informe o número */),
                    'text',
                    false,
                    disableNumber,
                  )}
                  {this.createInput(
                    complement,
                    'complement',
                    `${I18n.t('BEE125' /* Complemento */)}:`,
                    I18n.t('BEE126' /* Informe o complemento */),
                    'text',
                    false,
                    disableComplement,
                  )}
                  {this.createInput(
                    district,
                    'district',
                    `${I18n.t('BEE127' /* Bairro */)}:`,
                    I18n.t('BEE128' /* Informe o bairro */),
                    'text',
                    false,
                    disableDistrict,
                  )}
                  {this.createInput(
                    city,
                    'city',
                    `${I18n.t('BEE53' /* Cidade */)}:`,
                    I18n.t('BEE129' /* Informe a cidade */),
                    'text',
                    false,
                    disableCity,
                  )}
                  {this.createInput(
                    state,
                    'state',
                    `${I18n.t('BEE87' /* Estado */)}:`,
                    I18n.t('BEE130' /* Informe o estado */),
                    'text',
                    false,
                    disableState,
                  )}
                  {this.createInput(
                    country,
                    'country',
                    `${I18n.t('BEE88' /* País */)}:`,
                    I18n.t('BEE131' /* Informe o país */),
                    'text',
                    false,
                    disableCountry,
                  )}
                  {this.createInput(
                    email,
                    'email',
                    `${I18n.t('BEE86' /* E-mail */)}:`,
                    I18n.t('BEE3309' /* Informe o e-mail (obrigatório) */),
                    'text',
                    false,
                    disableEmail,
                  )}
                  {this.createInputMask(
                    phone,
                    'phone',
                    `${I18n.t('BEE133' /* Telefone */)}:`,
                    I18n.t('BEE134' /* Informe o telefone */),
                    phone && phone.length < 11 ? '(99) 9999-9999' : '(99) 99999-9999',
                    'tel',
                    false,
                    disablePhone,
                  )}
                  {this.createInput(
                    note,
                    'note',
                    `${I18n.t('BEE135' /* Observação */)}:`,
                    I18n.t('BEE136' /* Informe a observação */),
                    'text',
                    false,
                    disableNote,
                  )}
                </PanelBody>
                <PanelFooter>
                  <Link to={{ pathname: ROUTES.SUPPLIER_LIST, state: { dice: this.props.location.dice } }}>
                    {(showCancel
                      ? (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          {I18n.t('BEE99' /* Cancelar */)}
                        </OneClickButton>
                      )
                      : (
                        <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">
                          {I18n.t('BEE137' /* Voltar */)}
                        </OneClickButton>
                      ))}
                  </Link>
                  {(showCreate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createSupplierSubmit()}
                    >
                      {I18n.t('BEE138' /* Criar */)}
                    </OneClickButton>
                    )
                  )}
                  {(showUpdate
                    && (
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateSupplierSubmit()}
                    >
                      {I18n.t('BEE139' /* Atualizar */)}
                    </OneClickButton>
                    )
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getSupplier: (supplierId) => dispatch(getSupplier(supplierId)),
  createSupplier: (supplier) => dispatch(createSupplier(supplier)),
  updateSupplier: (supplier) => dispatch(updateSupplier(supplier)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SupplierForm));
