import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import {
  addOrderToWave, getOpenWaveList,
  getOutboundWavesProductsList,
} from '../../../app/store/actions/outboundWaves';
import { getUserByRange } from '../../../app/store/actions/ranges';
import OneClickButton from '../../../components/form/button';
import Select from '../../../components/form/select';
import addNotification from '../../../components/notification';

import PickingWave from './pickingWave';
import TableWave from './tableWave';

import WikiHelp from '../../../components/tooltip/wikiHelp';
import ROUTES from '../../../config/routes';

const colourStyles = {
  control: (styles) => ({ ...styles, backgroundColor: 'white' }),
  option: (styles, {
    data, isDisabled, isFocused, isSelected,
  }) => ({
    ...styles,
    backgroundColor: isDisabled ? 'grey' : 'white',
    color: isDisabled ? 'grey' : 'black',
    cursor: isDisabled ? 'not-allowed' : 'default',
    textAlign: 'left',

    ':hover': {
      ...styles[':hover'],
      backgroundColor: isSelected ? 'grey' : '#eb900a',
      color: 'white',
    },
  }),
};
class OutboundOrderPickingWave extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      productsList: [],
      rangeList: [],
      outboundWavesList: [],
      activateAddButton: true,
      page: 1,
      rangeCode: null,
      rangeName: '',
      rangeDisabled: false,
      addNew: false,
    };
  }

  async componentDidMount() {
    await this.selectPage();
  }

  setValueDrop = async (attr, value) => {
    await this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
  };

  createSelectDropDown = (value, attr, label, items, disabled, id) => (
    <div className="form-group row" style={{ marginBottom: 'auto' }}>
      <div className="col-md-5" style={{ marginLeft: 'auto' }}>
        <Select
          value={value.label ? value : ''}
          id={id}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={colourStyles}
          placeholder={label}
          isDisabled={disabled}
        />
      </div>
    </div>
  );

  selectPage = async () => {
    const outboundWavesList = await this.props.getOpenWaveList();

    if (outboundWavesList) {
      const { waveRegensList = [], status = 0 } = outboundWavesList;
      if (waveRegensList.length && status === 2) {
        const productsList = await this.props.getOutboundWavesProductsList(waveRegensList[0].id) || [];

        this.setState({
          productsList,
          activateAddButton: false,
          page: 2,
          rangeDisabled: true,
        });
      } else {
        this.setState({
          outboundWavesList: outboundWavesList.waveRegensList,
          activateAddButton: true,
          page: 1,
          rangeDisabled: true,
          rangeCode: outboundWavesList.waveRegensList[0].outboundWaveRangeCode,
          rangeName: (outboundWavesList.waveRegensList[0].outboundWaveRangeCode === 'all')
            ? I18n.t('BEE793' /* Todos */) : outboundWavesList.waveRegensList[0].outboundWaveRangeName,
        });
        await this.getUserByRange();
      }
    } else {
      this.setState({
        outboundWavesList: [],
        activateAddButton: true,
        page: 1,
        rangeDisabled: false,
      });
      await this.getUserByRange();
      if (this.state.rangeList.length && !this.state.rangeCode) {
        this.setState({
          rangeCode: 'all',
          rangeName: I18n.t('BEE793' /* Todos */),
        });
      }
    }
  };

  addOrderToWave = async () => {
    const { rangeCode, rangeList } = this.state;
    if (!rangeList.length) {
      addNotification(
        'danger',
        I18n.t('BEE1446' /* Separação por Onda */),
        I18n.t('BEE2066' /* O usuário não está vinculado a nenhum range */),
        'top-right',
      );
    } else if (!rangeCode) {
      addNotification(
        'danger',
        I18n.t('BEE1446' /* Separação por Onda */),
        I18n.t('BEE2043' /* Nenhum range selecionado */),
        'top-right',
      );
    } else {
      try {
        const result = await this.props.addOrderToWave(rangeCode);

        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE1446' /* Separação por Onda */),
            I18n.t('BEE1841' /* Nenhum Documento de Saída pendente para o usuário! */),
            'top-right',
          );
          await this.selectPage();
        } else {
          await this.selectPage();
          this.setState({ addNew: true });
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          const { error } = err.response.data;

          if (error.details || error.message) {
            addNotification(
              'danger',
              I18n.t('BEE1446' /* Separação por Onda */),
              `${error.code} - ${error.details || error.message}`,
              'top-right',
            );
          } else {
            addNotification(
              'danger',
              I18n.t('BEE1446' /* Separação por Onda */),
              I18n.t('BEE1842' /* Erro ao buscar documento para separação */),
              'top-right',
            );
          }
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1446' /* Separação por Onda */),
            I18n.t('BEE1842' /* Erro ao buscar documento para separação */),
            'top-right',
          );
        }
      }
    }
  };

  getUserByRange = async () => {
    try {
      const rangeList = await this.props.getUserByRange();
      if (rangeList && rangeList.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE1446' /* Separação por Onda */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        if (!rangeList.length) {
          addNotification(
            'danger',
            I18n.t('BEE1446' /* Separação por Onda */),
            I18n.t('BEE2066' /* O usuário não está vinculado a nenhum range */),
            'top-right',
          );
          this.setState({ rangeDisabled: true });
        }
        this.setState({ rangeList });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;

        addNotification('danger', I18n.t('BEE1446' /* Separação por Onda */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE1446' /* Separação por Onda */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      page, outboundWavesList, productsList,
      rangeList = [], rangeCode, rangeName, rangeDisabled,
    } = this.state;

    return (
      <>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1400' /* Movimentação */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE41' /* Expedição */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE1446' /* Separação por Onda */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center">
          <h1 className="page-header mb-0">
            {I18n.t('BEE1446' /* Separação por Onda */)}
          </h1>
          <WikiHelp wikiPath={ROUTES.OUTBOUND_WAVE_PICKING_HELP} />
          {this.state.activateAddButton && (
            <div className="ml-auto">
              <OneClickButton
                className="btn btn-success btn-sm btn-rounded pl-2 pr-3"
                onClick={() => this.addOrderToWave()}
              >
                <i className="fa fa-plus mr-2" />
                {I18n.t('BEE407' /* Buscar */)}
              </OneClickButton>
            </div>
          )}
        </div>
        {page === 1 && (
          <div className="mt-2">
            <div className="select-input-range">
              {this.createSelectDropDown(
                { value: rangeCode, label: rangeName },
                'range',
                `${I18n.t('BEE581' /* Range */)}: `,
                rangeList,
                rangeDisabled,
                1,
              )}
            </div>
          </div>
        )}
        {page === 1 && (
          <TableWave
            outboundWavesList={outboundWavesList}
            selectPage={() => this.selectPage()}
            addNew={this.state.addNew}
            setState={(value) => this.setState(value)}
          />
        )}
        {page === 2 && (
          <PickingWave
            selectPage={() => this.selectPage()}
            productsList={productsList}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getOpenWaveList: () => dispatch(getOpenWaveList()),
  getOutboundWavesProductsList: (outboundWaveId) => dispatch(getOutboundWavesProductsList(outboundWaveId)),
  addOrderToWave: (rangeCode) => dispatch(addOrderToWave(rangeCode)),
  getUserByRange: () => dispatch(getUserByRange()),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundOrderPickingWave));
