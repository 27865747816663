import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { UncontrolledButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import _ from 'lodash';

import addNotification from '../../../../components/notification';
import Table from '../../../../components/table/Table';

import ROUTES from '../../../../config/routes';

import { getStorageAddressTypesList, deleteStorageAddressType } from '../../../../app/store/actions/storageAdressTypes';
import StorageAddressTypeHelpers from '../../../../helpers/storageAddressTypes';

class StorageAddressTypeList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showModalDelete: false,
      addressTypeUpdate: null,
    };

    this.defaultSorted = [
      {
        id: 'code',
        desc: false
      }
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE55' /* Ações */),
        accessor: 'action',
        style: { overflow: 'visible', alignSelf: 'center' },
        filterable: false,
        sortable: false,
        width: 100,
        Cell: rows => (
          <div style={{ textAlign: 'center' }}>
            <button className="btn btn-default btn-xs">{I18n.t('BEE55' /* Ações */)}</button>
            <UncontrolledButtonDropdown>
              <DropdownToggle color="default" caret className="btn-xs"></DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => this.showAddressTypeDetail(rows.row)}>{I18n.t('BEE56' /* Detalhes */)}</DropdownItem>
                <DropdownItem onClick={() => this.showAdressTypeDetail(rows.row)}>{I18n.t('BEE57' /* Editar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showAddressTypeCopy(rows.row)}>{I18n.t('BEE80' /* Copiar */)}</DropdownItem>
                <DropdownItem onClick={() => this.showAddressTypeDelete(rows.row)}>{I18n.t('BEE81' /* Eliminar */)}</DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>
        )
      }, {
        Header: I18n.t('BEE82' /* Código */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Cell: rows => (
          <span>
            {`${rows.row._original.status} - ${StorageAddressTypeHelpers.status(rows.row._original.status)}`}
          </span>
        ),
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const id = row[filter.id];
          const value = _.lowerCase(StorageAddressTypeHelpers.status(id))
          if (_.includes(value, input)) {
            return true;
          } 
        },
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250
      }
    ];
  }

  componentDidMount() {
    this.maintainsState();
  }

  maintainsState = async () => {
    if (this.props.location.state && this.props.location.state.dice) {
      this.setState({
        page: this.props.location.state.dice.page,
        firstRun: true
      });
      await this.getStorageAddressTypes();
    } else {
      await this.getStorageAddressTypes();
    }
  }

  setInitState = () => {
    this.setState({
      showModalDelete: false,
      addressTypeUpdate: null,
    });
  }

  getStorageAddressTypes = async () => {
    await this.props.getStorageAddressTypesList();
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}`]: value,
    });
  }

  deleteStorageAddressType = async (addressType) => {
    const result = await this.props.deleteStorageAddressType(addressType._original.id);

    this.setInitState();
    if (result) {
      addNotification(
        'danger', I18n.t('BEE748' /* Eliminar Tipo de Endereço */), I18n.t('BEE749' /* Tipo de endereço eliminado com sucesso! */), 'top-right'
      );
    }
    await this.getStorageAddressTypes();
  }

  showAddressTypeDetail = (addressType) => {
    this.props.history.push({
      pathname: ROUTES.ADDRESS_TYPE_DETAIL,
      state: {
        addressType: addressType._original,
      },
      dice: {
        page: this.state.page,
      }
    });
  }

  showAdressTypeDetail = (addressType) => {
    this.props.history.push({
      pathname: ROUTES.ADDRESS_TYPE_EDIT,
      state: {
        addressType: addressType._original,
      },
      dice: {
        page: this.state.page,
      }
    });
  }

  showAddressTypeCopy = (addressType) => {
    this.props.history.push({
      pathname: ROUTES.ADDRESS_TYPE_COPY,
      state: {
        addressType: addressType._original,
      }
    });
  }

  showAddressTypeDelete = (addressType) => {
    this.setState({ showModalDelete: true, addressTypeUpdate: addressType });
  }

  render() {
    const { addressTypeUpdate, showModalDelete, page,} = this.state;
    const { addressTypesList = [] } = this.props;

    return (
      <div>
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE732' /* Tipos de Endereço */)}</li>
          </ol>
        </div>
        <Table
          downloadCSV
          headerTitle={I18n.t('BEE732' /* Tipos de Endereço */)}
          actionButtons={(
            <div className="ml-auto">
              <Link to={ROUTES.ADDRESS_TYPE_CREATE} className="btn btn-success btn-sm btn-rounded pl-2 pr-3">
                <i className="fa fa-plus mr-1"></i> { I18n.t('BEE750' /* Incluir Tipo de Endereço */) }
              </Link>
            </div>
          )}
          panelHeaderProps={{
            onClickReload: this.getStorageAddressTypes
          }}
          filterable
          data={addressTypesList}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          page={(page) ? page : (!this.state.firstRun && this.props.location.state && this.props.location.state.dice && this.props.location.state.dice.page) ? this.props.location.state.dice.page : page}
          onPageChange={(page) =>  ((page >= 0) ? this.setState({page}) : this.setState({page: 0}))}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
        />
        {(showModalDelete &&
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.deleteStorageAddressType(addressTypeUpdate)}
            onCancel={() => this.setInitState()}
          >
            {I18n.t('BEE752', { 0: addressTypeUpdate.name } /* O tipo de endereço %{0} será eliminado! */)}
          </SweetAlert>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  addressTypesList: state.storageAddressTypes && state.storageAddressTypes.storageAddressTypesList,
});

const mapDispatchToProps = dispatch => ({
  getStorageAddressTypesList: () => dispatch(getStorageAddressTypesList()),
  deleteStorageAddressType: (addressTypeMeasureId) => dispatch(deleteStorageAddressType(addressTypeMeasureId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StorageAddressTypeList));