const INITIAL_STATE = {
  jsonCsv: null,
  fileCsv: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'GET_JSON_CSV': {
      return { ...state, jsonCsv: action.jsonCsv };
    }
    case 'GET_FILE_CSV': {
      return { ...state, fileCsv: action.fileCsv };
    }
    default:
      return state;
  }
};
