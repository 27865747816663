import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import Table from '../../../../components/table/Table';
import SerialLabelHelpers from '../../../../helpers/serialLabel';
import { getListSerialLabels } from '../../../../app/store/actions/serialLabel';
import { getListSerialGroups } from '../../../../app/store/actions/serialGroup';
import addNotification from '../../../../components/notification';

class SerialLabel extends React.PureComponent {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      pages: 1,
      defaultPageSize: 10,
      totalDataLength: 0,
      dataList: [],
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      bolTableGroup: true,
    };

    this.defaultSorted = [{
      id: 'code',
      desc: true,
    }];

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>
        ),
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE2771' /* Indústria */) },
      { value: I18n.t('BEE2760' /* Etiqueta Seriada */), active: true },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2764' /* Etiqueta Agrupadora */),
        accessor: 'serialGroup.code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2765' /* Etiqueta */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1043' /* Nome do Produto */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE432' /* Endereço */),
        accessor: 'serialGroup.balance.addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2999' /* Quantidade Original */),
        accessor: 'originalQuantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{parseFloat(row.value)}</span>
          </div>
        ),
      },
      {
        Header: I18n.t('BEE530' /* Disponível */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{parseFloat(row.value)}</span>
          </div>
        ),
      },
      {
        Header: I18n.t('BEE2770' /* Ordem de Produção */),
        accessor: 'productionOrder.code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2398' /* Data de Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div>
            <span>
              {' '}
              {row.value ? moment(row.value).format('L') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE578' /* Data Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div>
            <span>
              {' '}
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{SerialLabelHelpers.serialLabelStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.status.toString()) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%', textAlign: 'center' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {SerialLabelHelpers.serialLabelStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE204' /* Situação */),
        accessor: 'situation',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{SerialLabelHelpers.serialLabelSituation(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.status.toString()) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%', textAlign: 'center' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {SerialLabelHelpers.serialLabelSituation().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }];

    this.tableColumns2 = [
      {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2765' /* Etiqueta */),
        accessor: 'code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 140,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE378' /* Código do Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1043' /* Nome do Produto */),
        accessor: 'product.name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 200,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE432' /* Endereço */),
        accessor: 'balance.addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1688' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{parseFloat(row.value)}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE2834' /* Quantidade de Volumes */),
        accessor: 'quantityBox',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{parseFloat(row.value)}</span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE2770' /* Ordem de Produção */),
        accessor: 'productionOrder.code',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 160,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2398' /* Data de Validade */),
        accessor: 'expirationDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div>
            <span>
              {' '}
              {row.value ? moment(row.value).format('L') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE578' /* Data Criação */),
        accessor: 'createdAt',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
        Cell: (row) => (
          <div>
            <span>
              {' '}
              {row.value ? moment(row.value).format('L LTS') : ''}
            </span>
          </div>
        ),
      }, {
        Header: I18n.t('BEE224' /* Status */),
        accessor: 'status',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{SerialLabelHelpers.serialLabelStatus(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.status.toString()) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%', textAlign: 'center' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {SerialLabelHelpers.serialLabelStatus().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE204' /* Situação */),
        accessor: 'situation',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{SerialLabelHelpers.serialLabelSituation(row.value)}</span>
          </div>
        ),
        filterMethod: (filter, row) => {
          if (filter.value === row.status.toString()) {
            return true;
          }
        },
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%', textAlign: 'center' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="" key="all">{I18n.t('BEE793' /* Todos */)}</option>
            {SerialLabelHelpers.serialLabelSituation().map((elem, index) => (
              <option value={elem.value} key={index}>{elem.label}</option>
            ))}
          </select>
        ),
      }];
  }

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onChange={(event) => {
        if (event.target.value === '') {
          onChange(event.target.value);
        }
      }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getList = async () => {
    const { startDate, endDate, bolTableGroup } = this.state;

    try {
      const dataList = bolTableGroup ? await this.props.getListSerialGroups(
        moment(startDate).format(),
        moment(endDate).format(),
        this.page,
        this.pageSize,
        this.sorted,
        this.filtered,
      ) : await this.props.getListSerialLabels(
        moment(startDate).format(),
        moment(endDate).format(),
        this.page,
        this.pageSize,
        this.sorted,
        this.filtered,
      );

      if (dataList.rows) {
        const pages = Math.ceil(dataList.count / this.pageSize);
        this.setState({
          dataList: [...dataList.rows],
          totalDataLength: dataList.count,
          pages,
        });
      } else this.setState({ dataList: [] });
    } catch (err) {
      this.setState({ dataList: [] });
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2760' /* Etiquetas Seriadas */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2760' /* Etiquetas Seriadas */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  handleDateApplyEvent = async (event) => {
    await this.setState({
      startDate: event[0],
      endDate: event[1],
    });
    this.getList();
  };

  handleSetOtherTable = async (isTableGroup, ref) => {
    const { bolTableGroup } = this.state;

    if (bolTableGroup === isTableGroup) return;

    await this.setState({ bolTableGroup: isTableGroup });

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [{
      id: 'code',
      desc: true,
    }];
    this.filtered = [];
    ref.state.filtered = [];
    ref.state.sorted = [];

    await this.getList();
  };

  render() {
    const {
      defaultPageSize, dataList, totalDataLength, pages, bolTableGroup,
    } = this.state;

    return (
      <Table
        expander
        filterable
        downloadCSV
        manual
        downButtonPosition
        data={dataList}
        columns={bolTableGroup ? this.tableColumns2 : this.tableColumns}
        defaultSorted={this.defaultSorted}
        defaultPageSize={defaultPageSize}
        panelHeaderProps={{
          onClickReload: this.getList,
        }}
        refActionButtons={(ref) => (

          <div className="ml-auto">
            <button
              type="button"
              className={
                `btn btn-120 ${bolTableGroup ? 'btn-primary' : 'btn-white'} btn-rounded p-5 m-5`
              }
              onClick={() => this.handleSetOtherTable(true, ref)}
            >
              {I18n.t('BEE2794' /* Por Agrupadora */)}
            </button>
            <button
              type="button"
              className={
                `btn btn-120 ${!bolTableGroup ? 'btn-primary' : 'btn-white'} btn-rounded p-5 m-5`
              }
              onClick={() => this.handleSetOtherTable(false, ref)}
            >
              {I18n.t('BEE2795' /* Por Etiqueta */)}
            </button>
          </div>
        )}
        totalDataLength={totalDataLength}
        pages={pages}
        datePicker={{
          handleDateApplyEvent: this.handleDateApplyEvent,
        }}
        breadcrumb={this.breadcrumb}
        headerTitle={I18n.t('BEE2760' /* Etiquetas Seriadas */)}
        onFetchData={(state) => {
          this.page = state.page;
          this.pageSize = state.pageSize;
          this.sorted = state.sorted;
          this.filtered = state.filtered;
          this.getList();
        }}
        defaultFilterMethod={(filter, row) => {
          const input = _.lowerCase(filter.value);
          const value = _.lowerCase(row[filter.id]);
          if (_.includes(value, input)) {
            return true;
          }
        }}
      />
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  getListSerialLabels: (filterStartDate, filterEndDate, page, pageSize, sorted, filtered) => dispatch(
    getListSerialLabels(
      filterStartDate,
      filterEndDate,
      page,
      pageSize,
      sorted,
      filtered,
    ),
  ),
  getListSerialGroups: (filterStartDate, filterEndDate, page, pageSize, sorted, filtered) => dispatch(
    getListSerialGroups(
      filterStartDate,
      filterEndDate,
      page,
      pageSize,
      sorted,
      filtered,
    ),
  ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(SerialLabel));
