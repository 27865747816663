/* eslint-disable class-methods-use-this */
import ChartDataLabels from 'chartjs-plugin-datalabels';
// import { format, subDays } from 'date-fns';
import moment from 'moment';
import React from 'react';
import { Chart, Doughnut } from 'react-chartjs-2';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import Select from 'react-select';
import addNotification from '../../../components/notification';
import DateRangePicker from '../../../components/date/DateRangePicker.jsx';
import { getBranchesOptions } from '../../../app/store/actions/branches';
import {
  getAllGroupersByBranch,
  getDepositsByBranch,
  getInventoryDashboardSheetsList,
} from '../../../app/store/actions/inventory';
import BarChart from '../../../components/charts/BarChart';
import CountsInformation from '../../../components/charts/CountsInformation';
import DonutChart from '../../../components/charts/DonutChart';
import { Panel, PanelBody, PanelHeader } from '../../../components/panel/panel';

import { PopoverHeader, UncontrolledPopover } from 'reactstrap';
// 😁

class inventoryGeneralDashboard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      userBranches: [],
      selectedBranch: '',
      groupers: [],
      selectedGroupers: [],
      deposits: [],
      selectedDeposits: [],
      startDate: moment().subtract(30, 'd').format(),
      endDate: moment().format(),
      charts: {},
      speedometer: {},
    };

    this.breadcrumb = [
      { value: I18n.t('BEE42' /* Dashboard */), iconClassName: 'fa fa-chart-line m-t-10 m-r-5' },
      { value: I18n.t('BEE572' /* Inventário */) },
      { value: I18n.t('BEE2427' /* Status Geral Por Filial */), active: true },
    ];
  }

  componentDidMount() {
    this.getBranchesOptions();
    this.getInventoryInfo();
  }

  getBranchesOptions = async () => {
    const userBranches = await this.props.getBranchesOptions();

    this.setState({
      userBranches,
    });
  };

  handleApplyDate = async (value) => {
    const { selectedBranch } = this.state;
    const startDate = moment(value[0]).format();
    const endDate = moment(value[1]).format();

    if (selectedBranch) {
      const groupers = await this.props.getAllGroupersByBranch(selectedBranch, 5, startDate, endDate);
      const deposits = await this.props.getDepositsByBranch(selectedBranch, startDate, endDate);

      await this.setState({
        groupers,
        deposits,
      });
    }

    await this.setState({
      startDate,
      endDate,
    });
  };

  handleSelectedBranches = async (event) => {
    const selectedBranch = event.value;
    const { startDate, endDate } = this.state;

    const groupers = await this.props.getAllGroupersByBranch(selectedBranch, 5, startDate, endDate);
    const deposits = await this.props.getDepositsByBranch(selectedBranch, startDate, endDate);

    await this.setState({
      selectedGroupers: [],
      selectedDeposits: [],
      groupers,
      deposits,
      selectedBranch,
    });
  };

  handleSelectedGroupers = async (event) => {
    const selectedGroupers = [];
    for (const element of event) {
      selectedGroupers.push({
        value: element.value,
        label: element.label,
      });
    }

    await this.setState({
      selectedGroupers,
    });
  };

  handleSelectedDeposits = async (event) => {
    const selectedDeposits = [];

    for (const element of event) {
      selectedDeposits.push({
        value: element.value,
        label: element.label,
      });
    }

    await this.setState({
      selectedDeposits,
    });
  };

  handleConfirmButton = async () => {
    const { startDate, endDate, selectedBranch } = this.state;

    if (!startDate || !endDate) {
      await addNotification(
        'danger',
        I18n.t('BEE2386' /* Verifique os Filtros */),
        I18n.t('BEE2440' /* Nenhuma data selecionada */),
        'top-right',
      );
      return;
    }

    if (!selectedBranch) {
      await addNotification(
        'danger',
        I18n.t('BEE2386' /* Verifique os Filtros */),
        I18n.t('BEE1005' /* É necessário informar a filial */),
        'top-right',
      );
      return;
    }
    this.getInventoryInfo();
  };

  handleColorAccuracityPercentage = (value) => {
    if (value <= 50) {
      return '#F70000';
    }
    if (value <= 65) {
      return '#F54B4B';
    }
    if (value <= 90) {
      return '#FDC823';
    }
    if (value <= 98) {
      return '#F9BB00';
    }
    return '#04d38d';
  };

  getInventoryInfo = async () => {
    const {
      selectedBranch, selectedDeposits, selectedGroupers, startDate, endDate,
    } = this.state;
    const selectedGroupersCodes = [];
    const selectedDepositsCodes = [];

    selectedGroupers.map((element) => (selectedGroupersCodes.push(element.value)));
    selectedDeposits.map((element) => (selectedDepositsCodes.push(element.value)));

    const inventoryInfo = await this.props.getInventoryDashboardSheetsList(
      selectedBranch,
      selectedGroupersCodes,
      selectedDepositsCodes,
      startDate,
      endDate,
    );

    await this.setState({
      charts: inventoryInfo.charts,
      speedometer: {
        labels: ['Crítico', 'Baixo', 'Médio', 'Alto', 'Excelente'],
        datasets: [
          {
            data: [50, 15, 25, 8, 2],
            needleValue: inventoryInfo.charts.speedometerChart.needleValue,
            backgroundColor: ['#FD4B4B', '#FB7979', '#FFD654', '#B79000', '#009462'],
            hoverBackgroundColor: ['#ff3d3d', '#ff5757', '#f0ec00', '#ffab2e', '#008a37'],
          },
        ],
        options: {
          tooltips: { enabled: false },
          plugins: {
            datalabels: {
              formatter(value, context) {
                return context.chart.config.data.labels[context.dataIndex];
              },
              display: true,
              borderRadius: 3,
              color: 'white',
              font: {
                size: 16,
              },
            },
          },
          layout: {
            padding: {
              bottom: 3,
            },
          },
          rotation: 1 * Math.PI,
          circumference: 1 * Math.PI,
          legend: {
            display: false,
          },
          cutoutPercentage: 50,
        },
      },
    });

    Chart.pluginService.register({
      afterDraw: (chart) => {
        const { needleValue } = chart.chart.config.data.datasets[0];
        const dataTotal = chart.chart.config.data.datasets[0].data.reduce(
          (a, b) => a + b,
          0,
        );
        const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;
        const { ctx } = chart.chart;
        const cw = chart.chart.canvas.offsetWidth;
        const ch = chart.chart.canvas.offsetHeight;
        const cx = cw / 2;
        const cy = ch - 5;
        ctx.translate(cx, cy);
        ctx.rotate(angle);
        ctx.beginPath();
        ctx.moveTo(0, -5);
        ctx.lineTo(ch - 30, 0);
        ctx.lineTo(0, 5);
        ctx.fillStyle = 'rgb(0, 0, 0)';
        ctx.fill();
        ctx.rotate(-angle);
        ctx.translate(-cx, -cy);
        ctx.beginPath();
        ctx.arc(cx, cy, 5, 0, Math.PI * 2);
        ctx.fill();

        const { width } = chart.chart;
        const { height } = chart.chart;
        ctx.restore();
        ctx.font = 'normal small-caps 1.7rem bold sans-serif';
        ctx.textBaseline = 'bottom';
        ctx.fillStyle = this.handleColorAccuracityPercentage(needleValue);
        const text = `${needleValue}%`;
        const textX = Math.round((width - ctx.measureText(text).width) / 1.95);
        const textY = height / 1.4;
        ctx.fillText(text, textX, textY);
        ctx.save();
      },
    });
  };

  render() {
    return (
      <div>
        {!!this.breadcrumb && this.breadcrumb.length && (
          <div className="d-flex align-items-center">
            <ol className="breadcrumb float-xl-left">
              {this.breadcrumb.map((it, index) => (
                <li key={`${it.value}${index}`} className={`breadcrumb-item${it.active ? ' active' : ''}`}>
                  {
                    it.iconClassName
                      ? (
                        <>
                          <i className="fa fa-chart-line m-t-10 m-r-5" />
                          {' '}
                          {it.value}
                        </>
                      ) : (
                        it.value
                      )
                  }
                </li>
              ))}
            </ol>
          </div>
        )}
        <div className="d-flex align-items-center testet teste11">
          <h1 className="page-header mb-0">
            {I18n.t('BEE2254' /* Dashboard - Acompanhamento de Inventário */)}
          </h1>
        </div>
        <div className="row mt-2 mx-0 p-0">
          <div className="mt-1">
            <DateRangePicker
              onChange={this.handleApplyDate}
            />
            <UncontrolledPopover trigger="hover" placement="top" target="dateRangePicker">
              <PopoverHeader>
                { I18n.t('BEE3342', { 0:(I18n.t('BEE3344' /* data de criação */)) } /* O intervalo segue o critério pela %{0} */) }
              </PopoverHeader>
            </UncontrolledPopover>
          </div>
          <div className="d-flex mt-1">
            <Select
              options={this.state.userBranches}
              onChange={(event) => this.handleSelectedBranches(event || [])}
              blurInputOnSelect={false}
              isSearchable
              styles={{
                singleValue: (defaultStyles) => ({
                  ...defaultStyles,
                  color: '#FFF',
                  backgroundColor: '#2d353c',
                }),
                control: (defaultStyles) => ({
                  ...defaultStyles,
                  minHeight: '35px',
                  width: '250px',
                  border: 0,
                  boxShadow: 'none',
                  backgroundColor: '#2d353c',
                  fontWeight: '600',
                  borderRadius: '5px',
                  marginRight: '5px',
                  outline: 'none',
                }),
                placeholder: (defaultStyles) => ({
                  ...defaultStyles,
                  color: '#ffffff',
                }),
                dropdownIndicator: (defaultStyles) => ({
                  ...defaultStyles,
                  paddingTop: 0,
                  paddingBottom: 0,
                  color: '#ffffff',
                }),
                clearIndicator: (defaultStyles) => ({
                  ...defaultStyles,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
              }}
              maxMenuHeight={300}
              placeholder={I18n.t('BEE2433' /* Nenhuma filial selecionada */)}
            />
          </div>
          <div className="d-flex mt-1">
            <Select
              options={this.state.groupers}
              value={this.state.selectedGroupers}
              isDisabled={!this.state.selectedBranch}
              isMulti
              isClearable={false}
              onChange={(event) => this.handleSelectedGroupers(event || [])}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              isSearchable
              styles={{
                control: (defaultStyles, state) => ({
                  ...defaultStyles,
                  minHeight: '35px',
                  width: '250px',
                  border: 0,
                  boxShadow: 'none',
                  color: '#FFF',
                  backgroundColor: '#2d353c',
                  fontWeight: '600',
                  borderRadius: '5px',
                  outline: 'none',
                  marginRight: '5px',
                  ...(state.isDisabled && {
                    pointerEvents: 'auto',
                    cursor: 'not-allowed',
                  }),
                }),
                multiValueLabel: (styles) => ({
                  ...styles,
                  color: '#FFF',
                  backgroundColor: '#2d353c',
                  borderRadius: '0',
                  fontSize: '0.75rem',
                  fontWeight: '600',
                }),
                multiValueRemove: (styles) => ({
                  ...styles,
                  color: '#FFF',
                  backgroundColor: '#2d353c',
                  ':hover': {
                    backgroundColor: '#16191d',
                    color: '#FFF',
                  },
                  borderRadius: '0',
                  fontSize: '1.2rem',
                }),
                valueContainer: (defaultStyles) => ({
                  ...defaultStyles,
                  maxHeight: '55px',
                  overflow: 'auto',
                  backgroundColor: '#2d353c',
                  borderTopLeftRadius: '5px',
                  borderBottomLeftRadius: '5px',
                }),
                placeholder: (defaultStyles) => ({
                  ...defaultStyles,
                  color: '#ffffff',
                  backgroundColor: '#2d353c',
                }),
                dropdownIndicator: (defaultStyles) => ({
                  ...defaultStyles,
                  paddingTop: 0,
                  paddingBottom: 0,
                  color: '#FFF',
                  ':hover': {
                    backgroundColor: '#2d353c',
                    color: '#FFF',
                  },
                }),
                clearIndicator: (defaultStyles) => ({
                  ...defaultStyles,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
              }}
              maxMenuHeight={300}
              placeholder={I18n.t('BEE3203' /* Todos os agrupadores selecionados */)}
            />
          </div>
          <div className="d-flex mt-1">
            <Select
              options={this.state.deposits}
              value={this.state.selectedDeposits}
              isMulti
              onChange={(event) => this.handleSelectedDeposits(event || [])}
              closeMenuOnSelect={false}
              blurInputOnSelect={false}
              isSearchable
              isDisabled={!this.state.selectedBranch}
              styles={{
                control: (defaultStyles, state) => ({
                  ...defaultStyles,
                  minHeight: '35px',
                  width: '250px',
                  border: 0,
                  boxShadow: 'none',
                  color: '#FFF',
                  backgroundColor: '#2d353c',
                  fontWeight: '600',
                  borderRadius: '5px 0px 0px 5px',
                  outline: 'none',
                  ...(state.isDisabled && {
                    pointerEvents: 'auto',
                    cursor: 'not-allowed',
                  }),
                }),
                multiValueLabel: (styles) => ({
                  ...styles,
                  color: '#FFF',
                  backgroundColor: '#2d353c',
                  borderRadius: '0',
                  fontSize: '0.75rem',
                  fontWeight: '600',
                }),
                multiValueRemove: (styles) => ({
                  ...styles,
                  color: '#FFF',
                  backgroundColor: '#2d353c',
                  ':hover': {
                    backgroundColor: '#16191d',
                    color: '#FFF',
                  },
                  borderRadius: '0',
                  fontSize: '1.2rem',
                }),
                valueContainer: (defaultStyles) => ({
                  ...defaultStyles,
                  maxHeight: '55px',
                  overflow: 'auto',
                  backgroundColor: '#2d353c',
                  borderTopLeftRadius: '5px',
                  borderBottomLeftRadius: '5px',
                }),
                placeholder: (defaultStyles) => ({
                  ...defaultStyles,
                  color: '#ffffff',
                  backgroundColor: '#2d353c',
                }),
                dropdownIndicator: (defaultStyles) => ({
                  ...defaultStyles,
                  paddingTop: 0,
                  paddingBottom: 0,
                  color: '#FFF',
                  ':hover': {
                    backgroundColor: '#2d353c',
                    color: '#FFF',
                  },
                }),
                clearIndicator: (defaultStyles) => ({
                  ...defaultStyles,
                  paddingTop: 0,
                  paddingBottom: 0,
                }),
              }}
              maxMenuHeight={300}
              placeholder={I18n.t('BEE2434' /* Todos depósitos selecionados */)}
            />
            <div style={{ borderRadius: '0px 5px 5px 0px', height: '35px' }} className="bg-dark">
              <button
                className="h-100 border-0 bg-transparent"
                style={{ fontSize: '22px' }}
                type="button"
                onClick={() => this.handleConfirmButton()}
              >
                <i className="fa fa-search text-white" style={{ fontSize: '20px' }} />
              </button>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-xl-6">
            <Panel>
              <PanelHeader onClickCollapse onClickReload={this.handleConfirmButton}>
                {I18n.t('BEE2435' /* Status Geral */)}
              </PanelHeader>
              <PanelBody>
                <DonutChart donutChartValues={this.state.charts.donutChart} />
              </PanelBody>
            </Panel>
          </div>
          <div className="col-xl-6">
            <Panel>
              <PanelHeader onClickCollapse onClickReload={this.handleConfirmButton}>
                {I18n.t('BEE2436' /* Indicador de Acuracidade do Estoque Físico (Produto X Qtde. X Endereço) */)}
              </PanelHeader>
              <PanelBody>
                {this.state.charts.speedometerChart && this.state.charts.speedometerChart.needleValue
                  ? (
                    <div className="d-flex flex-column" style={{ height: '450px' }}>
                      <div style={{ flex: 'none' }}>
                        {this.state.speedometer
                          && (
                            <Doughnut
                              height={100}
                              data={this.state.speedometer}
                              plugins={[ChartDataLabels]}
                              options={this.state.speedometer.options}
                            />
                          )}
                      </div>
                      <div className="d-flex justify-content-center mt-4" style={{ flex: 'none' }}>
                        <table className="text-black text-center border">
                          <thead>
                            <tr className="border font-weight-bold bg-dark text-white" style={{ height: '35px' }}>
                              <th>{I18n.t('BEE283' /* Nível */)}</th>
                              <th>{I18n.t('BEE2437' /* Faixa */)}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="border" style={{ height: '25px' }}>
                              <td className="pr-3 pl-3">{I18n.t('BEE2438' /* Crítico */)}</td>
                              <td className="pr-3 pl-3">{I18n.t('BEE2444', { 0: '50%' } /* Até %{0} */)}</td>
                            </tr>
                            <tr className="border" style={{ height: '25px' }}>
                              <td className="pr-3 pl-3">{I18n.t('BEE611' /* Baixo */)}</td>
                              <td className="pr-3 pl-3">
                                {I18n.t('BEE2443', { 0: '50%', 1: '65%' } /* %{0} a %{1} */)}

                              </td>
                            </tr>
                            <tr className="border" style={{ height: '25px' }}>
                              <td className="pr-3 pl-3">{I18n.t('BEE391' /* Médio */)}</td>
                              <td className="pr-3 pl-3">
                                {I18n.t('BEE2443', { 0: '65%', 1: '90%' } /* %{0} a %{1} */)}

                              </td>
                            </tr>
                            <tr className="border" style={{ height: '25px' }}>
                              <td className="pr-3 pl-3">{I18n.t('BEE2445' /* Alto */)}</td>
                              <td className="pr-3 pl-3">
                                {I18n.t('BEE2443', { 0: '90%', 1: '98%' } /* %{0} a %{1} */)}

                              </td>
                            </tr>
                            <tr className="border" style={{ height: '25px' }}>
                              <td className="pr-3 pl-3">{I18n.t('BEE2446' /* Excelente */)}</td>
                              <td className="pr-3 pl-3">
                                {I18n.t('BEE2443', { 0: '98%', 1: '100%' } /* %{0} a %{1} */)}

                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  )
                  : (
                    <div className="d-flex flex-column" style={{ height: '450px' }}>
                      <div
                        className="d-flex align-items-center justify-content-center user-select-none"
                        style={{
                          flex: 'auto', color: '#9b8a79', fontSize: '1rem', fontFamily: 'Helvetica',
                        }}
                      >
                        {I18n.t('BEE2423' /* Não há dados na data informada */)}
                      </div>
                    </div>
                  )}
              </PanelBody>
            </Panel>
          </div>
        </div>
        {this.state.charts.countChart
          && (
            <Panel>
              <PanelHeader onClickCollapse onClickReload={this.handleConfirmButton}>
                {I18n.t('BEE2447' /* Fichas por contagem - Detalhes */)}
              </PanelHeader>
              <PanelBody>
                <CountsInformation countChartValues={this.state.charts.countChart} />
              </PanelBody>
            </Panel>
          )}
        <BarChart
          barChartValues={this.state.charts.barChart}
          title={I18n.t('BEE2439' /* Fichas Pendentes (Por Contagem) */)}
          onClickReload={this.handleConfirmButton}
        />
      </div>
    );
  }
}

const mapStateToProps = () => ({
});

const mapDispatchToProps = (dispatch) => ({
  getBranchesOptions: () => dispatch(getBranchesOptions()),
  getAllGroupersByBranch: (
    selectedBranch,
    statusFilter,
    startDate,
    endDate,
  ) => dispatch(getAllGroupersByBranch(selectedBranch, statusFilter, startDate, endDate)),
  getDepositsByBranch: (
    selectedBranch,
    startDate,
    endDate,
  ) => dispatch(getDepositsByBranch(selectedBranch, startDate, endDate)),
  getInventoryDashboardSheetsList: (
    selectedBranch,
    selectedGroupers,
    selectedDeposits,
    startDate,
    endDate,
  ) => dispatch(getInventoryDashboardSheetsList(
    selectedBranch,
    selectedGroupers,
    selectedDeposits,
    startDate,
    endDate,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(inventoryGeneralDashboard));
