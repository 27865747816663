import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { I18n } from 'react-redux-i18n';

import OneClickButton from './../../../../components/form/button';
import { Panel, PanelHeader, PanelBody, PanelFooter } from './../../../../components/panel/panel';
import addNotification from '../../../../components/notification';

import ROUTES from '../../../../config/routes';
import getAddressByCep from '../../../../app/services/viaCep';

import {
  createCarrier, updateCarrier, getCarrier
} from '../../../../app/store/actions/carriers';

class CarrierForm extends React.Component {
  constructor(props) {
    super(props);

    const listModeTransport = [
      { value: 1, label: I18n.t('BEE292' /* Rodoviário */) },
      { value: 2, label: I18n.t('BEE293' /* Aeroviário */) },
      { value: 3, label: I18n.t('BEE294' /* Marítimo */) },
      { value: 4, label: I18n.t('BEE295' /* Ferroviário */) },
      { value: 5, label: I18n.t('BEE296' /* Outros */) },
    ];

    this.state = {
      mode: 'detail',
      code: '',
      name: '',
      businessName: '',
      modeTransport: 1,
      email: '',
      phone: '',
      cnpj: '',
      stateRegistration: '',
      postalCode: '',
      ibge: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
      note: '',
      disableCode: true,
      disableName: true,
      disableBusinessName: true,
      disableModeTransport: true,
      disableEmail: true,
      disablePhone: true,
      disableCnpj: true,
      disableStateRegistration: true,
      disablePostalCode: true,
      disableStreet: true,
      disableNumber: true,
      disableComplement: true,
      disableDistrict: true,
      disableCity: true,
      disableState: true,
      disableCountry: true,
      disableNote: true,
      showCreate: false,
      showUpdate: false,
      showCancel: false,
      listModeTransport,
    };
  }

  async componentDidMount() {
    await this.setMode();
    if (this.state.mode !== 'create') {
      await this.getCarrierSelected();
    }
  }

  getCarrierSelected = async () => {
    if (this.props.location.state.carrier) {
      const { carrier } = this.props.location.state;

      const carrierSelected = await this.props.getCarrier(carrier.id);

      if (carrierSelected) {
        this.setState({
          carrierSelected,
          carrierId: carrierSelected.id,
          code: carrierSelected.code,
          name: carrierSelected.name,
          businessName: carrierSelected.businessName,
          modeTransport: carrierSelected.mode,
          email: carrierSelected.email,
          phone: carrierSelected.phone,
          cnpj: carrierSelected.cnpj,
          stateRegistration: carrierSelected.stateRegistration,
          postalCode: carrierSelected.postalCode,
          ibge: carrierSelected.ibge,
          number: carrierSelected.number,
          complement: carrierSelected.complement,
          district: carrierSelected.district,
          city: carrierSelected.city,
          state: carrierSelected.state,
          country: carrierSelected.country,
          note: carrierSelected.note,
        });
      }
    }
  }

  setMode = () => {
    const mode = this.props.isCreate ? 'create'
      : this.props.isCopy ? 'copy'
        : this.props.isEdit ? 'edit'
          : this.props.isDetail ? 'detail' : '';

    if (mode === 'create' || mode === 'copy') {
      this.setState({
        mode,
        disableCode: false,
        disableName: false,
        disableBusinessName: false,
        disableModeTransport: false,
        disableEmail: false,
        disablePhone: false,
        disableCnpj: false,
        disableStateRegistration: false,
        disablePostalCode: false,
        disableStreet: false,
        disableNumber: false,
        disableComplement: false,
        disableDistrict: false,
        disableCity: false,
        disableState: false,
        disableCountry: false,
        disableNote: false,
        showCreate: true,
        showUpdate: false,
        showCancel: true,
      });
    } else if (mode === 'edit') {
      this.setState({
        mode,
        disableCode: true,
        disableName: false,
        disableBusinessName: false,
        disableModeTransport: false,
        disableEmail: false,
        disablePhone: false,
        disableCnpj: false,
        disableStateRegistration: false,
        disablePostalCode: false,
        disableStreet: false,
        disableNumber: false,
        disableComplement: false,
        disableDistrict: false,
        disableCity: false,
        disableState: false,
        disableCountry: false,
        disableNote: false,
        showCreate: false,
        showUpdate: true,
        showCancel: true,
      });
    }
  }

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createInputMask = (value, attr, label, placeholder, mask, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <InputMask
          className="form-control"
          placeholder={placeholder}
          type={type}
          mask={mask}
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
          required={required}
          disabled={disabled}
        ></InputMask>
      </div>
    </div>
  );

  createSelect = (value, attr, label, items) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <select
          className="form-control"
          value={value || ''}
          onChange={e => this.setValue(attr, e.target.value)}
        >
          {items.map(item => (
            <option key={item.value} value={item.value}>{item.label}</option>
          ))}
        </select>
      </div>
    </div>
  );

  setValue = async (attr, value) => {
    if (attr === 'postalCode') {
      const newPostalCode = value;
      const { postalCode } = this.state;

      if (newPostalCode !== postalCode) {
        await this.setState({
          [`${attr}`]: value,
        });

        const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

        if (re.test(newPostalCode)) {
          await this.searchViaCep(newPostalCode);
        } else {
          await this.cleanAddress();
        }
      }
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }
  }

  cleanAddress = async () => {
    await this.setState({
      ibge: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
    });
  }

  searchViaCep = async (postalCode) => {
    const keepOnlyNumber = value => value.replace(/\D/g, '');
    const address = await getAddressByCep(keepOnlyNumber(postalCode));

    if (address.erro) {
      await this.cleanAddress();
    } else {
      await this.setState({
        street: address.logradouro,
        ibge: address.ibge,
        number: '',
        complement: '',
        district: address.bairro,
        city: address.localidade,
        state: address.uf,
        country: I18n.t('BEE103' /* Brasil */),
      });
    }
  }

  createCarrierSubmit = async () => {
    const {
      code, name, businessName, modeTransport, email, phone, cnpj,
      stateRegistration, postalCode, ibge, street,
      number, complement, district, city, state,
      country, note,
    } = this.state;

    try {
      const newCarrier = await this.props.createCarrier({
        code,
        name,
        businessName,
        mode: modeTransport,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        ibge,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
        note,
      });

      if (newCarrier) {
        await addNotification(
          'success', I18n.t('BEE301' /* Transportadora */), I18n.t('BEE302', { 0: code } /* Transportadora %{0} incluída com sucesso */), 'top-right'
        );

        this.props.history.push(ROUTES.CARRIER_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE301' /* Transportadora */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE301' /* Transportadora */), I18n.t('BEE303', { 0: code } /* Erro ao incluir a Transportadora %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE301' /* Transportadora */), I18n.t('BEE303', { 0: code } /* Erro ao incluir a Transportadora %{0}! */), 'top-right'
        );
      }
    }
  };

  updateCarrierSubmit = async () => {
    const {
      code, name, businessName, modeTransport, email, phone, cnpj,
      stateRegistration, postalCode, ibge, street,
      number, complement, district, city, state,
      country, note, carrierId,
    } = this.state;

    try {
      const updUser = await this.props.updateUser({
        carrierId,
        name,
        businessName,
        modeTransport,
        email,
        phone: phone ? phone.replace(/\D/g, '') : '',
        cnpj: cnpj ? cnpj.replace(/\D/g, '') : '',
        stateRegistration,
        postalCode: postalCode ? postalCode.replace(/\D/g, '') : '',
        ibge,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
        note,
      });

      if (updUser) {
        await addNotification('success', I18n.t('BEE304' /* Atualizar Cliente */), I18n.t('BEE305', { 0: code } /* Atualização do Cliente %{0} efetuada com sucesso! */), 'top-right');

        this.props.history.push(ROUTES.CARRIER_LIST);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          await addNotification(
            'danger',
            I18n.t('BEE306' /* Atualizar Transportadora */),
            `${error.code} - ${error.details || error.message}`,
            'top-right'
          );
        } else {
          await addNotification(
            'danger', I18n.t('BEE306' /* Atualizar Transportadora */), I18n.t('BEE307', { 0: code } /* Erro ao atualizar a transportadora %{0}! */), 'top-right'
          );
        }
      } else {
        await addNotification(
          'danger', I18n.t('BEE306' /* Atualizar Transportadora */), I18n.t('BEE307', { 0: code } /* Erro ao atualizar a transportadora %{0}! */), 'top-right'
        );
      }
    }
  };

  descModes(type) {
    if (type === 1) {
      return I18n.t('BEE292' /* Rodoviário */);
    } else if (type === 2) {
      return I18n.t('BEE293' /* Aeroviário */);
    } else if (type === 3) {
      return I18n.t('BEE294' /* Marítimo */);
    } else if (type === 4) {
      return I18n.t('BEE295' /* Ferroviário */);
    } else if (type === 5) {
      return I18n.t('BEE296' /* Outros */);
    }
  }

  render() {

    const {
      code, name, businessName, modeTransport, email, phone, cnpj,
      stateRegistration, postalCode, street,
      number, complement, district, city, state,
      country, note, listModeTransport
    } = this.state;

    const {
      mode, disableCode, disableName, disableBusinessName, disableModeTransport, disableEmail,
      disablePhone, disableCnpj, disableStateRegistration, disablePostalCode,
      disableStreet, disableNumber, disableComplement, disableDistrict,
      disableCity, disableState, disableCountry, disableNote,
      showCancel, showCreate, showUpdate
    } = this.state;

    let title = '';
 //   let breadActive = '';

    if (mode === 'create' || mode === 'copy') {
      title = I18n.t('BEE308' /* Nova Transportadora */);
    } else if (mode === 'edit') {
      title = I18n.t('BEE309' /* Editar Transportadora */);
  //    breadActive = `${code} - ${name}`;
    } else if (mode === 'detail') {
      title = I18n.t('BEE310' /* Detalhes Transportadora */);
   //   breadActive = `${code} - ${name}`;
    }

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5"></i> {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE1389' /* Cadastros */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE14' /* Gerais */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE24' /* Transportadoras */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE308' /* Nova Transportadora */)}</li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {title}
          </h1>
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton={true} />
                <PanelBody>
                  {this.createInput(
                    code, 'code', `${I18n.t('BEE82' /* Código */)}:`, I18n.t('BEE790' /* Informe o código da transportadora (obrigatório) */), 'text', true, disableCode,
                  )}
                  {this.createInput(
                    name, 'name', `${I18n.t('BEE83' /* Nome */)}:`, I18n.t('BEE791' /* Informe o nome da transportadora */), 'text', false, disableName,
                  )}
                  {this.createInput(
                    businessName, 'businessName', `${I18n.t('BEE352' /* Nome Fantasia */)}:`, I18n.t('BEE353' /* Informe o nome fantasia da transportadora */), 'text', false, disableBusinessName,
                  )}
                  {(disableModeTransport
                    ? this.createInput(this.descModes(modeTransport), 'modeTransport', `${I18n.t('BEE291' /* Via de Transporte */)}:`, '', 'text', false, disableModeTransport)
                    : this.createSelect(modeTransport, 'modeTransport', `${I18n.t('BEE291' /* Via de Transporte */)}:`, listModeTransport))}
                  {this.createInputMask(
                    cnpj, 'cnpj', `${I18n.t('BEE85' /* CNPJ */)}:`, I18n.t('BEE116' /* Informe o CNPJ (obrigatório) */), '99.999.999/9999-99', 'text', true, disableCnpj,
                  )}
                  {this.createInput(
                    stateRegistration, 'stateRegistration', `${I18n.t('BEE117' /* Inscrição Estadual */)}:`, I18n.t('BEE792' /* Informe a inscrição estadual (obrigatório) */), 'text', true, disableStateRegistration,
                  )}
                  {this.createInputMask(
                    postalCode, 'postalCode', `${I18n.t('BEE119' /* CEP */)}:`, I18n.t('BEE120' /* Informe o CEP (obrigatório) */), '99999-999', 'text', true, disablePostalCode,
                  )}
                  {this.createInput(
                    street, 'street', `${I18n.t('BEE121' /* Rua */)}:`, I18n.t('BEE122' /* Informe a rua */), 'text', false, disableStreet,
                  )}
                  {this.createInput(
                    number, 'number', `${I18n.t('BEE123' /* Número */)}:`, I18n.t('BEE124' /* Informe o número */), 'text', false, disableNumber,
                  )}
                  {this.createInput(
                    complement, 'complement', `${I18n.t('BEE125' /* Complemento */)}:`, I18n.t('BEE126' /* Informe o complemento */), 'text', false, disableComplement,
                  )}
                  {this.createInput(
                    district, 'district', `${I18n.t('BEE127' /* Bairro */)}:`, I18n.t('BEE128' /* Informe o bairro */), 'text', false, disableDistrict,
                  )}
                  {this.createInput(
                    city, 'city', `${I18n.t('BEE53' /* Cidade */)}:`, I18n.t('BEE129' /* Informe a cidade */), 'text', false, disableCity,
                  )}
                  {this.createInput(
                    state, 'state', `${I18n.t('BEE87' /* Estado */)}:`, I18n.t('BEE130' /* Informe o estado */), 'text', false, disableState,
                  )}
                  {this.createInput(
                    country, 'country', `${I18n.t('BEE88' /* País */)}:`, I18n.t('BEE131' /* Informe o país */), 'text', false, disableCountry,
                  )}
                  {this.createInput(
                    email, 'email', `${I18n.t('BEE86' /* E-mail */)}:`, I18n.t('BEE132' /* Informe o e-mail */), 'text', false, disableEmail,
                  )}
                  {this.createInputMask(
                    phone,
                    'phone',
                    `${I18n.t('BEE133' /* Telefone */)}:`,
                    I18n.t('BEE134' /* Informe o telefone */),
                    phone && phone.length < 11 ? '(99) 9999-9999' : '(99) 99999-9999',
                    'tel',
                    false,
                    disablePhone
                  )}
                  {this.createInput(
                    note, 'note', `${I18n.t('BEE135' /* Observação */)}:`, I18n.t('BEE136' /* Informe a observação */), 'text', false, disableNote,
                  )}
                </PanelBody>
                <PanelFooter>
                  <Link to={{pathname: ROUTES.CARRIER_LIST, state: {dice: this.props.location.dice}}}>
                    {(showCancel
                      ? <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE99' /* Cancelar */)}</OneClickButton>
                      : <OneClickButton type="submit" className="btn btn-120 btn-white p-5 m-5">{I18n.t('BEE137' /* Voltar */)}</OneClickButton>)}
                  </Link>
                  {(showCreate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.createCarrierSubmit()}
                    >{I18n.t('BEE138' /* Criar */)}</OneClickButton>
                  )}
                  {(showUpdate &&
                    <OneClickButton
                      type="button"
                      className="btn btn-120 btn-primary p-5 m-5"
                      onClick={() => this.updateCarrierSubmit()}
                    >{I18n.t('BEE139' /* Atualizar */)}</OneClickButton>
                  )}
                </PanelFooter>
              </Panel>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
  getCarrier: carrierId => dispatch(getCarrier(carrierId)),
  createCarrier: carrier => dispatch(createCarrier(carrier)),
  updateCarrier: carrier => dispatch(updateCarrier(carrier)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CarrierForm));