import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import DatePicker from 'react-datepicker';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import {
  createAddressTransferWaveProduct,
  getAddressTransferWaveByBaptism,
} from '../../../../app/store/actions/addressTransferWave';
import { getProductStockBalanceByAddresAndEan } from '../../../../app/store/actions/stocks';
import { getStorageAddressesOptions } from '../../../../app/store/actions/storageAddresses';
import OneClickButton from '../../../../components/form/button';
import Select from '../../../../components/form/select';
import addNotification from '../../../../components/notification';
import { PanelPage } from '../../../../components/pages/pages';
import ROUTES from '../../../../config/routes';
import ProductsList from './productsList';
import { getDepositsOptionsList } from '../../../../app/store/actions/deposits';
import { getSerialGroupOrSerialLabel } from '../../../../app/store/actions/serialGroup';

class WaveTransferForm extends React.Component {
  constructor(props) {
    super(props);

    this.breadcrumb = [
      {
        value: (
          <>
            <i className="fas fa-home fa-fw m-t-10 m-r-5" />
            {' '}
            {I18n.t('BEE12' /* Início */)}
          </>)
        ,
      },
      { value: I18n.t('BEE1400' /* Movimentação */) },
      { value: I18n.t('BEE32' /* Estoque */) },
      { value: I18n.t('BEE2294' /* Transferência por Onda */), active: true },
    ];

    this.state = {
      baptism: '',
      idTransferDoc: null,
      disableBaptism: false,
      ean: '',
      disableEan: true,
      addressId: null,
      addressName: '',
      listStorageAddress: [],
      disableAddress: true,
      productName: '',
      productCode: null,
      unit: '',
      lotNumber: '',
      disableLotNumber: true,
      expirationDate: null,
      disabledExpirationDate: true,
      quantity: '',
      disabledQuantity: true,
      documentNumber: '',
      foundDocument: false,
      showConfirmNewDocument: false,
      products: [],
      stockControlType: null,
      controlExpirationDate: null,
      correctLot: '',
      depositCode: '',
      depositName: '',
      disableDeposit: true,
      isSerialControl: false,
      serialCode: '',
      serialGroupId: null,
      serialLabelId: null,
    };

    this.initialState = this.state;

    this.colourStyles = {
      control: (styles) => ({ ...styles, backgroundColor: 'white' }),
      option: (styles, {
        isDisabled, isSelected,
      }) => ({
        ...styles,
        backgroundColor: isDisabled ? 'grey' : 'white',
        color: isDisabled ? 'grey' : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        textAlign: 'left',

        ':hover': {
          ...styles[':hover'],
          backgroundColor: isSelected ? 'grey' : '#eb900a',
          color: 'white',
        },
      }),
    };
  }

  async componentDidMount() {
    const listDeposit = await this.props.getDepositsOptions();

    this.setState({
      listDeposit,
    });

    document.getElementById(1).focus();
    document.getElementById(1).select();
  }

  confirmStorageSubmit = async () => {
    const {
      idTransferDoc, branchCode, productCode, ean, baptism, depositCode,
      lotNumber, addressId, quantity, expirationDate, serialGroupId, serialLabelId,
    } = this.state;
    this.setState({ showConfirmNewDocument: false });

    try {
      const addressTransferWaveProducts = {
        idTransferDoc,
        branchCode,
        depositCode,
        baptism,
        productCode,
        ean,
        pickupAddressId: addressId,
        lotNumber,
        quantity,
        status: 1,
        expirationDate,
        serialGroupId,
        serialLabelId,
      };

      const result = await this.props.createAddressTransferWaveProduct(addressTransferWaveProducts);

      if (result && result.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2294' /* Transferência por Onda */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE2294' /* Transferência por Onda */),
          I18n.t('BEE2985' /* Produto alocado com sucesso! */),
          'top-right',
        );
        this.getAddressTransferWaveByBaptism();
        this.setState({
          addressId: null,
          addressName: '',
          ean: '',
          serialCode: '',
          disableEan: true,
          productName: '',
          productCode: null,
          unit: '',
          lotNumber: '',
          disableLotNumber: true,
          expirationDate: null,
          disabledExpirationDate: true,
          quantity: '',
          disabledQuantity: true,
          stockControlType: null,
          controlExpirationDate: null,
          correctLot: '',
          serialGroupId: null,
          serialLabelId: null,
        });
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2294' /* Transferência por Onda */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2294' /* Transferência por Onda */),
          I18n.t('BEE1960' /* Erro ao gravar os dados */),
          'top-right',
        );
      }
    }
  };

  confirmStorage = () => {
    const {
      quantity, ean, addressId, lotNumber, documentNumber, isSerialControl,
      stockControlType, correctLot, foundDocument, disableEan,
    } = this.state;

    if (!addressId) {
      addNotification(
        'danger',
        I18n.t('BEE2294' /* Transferência por Onda */),
        I18n.t('BEE1073' /* Endereço selecionado deve ser informado ! */),

        'top-right',
      );
    } else if (!ean && !isSerialControl) {
      addNotification(
        'danger',
        I18n.t('BEE2294' /* Transferência por Onda */),
        I18n.t('BEE1072' /* Nenhum produto selecionado ! */),

        'top-right',
      );
    } else if (ean && !disableEan) {
      this.getProductByEan();
    } else if (stockControlType === 3 && !lotNumber) {
      addNotification(
        'danger',
        I18n.t('BEE2294' /* Transferência por Onda */),
        I18n.t('BEE1074' /* Lote deve ser informado ! */),

        'top-right',
      );
    } else if (stockControlType === 3 && lotNumber !== correctLot) {
      addNotification(
        'danger',
        I18n.t('BEE2294' /* Transferência por Onda */),
        I18n.t(
          'BEE1075',
          { 0: lotNumber, 1: correctLot },
          /* Lote %{0} diferente do Lote informado na Conferência %{1} ! */),
        'top-right',
      );
    } else if (!quantity) {
      addNotification(
        'danger',
        I18n.t('BEE2294' /* Transferência por Onda */),
        I18n.t('BEE1039' /* É necessário informar a quantidade */),

        'top-right',
      );
    } else if (foundDocument && !documentNumber) {
      this.setState({ showConfirmNewDocument: true });
    } else {
      this.confirmStorageSubmit();
    }
  };

  getProductByEan = async () => {
    const { ean, addressId } = this.state;

    try {
      const productEan = await this.props.getProductStockBalanceByAddresAndEan(ean, addressId);
      if (productEan) {
        this.setState({
          disableEan: true,
          productCode: productEan.productCode,
          unit: productEan.unitMeasure,
          productName: productEan.name,
          disableLotNumber: (productEan.stockControlType !== 3),
          disabledExpirationDate: (productEan.controlExpirationDate !== true),
          disabledQuantity: false,
          stockControlType: productEan.stockControlType,
          controlExpirationDate: productEan.controlExpirationDate,
          correctLot: productEan.lotNumber,
        });

        const id = (productEan.stockControlType === 3 ? 4 : productEan.controlExpirationDate && 5) || 6;

        document.getElementById(id).focus();
        document.getElementById(id).select();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2294' /* Transferência por Onda */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2294' /* Transferência por Onda */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  serialGroupSearch = async () => {
    const { serialCode, depositCode } = this.state;

    try {
      if (serialCode) {
        const serial = await this.props.getSerialGroupOrSerialLabel(serialCode, depositCode);

        if (serial.productCode) {
          this.setState({
            serialGroupId: serial.serialGroupId,
            serialLabelId: serial.serialLabelId,
            productCode: serial.productCode,
            productName: serial.productName,
            unit: serial.unit,
            quantity: serial.quantity,
            addressId: serial.addressId,
            addressName: serial.addressName,
            lotNumber: serial.lotNumber,
            expirationDate: serial.expirationDate ? new Date(serial.expirationDate) : '',
            controlExpirationDate: serial.controlExpirationDate,
            disableDeposit: true,
          });
        } else {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            I18n.t('BEE2857' /* Etiqueta não encontrada. */),
            'top-right',
          );
          this.setValue('showForm', false);
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE34' /* Transferência */),
          I18n.t('BEE2979' /* Informe a Etiqueta Agrupadora */),
          'top-right',
        );
        this.setValue('showForm', false);
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        const { error } = err.response.data;

        if (error.details || error.message) {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            `${error.code} - ${error.message || error.details}`,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE34' /* Transferência */),
            I18n.t('BEE1969' /* Erro ao buscar dados */),
            'top-right',
          );
        }
      } else {
        addNotification(
          'danger',
          I18n.t('BEE34' /* Transferência */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
      this.setValue('showForm', false);
    }
  };

  getStorageAddressesOptions = async () => {
    const { userLogged = {} } = this.props;
    try {
      const listStorageAddress = await this.props.getStorageAddressesOptions(userLogged.mainBranch);
      if (listStorageAddress.length) this.setState({ listStorageAddress: [...listStorageAddress] });
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2294' /* Transferência por Onda */), messageError, 'top-right');
      } else {
        addNotification(
          'danger',
          I18n.t('BEE2294' /* Transferência por Onda */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
    }
  };

  getAddressTransferWaveByBaptism = async (initialState = false) => {
    const { baptism } = this.state;
    const { userLogged = {} } = this.props;

    if (initialState) {
      this.setState({
        ...this.initialState,
        baptism,
      });
    }

    try {
      const documentData = await this.props.getAddressTransferWaveByBaptism(userLogged.mainBranch, baptism);

      if (!initialState && documentData && documentData.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE2294' /* Transferência por Onda */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      } else {
        if (documentData) {
          if (documentData.status === 1) {
            await this.setState({
              disableBaptism: true,
              idTransferDoc: documentData.id,
              documentNumber: documentData.document,
              depositCode: documentData.depositCode,
              depositName: documentData.depositName,
              isSerialControl: documentData.isSerialControl,
              foundDocument: true,
              products: [...documentData.products],
              disableAddress: false,
              disableDeposit: true,
            });
            if (documentData.isSerialControl) {
              document.getElementById(3).focus();
            } else {
              this.getStorageAddressesOptions();
            }
          }
        } else if (!initialState) {
          this.setState({
            disableBaptism: true,
            foundDocument: true,
            disableAddress: false,
            disableDeposit: false,
          });
          this.getStorageAddressesOptions();
        } else if (initialState) {
          this.setState({ baptism: '' });
        }
        document.getElementById(2).focus();
      }
    } catch (err) {
      const error = err.response && err.response.data && err.response.data.error;

      if (!initialState && error && error.message) {
        const messageError = (error.details)
          ? `${error.code} - ${error.details} | ${error.message}`
          : error.message;
        addNotification('danger', I18n.t('BEE2294' /* Transferência por Onda */), messageError, 'top-right');
      } else if (!initialState) {
        addNotification(
          'danger',
          I18n.t('BEE2294' /* Transferência por Onda */),
          I18n.t('BEE1969' /* Erro ao buscar dados */),
          'top-right',
        );
      }
      if (initialState) {
        this.setState({ baptism: '' });
      }
    }
  };

  validBaptism = async () => {
    const { baptism } = this.state;
    if (!baptism) {
      addNotification(
        'danger',
        I18n.t('BEE425' /* Batismo */),
        I18n.t('BEE982' /* É necessário informar o batismo */),
        'top-right',
      );
    } else {
      this.getAddressTransferWaveByBaptism();
    }
  };

  goToElement = (e, Id, attr) => {
    if (e.keyCode === 13) {
      if (attr === 'baptism') {
        this.validBaptism();
      } else if (attr === 'ean') {
        this.getProductByEan();
      } else if (attr === 'serialCode') {
        this.serialGroupSearch();
      } else if (attr === 'quantity') {
        this.confirmStorage();
      } else if (attr === 'lotNumber') {
        const id = (this.state.controlExpirationDate) ? 5 : 6;

        document.getElementById(id).focus();
        document.getElementById(id).select();
      }
    }
  };

  setValue = (attr, value, reset) => {
    this.setState({
      [`${attr}`]: (!reset) ? value : '',
    });
    if (attr === 'baptism' && reset) this.setState({ ...this.initialState });
    else if (attr === 'ean' && reset && this.state.ean) {
      this.setState({
        ean: '',
        disableEan: false,
        productName: '',
        productCode: null,
        unit: '',
        lotNumber: '',
        disableLotNumber: true,
        expirationDate: null,
        disabledExpirationDate: true,
        quantity: '',
        disabledQuantity: true,
        stockControlType: null,
        controlExpirationDate: null,
        correctLot: '',
      });
      document.getElementById(3).focus();
      document.getElementById(3).select();
    } else if (attr === 'serialCode' && reset && this.state.serialCode) {
      this.setState({
        ean: '',
        disableEan: false,
        productName: '',
        productCode: null,
        unit: '',
        lotNumber: '',
        disableLotNumber: true,
        expirationDate: null,
        disabledExpirationDate: true,
        quantity: '',
        disabledQuantity: true,
        stockControlType: null,
        controlExpirationDate: null,
        correctLot: '',
        addressId: null,
        addressName: '',
        disableDeposit: false,
        serialGroupId: null,
        serialLabelId: null,
      });
      document.getElementById(3).focus();
      document.getElementById(3).select();
    }
  };

  setValueDrop = async (attr, value) => {
    this.setState({
      [`${attr}Code`]: value ? value.value : null,
      [`${attr}Name`]: value ? value.label : '',
    });
    if (attr === 'address') {
      if (value) {
        document.getElementById(3).focus();
        document.getElementById(3).select();
        this.setState({
          disableEan: false,
          addressId: value.value,
        });
      } else {
        this.setState({
          addressId: null,
          ean: '',
          disableEan: true,
          productName: '',
          productCode: null,
          unit: '',
          lotNumber: '',
          disableLotNumber: true,
          expirationDate: null,
          disabledExpirationDate: true,
          quantity: '',
          disabledQuantity: true,
          stockControlType: null,
          controlExpirationDate: null,
          correctLot: '',
        });
        document.getElementById(2).focus();
      }
    } else if (attr === 'deposit') {
      const { userLogged } = this.props;
      const isSerialControl = value ? (value.serialLabel
        && (userLogged && userLogged.mainBranchData ? userLogged.mainBranchData.serialControlDeposit : false)) : false;

      if (isSerialControl) {
        await this.setState({
          isSerialControl,
          addressId: null,
          addressName: '',
        });
        document.getElementById(3).focus();
      } else {
        this.setState({
          isSerialControl,
        });

        if (!value) {
          this.setState({
            idTransferDoc: null,
            disableBaptism: false,
            ean: '',
            disableEan: true,
            addressId: null,
            addressName: '',
            disableAddress: true,
            productName: '',
            productCode: null,
            unit: '',
            lotNumber: '',
            disableLotNumber: true,
            expirationDate: null,
            disabledExpirationDate: true,
            quantity: '',
            disabledQuantity: true,
            documentNumber: '',
            foundDocument: false,
            showConfirmNewDocument: false,
            stockControlType: null,
            controlExpirationDate: null,
            correctLot: '',
            depositCode: '',
            depositName: '',
            disableDeposit: true,
            serialCode: '',
            serialGroupId: null,
            serialLabelId: null,
          });

          document.getElementById(1).focus();
          document.getElementById(1).select();
        }
      }
    }
  };

  createNumberFormat = (value, attr, label, disabled = false, decimals, keypressFunction = undefined, id = null) => (
    <div className="form-group text-left">
      <span>{label}</span>
      <NumberFormat
        onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
        id={id && id}
        className="form-control"
        thousandSeparator="."
        decimalSeparator=","
        decimalScale={(!this.props.fractionalQuantity) ? 0 : decimals}
        value={value || ''}
        onValueChange={(values) => {
          this.setValue(attr, Math.abs(values.floatValue));
        }}
        fixedDecimalScale
        defaultValue={0}
        disabled={disabled}
        allowEmptyFormatting
        isAllowed={(valueToValidate) => (Math.sign(valueToValidate.floatValue) !== -1)}
      />
    </div>
  );

  customDateStyle = ({ className, children }) => (
    <div>
      <div className="bg-primary rounded p-2">
        <h5 className="text-light text-center pt-2">
          Bee
          <span className="text-dark">Stock</span>
        </h5>
      </div>
      <div className={className}>
        <div>{children}</div>
      </div>
    </div>
  );

  createDateInput = (value, attr, label, disabled, keypressFunction = undefined, id = null, isClearable = true) => (
    <div className="form-group p-2 text-left">
      <span>{label}</span>
      <div className="add_display_flex">
        <DatePicker
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          isClearable={isClearable}
          selected={value}
          className="form-control"
          dateFormat="dd/MM/yyyy"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e)}
          calendarContainer={this.customDateStyle}
          disabled={disabled}
        />
      </div>
    </div>
  );

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group p-2 text-left">
      <span>{label}</span>
      <input
        onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
        id={id && id}
        type={type}
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
        placeholder={disabled ? '' : placeholder}
        required={required}
        disabled={disabled}
      />
    </div>
  );

  createSelectDropDown = (value, attr, label, items, disabled, keypressFunction = undefined, id) => (
    <div className="form-group m-b-15 text-left">
      <span>{label}</span>
      <div>
        <Select
          id={id && id}
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          value={value.label ? value : ''}
          onChange={(e) => this.setValueDrop(attr, e)}
          options={items}
          isClearable
          styles={this.colourStyles}
          placeholder={I18n.t('BEE144' /* Selecione... */)}
          isDisabled={disabled}
        />
      </div>
    </div>
  );

  createInputButton = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
    buttonLabel,
    buttonFunction,
  ) => (
    <div className="form-group p-2 text-left">
      <span>{label}</span>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '', true)}>
            <i className="fa fa-times" />
          </button>
          {buttonLabel
            && (
            <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
              {buttonLabel}
            </button>
            )}
        </div>
      </div>
    </div>
  );

  render() {
    const {
      baptism, disableBaptism, addressId, addressName, listStorageAddress, disableAddress, serialCode,
      ean, disableEan, productName, productCode, unit, lotNumber, disableLotNumber, expirationDate,
      disabledExpirationDate, quantity, disabledQuantity, documentNumber, foundDocument, isSerialControl,
      showConfirmNewDocument, products, listDeposit, depositCode, depositName, disableDeposit, serialLabelId,
      serialGroupId,
    } = this.state;

    return (
      <>
        <PanelPage
          breadcrumb={this.breadcrumb}
          title={I18n.t('BEE2294' /* Transferência por Onda */)}
          wikiHelp={ROUTES.STOCK_WAVE_TRANSFER_HELP}
          noButton
          content={(
            <div className="col">

              <div className="row">
                <div className="col-md-3">
                  {this.createInputButton(
                    baptism,
                    'baptism',
                    I18n.t('BEE425' /* Batismo */),
                    '',
                    'text',
                    false,
                    disableBaptism,
                    this.goToElement,
                    1,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createInput(
                    documentNumber,
                    'documentNumber',
                    I18n.t('BEE1378' /* Document */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createSelectDropDown(
                    { value: depositCode, label: depositName },
                    'deposit',
                    I18n.t('BEE1795' /* Depósito Retirada */),
                    listDeposit,
                    disableDeposit,
                    this.goToElement,
                    2,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createSelectDropDown(
                    { value: addressId, label: addressName },
                    'address',
                    I18n.t('BEE432' /* Endereço */),
                    listStorageAddress,
                    disableAddress || isSerialControl,
                    this.goToElement,
                    2,
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-3">
                  {
                    isSerialControl
                      ? this.createInputButton(
                        serialCode,
                        'serialCode',
                        (serialLabelId
                          ? I18n.t('BEE2760' /* Etiqueta Seriada */)
                          : serialGroupId && I18n.t('BEE2764' /* Etiqueta Agrupadora */))
                          || I18n.t('BEE2765' /* Etiqueta */),
                        '',
                        'text',
                        false,
                        !depositCode || addressId,
                        this.goToElement,
                        3,
                      )
                      : this.createInputButton(
                        ean,
                        'ean',
                        I18n.t('BEE377' /* EAN */),
                        '',
                        'text',
                        false,
                        disableEan,
                        this.goToElement,
                        3,
                      )
}
                </div>
                <div className="col-md-5">
                  {this.createInput(
                    productName,
                    'productName',
                    `${I18n.t('BEE758' /* Descrição Produto */)}:`,
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-2">
                  {this.createInput(
                    productCode,
                    'productCode',
                    `${I18n.t('BEE378' /* Código do Produto */)}:`,
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-2">
                  {this.createInput(
                    unit,
                    'unit',
                    `${I18n.t('BEE384' /* Unidade */)}:`,
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-2">
                  {this.createInput(
                    lotNumber,
                    'lotNumber',
                    `${I18n.t('BEE428' /* Lote */)}:`,
                    '',
                    'text',
                    false,
                    disableLotNumber,
                    this.goToElement,
                    4,
                  )}
                </div>
                <div className="col-md-2">
                  {this.createDateInput(
                    expirationDate,
                    'expirationDate',
                    I18n.t('BEE434' /* Data de Validade */),
                    disabledExpirationDate,
                    this.goToElement,
                    5,
                    !disabledExpirationDate,
                  )}
                </div>
                <div className="col-md-2">
                  {this.createNumberFormat(
                    quantity,
                    'quantity',
                    I18n.t('BEE441' /* Quantidade */),
                    disabledQuantity,
                    3,
                    this.goToElement,
                    6,
                  )}
                </div>
              </div>

            </div>
          )}
          footerContent={(
            <>
              {(!foundDocument
                && (
                <OneClickButton
                  type="submit"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={this.validBaptism}
                >
                  {I18n.t('BEE407' /* Buscar */)}

                </OneClickButton>
                )
              )}
              {(foundDocument
                && (
                <OneClickButton
                  type="button"
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={this.confirmStorage}
                >
                  {documentNumber
                    ? I18n.t('BEE785' /* Adicionar */)
                    : I18n.t('BEE1033' /* Criar documento */)}

                </OneClickButton>
                )
              )}
            </>
          )}
        />
        <ProductsList
          products={products}
          getAddressTransferWaveByBaptism={() => this.getAddressTransferWaveByBaptism(true)}
        />
        {(showConfirmNewDocument
          && (
          <SweetAlert
            info
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="info"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE2287' /* Novo Documento */)}
            onConfirm={this.confirmStorageSubmit}
            onCancel={() => this.setState({ showConfirmNewDocument: false })}
          >
            {I18n.t('BEE1785', { 0: baptism } /* Confirma criação de novo documento de saída para a etiqueta? */)}
          </SweetAlert>
          )
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userLogged: state.app.userLogged && state.app.userLogged,
  fractionalQuantity: state.app.permissionsCompany && state.app.permissionsCompany.fractionalQuantity,
});

const mapDispatchToProps = (dispatch) => ({
  getAddressTransferWaveByBaptism: (
    branch,
    baptism,
  ) => dispatch(getAddressTransferWaveByBaptism(branch, baptism, true)),
  getProductStockBalanceByAddresAndEan: (
    ean,
    addressId,
  ) => dispatch(getProductStockBalanceByAddresAndEan(ean, addressId)),
  createAddressTransferWaveProduct: (
    addressTransferWaveProduct,
  ) => dispatch(createAddressTransferWaveProduct(addressTransferWaveProduct)),
  getStorageAddressesOptions: (branchCode) => dispatch(getStorageAddressesOptions(branchCode)),
  getDepositsOptions: () => dispatch(getDepositsOptionsList()),
  getSerialGroupOrSerialLabel: (code, depositCode) => dispatch(getSerialGroupOrSerialLabel(code, depositCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(WaveTransferForm));
