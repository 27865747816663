/* eslint-disable class-methods-use-this */
import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import _ from 'lodash';
import moment from 'moment';

import Table from '../../../components/table/Table';

import StockMovementsHelpers from '../../../helpers/stockMovements';

import { getStockMovements } from '../../../app/store/actions/stocks';

import FormatHelpers from '../../../helpers/format';
import ROUTES from '../../../config/routes';

class StockMovementList extends React.Component {
  constructor(props) {
    super(props);

    this.page = 0;
    this.pageSize = 10;
    this.sorted = [];
    this.filtered = [];

    this.state = {
      startDate: moment().subtract(30, 'd'),
      endDate: moment(),
      pages: 0,
      totalDataLength: 0,
    };

    this.defaultSorted = [
      { id: 'movementDate', desc: true },
      { id: 'id', desc: true },
    ];

    this.tableColumns = [
      {
        Header: I18n.t('BEE200' /* Tipo */),
        accessor: 'type',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => (
          <div style={{ textAlign: 'center' }}>
            <span>{StockMovementsHelpers.movementType(row.value)}</span>
          </div>
        ),
        Filter: ({ onChange }) => (
          <select
            type="text"
            style={{ width: '100%' }}
            onChange={(event) => { onChange(event.target.value); }}
          >
            <option value="">{I18n.t('BEE793' /* Todos */)}</option>
            {StockMovementsHelpers.movementType().map((element, index) => (
              <option key={index} value={element.value}>
                {element.label}
              </option>
            ))}
          </select>
        ),
      }, {
        Header: I18n.t('BEE524' /* Operação */),
        accessor: 'operation',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1378' /* Documento */),
        accessor: 'orderNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE182' /* Depósito */),
        accessor: 'depositName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 120,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE225' /* Produto */),
        accessor: 'productCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE425' /* Batismo */),
        accessor: 'baptismLabel',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE277' /* Descrição */),
        accessor: 'name',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 350,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE441' /* Quantidade */),
        accessor: 'quantity',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 110,
        Cell: (row) => FormatHelpers.formatNumber(row.value ? row.value : 0, 3, false),
        filterable: false,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE384' /* Unidade */),
        accessor: 'unitMeasure',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 90,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE432' /* Endereço */),
        accessor: 'addressCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 150,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE428' /* Lote */),
        accessor: 'lotNumber',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 100,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE1516' /* Agrupador */),
        accessor: 'serialGroup',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE2760' /* Etiqueta Seriada */),
        accessor: 'serialLabel',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 250,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE523' /* Data Movimento */),
        accessor: 'movementDate',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 170,
        filterMethod: (filter, row) => {
          const input = _.lowerCase(filter.value);
          const dateFirstOption = moment(row[filter.id]).format('DD MM YYYY HH:mm:ss');
          const dateSecondOption = moment(row[filter.id]).format('DDMMYYYY HH:mm:ss');
          if (_.includes(dateFirstOption, input) || _.includes(dateSecondOption, input)) {
            return true;
          }
        },
        Cell: (row) => (
          <span>
            {' '}
            {row.value ? moment(row.value).format('L LTS') : ''}
          </span>
        ),
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE522' /* Usuário Movimento */),
        accessor: 'movementUserName',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 170,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE145' /* Filial */),
        accessor: 'branchCode',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 80,
        Filter: this.filterColumn,
      }, {
        Header: I18n.t('BEE135' /* Observação */),
        accessor: 'note',
        style: { alignSelf: 'center', textAlign: 'center' },
        minWidth: 300,
        Filter: this.filterColumn,
      },
    ];
  }

  filterColumn = ({ onChange }) => (
    <input
      type="text"
      style={{ width: '100%' }}
      onKeyPress={(event) => {
        if (event.keyCode === 13 || event.which === 13) {
          onChange(event.target.value);
        }
      }}
      onKeyUp={(event) => {
        if (event.target.value === '' && (event.keyCode === 8 || event.which === 8)) {
          onChange(event.target.value);
        }
      }}
    />
  );

  getStockMovements = async () => {
    const { startDate, endDate } = this.state;
    const index = this.sorted.findIndex((element) => element.id === 'movementDate');
    if (index >= 0) this.sorted.push({ id: 'id', desc: this.sorted[index].desc });

    const data = await this.props.getStockMovements({
      page: this.page,
      pageSize: this.pageSize,
      sorted: this.sorted,
      filtered: this.filtered,
      startDate: moment(startDate).format(),
      endDate: moment(endDate).format(),
    });
    this.setState({ pages: data.pages, totalDataLength: data.totalDataLength });
  };

  handleDateApplyEvent = async (event) => {
    const startDate = event[0];
    const endDate = event[1];

    await this.setState({
      startDate,
      endDate,
    });

    this.getStockMovements();
  };

  render() {
    const { stockMovementsList = [] } = this.props;
    const { pages, totalDataLength } = this.state;

    return (
      <div>
        <div className="d-flex align-items-right">
          <ol className="breadcrumb float-xl-right">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {' '}
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">{I18n.t('BEE33' /* Consultas */)}</li>
            <li className="breadcrumb-item">{I18n.t('BEE32' /* Estoque */)}</li>
            <li className="breadcrumb-item active">{I18n.t('BEE525' /* Movimentos Estoque */)}</li>
          </ol>
        </div>
        <Table
          customRangeMessage={I18n.t('BEE3342', { 0:(I18n.t('BEE3345' /*data do movimento de estoque */)) })}
          downloadCSV
          headerTitle={I18n.t('BEE525' /* Movimentos Estoque */)}
          wikiHelp={ROUTES.STOCK_MOVEMENT_HELP}
          panelHeaderProps={{
            onClickReload: this.getStockMovements,
          }}
          datePicker={{
            handleDateApplyEvent: this.handleDateApplyEvent,
          }}
          filterable
          data={stockMovementsList}
          totalDataLength={totalDataLength}
          columns={this.tableColumns}
          expander
          defaultPageSize={10}
          defaultSorted={this.defaultSorted}
          defaultFilterMethod={(filter, row) => {
            const input = _.lowerCase(filter.value);
            const value = _.lowerCase(row[filter.id]);
            if (_.includes(value, input)) {
              return true;
            }
          }}
          manual
          pages={pages}
          onFetchData={(state) => {
            this.page = state.page;
            this.pageSize = state.pageSize;
            this.sorted = state.sorted;
            this.filtered = state.filtered;
            this.getStockMovements();
          }}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  stockMovementsList: state.stocks && state.stocks.movementsList,
});

const mapDispatchToProps = (dispatch) => ({
  getStockMovements: (tableParams) => dispatch(getStockMovements(tableParams)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(StockMovementList));
