import moment from 'moment';
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';
import {
  Modal, ModalBody, ModalFooter, ModalHeader,
} from 'reactstrap';

import {
  changePackageVolume,
  confirmVolumeCheck,
  finishVolume,
  getProductQuantityVolume,
  restartVolumeCheck,
} from '../../../app/store/actions/outboundOrderVolumes';
import { getUserPrinterOptions } from '../../../app/store/actions/printers';
import { getProductByEan } from '../../../app/store/actions/stocks';
import OneClickButton from '../../../components/form/button';
import Form from '../../../components/form/form';
import addNotification from '../../../components/notification';
import { PanelPage } from '../../../components/pages/pages';
import { Panel, PanelBody } from '../../../components/panel/panel';
import ModalTypePackaging from './modalTypePackaging';
import ProductsList from './productsList';

class OutboundConference extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      baptismLabel: '',
      orderNumber: '',
      customerOrderName: '',
      product: '',
      unitMeasure: '',
      productFullName: '',
      userCheck: '',
      storageAddress: '',
      packageName: '',
      packageCode: '',
      mainPackageCode: '',
      lotNumber: '',
      lines: 0,
      linesRemaining: 0,
      selectedProduct: {},
      openModalPackaging: false,
      isFinishing: false,
      ean: '',
      qtyChecked: 0,
      validEan: false,
      showModalConference: false,
      grossWeightVolume: 0,
      WeightByVolume: 0,
      amountVolume: 1,
      disabledAmountVolume: false,
      amountProductVolume: 0,
      disabledQtyChecked: false,
      conferenceModal: false,
      qtyCheckedLot: 0,
      stockControlType: 0,
      controlExpirationDate: false,
      lotVerification: false,
      expirationDate: '',
      manufacturingDate: '',
      mfgDate: '',
      expDate: '',
      divergenceProduct: false,
      showModalDivergence: false,
      showPrinterDialog: false,
      printerOptions: [],
      printerSelected: null,
      singleVolume: false,
      disableButtons: false,
      multipleVolumes: true,
      showModalMultipleVolumes: false,
      orderType: '',
      transportCodName: '',
      confirmedPackaging: false,
      showModalRestartVolume: false,
    };

    this.disableButtons = false;
  }

  async componentDidMount() {
    await this.selectProduct();
    document.getElementById(1).focus();
    document.getElementById(1).select();
    if (this.state.baptismLabel && this.state.linesRemaining === 0) await this.checkDataShowFinishScreen();
  }

  setValue = (attr, value, clean = false) => {
    if (attr === 'amountVolume') {
      this.setState({
        [`${attr}`]: parseInt(Math.abs(value), 10),
      });
    } else {
      this.setState({
        [`${attr}`]: value,
      });
    }

    if (attr === 'packageName' && value === '') {
      this.setState({
        packageName: {},
        packageCode: '',
      });
    } else if (attr === 'ean' && clean) {
      this.setState({
        validEan: false,
        disabledQtyChecked: false,
        qtyCheckedLot: 0,
        qtyChecked: 0,
      });
    } else if (attr === 'amountVolume' && (parseInt(value, 10) || value === '')) {
      this.setState((prevState) => ({
        amountVolume: parseInt(Math.abs(value), 10),
        WeightByVolume: (prevState.grossWeightVolume / parseInt(Math.abs(value), 10)),
        multipleVolumes: !(prevState.amountProductVolume % value),
      }));
    } else if (attr === 'grossWeightVolume') {
      this.setState((prevState) => ({
        WeightByVolume: (value / prevState.amountVolume),
      }));
    }
  };

  goToElement = (e, id, attr) => {
    if (e.keyCode === 13) {
      document.getElementById(id + 1).focus();
      document.getElementById(id + 1).select();

      if (attr === 'ean') {
        this.getEanRegisterProduct();
      }
    }
  };

  keydownSendConfirmCheck = (e) => {
    if (e.keyCode === 13) this.checkConference();
  };

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
    buttonLabel = '',
    buttonFunction = undefined,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          {buttonLabel && buttonFunction && (
            <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  createInputButton = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
    buttonLabel = '',
    buttonFunction = undefined,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => this.setValue(attr, '', true)}
          >
            <i className="fa fa-times" />
          </button>
          {buttonLabel && buttonFunction && (
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => buttonFunction()}
            >
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  createNumberFormat = (
    value,
    attr,
    label,
    decimals,
    disabled = false,
    keypressFunction = undefined,
    id = null,
    disabledButton = false,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <NumberFormat
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={(!this.props.fractionalQuantity) ? 0 : decimals}
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue);
          }}
          fixedDecimalScale
          defaultValue={0}
          disabled={disabled}
          allowEmptyFormatting
        />
        {(!disabled && !disabledButton) && (
          <div className="input-group-append">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={() => this.setValue(attr, 0)}
            >
              <i className="fa fa-times" />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  createTextArea = (
    value,
    attr,
    label,
    placeholder,
    rows,
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group p-2 text-left">
      <label htmlFor={label}>{label}</label>
      <div className="input-group">
        <textarea
          className="form-control"
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  resetDefaultValue = () => {
    this.setState({
      openModalPackaging: false,
      ean: '',
      qtyChecked: 0,
      validEan: false,
      grossWeightVolume: 0,
      amountVolume: 1,
      disabledAmountVolume: false,
      amountProductVolume: 0,
      WeightByVolume: 0,
      disabledQtyChecked: false,
      conferenceModal: false,
      qtyCheckedLot: 0,
      stockControlType: 0,
      controlExpirationDate: false,
      lotVerification: false,
      expirationDate: '',
      manufacturingDate: '',
      mfgDate: '',
      expDate: '',
      divergenceProduct: false,
      showPrinterDialog: false,
      singleVolume: false,
      disableButtons: false,
      orderType: '',
      transportCodName: '',
    });

    this.disableButtons = false;
  };

  selectProduct = (selectedProduct = {}) => {
    const { outboundOrderCheckProductList = [], outboundVolumeOpen = null, mainPackage } = this.props;

    const linesRemaining = outboundOrderCheckProductList.reduce((acumulador, element) => {
      if (element.status < 5) return acumulador + 1;
      return acumulador;
    }, 0);

    this.setState({
      divergenceProduct: false,
    });

    if (!this.state.mainPackageCode && mainPackage) {
      this.setState({
        mainPackageCode: mainPackage.code,
        packageCode: mainPackage.code,
        packageName: `${mainPackage.code} - ${mainPackage.name}`,
      });
    }

    if (outboundVolumeOpen) {
      this.setState({
        packageCode: outboundVolumeOpen.packageCode,
        packageName: outboundVolumeOpen.package
          ? `${outboundVolumeOpen.package.code} - ${outboundVolumeOpen.package.name}`
          : '',
        outboundVolumeOpen,
        orderNumber: outboundVolumeOpen.orderNumber,
        customerOrderName: outboundVolumeOpen.customerOrderName,
        customerOrderCode: outboundVolumeOpen.customerOrderCode,
        baptismLabel: outboundVolumeOpen.baptismLabel,
        singleVolume: outboundVolumeOpen.singleVolume,
      });
    }

    if (!Object.values(selectedProduct).length) {
      let alreadySelected = false;

      outboundOrderCheckProductList.forEach(async (element) => {
        if (element.status === 4 && !alreadySelected) {
          alreadySelected = true;
          this.setState({
            baptismLabel: element.baptismLabel,
            orderNumber: element.orderNumber,
            customerOrderCode: element.customerOrderCode,
            customerOrderName: element.customerOrderName,
            product: `${element.productCode} - ${element.productname}`,
            unitMeasure: element.unitMeasure,
            productFullName: element.productFullName,
            userCheck: element.userCheck,
            storageAddress: element.storageAddress,
            lotNumber: element.lotNumber,
            lines: outboundOrderCheckProductList.length,
            manufacturingDate: (element.manufacturingDate) ? moment(element.manufacturingDate).format('L') : '',
            expirationDate: (element.expirationDate) ? moment(element.expirationDate).format('L') : '',
            selectedProduct: element,
            orderType: element.orderType,
            transportCodName: element.transportCodName,
          });
        }
      });
      this.setState({ linesRemaining });
    } else {
      this.setState({
        baptismLabel: selectedProduct.baptismLabel,
        orderNumber: selectedProduct.orderNumber,
        customerOrderName: selectedProduct.customerOrderName,
        customerOrderCode: selectedProduct.customerOrderCode,
        product: `${selectedProduct.productCode} - ${selectedProduct.productname}`,
        unitMeasure: selectedProduct.unitMeasure,
        productFullName: selectedProduct.productFullName,
        userCheck: selectedProduct.userCheck,
        storageAddress: selectedProduct.storageAddress,
        orderType: selectedProduct.orderType,
        transportCodName: selectedProduct.transportCodName,
        lotNumber: selectedProduct.lotNumber,
        lines: outboundOrderCheckProductList.length,
        manufacturingDate: (selectedProduct.manufacturingDate)
          ? moment(selectedProduct.manufacturingDate).format('L')
          : '',
        expirationDate: (selectedProduct.expirationDate) ? moment(selectedProduct.expirationDate).format('L') : '',
        linesRemaining,
        selectedProduct,
      });
    }
  };

  openModalPackaging = (confirmedPackaging = false) => {
    this.setState({
      openModalPackaging: true,
      confirmedPackaging,
    });
  };

  openModalRestartVolume = (open = false) => {
    this.setState({
      showModalRestartVolume: open,
    });
  };

  restartVolumeCheck = async () => {
    const { outboundVolumeOpen } = this.state;

    if (outboundVolumeOpen) {
      try {
        const { data } = await this.props.restartVolumeCheck(outboundVolumeOpen.id);

        if (!data || !data.success) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1879' /* erro ao localizar o volume em aberto */),
            'top-right',
          );
        } else {
          await this.props.searchConferenceProducts();
          await this.resetDefaultValue();
          await this.selectProduct();
          this.setState({ showModalRestartVolume: false, outboundVolumeOpen: null });
          document.getElementById(1).focus();

          addNotification(
            'success',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE3367' /* O volume foi reiniciado! */),
            'top-right',
          );
        }
      } catch (error) {
        const messageError = error.response && error.response.data
          && error.response.data.error && error.response.data.error.message;

        if (messageError) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1879' /* erro ao localizar o volume em aberto */),
            'top-right',
          );
        }
      }
    } else {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1878' /* Nenhum volume em aberto para este documento */),
        'top-right',
      );
    }
  };

  selectPackaging = async (selectPackaging) => {
    const {
      outboundVolumeOpen, ean, validEan, qtyChecked,
      packageCode,
    } = this.state;
    if (!ean || !validEan || !qtyChecked) {
      setTimeout(() => {
        const id = (!ean || !validEan) ? 1 : 2;
        document.getElementById(id).focus();
        document.getElementById(id).select();
      }, 500);
    }
    // Trocar Embalagem
    if (outboundVolumeOpen && outboundVolumeOpen.code !== selectPackaging.code) {
      try {
        const result = await this.props.changePackageVolume(outboundVolumeOpen.id, selectPackaging.code);

        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            'Erro ao efetuar a Troca da Embalagem!',
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE37' /* Conferência */),
            'Embalagem alterada com sucesso!',
            'top-right',
          );
          this.setState({
            packageName: `${selectPackaging.code} - ${selectPackaging.name}`,
            packageCode: selectPackaging.code,
            openModalPackaging: false,
          });
        }
      } catch (error) {
        const messageError = error.response && error.response.data
          && error.response.data.error && error.response.data.error.message;

        if (messageError) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            'Erro ao efetuar a Troca da Embalagem!',
            'top-right',
          );
        }
      }
    } else if (packageCode !== selectPackaging.code) {
      this.setState({
        packageName: `${selectPackaging.code} - ${selectPackaging.name}`,
        packageCode: selectPackaging.code,
        openModalPackaging: false,
      });
      if (this.state.confirmedPackaging) this.checkConference();
    } else {
      this.setState({ openModalPackaging: false });
      if (this.state.confirmedPackaging) this.checkConference();
    }
  };

  getEanRegisterProduct = async () => {
    const {
      ean, selectedProduct, singleVolume = false, orderType,
    } = this.state;
    const { outboundOrderCheckProductList = [] } = this.props;

    if (singleVolume) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE2104' /* E necessário finalizar esse volume para continuar */),
        'top-right',
      );
    } else {
      try {
        const result = await this.props.getProductByEan(ean, orderType);

        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1470' /* EAN Não Localizado! */),
            'top-right',
          );
          document.getElementById(1).focus();
          document.getElementById(1).select();
        } else if (!result) {
          document.getElementById(1).focus();
          document.getElementById(1).select();
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1470' /* EAN Não Localizado! */),
            'top-right',
          );
        } else {
          const filteredProduct = outboundOrderCheckProductList.find((prod) => {
            if (selectedProduct) {
              return prod.productCode === result.productCode
                && prod.outboundRangeProductId === selectedProduct.outboundRangeProductId;
            }
            return prod.productCode === result.productCode;
          });

          if (filteredProduct && filteredProduct.pendingQuantity > 0) {
            this.selectProduct(filteredProduct);
            this.setState({
              validEan: true,
            });
            if (result.product && (result.product.stockControlType === 3 || result.product.controlExpirationDate)) {
              if (result.product.controlExpirationDate) {
                this.setState((prevState) => ({
                  mfgDate: prevState.manufacturingDate,
                  expDate: prevState.expirationDate,
                }));
              }
              this.setState({
                disabledQtyChecked: true,
                conferenceModal: true,
                stockControlType: result.product.stockControlType,
                controlExpirationDate: result.product.controlExpirationDate,
              });
            }
          } else if (filteredProduct && filteredProduct.pendingQuantity === 0) {
            document.getElementById(1).focus();
            document.getElementById(1).select();
            addNotification(
              'danger',
              I18n.t('BEE37' /* Conferência */),
              I18n.t('BEE3298' /* O produto já foi totalmente conferido! */),
              'top-right',
            );
          } else {
            document.getElementById(1).focus();
            document.getElementById(1).select();
            addNotification(
              'danger',
              I18n.t('BEE37' /* Conferência */),
              I18n.t('BEE1867' /* EAN não corresponde para os produtos */),
              'top-right',
            );
          }
        }
      } catch (error) {
        const messageError = error.response && error.response.data
          && error.response.data.error && error.response.data.error.message;

        if (messageError) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1470' /* EAN Não Localizado! */),
            'top-right',
          );
        }
      }
    }
  };

  confirmDivergence = (divergenceProduct) => {
    this.setState({
      showModalDivergence: false,
      divergenceProduct,
    });
    this.checkConferenceSubmit();
  };

  checkConference = async () => {
    const {
      ean, qtyChecked, selectedProduct, packageName, validEan,
      stockControlType, controlExpirationDate, lotVerification,
      packageCode,
    } = this.state;
    this.setState({ confirmedPackaging: false });

    if (!lotVerification && (stockControlType === 3 || controlExpirationDate)) {
      if (controlExpirationDate) {
        this.setState((prevState) => ({
          mfgDate: prevState.manufacturingDate,
          expDate: prevState.expirationDate,
        }));
      }
      this.setState({
        disabledQtyChecked: true,
        conferenceModal: true,
      });
    } else if (!packageName && !packageCode) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1863' /* É necessário selecionar uma embalagem */),
        'top-right',
      );
      this.openModalPackaging();
    } else if (!ean) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1556' /* Campo (EAN) deve ser informado. */),
        'top-right',
      );
      document.getElementById(1).focus();
      document.getElementById(1).select();
    } else if (!validEan) {
      await this.getEanRegisterProduct();
    } else if (!qtyChecked) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1219' /* Quantidade conferida não informado */),
        'top-right',
      );
      document.getElementById(2).focus();
      document.getElementById(2).select();
    } else if (qtyChecked > selectedProduct.pendingQuantity) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1880' /* Quantidade conferida superior a Quantidade disponível */),
        'top-right',
      );
      document.getElementById(2).focus();
      document.getElementById(2).select();
    } else if (qtyChecked < selectedProduct.pendingQuantity) {
      this.setState({
        showModalDivergence: true,
      });
    } else {
      this.checkConferenceSubmit();
    }
  };

  checkConferenceSubmit = async () => {
    if (this.disableButtons) {
      return;
    }

    this.disableButtons = true;
    this.setState({ disableButtons: true });

    const {
      qtyChecked, baptismLabel, selectedProduct, packageCode, divergenceProduct,
    } = this.state;

    try {
      const confirmVolumeCheckReturn = await this.props.confirmVolumeCheck(
        selectedProduct.outboundRangeProductId,
        qtyChecked,
        packageCode,
        baptismLabel,
        divergenceProduct,
      );

      if (confirmVolumeCheckReturn && confirmVolumeCheckReturn.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE1872' /* Não foi possível fazer a conferência */),
          'top-right',
        );
      } else {
        addNotification(
          'success',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE1873' /* Conferência efetuada */),
          'top-right',
        );
        await this.props.searchConferenceProducts();
        await this.resetDefaultValue();
        await this.selectProduct();

        if (this.state.linesRemaining === 0) {
          await this.checkDataShowFinishScreen();
        }
      }

      this.setState({ disableButtons: false }, () => { this.disableButtons = false; });
    } catch (error) {
      this.setState({ disableButtons: false }, () => { this.disableButtons = false; });
      const messageError = error.response && error.response.data
        && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE1852' /* Não foi possível finalizar esta ação */),
          'top-right',
        );
      }
    }
  };

  finishVolumeSubmit = async () => {
    const {
      outboundVolumeOpen, amountVolume, grossWeightVolume, linesRemaining, printerSelected, printerOptions,
    } = this.state;
    this.setState({ showPrinterDialog: false });

    try {
      const selected = (printerSelected === null) ? printerOptions[0].value : printerSelected;

      const finishVolumeReturn = await this.props.finishVolume(
        outboundVolumeOpen.id,
        amountVolume,
        grossWeightVolume,
        selected,
      );

      if (finishVolumeReturn && finishVolumeReturn.success === false) {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE1871' /* Não foi possível finalizar a conferência */),
          'top-right',
        );
      } else {
        this.setState({
          packageName: '',
          packageCode: '',
          outboundVolumeOpen: null,
        });

        addNotification(
          'success',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE1874' /* Conferência Finalizada! */),
          'top-right',
        );
        addNotification(
          'success',
          I18n.t('BEE1922' /* Etiqueta Volume */),
          I18n.t('BEE1427' /* Etiquetas enviadas para impressora ! */),
          'top-right',
        );
        await this.resetDefaultValue();
        // await this.selectProduct();
        if (!linesRemaining) {
          await this.props.resetForm();
        } else {
          await this.props.searchConferenceProducts();
        }
      }
    } catch (error) {
      const messageError = error.response && error.response.data
        && error.response.data.error && error.response.data.error.message;

      if (messageError) {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          I18n.t('BEE37' /* Conferência */),
          I18n.t('BEE1852' /* Não foi possível finalizar esta ação */),
          'top-right',
        );
      }
    }
  };

  checkDataShowFinishScreen = async () => {
    const { outboundVolumeOpen } = this.state;

    if (outboundVolumeOpen) {
      try {
        const result = await this.props.getProductQuantityVolume(outboundVolumeOpen.id);

        if (!result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1879' /* erro ao localizar o volume em aberto */),
            'top-right',
          );
        } else if (Object.keys(result).length) {
          const disabledAmountVolume = !!result.differentProducts;

          this.setState({
            grossWeightVolume: result.grossWeight,
            amountProductVolume: result.productQuantity,
            WeightByVolume: result.grossWeight,
            amountVolume: 1,
            disabledAmountVolume,
            isFinishing: true,
          });

          this.openModalPackaging();
        }
      } catch (error) {
        const messageError = error.response && error.response.data
          && error.response.data.error && error.response.data.error.message;

        if (messageError) {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            I18n.t('BEE37' /* Conferência */),
            I18n.t('BEE1879' /* erro ao localizar o volume em aberto */),
            'top-right',
          );
        }
      }
    } else {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1878' /* Nenhum volume em aberto para este documento */),
        'top-right',
      );
    }
  };

  keypressFunctionConfirmConferenceModal = (e) => {
    if (e.keyCode === 13) this.confirmConferenceModal();
  };

  confirmConferenceModal = () => {
    const { selectedProduct, qtyCheckedLot } = this.state;

    if (!qtyCheckedLot) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1219' /* Quantidade conferida não informado */),
        'top-right',
      );
    } else if (qtyCheckedLot > selectedProduct.pendingQuantity) {
      addNotification(
        'danger',
        I18n.t('BEE37' /* Conferência */),
        I18n.t('BEE1880' /* Quantidade conferida superior a Quantidade disponível */),
        'top-right',
      );
    } else {
      this.setState({
        qtyChecked: qtyCheckedLot,
        conferenceModal: false,
        lotVerification: true,
      });
    }
  };

  checkPrinters = async () => {
    const { printerOptions, printerSelected } = this.state;
    if (printerOptions.length === 0 && printerSelected === null) {
      addNotification(
        'danger',
        I18n.t('BEE19' /* Docas */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrinterDialog: true,
      });
    } else {
      this.finishVolumeSubmit();
    }
  };

  multipleValidation = async () => {
    if (this.state.multipleVolumes) {
      this.setState({ showModalConference: false });
      await this.getUserPrinterOptions();
      await this.checkPrinters();
    } else {
      this.setState({ showModalConference: false, showModalMultipleVolumes: true });
    }
  };

  getUserPrinterOptions = async () => {
    const Info = await this.props.getUserPrinterOptions();
    const { userPrinter, printerOptions } = Info;
    const { printerSelected } = this.state;

    let printerChosen;
    if (printerSelected === null) printerChosen = userPrinter;
    else if (printerSelected !== userPrinter) printerChosen = printerSelected;
    else printerChosen = userPrinter;

    this.setState({
      printerOptions,
      printerSelected: printerChosen,
    });
  };

  render() {
    const {
      baptismLabel, orderNumber, customerOrderName, customerOrderCode, lines, linesRemaining, ean,
      qtyChecked, product, unitMeasure, productFullName, userCheck, storageAddress, showModalRestartVolume,
      lotNumber, packageName, openModalPackaging, validEan, showModalConference, grossWeightVolume,
      showModalMultipleVolumes, amountVolume, disabledAmountVolume, outboundVolumeOpen, conferenceModal,
      disabledQtyChecked, qtyCheckedLot, mfgDate, expDate, WeightByVolume, showModalDivergence, showPrinterDialog,
      printerSelected, printerOptions, orderType, transportCodName, isFinishing,
    } = this.state;

    const { disableButtons } = this.state;

    const { outboundOrderCheckProductList = [] } = this.props;

    return (
      <>
        <PanelPage
          noButton
          content={(
            <>
              <div className="row">
                <div className="col-md-4">
                  {this.createInput(
                    packageName,
                    'packageName',
                    I18n.t('BEE497' /* Embalagem */),
                    '',
                    'text',
                    false,
                    true,
                    undefined,
                    null,
                    !outboundVolumeOpen ? <i className="fa fa-search" /> : null,
                    !outboundVolumeOpen ? this.openModalPackaging : null,
                  )}
                </div>
                <div className="col-md-2">
                  {this.createInput(
                    orderNumber,
                    'orderNumber',
                    'Documento Saída',
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-2">
                  {this.createInput(
                    customerOrderCode,
                    'customerOrderCode',
                    'Pedido Cliente',
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-4">
                  {this.createInput(
                    baptismLabel,
                    'baptismLabel',
                    I18n.t('BEE425' /* Batismo */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {this.createInput(
                    customerOrderName,
                    'customerOrderName',
                    'Cliente',
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createInput(
                    lines,
                    'lines',
                    'Total de Linhas',
                    '',
                    'number',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createInput(
                    linesRemaining,
                    'linesRemaining',
                    I18n.t('BEE1090' /* Linhas pendentes */),
                    '',
                    'number',
                    false,
                    true,
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {this.createInputButton(
                    ean,
                    'ean',
                    I18n.t('BEE377' /* EAN */),
                    '',
                    'text',
                    false,
                    validEan,
                    this.goToElement,
                    1,
                    '',
                    undefined,
                  )}
                </div>
                <div className="col-md-6">
                  {this.createNumberFormat(
                    qtyChecked,
                    'qtyChecked',
                    I18n.t('BEE422' /* Quantidade Conferida */),
                    3,
                    disabledQtyChecked,
                    this.keydownSendConfirmCheck,
                    2,
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {this.createInput(
                    product,
                    'product',
                    I18n.t('BEE225' /* Produto */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createInput(
                    unitMeasure,
                    'unitMeasure',
                    I18n.t('BEE1756' /* Unidade de Medida */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createInput(lotNumber, 'lotNumber', I18n.t('BEE428' /* Lote */), '', 'text', false, true)}
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  {this.createTextArea(
                    productFullName,
                    'productFullName',
                    I18n.t('BEE277' /* Descrição */),
                    '',
                    3,
                    false,
                    true,
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  {this.createInput(userCheck, 'userCheck', I18n.t('BEE1092' /* Separador */), '', 'text', false, true)}
                </div>
                <div className="col-md-6">
                  {this.createInput(
                    storageAddress,
                    'storageAddress',
                    I18n.t('BEE495' /* Endereço Retirada */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
              </div>

              <div className="row">
                <div className="col-md-5">
                  {this.createInput(
                    transportCodName,
                    'transportCodName',
                    I18n.t('BEE301' /* Transportadora */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-1">
                  {this.createInput(
                    orderType,
                    'orderType',
                    I18n.t('BEE1129' /* Tipo de Pedido */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
              </div>

              <ModalTypePackaging
                isFinishing={isFinishing}
                openModalPackaging={openModalPackaging}
                closeModalPackaging={() => this.setState({
                  openModalPackaging: false,
                  confirmedPackaging: false,
                  isFinishing: false,
                })}
                checkDataShowFinishScreen={() => this.setState({ showModalConference: true, isFinishing: false })}
                selectPackaging={(selectPackaging) => this.selectPackaging(selectPackaging)}
                mainPackageCode={this.state.mainPackageCode}
                packageCode={this.state.packageCode}
              />
            </>
          )}
          footerContent={(
            <>
              {outboundVolumeOpen && (
                <button
                  type="button"
                  className="btn btn-blue p-5 m-5"
                  onClick={() => this.openModalRestartVolume(true)}
                >
                  {I18n.t('BEE3366' /* Reiniciar conferência */)}
                </button>
              )}
              {outboundVolumeOpen && (
                <button
                  type="button"
                  className="btn btn-blue p-5 m-5"
                  onClick={() => this.openModalPackaging()}
                >
                  {I18n.t('BEE3365' /* Trocar embalagem */)}
                </button>
              )}
              <button
                type="button"
                className="btn btn-120 btn-white p-5 m-5"
                onClick={() => this.props.resetForm()}
              >
                {I18n.t('BEE137' /* Voltar */)}
              </button>
              {validEan && (
                <button
                  type="button"
                  disabled={disableButtons}
                  className="btn btn-120 btn-primary p-5 m-5"
                  onClick={() => this.checkConference()}
                >
                  {I18n.t('BEE100' /* Confirmar */)}
                </button>
              )}
              {outboundVolumeOpen && !validEan && (
                <button
                  type="button"
                  className="btn btn-120 btn-success p-5 m-5"
                  disabled={disableButtons}
                  onClick={() => this.checkDataShowFinishScreen()}
                >
                  {I18n.t('BEE1567' /* Finalizar */)}
                </button>
              )}
            </>
          )}
        />
        <Panel>
          <PanelBody>
            <ProductsList
              outboundOrderCheckProductList={outboundOrderCheckProductList}
              getSelectedProduct={(selectedProduct) => {
                this.resetDefaultValue();
                this.selectProduct(selectedProduct);
              }}
            />
          </PanelBody>
        </Panel>
        {(showModalConference
          && (
            <SweetAlert
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1567' /* Finalizar */)}
              onConfirm={this.multipleValidation}
              onCancel={() => this.setState({ showModalConference: false })}
            >
              {this.createInput(
                amountVolume,
                'amountVolume',
                I18n.t('BEE405' /* Volumes */),
                '',
                'number',
                true,
                disabledAmountVolume,
              )}
              {this.createNumberFormat(
                grossWeightVolume,
                'grossWeightVolume',
                `${I18n.t('BEE564' /* Peso Bruto */)} (${I18n.t('BEE2327' /* G */)})`,
                0,
                false,
                undefined,
                0,
                true,
              )}
              {this.createNumberFormat(
                WeightByVolume,
                'WeightByVolume',
                `${I18n.t('BEE2016' /* Peso Por Volume */)} (${I18n.t('BEE2327' /* G */)})`,
                0,
                true,
              )}
            </SweetAlert>
          )
        )}
        {(showModalMultipleVolumes
          && (
            <SweetAlert
              warning
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE2316' /* Volumes não múltiplos */)}
              onConfirm={async () => {
                this.setState({
                  multipleVolumes: true,
                  showModalMultipleVolumes: false,
                });
                await this.getUserPrinterOptions();
                await this.checkPrinters();
              }}
              onCancel={() => this.setState({
                multipleVolumes: true,
                showModalMultipleVolumes: false,
              })}
            >
              {I18n.t('BEE2317' /* A quantidade de volumes informada não e múltiplo */)}
            </SweetAlert>
          )
        )}
        {(showModalDivergence
          && (
            <SweetAlert
              warning
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE2373' /* Quantidade Parcial */)}
              onConfirm={() => this.confirmDivergence(false)}
              onCancel={() => this.setState({ showModalDivergence: false })}
            >
              {I18n.t(
                'BEE2374',
                /* A quantidade conferida é menor que a quantidade separada! Prosseguir com a conferência parcial? */
              )}
            </SweetAlert>
          )
        )}
        {(showPrinterDialog && (
          <SweetAlert
            confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
            confirmBtnBsStyle="primary"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE1324' /* Selecionar Impressora */)}
            onConfirm={this.finishVolumeSubmit}
            onCancel={() => this.setState({ showPrinterDialog: false })}
          >
            <div className="mt-4">
              <Form
                noPanel
                setValue={this.setValue}
                inputs={(formContext) => ([
                  formContext.createSelect(
                    printerSelected,
                    'printerSelected',
                    I18n.t('BEE328' /* Impressora */),
                    printerOptions,
                    '',
                    '',
                    12,
                    true,
                  ),
                ])}
              />
            </div>
          </SweetAlert>
        ))}
        {(showModalRestartVolume
          && (
          <SweetAlert
            danger
            showCancel
            cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
            confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="default"
            title={I18n.t('BEE101' /* Você tem certeza? */)}
            onConfirm={() => this.restartVolumeCheck()}
            onCancel={() => this.openModalRestartVolume(false)}
          >
            {I18n.t('BEE3368' /* Deseja reiniciar a conferência do volume? */)}
          </SweetAlert>
          )
        )}
        <Modal
          size="lg"
          isOpen={conferenceModal}
          toggle={() => this.setState({ conferenceModal: false, qtyCheckedLot: 0 })}
        >
          <ModalHeader
            toggle={() => this.setState({ conferenceModal: false, qtyCheckedLot: 0, disabledQtyChecked: false })}
          >
            {I18n.t('BEE491' /* Conferência de Lote */)}
          </ModalHeader>
          <ModalBody>
            <div className="row col">
              <p>
                {I18n.t('BEE225' /* Produto */)}
                :
                {' '}
                <strong>{product}</strong>
              </p>
            </div>
            <div className="row align-items-end">
              <div className="col-md-3">
                {this.createInput(lotNumber, 'lotNumber', I18n.t('BEE428' /* Lote */), '', 'text', false, true)}
              </div>
              <div className="col-md-3">
                {this.createInput(
                  mfgDate,
                  'mfgDate',
                  I18n.t('BEE488' /* Data de Fabricação */),
                  '',
                  'text',
                  false,
                  true,
                )}
              </div>
              <div className="col-md-3">
                {this.createInput(expDate, 'expDate', I18n.t('BEE434' /* Data de Validade */), '', 'text', false, true)}
              </div>
              <div className="col-md-3">
                {this.createNumberFormat(
                  qtyCheckedLot,
                  'qtyCheckedLot',
                  I18n.t('BEE422' /* Quantidade Conferida */),
                  3,
                  false,
                  this.keypressFunctionConfirmConferenceModal,
                  0,
                  true,
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <OneClickButton
              type="button"
              disabled={disableButtons}
              className="btn btn-120 btn-primary p-5 m-5"
              onClick={() => this.confirmConferenceModal()}
            >
              {I18n.t('BEE100' /* Confirmar */)}
            </OneClickButton>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fractionalQuantity: state.app.permissionsCompany && state.app.permissionsCompany.fractionalQuantity,
});

const mapDispatchToProps = (dispatch) => ({
  getProductByEan: (ean, orderType) => dispatch(getProductByEan(ean, orderType)),
  getProductQuantityVolume: (outboundVolumeId) => dispatch(getProductQuantityVolume(outboundVolumeId)),
  confirmVolumeCheck: (outboundRangeProductId, quantity, packageCode, baptismLabel, divergenceProduct) => dispatch(
    confirmVolumeCheck(outboundRangeProductId, quantity, packageCode, baptismLabel, divergenceProduct),
  ),
  finishVolume: (outboundVolumeId, quantityVolume, grossWeight, printerCode) => dispatch(
    finishVolume(outboundVolumeId, quantityVolume, grossWeight, printerCode),
  ),
  changePackageVolume: (outboundVolumeId, packageCode) => dispatch(changePackageVolume(outboundVolumeId, packageCode)),
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  restartVolumeCheck: (outboundVolumeId) => dispatch(restartVolumeCheck(outboundVolumeId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(OutboundConference));
