/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
import { isInteger } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';
import { withRouter } from 'react-router-dom';

import SweetAlert from 'react-bootstrap-sweetalert';
import { generateBaptism, getBaptismInfo } from '../../../../app/store/actions/baptism';
import { printBaptismLabel } from '../../../../app/store/actions/labels';
import { getUserPrinterOptions } from '../../../../app/store/actions/printers';
import OneClickButton from '../../../../components/form/button';
import Form from '../../../../components/form/form';
import addNotification from '../../../../components/notification';
import {
  Panel, PanelBody, PanelFooter, PanelHeader,
} from '../../../../components/panel/panel';
import WikiHelp from '../../../../components/tooltip/wikiHelp';
import ROUTES from '../../../../config/routes';
import FormatHelpers from '../../../../helpers/format';

class BaptismForm extends React.Component {
  constructor(props) {
    super(props);

    const listType = [
      { value: 1, label: I18n.t('BEE201' /* Entrada */) },
      { value: 2, label: I18n.t('BEE202' /* Saída */) },
      { value: 3, label: 'Transferência' },
    ];

    this.state = {
      branchCode: '',
      branchName: '',
      type: null,
      typeName: '',
      lastBaptism: 0,
      quantity: 1,
      listType,
      showPrintDialog: false,
      printStart: 0,
      printEnd: 0,
      showPrinterDialog: false,
      printerOptions: [],
      printerSelected: null,
      showPrintConfirmation: false,
      reprint: false,
      branchLabel: '',
    };
  }

  async componentDidMount() {
    const printerReq = await this.props.getUserPrinterOptions();
    const { printerOptions, userPrinter } = printerReq;
    this.setState({
      printerOptions,
      printerSelected: userPrinter || '',
    });
    await this.getBaptismInfo();
  }

  setValue = async (attr, value) => {
    if (attr === 'quantity') {
      await this.setState({
        [`${attr}`]: FormatHelpers.formatNumber(value, 0, false, true),
      });
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }
  };

  createInput = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div className="form-group row m-b-15" style={{ height: 40 }}>
      <label className="col-form-label col-md-4">{label}</label>
      <div className="col-md-5">
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          min={1}
        />
      </div>
    </div>
  );

  createInputPrint = (value, attr, label, placeholder, type = 'text', required, disabled) => (
    <div
      className="form-group row m-b-15"
      style={{
        display: 'block',
        textAlign: 'center',
        width: '25rem',
      }}
    >
      <label className="col-form-label col-md-4" style={{ display: 'inline' }}>{label}</label>
      <div
        className="col-md-10"
        style={{
          marginTop: '1rem',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <input
          type={type}
          className="form-control m-b-5"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          min={1}
          style={{ textAlign: 'center', width: '20rem' }}
        />
      </div>
    </div>
  );

  createSelect = (value, attr, label, items) => (
    <div className="form-group p-2">
      <label>{label}</label>
      <select
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  getBaptismInfo = async () => {
    const { type } = this.props;
    const typeNameObj = this.state.listType.find((t) => t.value === type);
    const baptismInfo = await this.props.getBaptismInfo(type);

    this.setState({
      type,
      typeName: typeNameObj.label,
      branchCode: (baptismInfo.branchCode) ? baptismInfo.branchCode : '',
      branchName: (baptismInfo.branchName) ? baptismInfo.branchName : '',
      lastBaptism: (baptismInfo.lastBaptism) ? baptismInfo.lastBaptism : '',
      branchLabel: (baptismInfo.branchCode && baptismInfo.branchName)
        ? `${baptismInfo.branchCode} - ${baptismInfo.branchName}` : '',
    });
    if (!baptismInfo.branchCode) this.callNotification();
  };

  callNotification = () => {
    addNotification(
      'danger',
      I18n.t('BEE1255' /* Gerar Batismo */),
      I18n.t('BEE1448' /* Usuário não possui filial relacionada */),
      'top-right',
    );
  };

  showPrinterDialog = async (reprint) => {
    const { printerOptions, printStart, lastBaptism } = this.state;
    if (!printerOptions || printerOptions.length === 0) {
      addNotification(
        'danger',
        I18n.t('BEE1255' /* Gerar Batismo */),
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (reprint && printStart === 0) {
      addNotification(
        'danger',
        I18n.t('BEE1255' /* Gerar Batismo */),
        I18n.t('BEE2495', { 0: lastBaptism }/* Batismo inválido! Tente novamente inserindo um valor entre 1 e %{0}. */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrintConfirmation: false,
        showPrinterDialog: true,
        reprint,
      });
    } else if (printerOptions && printerOptions.length === 1) {
      await this.setState({
        printerSelected: printerOptions[0].value,
        reprint,
        showPrintConfirmation: false,
        showPrintDialog: false,
      });
      if (reprint) {
        this.printBaptism();
      } else {
        this.generateBaptism();
      }
    }
  };

  generateBaptism = async () => {
    const { branchCode, type, quantity } = this.state;
    this.setState({ showPrinterDialog: false });
    try {
      if (!isInteger(parseFloat(quantity)) || quantity < 1) {
        addNotification(
          'danger',
          I18n.t('BEE1255' /* Gerar Batismo */),
          I18n.t('BEE1381' /* A quantidade de etiquetas a gerar deve ser de, no mínimo, um! */),
          'top-right',
        );
      } else {
        const result = await this.props.generateBaptism(type, branchCode, parseInt(quantity, 10));
        this.setState({
          printStart: result[0].code,
          printEnd: result[result.length - 1].code,
          lastBaptism: result[result.length - 1].code,
        });
        this.printBaptism();
      }
    } catch (error) {
      addNotification(
        'danger',
        I18n.t('BEE1255' /* Gerar Batismo */),
        I18n.t('BEE1268' /* Erro ao gerar batismo! */),
        'top-right',
      );
    }
  };

  printBaptism = async () => {
    const {
      printStart, printEnd, printerSelected, printerOptions, branchCode, type, lastBaptism, reprint,
    } = this.state;
    this.setState({
      reprint: false, showPrinterDialog: false, printStart: 0, printEnd: 0, quantity: 1,
    });
    if (printStart !== 0 && printStart >= 0 && printStart <= lastBaptism) {
      try {
        const selected = (!printerSelected) ? printerOptions[0].value : printerSelected;

        const result = await this.props.printBaptismLabel(
          selected,
          printStart,
          reprint ? printStart : printEnd,
          branchCode,
          type,
        );
        if (result && result.success === false) {
          addNotification(
            'danger',
            I18n.t('BEE1255' /* Gerar Batismo */),
            I18n.t('BEE1441' /* Erro ao tentar imprimir */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            I18n.t('BEE1255' /* Gerar Batismo */),
            I18n.t('BEE1427' /* Etiquetas enviadas para impressora ! */),
            'top-right',
          );
        }
      } catch (err) {
        const error = err.response && err.response.data && err.response.data.error;

        if (error && error.message) {
          const messageError = (error.details)
            ? `${error.code} - ${error.details} | ${error.message}`
            : error.message;
          addNotification('danger', I18n.t('BEE1255' /* Gerar Batismo */), messageError, 'top-right');
        } else {
          addNotification(
            'danger',
            I18n.t('BEE1255' /* Gerar Batismo */),
            I18n.t('BEE1441' /* Erro ao tentar imprimir */),
            'top-right',
          );
        }
      }
    } else {
      addNotification(
        'danger',
        I18n.t('BEE1255' /* Gerar Batismo */),
        I18n.t('BEE1475' /* Etiqueta Batismo inválida! */),
        'top-right',
      );
    }
  };

  printConfirmation = () => {
    const { quantity } = this.state;
    if (!parseInt(quantity, 10)) {
      addNotification(
        'danger',
        I18n.t('BEE1372' /* Etiqueta EAN */),
        I18n.t('BEE2293' /* A quantidade tem que ser maior que 0 */),
        'top-right',
      );
    } else if (parseInt(quantity, 10) === 1) this.showPrinterDialog(false);
    else this.setState({ showPrintConfirmation: true });
  };

  render() {
    const {
      type, typeName, branchCode, branchName, lastBaptism, quantity, showPrintDialog,
      printStart, showPrinterDialog, reprint, branchLabel,
      printerOptions, printerSelected, showPrintConfirmation,
    } = this.state;

    return (
      <div className="slideUpTransition">
        <div className="d-flex align-items-center">
          <ol className="breadcrumb float-xl-left">
            <li className="breadcrumb-item">
              <i className="fas fa-home fa-fw m-t-10 m-r-5" />
              {I18n.t('BEE12' /* Início */)}
            </li>
            <li className="breadcrumb-item">
              {I18n.t('BEE1400' /* Movimentação */)}
            </li>
            <li className="breadcrumb-item">
              {I18n.t('BEE1371' /* Etiquetas */)}
            </li>
            <li className="breadcrumb-item active">
              {type === 1
                ? I18n.t('BEE1403' /* Etiqueta Batismo Recebimento */)
                : type === 2
                  ? I18n.t('BEE1404' /* Etiqueta Batismo Expedição */)
                  : I18n.t('BEE2572' /* Etiqueta Batismo Transferência */)}
            </li>
          </ol>
        </div>
        <div className="d-flex align-items-center mb-md-3 mb-2">
          <h1 className="page-header mb-0">
            {type === 1
              ? I18n.t('BEE1403' /* Etiqueta Batismo Recebimento */)
              : type === 2
                ? I18n.t('BEE1404' /* Etiqueta Batismo Expedição */)
                : I18n.t('BEE2572' /* Etiqueta Batismo Transferência */)}
          </h1>
          {type === 1
            ? <WikiHelp wikiPath={ROUTES.INBOUND_BAPTISM_HELP} />
            : type === 2
              ? <WikiHelp wikiPath={ROUTES.OUTBOUND_BAPTISM_HELP} />
              : <WikiHelp wikiPath={ROUTES.TRANSFER_BAPTISM_HELP} />}
        </div>
        <div className="row">
          <div className="col-xl-12 text-right">
            <form>
              <Panel>
                <PanelHeader noButton />
                <PanelBody>
                  {this.createInput(branchLabel, 'branchLabel', I18n.t('BEE145' /* Filial */), '', 'text', true, true)}
                  {this.createInput(typeName, 'typeName', I18n.t('BEE200' /* Tipo */), '', 'text', true, true)}
                  {this.createInput(
                    lastBaptism,
                    'lastBaptism',
                    I18n.t('BEE1266' /* Último número de batismo */),
                    '',
                    'number',
                    true,
                    true,
                  )}
                  {this.createInput(
                    FormatHelpers.formatNumber(quantity, 0, false, true),
                    'quantity',
                    I18n.t('BEE1267' /* Quantidade a gerar */),
                    '',
                    'number',
                    true,
                    !!((branchCode === '' && branchName === '')),
                  )}
                </PanelBody>
                <PanelFooter>
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-white p-5 m-5"
                    onClick={() => {
                      (branchCode === '' && branchName === '')
                        ? this.callNotification() : this.setState({ showPrintDialog: true });
                    }}
                  >
                    {I18n.t('BEE1269' /* Reimprimir */)}
                  </OneClickButton>
                  <OneClickButton
                    type="button"
                    className="btn btn-120 btn-primary p-5 m-5"
                    onClick={() => {
                      (branchCode === '' && branchName === '')
                        ? this.callNotification()
                        : this.printConfirmation();
                    }}
                  >
                    {I18n.t('BEE1255' /* Gerar Batismo */)}
                  </OneClickButton>
                </PanelFooter>
              </Panel>
            </form>
            {(showPrintDialog && (
            <SweetAlert
              info
              showCancel
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnText={I18n.t('BEE1270' /* Imrpimir */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1269' /* Reimprimir */)}
              onConfirm={() => this.showPrinterDialog(true)}
              onCancel={() => this.setState({ showPrintDialog: false, printStart: 0, printEnd: 0 })}
              style={{ width: '300px !important' }}
            >
              <Form
                noPanel
                setValue={this.setValue}
                inputs={() => ([
                  this.createInputPrint(
                    printStart,
                    'printStart',
                    I18n.t('BEE2456' /* Informe o Batismo que deseja reimprimir */),
                    `${I18n.t('BEE1266' /* Último Número de Batismo */)} - ${lastBaptism}`,
                  ),
                ])}
              />
            </SweetAlert>
            ))}
            {(showPrinterDialog && (
            <SweetAlert
              showCancel
              confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
              confirmBtnBsStyle="primary"
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              cancelBtnBsStyle="default"
              title={I18n.t('BEE1324' /* Selecionar Impressora */)}
              onConfirm={(reprint) ? this.printBaptism : this.generateBaptism}
              onCancel={() => this.setState({ showPrinterDialog: false })}
            >
              {this.createSelect(printerSelected, 'printerSelected', I18n.t('BEE328' /* Impressora */), printerOptions)}
            </SweetAlert>
            ))}
            {(showPrintConfirmation && (
            <SweetAlert
              warning
              showCancel
              confirmBtnText={I18n.t('BEE1800' /* Prosseguir */)}
              cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
              confirmBtnBsStyle="primary"
              cancelBtnBsStyle="danger"
              title={I18n.t('BEE1975' /* Verificação de Quantidade */)}
              onConfirm={() => this.showPrinterDialog(false)}
              onCancel={() => this.setState({ showPrintConfirmation: false })}
            >
              <h5>
                {I18n.t('BEE1974', { 0: quantity } /* A quantidade informada é %{0}. Deseja prosseguir? */)}
              </h5>
            </SweetAlert>
            )
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
});

const mapDispatchToProps = (dispatch) => ({
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  getBaptismInfo: (type) => dispatch(getBaptismInfo(type)),
  generateBaptism: (type, branchCode, quantity) => dispatch(generateBaptism(type, branchCode, quantity)),
  printBaptismLabel: (
    printerCode,
    baptismStart,
    baptismEnd,
    branchCode,
    typeBaptism,
  ) => dispatch(printBaptismLabel(printerCode, baptismStart, baptismEnd, branchCode, typeBaptism)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(BaptismForm));
