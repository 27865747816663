import CarriersRequests from '../../server/carriers';
import { addLoading, removeLoading } from './loading';

export const setCarriers = (carriers) => ({
  type: 'SET_CARRIERS',
  carriers,
});

export const getCarriersList = (tableParams = {}) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const carriers = await CarriersRequests.getCarriers(tableParams);
    dispatch(setCarriers(carriers ? carriers.rows : []));
    dispatch(removeLoading());
    return carriers || 0;
  } catch (e) {
    dispatch(removeLoading());
    return 0;
  }
};

export const createCarrier = (carrier) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const newCarrier = await CarriersRequests.createCarrier(carrier);
    return newCarrier;
  } finally {
    dispatch(removeLoading());
  }
};

export const updateCarrier = (carrier) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CarriersRequests.updateCarrier(carrier);
  } finally {
    dispatch(removeLoading());
  }
};

export const getCarrier = (carrierId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CarriersRequests.getCarrier(carrierId);
  } finally {
    dispatch(removeLoading());
  }
};

export const deleteCarrier = (carrierId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    return await CarriersRequests.deleteCarrier(carrierId);
  } finally {
    dispatch(removeLoading());
  }
};
