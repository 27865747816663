import React from 'react';
import { PopoverHeader, UncontrolledPopover } from 'reactstrap';

class WikiHelp extends React.PureComponent {
  render() {
    const { wikiPath = '' } = this.props;
    return (
      <div className="mb-3 text-grey">
        <a
          href={`http://wiki.szsolucoes.com.br/wiki/doku.php/beestock${wikiPath}`}
          rel="noopener noreferrer"
          target="_blank"
          id="popoverClickWiki"
          style={{ color: '#f17b1e', marginLeft: '5px' }}
        >
          <i className="fa fa-question-circle" />
          <UncontrolledPopover trigger="hover" placement="top" target="popoverClickWiki">
            <PopoverHeader>
              SZ Soluções Wiki
            </PopoverHeader>
          </UncontrolledPopover>
        </a>
      </div>
    );
  }
}

export default WikiHelp;
