import React from 'react';
import { withRouter } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';

import OneClickButton from '../../../components/form/button';
import { Panel, PanelBody } from '../../../components/panel/panel';
import { PanelPage } from '../../../components/pages/pages';
import addNotification from '../../../components/notification';
import { getUserPrinterOptions } from '../../../app/store/actions/printers';
import { printEanLabel } from '../../../app/store/actions/labels';
import { outboundWavesProductEan } from '../../../app/store/actions/outboundWaves';
import { confirmPick, outboundSerialLabel } from '../../../app/store/actions/outboundOrdersPicking';

import ProductsList from './productsList';

class PickingWave extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPrinterDialog: false,
      showWarningDialog: false,
      printerOptions: [],
      printerSelected: '',
      waveNumber: '',
      orderNumber: '',
      totalLines: '',
      pendingLines: '',
      customerOrderCode: '',
      customerName: '',
      transportCodName: '',
      orderType: '',
      serialLabelControl: false,
      fractionalLabelControl: false,
      suggestedAddress: '',
      baptismLabel: '',
      storageAddressSelected: '',
      product: '',
      productCode: '',
      unitMeasure: '',
      lotNumber: '',
      description: '',
      qtyOrdered: '',
      ean: '',
      serialCode: '',
      qtyPicked: 0,
      stickerQty: 1,
      validationEan: false,
      validationSerialLabel: false,
      productSelect: {},
      outboundRangeProductNote: '',
      showModalNote: false,
      title: I18n.t('BEE1446' /* Separação por Onda */),
      serialLabelId: null,
      serialGroupId: null,
      serialLabel: {},
    };
  }

  async componentDidMount() {
    if (this.props.pickingDocument) {
      this.setState({
        title: I18n.t('BEE1447' /* Separação por Documento */),
      });
    }

    await this.selectProduct();

    const printerReq = await this.props.getUserPrinterOptions();
    const { printerOptions, userPrinter } = printerReq;

    this.setState({
      printerOptions,
      printerSelected: userPrinter || '',
    });
  }

  resetForm = () => {
    this.setState({
      showPrinterDialog: false,
      printerOptions: [],
      printerSelected: '',
      waveNumber: '',
      orderNumber: '',
      totalLines: '',
      pendingLines: '',
      customerOrderCode: '',
      customerName: '',
      transportCodName: '',
      orderType: '',
      serialLabelControl: false,
      fractionalLabelControl: false,
      suggestedAddress: '',
      baptismLabel: '',
      storageAddressSelected: '',
      product: '',
      productCode: '',
      unitMeasure: '',
      lotNumber: '',
      description: '',
      qtyOrdered: '',
      ean: '',
      serialCode: '',
      qtyPicked: 0,
      stickerQty: 1,
      validationEan: false,
      validationSerialLabel: false,
      serialLabelId: null,
      serialGroupId: null,
      serialLabel: {},
    });
  };

  setValue = async (attr, value) => {
    if (attr === 'ean') {
      await this.setState({
        [`${attr}`]: value,
        validationEan: false,
      });
    } else if (attr === 'stickerQty') {
      if (parseInt(value, 10) >= 1) {
        await this.setState({
          [`${attr}`]: value,
        });
      } else if (value === '') {
        await this.setState({
          [`${attr}`]: 1,
        });
      }
    } else if (attr === 'serialCode') {
      await this.setState({
        [`${attr}`]: value,
        validationSerialLabel: false,
      });
    } else {
      await this.setState({
        [`${attr}`]: value,
      });
    }
  };

  keypressConfirmPicking = (e) => {
    if (e.keyCode === 13) this.confirmPicking();
  };

  goToElement = (e, id, attr) => {
    const { serialLabelControl } = this.state;
    if (e.keyCode === 13) {
      if (attr === 'ean') {
        this.scanEan();
      } else if (attr === 'serialCode') {
        this.scanSerial();
      } else if (attr === 'baptismLabel') {
        document.getElementById(serialLabelControl ? id + 2 : id + 1).focus();
        document.getElementById(serialLabelControl ? id + 2 : id + 1).select();
      }
    }
  };

  createSelect = (value, attr, label, items) => (
    <div className="form-group p-2">
      <span>{label}</span>
      <select
        className="form-control"
        value={value || ''}
        onChange={(e) => this.setValue(attr, e.target.value)}
      >
        {items.map((item) => (
          <option key={item.value} value={item.value}>{item.label}</option>
        ))}
      </select>
    </div>
  );

  createInput = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
  ) => (
    <div className="form-group pt-2 text-left">
      <span>{label}</span>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        {!disabled && (
          <div className="input-group-append">
            <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, '')}>
              <i className="fa fa-times" />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  onClickClearInput = (attr) => {
    this.setValue(attr, '');

    if (attr === 'serialCode') {
      this.setState({
        validationSerialLabel: false,
        qtyPicked: 0,
        storageAddressSelected: '',
        serialLabelId: null,
        serialGroupId: null,
        serialLabel: {},
      });
    }
  };

  createInputButton = (
    value,
    attr,
    label,
    placeholder,
    type = 'text',
    required,
    disabled,
    keypressFunction = undefined,
    id = null,
    buttonLabel,
    buttonFunction,
  ) => (
    <div className="form-group p-2 text-left">
      <span>{label}</span>
      <div className="input-group">
        <input
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          type={type}
          className="form-control"
          value={value || ''}
          onChange={(e) => this.setValue(attr, e.target.value)}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
        />
        <div className="input-group-append">
          <button type="button" className="btn btn-secondary" onClick={() => this.onClickClearInput(attr)}>
            <i className="fa fa-times" />
          </button>
          {buttonLabel && (
            <button type="button" className="btn btn-primary" onClick={() => buttonFunction()}>
              {buttonLabel}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  createNumberFormat = (value, attr, label, decimals, disabled = false, keypressFunction = undefined, id = null) => (
    <div className="form-group p-2 text-left">
      <span>{label}</span>
      <div className="input-group">
        <NumberFormat
          onKeyDown={keypressFunction && ((e) => keypressFunction(e, id, attr))}
          id={id && id}
          className="form-control"
          thousandSeparator="."
          decimalSeparator=","
          decimalScale={(!this.props.fractionalQuantity) ? 0 : decimals}
          value={value || ''}
          onValueChange={(values) => {
            this.setValue(attr, values.floatValue);
          }}
          fixedDecimalScale
          defaultValue={0}
          disabled={disabled}
          allowEmptyFormatting
        />
        {!disabled && (
          <div className="input-group-append">
            <button type="button" className="btn btn-secondary" onClick={() => this.setValue(attr, 0)}>
              <i className="fa fa-times" />
            </button>
          </div>
        )}
      </div>
    </div>
  );

  createTextArea = (value, attr, label, placeholder, rows, required, disabled) => (
    <div className="form-group p-2 text-left mt-2">
      <span>{label}</span>
      <div className="input-group">
        <textarea
          className="form-control"
          rows={rows}
          value={value || ''}
          placeholder={disabled ? '' : placeholder}
          required={required}
          disabled={disabled}
          onChange={(e) => this.setValue(attr, e.target.value)}
        />
      </div>
    </div>
  );

  scanEan = async () => {
    const { ean, productSelect } = this.state;

    if (ean === '') {
      addNotification(
        'danger',
        this.state.title,
        I18n.t('BEE980' /* Código EAN deve ser informado */),
        'top-right',
      );
      document.getElementById(3).focus();
    } else {
      try {
        const ProductEan = await this.props.outboundWavesProductEan(ean, productSelect.productCode);

        if (ProductEan) {
          document.getElementById(4).focus();
          document.getElementById(4).select();

          this.setState({ validationEan: true });
          this.modalNoteOpening(productSelect.outboundRangeProductNote);
        } else {
          addNotification(
            'danger',
            this.state.title,
            I18n.t(
              'BEE979',
              { 0: ean }, /* O produto com o EAN %{0} não foi encontrado ! */
            ),
            'top-right',
          );
          document.getElementById(3).focus();
        }
      } catch (error) {
        const messageError = error.response && error.response.data
          && error.response.data.error && error.response.data.error.message;

        if (messageError) {
          addNotification(
            'danger',
            this.state.title,
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            this.state.title,
            I18n.t('BEE1842' /* Erro ao buscar documento para separação */),
            'top-right',
          );
        }
      }
    }
  };

  scanSerial = async () => {
    const {
      serialCode, productSelect, qtyOrdered, fractionalLabelControl,
    } = this.state;

    if (!serialCode) {
      addNotification(
        'danger',
        this.state.title,
        I18n.t('BEE2982' /* Etiqueta não informada */),
        'top-right',
      );
      document.getElementById(3).focus();
    } else {
      try {
        const serialLabel = await this.props.outboundSerialLabel(serialCode, productSelect.outboundRangeId);

        if (!serialLabel || serialLabel.success === false || serialLabel.data === null) {
          addNotification(
            'danger',
            this.state.title,
            I18n.t('BEE3006', { 0: serialCode } /* Etiqueta %{0} não foi localizada! */),
            'top-right',
          );
          document.getElementById(3).focus();
        } else if (qtyOrdered < serialLabel.quantity && !fractionalLabelControl) {
          addNotification(
            'danger',
            this.state.title,
            I18n.t('BEE3007' /* A quantidade da etiqueta é superior à solicitada! */),
            'top-right',
          );
          document.getElementById(3).focus();
        } else if (fractionalLabelControl && !serialLabel.serialGroup) {
          addNotification(
            'danger',
            this.state.title,
            I18n.t('BEE3244'),
            'top-right',
            /* Não é possível realizar a separação fracionada com uma etiqueta
            agrupadora, por favor informe uma etiqueta seriada! */
          );
        } else if (serialLabel.quantity <= 0) {
          addNotification(
            'danger',
            this.state.title,
            I18n.t('BEE3009', { 0: serialCode } /* A Etiqueta %{0} está sem saldo! */),
            'top-right',
          );
          document.getElementById(3).focus();
        } else {
          await this.selectProduct(serialLabel.productCode, serialLabel.lotNumber, serialLabel.expirationDate);

          this.setState({
            serialLabel,
            serialCode,
            validationSerialLabel: true,
            qtyPicked: qtyOrdered < serialLabel.quantity && fractionalLabelControl
              ? parseFloat(qtyOrdered)
              : parseFloat(serialLabel.quantity),
            serialLabelId: serialLabel.serialGroup ? serialLabel.id : null,
            serialGroupId: !serialLabel.serialGroup ? serialLabel.id : null,
            storageAddressSelected: serialLabel.serialGroup
              ? serialLabel.serialGroup.balance && serialLabel.serialGroup.balance.addressCode
              : serialLabel.balance && serialLabel.balance.addressCode,
          });

          this.modalNoteOpening(productSelect.outboundRangeProductNote);
        }
      } catch (error) {
        const messageError = error.response && error.response.data
          && error.response.data.error && error.response.data.error.message;

        if (messageError) {
          addNotification(
            'danger',
            this.state.title,
            messageError,
            'top-right',
          );
        } else {
          addNotification(
            'danger',
            this.state.title,
            I18n.t('BEE1842' /* Erro ao buscar documento para separação */),
            'top-right',
          );
        }
      }
    }
  };

  selectProduct = async (productCode = '', lotNumber = '', expirationDate = '') => {
    const { productsList } = this.props;
    const { baptismLabel } = this.state;

    let check = true;

    productsList.forEach(async (element) => {
      if (element.status < 4 && check) {
        if (!productCode || productCode === element.productCode) {
          if (!lotNumber || lotNumber === element.lotNumber) {
            if (!expirationDate || expirationDate === element.expirationDate) {
              check = false;

              const pendingLines = productsList.filter((elem) => elem.status < 4);
              await this.setState({
                waveNumber: element.waveNumber,
                orderNumber: element.orderNumber,
                totalLines: productsList.length,
                pendingLines: pendingLines.length,
                customerOrderCode: element.customerOrderCode,
                customerName: element.customer,
                transportCodName: element.transportCodName,
                orderType: element.orderType,
                serialLabelControl: element.serialControl,
                fractionalLabelControl: element.fractionalLabel,
                suggestedAddress: element.addressCode,
                product: `${element.productCode} - ${element.productName}`,
                unitMeasure: element.unit,
                lotNumber: element.lotNumber,
                description: element.productFullName,
                qtyOrdered: element.quantity,
                productCode: element.productCode,
                productSelect: element,
                baptismLabel: element.baptismLabel || baptismLabel,
              });
            }
          }
        }
      } else if (check) {
        this.resetForm();
      }
    });
  };

  modalNoteOpening = (outboundRangeProductNote = '') => {
    if (outboundRangeProductNote) {
      this.setState({
        showModalNote: true,
        outboundRangeProductNote,
      });
    }
  };

  showPrinterDialog = async () => {
    const { printerOptions } = this.state;
    if (!printerOptions || printerOptions.length === 0) {
      addNotification(
        'danger',
        this.state.title,
        I18n.t('BEE1415' /* Usuário não possui impressora relacionada */),
        'top-right',
      );
    } else if (printerOptions && printerOptions.length > 1) {
      this.setState({
        showPrinterDialog: true,
      });
    } else if (printerOptions && printerOptions.length === 1) {
      await this.setState({
        printerSelected: printerOptions[0].value,
      });
      this.printLabel();
    }
  };

  printLabel = async () => {
    const {
      printerSelected, printerOptions, ean, stickerQty,
    } = this.state;

    if (stickerQty > 0) {
      await this.props.printEanLabel(ean, printerSelected || printerOptions[0].value, '', stickerQty);

      addNotification(
        'success',
        this.state.title,
        I18n.t('BEE1427' /* Etiquetas enviadas para impressora ! */),
        'top-right',
      );

      this.setState({ showPrinterDialog: false });
    } else {
      addNotification(
        'danger',
        this.state.title,
        I18n.t('BEE989' /* Informe a quantidade de etiquetas */),
        'top-right',
      );
      this.setState({ showPrinterDialog: false });
    }
  };

  confirmDivergence = () => {
    this.setState({
      showWarningDialog: false,
    });
    this.confirmPickingSubmit();
  };

  confirmPicking = async () => {
    const {
      productSelect, baptismLabel, storageAddressSelected, qtyPicked, ean, validationEan,
      validationSerialLabel, serialCode, serialLabelControl, serialGroupId, serialLabelId,
      serialLabel,
    } = this.state;

    if (!baptismLabel) {
      addNotification(
        'danger',
        this.state.title,
        I18n.t('BEE1076' /* Informe o Batismo */),
        'top-right',
      );
      document.getElementById(1).focus();
      document.getElementById(1).select();
    } else if (!storageAddressSelected) {
      addNotification(
        'danger',
        this.state.title,
        I18n.t('BEE1845' /* Informe a localização */),
        'top-right',
      );
      document.getElementById(2).focus();
      document.getElementById(2).select();
    } else if (storageAddressSelected.toLocaleUpperCase() !== productSelect.addressCode.toLocaleUpperCase()) {
      addNotification(
        'danger',
        this.state.title,
        I18n.t('BEE1604' /* Endereço Retirada diferente do Endereço Sugerido */),
        'top-right',
      );
      document.getElementById(2).focus();
      document.getElementById(2).select();
    } else if (!serialLabelControl && (!ean || !validationEan)) {
      addNotification(
        'danger',
        this.state.title,
        I18n.t('BEE1147' /* Informe o EAN do Produto */),
        'top-right',
      );
      document.getElementById(3).focus();
      document.getElementById(3).select();
    } else if (serialLabelControl && !serialCode && !validationSerialLabel && !serialGroupId && !serialLabelId) {
      addNotification(
        'danger',
        this.state.title,
        I18n.t('BEE2982' /* Etiqueta não informada */),
        'top-right',
      );
      document.getElementById(3).focus();
      document.getElementById(3).select();
    } else if ((qtyPicked < 0) || (this.state.qtyPicked > productSelect.quantity)) {
      addNotification(
        'danger',
        this.state.title,
        (this.state.qtyPicked > productSelect.quantity)
          ? I18n.t('BEE2338' /* Quantidade superior a solicitada */)
          : I18n.t('BEE1846' /* Informe a quantidade separada */),
        'top-right',
      );
      document.getElementById(4).focus();
      document.getElementById(4).select();
    } else if (serialLabelControl && ((serialLabel.quantity || 0) - qtyPicked < 0)) {
      addNotification(
        'danger',
        this.state.title,
        I18n.t(
          'BEE3011',
          { 0: serialCode, 1: parseFloat(serialLabel.quantity) },
          /* Saldo da etiqueta %{0} insuficiente! %{1} disponível. */
        ),
        'top-right',
      );
      document.getElementById(3).focus();
    } else if (qtyPicked < productSelect.quantity && !serialLabelControl) {
      this.setState({ showWarningDialog: true });
    } else {
      this.confirmPickingSubmit();
    }
  };

  confirmPickingSubmit = async () => {
    const { productsList } = this.props;
    const {
      productSelect,
      baptismLabel,
      storageAddressSelected,
      qtyPicked,
      serialGroupId,
      serialLabelId,
      serialLabelControl,
    } = this.state;

    try {
      const result = await this.props.confirmPick(
        productSelect.outboundRangeProductId,
        qtyPicked,
        baptismLabel,
        storageAddressSelected.toLocaleUpperCase(),
        serialGroupId,
        serialLabelId,
        serialLabelControl,
      );

      if (result && result.success === false) {
        addNotification(
          'danger',
          this.state.title,
          I18n.t('BEE2339' /* Erro ao efetivar a separação do produto */),
          'top-right',
        );
      } else {
        const pendingLines = productsList.filter((elem) => elem.status < 4);
        await this.props.selectPage();

        if (pendingLines.length === 1) {
          addNotification(
            'success',
            this.state.title,
            I18n.t('BEE2360' /* Todos os produtos da onda foram separados! */),
            'top-right',
          );
        } else {
          addNotification(
            'success',
            this.state.title,
            I18n.t('BEE2340' /* Separação do produto efetuada com sucesso */),
            'top-right',
          );
        }

        this.resetForm();
        await this.selectProduct();
      }
    } catch (error) {
      const messageError = error.response && error.response.data
        && error.response.data.error && error.response.data.error.message;
      if (messageError) {
        addNotification(
          'danger',
          this.state.title,
          messageError,
          'top-right',
        );
      } else {
        addNotification(
          'danger',
          this.state.title,
          I18n.t('BEE1121' /* Erro ao gravar a separação */),
          'top-right',
        );
      }
    }
  };

  render() {
    const {
      waveNumber, orderNumber, totalLines, pendingLines, customerOrderCode, customerName, transportCodName, orderType,
      suggestedAddress, baptismLabel, product, unitMeasure, lotNumber, description, storageAddressSelected, qtyOrdered,
      ean, qtyPicked, stickerQty, showPrinterDialog, printerSelected, printerOptions, validationEan, showWarningDialog,
      outboundRangeProductNote, showModalNote, productCode, serialLabelControl, validationSerialLabel, serialCode,
      fractionalLabelControl,
    } = this.state;

    const { pickingDocument } = this.props;

    return (
      <>
        <PanelPage
          noButton
          content={(
            <>
              <div className="row">
                {!pickingDocument
                  && (
                    <div className="col-md-3">
                      {
                        this.createInput(
                          waveNumber,
                          'waveNumber',
                          I18n.t('BEE1454' /* Onda */),
                          '',
                          'text',
                          false,
                          true,
                        )
                      }
                    </div>
                  )}
                <div className={pickingDocument ? 'col-md-3' : 'col-md-2'}>
                  {this.createInput(
                    totalLines,
                    'totalLines',
                    I18n.t('BEE2334' /* Total de Linhas */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className={pickingDocument ? 'col-md-3' : 'col-md-2'}>
                  {this.createInput(
                    pendingLines,
                    'pendingLines',
                    I18n.t('BEE1090' /* Linhas pendentes */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createInput(
                    orderNumber,
                    'orderNumber',
                    I18n.t('BEE2333' /* Documento Saída */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className={pickingDocument ? 'col-md-3' : 'col-md-2'}>
                  {this.createInput(
                    customerOrderCode,
                    'customerOrderCode',
                    I18n.t('BEE1808' /* Pedido Cliente */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {this.createInput(
                    customerName,
                    'customerName',
                    I18n.t('BEE50' /* Cliente */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-5">
                  {this.createInput(
                    transportCodName,
                    'transportCodName',
                    I18n.t('BEE301' /* Transportadora */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-1">
                  {this.createInput(
                    orderType,
                    'orderType',
                    I18n.t('BEE200' /* Tipo */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {this.createInput(
                    suggestedAddress,
                    'suggestedAddress',
                    I18n.t('BEE267' /* Endereço */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-6">
                  {this.createInput(
                    baptismLabel,
                    'baptismLabel',
                    I18n.t('BEE425' /* Batismo */),
                    '',
                    'text',
                    false,
                    false,
                    this.goToElement,
                    1,
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {this.createInput(product, 'product', I18n.t('BEE225' /* Produto */), '', 'text', false, true)}
                </div>
                <div className="col-md-3">
                  {this.createInput(
                    unitMeasure,
                    'unitMeasure',
                    I18n.t('BEE1756' /* Unidade de Medida */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
                <div className="col-md-3">
                  {this.createInput(lotNumber, 'lotNumber', I18n.t('BEE428' /* Lote */), '', 'text', false, true)}
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  {this.createTextArea(
                    description,
                    'description',
                    I18n.t('BEE277' /* Descrição */),
                    '',
                    3,
                    false,
                    true,
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  {this.createInput(
                    storageAddressSelected ? storageAddressSelected.toLocaleUpperCase() : '',
                    'storageAddressSelected',
                    I18n.t('BEE495' /* Endereço Retirada */),
                    '',
                    'text',
                    false,
                    serialLabelControl,
                    this.goToElement,
                    2,
                  )}
                </div>
                <div className="col-md-6">
                  {this.createInput(
                    qtyOrdered,
                    'qtyOrdered',
                    I18n.t('BEE441' /* Quantidade */),
                    '',
                    'text',
                    false,
                    true,
                  )}
                </div>
              </div>
              <div className="row">
                {serialLabelControl ? (
                  <div className="col-md-6">
                    {this.createInputButton(
                      serialCode,
                      'serialCode',
                      I18n.t('BEE2765' /* Etiqueta */),
                      '',
                      'text',
                      false,
                      validationSerialLabel,
                      this.goToElement,
                      3,
                      false,
                      false,
                    )}
                  </div>
                ) : (
                  <div className="col-md-6">
                    {this.createInputButton(
                      ean,
                      'ean',
                      I18n.t('BEE377' /* EAN */),
                      '',
                      'text',
                      false,
                      validationEan,
                      this.goToElement,
                      3,
                      false,
                      false,
                    )}
                  </div>
                )}

                <div className="col-md-6">
                  {this.createNumberFormat(
                    qtyPicked,
                    'qtyPicked',
                    I18n.t('BEE1134' /* Quantidade separada */),
                    3,
                    serialLabelControl && !fractionalLabelControl,
                    this.keypressConfirmPicking,
                    4,
                  )}
                </div>
              </div>
              {
                !this.state.serialLabelControl
                && (
                  <div className="row">
                    <div className="col-4">
                      {(validationEan || validationSerialLabel) && this.createInputButton(
                        stickerQty,
                        'stickerQty',
                        I18n.t('BEE426' /* Qtde Etiquetas */),
                        I18n.t('BEE989' /* Informe a quantidade de etiquetas */),
                        'number',
                        false,
                        false,
                        this.goToElement,
                        0,
                        <i className="fa fa-print" />,
                        this.showPrinterDialog,
                      )}
                    </div>
                  </div>
                )
              }
              {(showPrinterDialog
                && (
                  <SweetAlert
                    confirmBtnText={I18n.t('BEE436' /* Selecionar */)}
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="default"
                    title={I18n.t('BEE1324' /* Selecionar Impressora */)}
                    onConfirm={() => this.printLabel()}
                    onCancel={() => this.setState({ showPrinterDialog: false })}
                  >
                    <div className="mt-4">
                      {this.createSelect(
                        printerSelected,
                        'printerSelected',
                        I18n.t('BEE328' /* Impressora */),
                        printerOptions,
                      )}
                    </div>
                  </SweetAlert>
                )
              )}
              {(showWarningDialog
                && (
                  <SweetAlert
                    warning
                    showCancel
                    cancelBtnText={I18n.t('BEE99' /* Cancelar */)}
                    confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="default"
                    title={I18n.t('BEE1847' /* Quantidade Divergente */)}
                    onConfirm={() => this.confirmDivergence()}
                    onCancel={() => this.setState({ showWarningDialog: false })}
                  >
                    {
                      I18n.t(
                        'BEE1848', /* A quantidade separada e menor do que a quantidade pedida! deseja continuar ? */
                      )
                    }
                  </SweetAlert>
                )
              )}
              {(showModalNote
                && (
                  <SweetAlert
                    confirmBtnText={I18n.t('BEE100' /* Confirmar */)}
                    confirmBtnBsStyle="primary"
                    title={I18n.t('BEE135' /* Observação */)}
                    onConfirm={() => {
                      this.setState({
                        showModalNote: false,
                        outboundRangeProductNote: '',
                      });
                    }}
                    onCancel={() => {
                      this.setState({
                        showModalNote: false,
                        outboundRangeProductNote: '',
                      });
                    }}
                  >
                    <>
                      <div className="row">
                        <div className="col-6">
                          {this.createInput(
                            orderNumber,
                            'orderNumber',
                            I18n.t('BEE443' /* Documento */),
                            '',
                            'text',
                            false,
                            true,
                          )}
                        </div>
                        <div className="col-6">
                          {this.createInput(
                            productCode,
                            'productCode',
                            I18n.t('BEE1999' /* Código Produto */),
                            '',
                            'text',
                            false,
                            true,
                          )}
                        </div>
                      </div>
                      <div className="text-left pt-2">
                        <span>{I18n.t('BEE135' /* Observação */)}</span>
                        <textarea
                          className="form-control"
                          rows={4}
                          value={outboundRangeProductNote || ''}
                          disabled
                        />
                      </div>
                    </>
                  </SweetAlert>
                ))}
            </>
          )}
          footerContent={(
            (validationEan || validationSerialLabel) && (
              <OneClickButton
                type="button"
                id={5}
                className="btn btn-120 btn-primary p-5 m-5"
                onClick={() => this.confirmPicking()}
              >
                {I18n.t('BEE100' /* Confirmar */)}
              </OneClickButton>
            )
          )}
        />
        <Panel>
          <PanelBody>
            <ProductsList
              products={this.props.productsList}
            />
          </PanelBody>
        </Panel>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  fractionalQuantity: state.app.permissionsCompany && state.app.permissionsCompany.fractionalQuantity,
});

const mapDispatchToProps = (dispatch) => ({
  getUserPrinterOptions: () => dispatch(getUserPrinterOptions()),
  outboundWavesProductEan: (ean, productCode) => dispatch(outboundWavesProductEan(ean, productCode)),
  outboundSerialLabel: (serialCode, outboundRangeId) => dispatch(outboundSerialLabel(
    serialCode,
    outboundRangeId,
  )),
  printEanLabel: (eanCode, printer, filters, amount) => dispatch(printEanLabel(eanCode, printer, filters, amount)),
  confirmPick: (
    outboundRangeProductId,
    pickedQuantity,
    baptismLabel,
    storageAddressPicked,
    serialGroupId,
    serialLabelId,
    serialLabelControl,
  ) => dispatch(confirmPick(
    outboundRangeProductId,
    pickedQuantity,
    baptismLabel,
    storageAddressPicked,
    serialGroupId,
    serialLabelId,
    serialLabelControl,
  )),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PickingWave));
